import { APP_STATE } from '../../../constants/index'
import {
  INPUT_EXCLUSIONS_RECEIVED,
  OUTPUT_EXCLUSIONS_RECEIVED,
  OUTGOING_CHANGED,
} from './actions'

export const initialState = {
  stateOfAction: APP_STATE.FETCHING,
  inputList: [],
  outputList: [],
  incoming: [],
  selectedOutgoing: undefined,
  errorMsg: '',
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATE.FETCHING:
      return {
        ...state,
        stateOfAction: APP_STATE.FETCHING,
      }

    case APP_STATE.ERROR:
      return {
        ...state,
        errorMsg: action.msg,
        stateOfAction: APP_STATE.ERROR,
      }

    case INPUT_EXCLUSIONS_RECEIVED:
      return {
        ...state,
        inputList: action.data,
        stateOfAction: APP_STATE.DONE,
      }

    case OUTPUT_EXCLUSIONS_RECEIVED:
      return {
        ...state,
        outputList: action.data,
        stateOfAction: APP_STATE.DONE,
      }

    case OUTGOING_CHANGED:
      return {
        ...state,
        selectedOutgoing: action.selectedOutgoing,
        stateOfAction: APP_STATE.DONE,
      }
    default:
      return state
  }
}

import axios from 'axios'

export const getToken = axios.CancelToken.source
export const isCancel = err => axios.isCancel(err)
export const notCanceled = fn => {
  return err => {
    if (!isCancel(err)) {
      return fn(err)
    } else {
      console.info(err.message)
    }
  }
}

// Creates a cancelationToken for axios and returns and cleanup effect function
// for when the component is removed
export const cancelable = fn => {
  const signal = getToken()
  fn(signal)
  return () => signal.cancel('An api call was canceled')
}

export default {
  getToken,
  isCancel,
  cancellable: cancelable,
  notCanceled,
}

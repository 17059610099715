import styled from 'styled-components'

import { MAX_CONTENT_WIDTH, LAPTOP_WIDTH } from '../../constants'

const ColumnGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 30px;
  max-width: ${MAX_CONTENT_WIDTH};

  @media (max-width: ${LAPTOP_WIDTH}) {
    grid-template-columns: 1fr;
  }
`

export default ColumnGridWrapper

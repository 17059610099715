import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const AUDIT_FETCHING = 'AUDIT_FETCHING'
export const AUDIT_DONE = 'AUDIT_DONE'
export const AUDIT_FAILED = 'AUDIT_FAILED'

const requestAuditList = () => ({
  type: AUDIT_FETCHING,
})

const receiveAuditList = (auditList, pageTotal, page) => ({
  type: AUDIT_DONE,
  auditList,
  pageTotal,
  page,
})

const fetchFailed = message => ({
  type: AUDIT_FAILED,
  message,
})

export async function createNewFeedAuditFile(
  organisationId,
  companyName = '',
  stringData,
  dispatch,
  page,
  t,
  filteredOrganisation
) {
  const newGuid = crypto.randomUUID()
  const date = Date.now()
  const datestring = new Date(date)
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, '')

  const dataName = `data_${companyName}_${datestring}_${newGuid}.csv`
  const infoName = `info_${companyName}_${datestring}_${newGuid}.json`
  const infoFile = createInfoFile(newGuid)

  api
    .post(`/orgs/${organisationId}/feed_audit/file/${dataName}`, stringData, {
      headers: { 'Content-Type': 'text/csv' },
    })
    .then(() => {
      return api
        .post(`/orgs/${organisationId}/feed_audit/file/${infoName}`, infoFile, {
          headers: { 'Content-Type': 'text/csv' },
        })
        .then(() => {
          return api.post(
            `/orgs/${organisationId}/feed_audit/${dataName}/${companyName}/${newGuid}`
          )
        })
    })
    .catch(error => {
      console.error('Error during service worker registration:', error)
      toast.error(t('feed_audit_fetch_unsuccessful'))
    })

  // addAuditToFileShare(organisationId, stringData, dataName, infoFile, infoName)
}

const createInfoFile = newGuid => {
  return `{
    "targetCountry": "GB",
    "googleCategoryLanguage": "GB",
    "verticalConfigName": "default",
    "type": "full",
    "firstName": null,
    "lastName": null,
    "company": "Summit",
    "email": "",
    "telephoneNumber": "",
    "salesPerson": "",
    "hash": "${newGuid}",
    "separator": ","
  }
  `
}

// async function addAuditToFileShare(
//   organisationId,
//   stringData,
//   dataName,
//   infoFile,
//   infoName,
// ){
//   console.log("Data name:"+dataName)
//   console.log("Info file:"+infoFile)
//   api
//     .post(`/orgs/${organisationId}/feed_audit/file/${dataName}`, stringData, {
//       headers: { 'Content-Type': 'text/csv' },
//     })
//     .then(() => {
//       return api.post(
//         `/orgs/${organisationId}/feed_audit/file/${infoName}`,
//         infoFile,
//         {
//           headers: { 'Content-Type': 'text/csv' },
//         }
//       )
//     })
//     .catch(error => {
//       console.error('Error during service worker registration:', error)
//     })
// }

export const fetchAuditList = (
  organisationId,
  dispatch,
  page = 0,
  t,
  filteredOrganisation
) => {
  dispatch(requestAuditList())

  const auditUrl = `/orgs/${filteredOrganisation ||
    organisationId}/feed_audit?page=1&limit=50`

  api
    .get(auditUrl)
    .then(({ data, headers: { fm_pagination_total } }) => {
      const pageTotal = Math.ceil(fm_pagination_total / 10)
      dispatch(receiveAuditList(data, pageTotal, page))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
      toast.error(t('job_detail_fetch_unsuccessful'))
    })
}

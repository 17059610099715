import React from 'react'
import styled from 'styled-components'
import { any, string } from 'prop-types'

import { BLOCK_PADDING_REGULAR } from '../../../constants'

const BlockRow = styled.div`
  padding: ${({ padding }) => padding || BLOCK_PADDING_REGULAR};

  ${({ border }) => border && `border-${border}: solid 1px lightgray`}
`

const Row = ({ children, border, padding }) => {
  return (
    <BlockRow border={border} padding={padding}>
      {children}
    </BlockRow>
  )
}

Row.propTypes = {
  padding: string,
  children: any,
  border: string,
}

export default Row

export const countryFormats = [
  {
    id: 'en-GB',
    name: 'United Kingdom',
    dateFormat: 'DD/MM/YYYY HH:mm (UTCZ)',
  },
  { id: 'cz-CS', name: 'Czechia', dateFormat: 'DD. MM. YYYY HH:mm (UTCZ)' },
  { id: 'de-DE', name: 'Germany', dateFormat: 'DD. MM. YYYY HH:mm (UTCZ)' },
  { id: 'fr-FR', name: 'France', dateFormat: 'DD/MM/YYYY HH:mm (UTCZ)' },
]

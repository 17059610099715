import styled from 'styled-components'

import {
  HEADER_HEIGHT,
  LAPTOP_WIDTH,
  LIGHT_GREY,
  SHADOW_GREY,
  WHITE,
} from '../../../constants'

const HeaderRow = styled.div`
  background-color: ${WHITE};
  border-bottom: 1px solid ${LIGHT_GREY};
  box-shadow: ${SHADOW_GREY};
  height: ${HEADER_HEIGHT};
  padding: 0 60px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    padding: 0;
  }
`

export default HeaderRow

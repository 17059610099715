import { APP_STATE } from '../../../constants'
import {
  EXCLUSION_RECEIVED,
  EXCLUSION_FILE_RECEIVED,
  EXCLUSION_UPDATED,
  EXCLUSION_CLONED,
} from './actions'

export const initialState = {
  stateOfAction: APP_STATE.FETCHING,
  errorMsg: '',
  exclusion: {},
  columns: [],
  files: [],
  isDuplicateOrNew: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATE.FETCHING:
      return {
        ...state,
        stateOfAction: APP_STATE.FETCHING,
      }

    case APP_STATE.ERROR:
      return {
        ...state,
        errorMsg: action.msg,
        stateOfAction: APP_STATE.ERROR,
      }

    case EXCLUSION_RECEIVED:
      return {
        ...state,
        exclusion: {
          ...action.exclusion,
          expressions:
            action.exclusion.expressions &&
            action.exclusion.expressions.length > 0
              ? action.exclusion.expressions
              : [
                  {
                    options: {
                      source: undefined,
                      value: undefined,
                    },
                    type: undefined,
                  },
                ],
        },
        columns: action.columns,
        files: action.files,
        isDuplicateOrNew: action.isDuplicateOrNew,
        stateOfAction: APP_STATE.DONE,
      }

    case EXCLUSION_FILE_RECEIVED:
      return {
        ...state,
        files: action.files,
      }

    case EXCLUSION_CLONED:
      return {
        ...state,
        isDuplicateOrNew: action.isDuplicateOrNew,
        stateOfAction: APP_STATE.DONE,
      }

    case EXCLUSION_UPDATED:
      return {
        ...state,
        exclusion: action.exclusion,
        stateOfAction: APP_STATE.DONE,
      }

    default:
      return state
  }
}

import React from 'react'
import styled from 'styled-components'
import { func, arrayOf, string, bool } from 'prop-types'

import {
  feedShape,
  incomingCompactShape,
  outgoingShape,
  userShape,
} from '../../../types'
import useOrganisations from '../../shared/system/useOrganisations'

import TopNavigation from './TopNavigation'
import FeedPanel from './FeedPanel'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: fixed;
  width: 100%;
`

const Header = ({
  changeFeed,
  changeOutgoing,
  changeIncoming,
  feeds,
  isAdmin,
  isUser,
  logoutUser,
  outgoing,
  incoming,
  pathName,
  rootContextPath,
  selectedFeed,
  selectedOutgoing,
  selectedIncoming,
  showFeedPanel,
  t,
  user,
}) => {
  const { selected, list, setSelected } = useOrganisations()

  const selectedOrganisation = selected
  const organisations = list
  const changeOrganisation = setSelected

  return (
    <Wrapper>
      <TopNavigation
        rootContextPath={rootContextPath}
        t={t}
        isAdmin={isAdmin}
        isUser={isUser}
        logoutUser={logoutUser}
        user={user}
      />
      {showFeedPanel && (
        <FeedPanel
          changeFeed={changeFeed}
          changeOrganisation={changeOrganisation}
          changeOutgoing={changeOutgoing}
          changeIncoming={changeIncoming}
          selectedIncoming={selectedIncoming}
          feeds={feeds}
          organisations={organisations}
          outgoing={outgoing}
          incoming={incoming}
          pathName={pathName}
          selectedFeed={selectedFeed}
          selectedOrganisation={selectedOrganisation}
          selectedOutgoing={selectedOutgoing}
        />
      )}
    </Wrapper>
  )
}

Header.propTypes = {
  changeFeed: func,
  changeOutgoing: func,
  changeIncoming: func,
  feeds: arrayOf(feedShape),
  isAdmin: bool,
  isUser: bool,
  logoutUser: func.isRequired,
  outgoing: arrayOf(outgoingShape),
  incoming: arrayOf(incomingCompactShape),
  pathName: string.isRequired,
  rootContextPath: string,
  selectedFeed: feedShape,
  selectedOutgoing: outgoingShape,
  selectedIncoming: incomingCompactShape,
  showFeedPanel: bool,
  user: userShape,
}

export default Header

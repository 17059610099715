import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import PageDescription from '../../shared/PageDescription'
import Input from '../../shared/Input'
import Button from '../../shared/Button'
import Form from '../../shared/Form'

import useAnnouncements from '../../../hooks/Announcements'

import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 30px 30px 0;
`

const InputLabel = styled.div`
  margin-right: 10px;
`

const WideInput = styled(Input)`
  width: 500px;
`

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  justify-content: flex-end;
`

const Announcements = () => {
  const { t } = useTranslation()
  const annoucements = useAnnouncements()

  const onSave = ({ annoucement }) => {
    annoucements
      .update(annoucement)
      .then(() => {
        toast.success(t`announcement_saved_successful`)
      })
      .catch(err => {
        console.log(t`announcement_saved_error`)
      })
  }

  return (
    <Fragment>
      <PageDescription heading={t`announcements`} />
      <Form
        initialValues={{ annoucement: annoucements.text }}
        onSubmit={onSave}
        content={(errors, values, setFieldValue) => (
          <Fragment>
            <InputWrapper>
              <InputLabel>{t('announcement_text')}</InputLabel>
              <WideInput
                name="annoucement"
                data-id="annoucement-text"
                maximumLength={256}
              />
            </InputWrapper>

            <ButtonWrapper>
              <Button
                data-id="button-source-cancel"
                theme={SECONDARY_BUTTON}
                onClick={() => setFieldValue('annoucement', '')}
              >
                {t('button_clear_one')}
              </Button>
              <Button data-id="button-source-save" type="submit">
                {t('button_save')}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

export default Announcements

import React, { useEffect, useReducer } from 'react'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import Loader from '../../shared/Loader'
import RuleRenderComponent from './RuleRenderComponent'

import { APP_STATE } from '../../../constants'
import { getConditionTree } from '../../../constants/conditionList'
import { getActionTree } from '../../../constants/actionList'

import { reducer, initialState } from './reducer'
import {
  fetchRuleData,
  removeRule,
  createOrUpdateRule,
  updateRule,
  removeCondition,
  addNewCondition,
  updateConditionCaseSensitive,
  updateConditionOperator,
  updateConditionSource,
  updateConditionValue,
  updateConditionType,
  getConditionValue,
  removeAction,
  addNewAction,
  updateActionType,
  updateActionColumn,
  updateActionValue,
  updateActionOptionsType,
  getActionValue,
  updatedRule,
} from './actions'

const RuleDetail = ({
  feedId,
  handleDeleteDialogVisibility,
  isDeleteDialogVisible,
  ruleId,
  organisationId,
  outgoingId,
  location,
  fetchData,
  selectedOutgoing,
  clipboardRule,
}) => {
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    stateOfAction,
    rule,
    inputCols,
    outputCols,
    files,
    isDuplicateOrNew,
  } = state
  const columns = [...outputCols, ...inputCols]

  useEffect(
    () => {
      const parsed = queryString.parse(location.search)
      fetchRuleData(
        feedId,
        ruleId,
        outgoingId,
        organisationId,
        parsed.isDuplicate === 'true' || !ruleId || ruleId === 'new',
        dispatch,
        t,
        clipboardRule
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (selectedOutgoing)
        navigate(location.href.replace(/outgoing\/(.*)/, `outgoing/$1`))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const onDuplicateClick = () => {
    fetchRuleData(feedId, ruleId, outgoingId, organisationId, true, dispatch, t)
  }

  const addNewRule = () => {
    navigate(
      outgoingId
        ? `/${organisationId}/feeds/${feedId}/rules/new/outgoing/${outgoingId}`
        : `/${organisationId}/feeds/${feedId}/rules/new/master`
    )

    fetchRuleData(feedId, 'new', outgoingId, organisationId, false, dispatch, t)
  }

  const updateName = event =>
    dispatch(updatedRule({ ...rule, name: event.target.value }))

  const updateStatus = ({ id }) =>
    dispatch(updatedRule({ ...rule, enabled: id === 'active' }))

  const onRemove = () => {
    removeRule(feedId, ruleId, outgoingId, t).finally(() => {
      fetchData(null, outgoingId)
      navigate(`${organisationId}/feeds/${feedId}/rules`)
    })
    handleDeleteDialogVisibility(false)
  }

  const onSave = () =>
    createOrUpdateRule(
      feedId,
      isDuplicateOrNew ? 'new' : ruleId,
      {
        name: rule.name,
        enabled: rule.enabled,
        conditions: rule.conditions,
        actions: rule.actions,
      },
      outgoingId,
      dispatch,
      t,
      organisationId
    ).finally(() => {
      fetchData(null, outgoingId)
    })

  const onPause = (ruleId, enabled) => {
    updateRule(
      feedId,
      ruleId,
      { enabled: enabled },
      outgoingId,
      dispatch,
      t
    ).finally(() => {
      fetchData(null, outgoingId)
    })
  }

  const updateCaseSensitive = (index, caseSensitive, isAction) => {
    updateConditionCaseSensitive(index, caseSensitive, rule, dispatch, isAction)
  }

  const updateOperator = (index, operator) =>
    updateConditionOperator(index, operator, rule, dispatch)

  const updateSource = (index, source) =>
    updateConditionSource(index, source, rule, dispatch)

  const updateValue = (index, { target, id }) =>
    updateConditionValue(index, target ? target.value : id, rule, dispatch)

  const updateValueType = (index, type) => {
    updateConditionType(index, type, rule, dispatch)
  }

  const onRemoveCondition = index => removeCondition(index, rule, dispatch)

  const onActionTypeChange = (index, operator) =>
    updateActionType(index, operator, rule, dispatch)

  const onActionColumnChange = (index, column) =>
    updateActionColumn(index, column, rule, dispatch)

  const onActionFirstValueChange = (index, { target, id }) =>
    updateActionValue(index, target ? target.value : id, rule, true, dispatch)

  const onActionOptionsTypeChange = (index, value) =>
    updateActionOptionsType(index, value, rule, dispatch, true)

  const onActionSecondOptionsTypeChange = (index, value) =>
    updateActionOptionsType(index, value, rule, dispatch)

  const onActionSecondValueChange = (index, value) => {
    const finalValue = value.target ? value.target.value : value.id
    updateActionValue(index, finalValue, rule, false, dispatch)
  }

  const onActionOperatorChange = (index, { target, id }) =>
    updateActionValue(
      index,
      target ? target.value : id,
      rule,
      true,
      dispatch,
      true
    )

  const onActionRemove = index => removeAction(index, rule, dispatch)

  const getConditionList = (
    inputValue,
    valueChange,
    valueType,
    valueTypeChange
  ) =>
    getConditionTree(
      getFileList(inputValue, valueChange),
      getColumnList(inputValue, valueChange),
      inputValue,
      valueChange,
      valueType,
      valueTypeChange
    )

  const getFileList = (value, onChange) =>
    files.map(({ id, name }) => ({
      id: id,
      name: name,
      render: undefined,
      selectedValue: value,
      onChange: onChange,
    }))

  const getColumnList = (value, onChange) =>
    columns.map(column => ({
      id: column,
      name: column,
      render: undefined,
      onChange: onChange,
      selectedValue: value,
    }))

  const getListOfActions = arrayOfActions =>
    getActionTree(
      getFileList(arrayOfActions[0].value, arrayOfActions[0].function),
      getColumnList(arrayOfActions[0].value, arrayOfActions[0].function),
      arrayOfActions
    )

  const onActionChanges = {
    onActionColumnChange,
    onActionFirstValueChange,
    onActionOperatorChange,
    onActionOptionsTypeChange,
    onActionRemove,
    onActionSecondOptionsTypeChange,
    onActionSecondValueChange,
    onActionTypeChange,
  }

  const updateChanges = {
    updateCaseSensitive,
    updateName,
    updateSource,
    updateOperator,
    updateValue,
    updateValueType,
    updateStatus,
  }

  if (stateOfAction === APP_STATE.FETCHING) return <Loader />

  if (!columns || !files || !rule || stateOfAction === APP_STATE.ERROR)
    return <p>{t('no_data')}</p>
  if (stateOfAction === APP_STATE.DONE && columns && rule) {
    return (
      <RuleRenderComponent
        addNewAction={addNewAction}
        addNewCondition={addNewCondition}
        addNewRule={addNewRule}
        columnList={columns}
        dispatch={dispatch}
        feedId={feedId}
        getActionValue={getActionValue}
        getConditionList={getConditionList}
        getConditionValue={getConditionValue}
        getListOfActions={getListOfActions}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        isDeleteDialogVisible={isDeleteDialogVisible}
        isDuplicateOrNew={isDuplicateOrNew}
        onActionChanges={onActionChanges}
        onRemove={onRemove}
        onRemoveCondition={onRemoveCondition}
        onPause={onPause}
        onSave={onSave}
        onDuplicateClick={onDuplicateClick}
        organisationId={organisationId}
        outputCols={outputCols}
        rule={rule}
        selectedOutgoing={selectedOutgoing}
        t={t}
        updateChanges={updateChanges}
      />
    )
  }
}

export default RuleDetail

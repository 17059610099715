import styled from 'styled-components'
import { MAX_CONTENT_WIDTH, LIGHT_GREY } from '../../constants'

const ContentWrapper = styled.div`
  background: ${LIGHT_GREY};
  padding: 30px 15px;
  max-width: ${MAX_CONTENT_WIDTH};
  margin-top: 15px;
`

export default ContentWrapper

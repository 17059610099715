import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { string, arrayOf } from 'prop-types'

import ContentWrapper from '../shared/ContentWrapper'
import Loader from '../shared/Loader'
import TopButtonsWrapper from '../shared/TopButtonsWrapper'
import PageDescription from '../shared/PageDescription'
import StructuredTable from '../shared/StructuredTable'
import SubTable from './SubTable'
import {
  fetchProcessing,
  fetchJobIncomingOutgoing,
  calculateDuration,
  calculateJobDuration,
  getPercentage,
} from './actions'
import { initialState, reducer } from './reducer'
import {
  WHITE,
  BLUE_GREY,
  LIGHT_ORANGE,
  LIGHT_TRANSPARENT_ORANGE,
} from '../../constants'
import { MainTableColumns } from './TableColumns'
import { brandShape, feedShape } from '../../types'
import ErrorBoundary from '../shared/ErrorBoundary'

const Processing = ({
  brands,
  t,
  organisationId,
  selectedBrand,
  selectedFeed,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [expanded, setExpanded] = useState({})
  const { processing, processingState, pageTotal, page } = state

  useEffect(
    () => {
      fetchProcessing(organisationId, dispatch, selectedBrand, selectedFeed, 0)
    },
    [selectedFeed, selectedBrand, organisationId]
  )

  const onExpandedChange = (newExpanded, index) => {
    if (newExpanded[index[0]] === false) {
      newExpanded = {}
    } else {
      Object.keys(newExpanded).map(
        row => (newExpanded[row] = Number(row) === index[0] ? {} : false)
      )
    }
    setExpanded(newExpanded)
  }

  const getTrProps = (_, rowInfo) => {
    const rowExpanded = Object.keys(expanded).filter(row => expanded[row])
    const isExpanded =
      rowInfo && rowExpanded.indexOf(String(rowInfo.index)) > -1

    if (!rowInfo) return {}

    const isWaiting = rowInfo.original.status === 'succeededWithoutPublishing'

    let backgroundColor = isExpanded && BLUE_GREY
    if (isWaiting) {
      backgroundColor = isExpanded ? LIGHT_ORANGE : LIGHT_TRANSPARENT_ORANGE
    }
    const color = isExpanded && WHITE

    return {
      style: {
        backgroundColor,
        color,
        opacity: isExpanded ? '0.9' : '0.8',
      },
    }
  }

  const minNumberOfRows = page * 10 + 1
  const showingNumberOfRows = minNumberOfRows + 9

  if (!processingState || processingState === 'FETCHING') {
    return <Loader />
  }
  return (
    <Fragment>
      <PageDescription
        heading={t('processing_queue_heading')}
        description={t('processing_queue_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('reload'),
            onClick: () =>
              fetchProcessing(
                organisationId,
                dispatch,
                selectedBrand,
                selectedFeed,
                page
              ),
          },
        ]}
      />
      <ContentWrapper>
        {t('processing_queue_showing_part1')}
        {minNumberOfRows} - {showingNumberOfRows}
        {t('processing_queue_showing_part2')}
        {pageTotal * 10} {t('processing_queue_showing_part3')}
        <ErrorBoundary>
          <StructuredTable
            columns={MainTableColumns(t, calculateJobDuration, brands)}
            data={processing}
            expanded={expanded}
            getTrProps={(_, rowInfo) => getTrProps(_, rowInfo)}
            onExpandedChange={(newExpanded, index) =>
              onExpandedChange(newExpanded, index)
            }
            page={page}
            pageSize={10}
            pageTotal={pageTotal}
            selectNextPage={() =>
              fetchProcessing(
                organisationId,
                dispatch,
                selectedBrand,
                selectedFeed,
                page + 1
              )
            }
            selectPreviousPage={() =>
              fetchProcessing(
                organisationId,
                dispatch,
                selectedBrand,
                selectedFeed,
                page - 1
              )
            }
            showPagination={false}
            sortable={false}
            SubComponent={({
              original: {
                id,
                feed: { name },
              },
            }) => (
              <SubTable
                calculateDuration={calculateDuration}
                fetchJobIncomingOutgoing={fetchJobIncomingOutgoing}
                getPercentage={getPercentage}
                getTrProps={(_, rowInfo) =>
                  rowInfo.original.status === 'succeededWithoutPublishing'
                    ? {
                        style: { backgroundColor: LIGHT_TRANSPARENT_ORANGE },
                      }
                    : {}
                }
                id={id}
                masterName={name}
                t={t}
              />
            )}
          />
        </ErrorBoundary>
      </ContentWrapper>
    </Fragment>
  )
}

Processing.propTypes = {
  brands: arrayOf(brandShape),
  organisationId: string.isRequired,
  selectedBrand: brandShape,
  selectedFeed: feedShape,
}

export default Processing

import styled from 'styled-components'
import Link from '../../shared/Link'

const StyledLink = styled(Link)`
  :focus {
    text-decoration: none;
  }
`

export default StyledLink

import { toast } from 'react-toastify'

import api from '../api'

const publishSelectedFeed = (feedId, organisationId, t) =>
  api
    .post(`/orgs/${organisationId}/jobs`, { feedId })
    .then(() => {
      toast.success(t('feed_published_success'))
    })
    .catch(err => {
      toast.error(t('feed_published_error'))
      console.log(err)
    })

export default publishSelectedFeed

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { formatToTimeZone } from 'date-fns-timezone'
import numeral from 'numeral'

import { countryFormats } from './constants/countryFormats'
import { timezones } from './constants/timezones'

import CZTranslation from './locales/cz/translation.json'
import ENTranslation from './locales/en/translation.json'
import DETranslation from './locales/de/translation.json'
import FETranslation from './locales/fr/translation.json'

const defaultTimeZone = 'Europe/London'
let timeZone = defaultTimeZone
let numberLocale = 'en-GB'
let dateFormat = countryFormats[0].dateFormat

const numberFormats = {
  en: '0,0.[00]',
  cs: '0,0.[00]',
}

export const setTimeZone = newtimeZone => {
  if (!newtimeZone) return
  const timeZoneIds = timezones.map(x => x.id.toUpperCase())
  timeZone = timeZoneIds.includes(newtimeZone.toUpperCase())
    ? newtimeZone
    : defaultTimeZone
}
export const getCurrentTimezone = () => timeZone

export const setNumberFormat = newNumberFormat =>
  (numberLocale = newNumberFormat)

export const setDateFormat = newDateFormat => {
  const findNewDateFormat = countryFormats.find(
    country => country.id === newDateFormat
  )
  return (dateFormat =
    findNewDateFormat.dateFormat || countryFormats[0].dateFormat)
}

const resources = {
  cz: { translation: CZTranslation },
  en: { translation: ENTranslation },
  de: { translation: DETranslation },
  fr: { translation: FETranslation },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (value instanceof Date) {
        return formatToTimeZone(value, format || dateFormat, {
          timeZone,
        })
      }
      if (typeof value == 'number') {
        return numeral(value).format(format || numberFormats[numberLocale])
      }
      return value
    },
  },
})

export default i18n

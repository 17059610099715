import React from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { number, func, object, bool } from 'prop-types'

import { WHITE, SHADOW_GREY, DARK_GREY } from '../../constants'
import ErrorBoundary from './ErrorBoundary'
import { structuredTableShape, structuredDataShape } from '../../types'

const MainTable = styled(ReactTable)`
  border: none !important;

  .rt-thead .rt-tr {
    padding: 10px 0;
    font-weight: bold;
    text-align: left !important;
    .rt-th {
      border: none;
    }
    .rt-resizable-header-content {
      display: flex;
    }
  }

  .rt-tbody {
    overflow: initial !important;
  }

  .rt-tbody .rt-td {
    border-right: none !important;
    text-overflow: ellipsis !important;
    display: flex;
    align-items: center;
    overflow: initial !important;
  }

  ${({ isUsersTable }) =>
    isUsersTable &&
    `.rt-tbody .rt-td:last-child {
      display: flex;
      justify-content: flex-end;
    }
`}

  .rt-tbody > .rt-tr-group {
    margin-bottom: 10px;
    background: ${WHITE};
    box-shadow: ${SHADOW_GREY};
    line-height: 1.8;
    ${({ isUsersTable }) => isUsersTable && 'padding: 0 10px'};
  }

  .rt-tbody .rt-tr-group {
    border-bottom: solid 0px rgba(0, 0, 0, 0.05) !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  transform: ${({ isPrev }) => (isPrev ? 'rotate(-135deg)' : 'rotate(45deg)')};
  padding: 9px;
  box-shadow: 3px -3px 0 0 ${DARK_GREY};
  margin: 14px;
`

const StyledButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 8px;
  ${({ disabled }) => !disabled && 'cursor: pointer'};
  outline: none;
`

const PagesNumber = styled.div`
  display: inline-block;
  position: relative;
  top: 13px;
`

const StructuredTable = ({
  columns,
  data,
  expanded,
  getTrProps,
  isUsersTable,
  onExpandedChange,
  page,
  pageSize,
  pageTotal,
  selectNextPage,
  selectPreviousPage,
  SubComponent,
}) => (
  <ErrorBoundary>
    <Wrapper>
      <ButtonWrapper isPrev>
        <StyledButton
          onClick={selectPreviousPage}
          disabled={page === 0 || page === null}
        />
      </ButtonWrapper>
      <PagesNumber>{`${page + 1} of ${pageTotal} `}</PagesNumber>

      <ButtonWrapper>
        <StyledButton
          onClick={selectNextPage}
          disabled={page + 1 === pageTotal || pageTotal === null}
        />
      </ButtonWrapper>
    </Wrapper>
    <MainTable
      columns={columns}
      data={data}
      expanded={expanded}
      getTrProps={getTrProps}
      isUsersTable={isUsersTable}
      onExpandedChange={onExpandedChange}
      pageSize={pageSize}
      resizable={false}
      showPagination={false}
      sortable={false}
      SubComponent={SubComponent}
      minRows={1}
    />
  </ErrorBoundary>
)

StructuredTable.propTypes = {
  columns: structuredTableShape,
  data: structuredDataShape,
  expanded: object,
  getTrProps: func,
  isUsersTable: bool,
  onExpandedChange: func,
  page: number,
  pageSize: number.isRequired,
  pageTotal: number,
  selectNextPage: func.isRequired,
  selectPreviousPage: func.isRequired,
  SubComponent: func,
}
export default StructuredTable

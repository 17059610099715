import { DARK_ORANGE, LIGHT_GREEN, MIDDLE_GREY } from '../constants'

export const recognizeColor = system => {
  if (system && system.required) {
    return DARK_ORANGE
  } else if (system && system.recommended) {
    return LIGHT_GREEN
  }
  return MIDDLE_GREY
}

export const recognizeText = (system, t) => {
  if (system && system.required) {
    return t('required')
  } else if (system && system.recommended) {
    return t('recommended')
  }
  return t('optional')
}

export const getText = (text, t) => {
  if (text) {
    if (text.length > 15) {
      return text.substring(0, 20) + '...'
    }
    return text
  }
  return t('not_available')
}

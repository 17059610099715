const optimisationItems = [
  { linkText: 'exclusions', path: 'exclusions', requiresConfiguredFeed: true },
  { linkText: 'transformations', path: 'rules', requiresConfiguredFeed: true },
  {
    linkText: 'enrichments',
    path: 'enrichments',
    requiresConfiguredFeed: true,
  },
]

const settingsItems = [
  { linkText: 'basic_info', path: 'basic-info' },
  { linkText: 'incoming', path: 'incoming' },
  {
    linkText: 'incoming_field_transformer',
    path: 'incomingmapping',
    requiresConfiguredFeed: true,
    level: 2,
  },

  { linkText: 'outgoing', path: 'outgoing' },
  {
    linkText: 'field_transformer',
    path: 'mapping',
    requiresConfiguredFeed: true,
    level: 2,
  },
  { linkText: 'scheduling', path: 'scheduling' },
  { linkText: 'alerts', path: 'alerts', hidden: false },
]

const feedItems = [{ linkText: 'overview', path: 'overview' }]

const reportsItems = [{ linkText: 'processing_queue', path: 'processing' }]

const adminItems = isAdmin => [
  { linkText: 'files', path: '' },
  ...(isAdmin
    ? [
        { linkText: 'users', path: 'users' },
        { linkText: 'partners', path: 'partners' },
        { linkText: 'brands', path: 'brands' },
        { linkText: 'invitations', path: 'invitations' },
        { linkText: 'feed_audit', path: 'feed_audit' },
      ]
    : []),
]

const systemItems = [
  { linkText: 'organisations', path: 'organisations' },
  { linkText: 'partners', path: 'partners' },
  { linkText: 'add-outgoing-partner', path: 'add-outgoing-partner' },
  { linkText: 'announcements', path: 'announcements' },
]

export const subItems = isAdmin => ({
  feeds: [
    { links: feedItems, linkText: 'feed' },
    { links: optimisationItems, linkText: 'optimisation' },
    { links: settingsItems, linkText: 'settings' },
  ],
  reports: [{ links: reportsItems, linkText: 'reports' }],
  admin: [{ links: adminItems(isAdmin), linkText: 'admin' }],
  system: [{ links: systemItems, linkText: 'system' }],
})

import {
  OUTGOING_LIST_FETCHING,
  OUTGOING_LIST_DONE,
  OUTGOING_LIST_FAILED,
} from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  outgoingListState: null,
  items: [],
  outgoingFeedId: null,
  fetchError: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case OUTGOING_LIST_FETCHING:
      return {
        ...state,
        outgoingListState: APP_STATE.FETCHING,
      }

    case OUTGOING_LIST_DONE:
      return {
        ...state,
        outgoingListState: APP_STATE.DONE,
        outgoingFeedId: action.outgoingFeedId,
        items: action.outgoing,
      }

    case OUTGOING_LIST_FAILED:
      return {
        ...state,
        outgoingListState: APP_STATE.ERROR,
        outgoingFeedId: null,
        items: [],
        fetchError: action.message,
      }

    default:
      return state
  }
}

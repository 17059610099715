import { APP_STATE } from '../../../constants'
import api from '../../../lib/api'
import { notCanceled } from '../../../lib/signal'

export const CREATE_SOURCE = 'CREATE_SOURCE'
export const SOURCE_FAILED = 'SOURCE_FAILED'
export const SOURCE_DONE = 'SOURCE_DONE'
export const SOURCE_FETCHING = 'SOURCE_FETCHING'
export const SOURCE_FETCHING_DONE = 'SOURCE_FETCHING_DONE'
export const SAVE_FEED_FILE = 'SAVE_FEED_FILE'
export const SOURCE_CHANGE = 'SOURCE_CHANGE'

const transformSourceData = ({
  sourceId,
  name,
  status,
  enabled,
  publish,
  connection: {
    type: connectionType,
    options: { hostname, port, path, username, password },
  },
  format: {
    type: formatType,
    options: {
      delimiter,
      quote,
      normalizeNewlines,
      product,
      columns,
      feedName,
      includeXmlDeclaration,
      prettyPrint,
      rootTag,
      rowTag,
      header,
      relaxColumnCount,
      feedXmlns,
      incremental,
    },
  },
  compression,
  lastPublishedFileId,
  job,
  merge,
}) => ({
  sourceFormState: APP_STATE.DONE,
  sourceId,
  name,
  status,
  enabled,
  publish,
  connectionType,
  connectionHostname: hostname,
  connectionPort: port,
  connectionPath: path,
  connectionUsername: username,
  connectionPassword: password || '',
  formatType: formatType,
  formatDelimiter: delimiter,
  formatJson: JSON.stringify({
    product,
    columns,
    feedName,
    includeXmlDeclaration,
    prettyPrint,
    rootTag,
    rowTag,
    feedXmlns,
    incremental,
  }), // change this once we change UI XML editing
  formatQuote: quote,
  formatNormalizeNewlines: normalizeNewlines,
  formatHeader: header,
  formatRelaxColumnCount: relaxColumnCount,
  compressionType: compression && compression.type,
  lastPublishedFileId,
  job,
  mergeType: merge?.type,
  mergeSrcKey: merge?.options?.srcKey,
  mergeDstKey: merge?.options?.dstKey,
})

export const fetchFailed = message => ({
  type: SOURCE_FAILED,
  message,
})

export const requestSource = () => ({
  type: SOURCE_FETCHING,
})

export const requestSourceDone = () => ({
  type: SOURCE_FETCHING_DONE,
})

export const receiveSource = source => ({
  type: SOURCE_DONE,
  source: transformSourceData(source),
})

export const createSource = () => ({
  type: CREATE_SOURCE,
})

export const saveFile = file => ({
  type: SAVE_FEED_FILE,
  file,
})

export const changeValue = valueObject => ({
  type: SOURCE_CHANGE,
  valueObject,
})

export const fetchSource = (
  dispatch,
  feedId,
  sourceId,
  type = 'outgoing',
  signal
) => {
  dispatch(requestSource())

  api
    .get(`/feeds/${feedId}/${type}/${sourceId}`, {
      cancelToken: signal && signal.token,
    })
    .then(({ data }) => dispatch(receiveSource(data)))
    .catch(
      notCanceled(err => {
        console.log(err)
        dispatch(fetchFailed(err.message))
        return Promise.reject(err)
      })
    )
}

export const getIncomingSourceFileUrl = (feedId, resourceId) =>
  `/feeds/${feedId}/incoming/${resourceId}/data?raw=true`

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { node } from 'prop-types'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { children, t } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <h3>{t('error_boundary_message')}</h3>
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: node,
}

export default withTranslation()(ErrorBoundary)

import { USERS_FETCHING, USERS_DONE, USERS_FAILED } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  userListState: null,
  userList: [],
  fetchError: '',
  pageTotal: null,
  page: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case USERS_FETCHING:
      return {
        ...state,
        userListState: APP_STATE.FETCHING,
      }

    case USERS_DONE:
      return {
        ...state,
        userListState: APP_STATE.DONE,
        userList: action.userList,
        pageTotal: action.pageTotal,
        page: action.page,
      }

    case USERS_FAILED:
      return {
        ...state,
        userListState: APP_STATE.ERROR,
        userList: [],
        fetchError: action.message,
        pageTotal: null,
        page: null,
      }

    default:
      return state
  }
}

import { useContext /*, useEffect*/ } from 'react'
import { OrganisationsContext } from './OrganisationsContext'
import { navigate } from '@reach/router'

const useOrganizations = () => {
  const [state, setState] = useContext(OrganisationsContext)

  const { list, selected } = state

  const setSelected = org => {
    if (list.includes(org)) {
      setState(state => ({ ...state, selected: org }))
    }
  }

  const setList = orgs => {
    // take a look at url
    const pathName = window.location.pathname
    let orgIdParam = pathName.split('/')[1]

    // if login redirect
    if (orgIdParam === 'login') {
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      const returnUrl = params.get('returnUrl')

      if (returnUrl) {
        navigate(returnUrl)

        const split = returnUrl.split('/')
        if (split.length > 3) orgIdParam = split[3]
      }
    }

    // select orgId from list
    const orgParam =
      orgIdParam.length &&
      orgs.find(organisation => organisation.id === orgIdParam)
    const finalOrg = orgParam ? orgParam : orgs[0]

    setState(state => ({ ...state, list: orgs, selected: finalOrg }))
  }

  return {
    list: list.sort((a, b) => a.name.localeCompare(b.name)),
    selected,
    setSelected,
    setList,
  }
}

export default useOrganizations

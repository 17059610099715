import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { func, arrayOf, bool, string, object } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getText } from '../../lib/recognizeCategory'
import { dropdownValueShape } from '../../types'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import { WHITE, DARK_GREY } from '../../constants'

import Row from './Row'
import Text from './Text'
import Button from './Button'
import Tooltip from './Tooltip2'
import MappingEditable from './MappingEditable'

const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  white-space: nowrap;
`

const AddRowButton = styled(Button)`
  margin: 20px auto;
  display: block;
`

const ColumnTitles = styled.div`
  display: ${({ isAddPartner }) => (isAddPartner ? 'grid' : 'flex')};
  ${({ isAddPartner }) =>
    isAddPartner && 'grid-template-columns: 1fr 1fr 1fr 1fr'};
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
`

const MappingList = ({
  addButtonText,
  color,
  columnTypeKeys,
  deleteMapping,
  description,
  dispatch,
  exampleValues = {},
  firstTitle,
  isAddPartner,
  isMaster,
  keys,
  mapping,
  requiredButEmpty,
  secondTitle,
  showHeading,
  thirdTitle,
  updateMapping,
}) => {
  const [isFormVisible, setFormVisible] = useState(false)
  const finalMapping = isAddPartner ? mapping.mapping : mapping
  const { t } = useTranslation()

  return (
    <Fragment>
      {showHeading && (
        <HeadingContainer>
          <Text color={color}>{description}</Text>&nbsp;
          <Text bold color={color}>
            {firstTitle}
          </Text>
        </HeadingContainer>
      )}
      <ColumnTitles isAddPartner={isAddPartner}>
        <Text
          width="100%"
          color={isMaster || isAddPartner ? DARK_GREY : WHITE}
          bold
        >
          <Tooltip
            title={
              isMaster
                ? t('mapping_column_title_master_tooltip')
                : t('mapping_column_title_partner_tooltip')
            }
          >
            {firstTitle}
          </Tooltip>
        </Text>
        <Text
          width="100%"
          color={isMaster || isAddPartner ? DARK_GREY : WHITE}
          bold
        >
          <Tooltip title={t('mapping_column_title_incoming_tooltip')}>
            {secondTitle}
          </Tooltip>
        </Text>
        {thirdTitle && (
          <Text width="100%" bold>
            <Tooltip title={t('mapping_column_title_preview_tooltip')}>
              {thirdTitle}
            </Tooltip>
          </Text>
        )}
      </ColumnTitles>
      {Object.keys(finalMapping).map((sourceColumn, index) => {
        const specificRow = finalMapping[sourceColumn]
        const isRequiredAndEmpty =
          requiredButEmpty && requiredButEmpty(specificRow)

        return (
          <Row
            columnTypeKeys={columnTypeKeys}
            dispatch={dispatch}
            dataId={`mapping-row-${sourceColumn}`}
            deleteMapping={deleteMapping}
            exampleValue={getText(exampleValues[specificRow.options.name], t)}
            index={index}
            isAddPartner={isAddPartner}
            isMaster={isMaster}
            isRequiredAndEmpty={isRequiredAndEmpty}
            key={`${sourceColumn}_${index}`}
            keys={keys}
            mapping={mapping}
            mappingRow={specificRow}
            sourceColumn={sourceColumn}
            updateMapping={updateMapping}
          />
        )
      })}
      {(isMaster || isAddPartner) && (
        <Fragment>
          {!isFormVisible && (
            <AddRowButton
              theme={SECONDARY_BUTTON}
              dataId="add-new-row"
              onClick={() => setFormVisible(true)}
            >
              {addButtonText}
            </AddRowButton>
          )}
          {isFormVisible && (
            <MappingEditable
              columnTypeKeys={columnTypeKeys}
              dispatch={dispatch}
              isAddPartner={isAddPartner}
              mapping={mapping}
              keys={keys}
              setFormVisible={setFormVisible}
              t={t}
              updateMapping={updateMapping}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

MappingList.propTypes = {
  addButtonText: string,
  color: string,
  columnTypeKeys: arrayOf(dropdownValueShape),
  deleteMapping: func,
  description: string.isRequired,
  exampleValues: object,
  firstTitle: string.isRequired,
  isAddPartner: bool,
  isMaster: bool,
  keys: arrayOf(dropdownValueShape).isRequired,
  mapping: object.isRequired,
  requiredButEmpty: func,
  secondTitle: string.isRequired,
  showHeading: bool,
  thirdTitle: string,
  updateMapping: func.isRequired,
}

export default MappingList

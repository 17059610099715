import {
  PROCESSING_FETCHING,
  PROCESSING_DONE,
  PROCESSING_FAILED,
} from './actions'
import { APP_STATE } from '../../constants'

export const initialState = {
  processingState: null,
  processing: [],
  fetchError: '',
  pageTotal: null,
  page: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case PROCESSING_FETCHING:
      return {
        ...state,
        processingState: APP_STATE.FETCHING,
      }

    case PROCESSING_DONE:
      return {
        ...state,
        processingState: APP_STATE.DONE,
        processing: action.processing,
        pageTotal: action.pageTotal,
        page: action.page,
      }

    case PROCESSING_FAILED:
      return {
        ...state,
        processingState: APP_STATE.ERROR,
        processing: [],
        fetchError: action.message,
        pageTotal: null,
        page: null,
      }

    default:
      return state
  }
}

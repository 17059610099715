import React, { Fragment, useEffect, useReducer } from 'react'
import { string } from 'prop-types'
import { navigate } from '@reach/router'

import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import StructuredTable from '../../shared/StructuredTable'
import Button from '../../shared/Button'
import { TEXT_SMALL } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

import { fetchUsers, removeUser } from './actions'
import { initialState, reducer } from './reducer'

const TableColumns = (t, organisationId, dispatch, page) => [
  {
    Header: t('users_table_header1'),
    accessor: 'name',
  },
  {
    Header: t('users_table_header2'),
    accessor: 'email',
  },
  {
    Header: t('users_table_header3'),
    accessor: 'updatedAt',
    Cell: row => {
      const {
        original: { updatedAt },
      } = row
      return updatedAt
        ? t('dateValueInput', {
            value: new Date(updatedAt),
          })
        : ''
    },
  },
  {
    Cell: row => {
      const {
        original: { id },
      } = row
      return (
        <Button
          dataId={`remove-user-${id}`}
          onClick={() => removeUser(organisationId, id, t, dispatch, page)}
          fontSize={TEXT_SMALL}
          theme={SECONDARY_BUTTON}
        >
          {t('button_remove').toUpperCase()}
        </Button>
      )
    },
  },
]

const UserList = ({ t, organisationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { userList, userListState, pageTotal, page } = state

  const filteredOrganisation = window.location.search.split('usersOf=')[1]

  useEffect(
    () => {
      fetchUsers(organisationId, dispatch, page, t, filteredOrganisation)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId]
  )

  if (!userListState || userListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('users')}
        description={t('users_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('invite_user'),
            onClick: () => navigate(`/${organisationId}/admin/add-new-user`),
          },
        ]}
      />
      <ContentWrapper>
        <StructuredTable
          columns={TableColumns(t, organisationId, dispatch, page)}
          data={userList}
          isUsersTable
          page={page}
          pageSize={10}
          pageTotal={pageTotal}
          selectNextPage={() =>
            fetchUsers(organisationId, dispatch, page + 1, t)
          }
          selectPreviousPage={() =>
            fetchUsers(organisationId, dispatch, page - 1, t)
          }
          showPagination={false}
          sortable={false}
        />
      </ContentWrapper>
    </Fragment>
  )
}

UserList.propTypes = {
  organisationId: string.isRequired,
}

export default UserList

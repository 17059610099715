import React from 'react'
import styled from 'styled-components'
import { arrayOf, func, bool, oneOf, string, any } from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  CARD_HEIGHT_MEDIUM,
  CARD_HEIGHT_LARGE,
  CARD_HEIGHT_SMALL,
} from '../../constants'
import { exclusionShape } from '../../types'
import downloadFile from '../../lib/downloadFile'

import Card from './Card'
import CardColumn from './CardColumn'

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`

const columnGeneral = (t, productNumber, productNumberlabel) =>
  productNumber
    ? [
        {
          heading: `${t('number_of_products')} ${productNumberlabel}`,
          description: productNumber,
        },
      ]
    : []

const columnEnrichments = (t, headers, updatedAt, createdAt) => [
  {
    heading: t('enrichments_mapping_columns'),
    description: (headers && headers[0]) || t('not_available'),
    isDescriptionBold: true,
    inline: true,
  },
  {
    heading: t('enrichments_columns_modified'),
    description:
      (headers &&
        Array.isArray(headers) &&
        headers.slice(1, headers.length).join(', ')) ||
      t('not_available'),
    isDescriptionBold: true,
    inline: true,
  },
  {
    heading: t('enrichments_last_modified'),
    description: updatedAt
      ? t('dateValueInput', {
          value: new Date(updatedAt),
        })
      : t('dateValueInput', {
          value: new Date(createdAt),
        }),
    inline: true,
  },
]

const buttonsGeneral = (
  t,
  id,
  outgoingId,
  enabled,
  onPause,
  handleDeleteDialogVisibility,
  addToClipboard,
  isRule,
  isFilter,
  isAlert
) => {
  const path = !outgoingId ? 'master' : `outgoing/${outgoingId}`

  let tooltipPrefix = ''
  if (isRule) tooltipPrefix = 'rules_'
  if (isFilter) tooltipPrefix = 'enrichments_'
  if (isAlert) tooltipPrefix = 'alert_'

  const buttons = [
    {
      name: t('button_edit'),
      tooltip: tooltipPrefix && t(`${tooltipPrefix}button_edit_tooltip`),
      link: `${id}/${path}`,
    },
    {
      name: t('button_delete'),
      tooltip: t(`${tooltipPrefix}button_delete_tooltip`),
      onClick: () => handleDeleteDialogVisibility(true, id, outgoingId),
    },
    {
      name: t('button_duplicate'),
      tooltip: t(`${tooltipPrefix}button_duplicate_tooltip`),
      link: `${id}/${path}?isDuplicate=true`,
    },
    {
      name: enabled ? t('button_pause') : t('button_enable'),
      tooltip: enabled
        ? t(`${tooltipPrefix}button_pause_tooltip`)
        : t(`${tooltipPrefix}button_enable_tooltip`),
      onClick: () => onPause(id, !enabled, outgoingId),
    },
  ]
  if (addToClipboard)
    return [
      ...buttons,
      {
        name: t('button_copy'),
        tooltip: t(`${tooltipPrefix}button_copy_tooltip`),
        onClick: () => addToClipboard(id, outgoingId),
      },
    ]
  return buttons
}

const buttonsEnrichment = (
  t,
  id,
  name,
  fileId,
  partnerId,
  outgoingId,
  enabled,
  onRemove,
  onPause,
  handleUploadDialogVisibility
) => [
  {
    name: t('button_delete'),
    tooltip: t('enrichment_button_delete_tooltip'),
    onClick: () => onRemove(true, id, partnerId),
  },
  {
    name: t('button_download'),
    tooltip: t('enrichment_button_download_tooltip'),
    onClick: () => downloadFile(t, `/files/${fileId}/data`, name, 'csv'),
  },
  {
    name: enabled ? t('button_pause') : t('button_enable'),
    tooltip: t('enrichment_button_pause_tooltip'),
    onClick: () => onPause(id, !enabled, outgoingId),
  },
  {
    name: t('button_overwrite'),
    tooltip: t('enrichment_button_override_tooltip'),
    onClick: () => handleUploadDialogVisibility(true, id, null),
  },
]

const CardList = ({
  addToClipboard,
  color,
  description,
  handleUploadDialogVisibility,
  isEnrichment,
  isRule,
  isFilter,
  isPartner,
  isAlert,
  list,
  name,
  outgoingId,
  textColor,
  partnerId,
  productNumberlabel,
  onPositionChange,
  onRemove,
  onPause,
  titleDescription,
  columnItemMargin,
  cardHeight = CARD_HEIGHT_MEDIUM,
  handleDeleteDialogVisibility,
  hideType,
  side,
}) => {
  const { t } = useTranslation()
  const hidePosition = !onPositionChange

  return (
    <CardColumn
      color={color}
      description={titleDescription}
      isPartner={isPartner}
      numberShowed={list.length}
      textColor={textColor}
      title={name}
      totalNumber={list.length}
      t={t}
      outgoingId={outgoingId}
      side={side}
      hideType={hideType}
    >
      <GridWrapper>
        {list.map(
          (
            {
              coverage,
              createdAt,
              id,
              fileId,
              headers,
              name,
              type,
              productNumber,
              enabled,
              updatedAt,
            },
            index
          ) => (
            <Card
              tooltip={isEnrichment ? t('button_order_change_tooltip') : null}
              key={id}
              height={cardHeight}
              dataId={`card-${name}-${index}`}
              position={hidePosition ? undefined : index + 1}
              columnItemMargin={columnItemMargin}
              arrows={
                hidePosition
                  ? undefined
                  : {
                      onDown: () =>
                        onPositionChange({
                          id,
                          index,
                          newPosition: index + 1,
                          outgoingId,
                          partnerId,
                        }),
                      onUp: () =>
                        onPositionChange({
                          id,
                          index,
                          newPosition: index - 1,
                          outgoingId,
                          partnerId,
                        }),
                      isFirst: index === 0,
                      isLast: index === list.length - 1,
                    }
              }
              status={enabled}
              title={name}
              description={isAlert ? t(type) : description}
              progress={
                !coverage
                  ? null
                  : {
                      percentage: coverage,
                      label: t('feed_modified'),
                    }
              }
              column={
                isEnrichment
                  ? columnEnrichments(t, headers, updatedAt, createdAt)
                  : columnGeneral(t, productNumber, productNumberlabel)
              }
              buttons={
                isEnrichment
                  ? buttonsEnrichment(
                      t,
                      id,
                      name,
                      fileId,
                      partnerId,
                      outgoingId,
                      enabled,
                      onRemove,
                      onPause,
                      handleUploadDialogVisibility
                    )
                  : buttonsGeneral(
                      t,
                      id,
                      outgoingId,
                      enabled,
                      onPause,
                      handleDeleteDialogVisibility,
                      addToClipboard,
                      isRule,
                      isFilter,
                      isAlert
                    )
              }
            />
          )
        )}
      </GridWrapper>
    </CardColumn>
  )
}

CardList.propTypes = {
  addToClipboard: func,
  cardHeight: oneOf([CARD_HEIGHT_MEDIUM, CARD_HEIGHT_LARGE, CARD_HEIGHT_SMALL]),
  color: string.isRequired,
  columnItemMargin: string,
  description: string.isRequired,
  handleUploadDialogVisibility: func,
  isEnrichment: bool,
  isRule: bool,
  isFilter: bool,
  isAlert: bool,
  isPartner: bool,
  list: arrayOf(exclusionShape).isRequired,
  name: string,
  onPause: func,
  onPositionChange: func,
  onRemove: func,
  outgoingId: string,
  partnerId: string,
  productNumberlabel: string,
  textColor: string,
  titleDescription: string.isRequired,
  handleDeleteDialogVisibility: func,
  isDeleteDialogVisible: bool,
  side: any,
  hideType: bool,
}

export default CardList

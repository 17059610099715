import React, { useEffect } from 'react'
import { func, string, array } from 'prop-types'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'

import {
  historyShape,
  feedShape,
  outgoingShape,
  incomingCompactShape,
} from '../../types'
import { FEED_DETAIL_STATE } from '../App/reducer'

import NoMatch from '../shared/NoMatch'

import AlertList from './AlertList'
import BasicInfo from './BasicInfo'
import EnrichmentList from './EnrichmentList'
import ExclusionList from './ExclusionList'
import Incoming from './Incoming'
import Loader from '../shared/Loader'
import Mapping from './Mapping'
import IncomingMapping from './IncomingMapping'
import OutgoingList from './OutgoingList'
import Overview from './Overview'
import RuleRouter from './RuleList'
import Scheduling from './Scheduling'
import ErrorBoundary from '../shared/ErrorBoundary'

import useOrganisations from '../shared/system/useOrganisations'

const FeedDetailContent = ({
  changeFeed,
  dispatch,
  errorMsg,
  feed,
  feedDetailState,
  feedId,
  reloadCurrentFeed,
  reloadFeeds,
  saveFeed,
  selectedOutgoing,
  selectedIncoming,
  changeOutgoing,
  changeIncoming,
  incoming,
  outgoing,
}) => {
  const { t } = useTranslation()
  const { selected } = useOrganisations()

  const organisationId = selected.id
  const organisationName = selected.name

  useEffect(
    () => changeFeed(feedId),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedId]
  )

  const isNew = feedId === 'new'

  const isSelectedFeedNotSimilarToLoadedFeed = (feed, feedId) =>
    feed && !isNew && feedId !== null && feedId !== feed.id

  if (
    feedDetailState === FEED_DETAIL_STATE.FETCHING ||
    isSelectedFeedNotSimilarToLoadedFeed(feed, feedId)
  ) {
    return <Loader />
  }

  if (feedDetailState === FEED_DETAIL_STATE.ERROR) return <p>{errorMsg}</p>

  if (!feed) return null
  const feedIsConfiguredAndReady = feed.isConfigured

  return (
    <ErrorBoundary>
      {feedDetailState === FEED_DETAIL_STATE.DONE && isNew && (
        <Router primary={false}>
          <BasicInfo
            country={feed.country}
            feedId={feedId}
            groupId={feed.groupId}
            name={feed.name}
            path="basic-info"
            organisationId={organisationId}
            reloadFeeds={reloadFeeds}
            saveFeedOverview={saveFeed}
            status={feed.enabled}
          />

          <NoMatch path="*" isRelative noThrow to="./basic-info" />
        </Router>
      )}

      {feedDetailState === FEED_DETAIL_STATE.DONE &&
        feed.id &&
        feed.id === feedId && (
          <Router primary={false}>
            <Overview
              feed={feed}
              feedId={feedId}
              path="overview"
              organisationId={organisationId}
            />

            <BasicInfo
              path="basic-info"
              groupId={feed.groupId}
              country={feed.country}
              feedId={feed.id}
              name={feed.name}
              status={feed.enabled}
              saveFeedOverview={saveFeed}
              reloadFeeds={reloadFeeds}
              organisationId={organisationId}
            />

            <Scheduling
              dispatch={dispatch}
              feedId={feed.id}
              organisationId={organisationId}
              path="scheduling"
              saveFeed={saveFeed}
              trigger={feed.trigger}
              t={t}
            />

            <Incoming
              path="incoming/*"
              feedId={feed.id}
              organisationId={organisationId}
              t={t}
              reloadCurrentFeed={reloadCurrentFeed}
              selectedIncoming={selectedIncoming}
              changeIncoming={changeIncoming}
            />

            <OutgoingList
              path="outgoing/*"
              feedId={feed.id}
              reloadCurrentFeed={reloadCurrentFeed}
              organisationId={organisationId}
              organisationName={organisationName}
              selectedOutgoing={selectedOutgoing}
              changeOutgoing={changeOutgoing}
              t={t}
            />

            {feedIsConfiguredAndReady && (
              <ExclusionList
                feed={feed.id}
                path="exclusions/*"
                organisationId={organisationId}
                selectedOutgoing={selectedOutgoing}
                t={t}
              />
            )}

            {feedIsConfiguredAndReady && (
              <IncomingMapping
                feed={feed.id}
                path="incomingmapping"
                selectedIncoming={selectedIncoming || incoming[0]}
                t={t}
              />
            )}

            {feedIsConfiguredAndReady && (
              <Mapping
                feed={feed.id}
                path="mapping"
                selectedOutgoing={selectedOutgoing || outgoing[0]}
                t={t}
              />
            )}

            {feedIsConfiguredAndReady && (
              <AlertList
                feed={feed}
                path="alerts/*"
                outgoing={outgoing}
                incoming={incoming}
              />
            )}

            {feedIsConfiguredAndReady && (
              <RuleRouter
                feedId={feed.id}
                organisationId={organisationId}
                path="rules/*"
                selectedIncoming={selectedIncoming}
                selectedOutgoing={selectedOutgoing}
                t={t}
              />
            )}

            {feedIsConfiguredAndReady && (
              <EnrichmentList
                path="enrichments"
                heading="List"
                feedId={feed.id}
                organisationId={organisationId}
                selectedOutgoing={selectedOutgoing}
                t={t}
              />
            )}

            <NoMatch path="*" isRelative noThrow to="./overview" />
          </Router>
        )}
    </ErrorBoundary>
  )
}

FeedDetailContent.propTypes = {
  changeFeed: func.isRequired,
  dispatch: func.isRequired,
  errorMsg: string,
  feed: feedShape,
  feedDetailState: string,
  feedId: string,
  history: historyShape,
  reloadCurrentFeed: func.isRequired,
  reloadFeeds: func.isRequired,
  saveFeed: func.isRequired,
  selectedOutgoing: outgoingShape,
  selectedIncoming: incomingCompactShape,
  changeOutgoing: func.isRequired,
  changeIncoming: func.isRequired,
  incoming: array, // fix this shape
  outgoing: array, // fix this shape
}

export default FeedDetailContent

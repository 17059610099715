import React, { useState } from 'react'
import { any } from 'prop-types'

const BrandsContext = React.createContext([{}, () => {}])

const BrandsProvider = props => {
  const [state, setState] = useState({
    list: [],
    selected: null,
  })
  return (
    <BrandsContext.Provider value={[state, setState]}>
      {props.children}
    </BrandsContext.Provider>
  )
}

BrandsProvider.propTypes = {
  children: any,
}

export { BrandsContext, BrandsProvider }

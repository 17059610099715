import React, { Fragment } from 'react'
import { arrayOf, func, bool } from 'prop-types'

import { exclusionShape, outgoingShape } from '../../../types'
import {
  LIGHT_GREY,
  MIDDLE_GREY,
  WHITE,
  CARD_HEIGHT_SMALL,
} from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

import CardList from '../../shared/CardList'

import Button from '../../shared/Button'

import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import RemoveModalWindow from '../../shared/RemoveModalWindow'
import ColumnGridWrapper from '../../shared/ColumnGridWrapper'

const List = ({
  addToClipboard,
  handleDeleteDialogVisibility,
  isDeleteDialogVisible,
  inputList,
  onPause,
  onPositionChange,
  onRemove,
  outputList,
  selectedOutgoing,
  t,
  removeExclusionFile,
}) => {
  const onCopyAll = isOutgoing => {
    const atc = id => addToClipboard(id, isOutgoing && selectedOutgoing.id)
    const list = isOutgoing ? outputList : inputList
    atc(list.map(x => x.id))
  }

  const CopyAllButton = isOutgoing => {
    if (isOutgoing && (!outputList || !outputList.length > 0)) return
    if (!isOutgoing && (!inputList || !inputList.length > 0)) return

    return (
      <Button theme={SECONDARY_BUTTON} onClick={() => onCopyAll(isOutgoing)}>
        {t('button_copy_all')}
      </Button>
    )
  }
  return (
    <Fragment>
      <PageDescription
        description={t('exclusions_description')}
        heading={t('exclusions')}
      />

      <ColumnGridWrapper>
        <TopButtonsWrapper
          buttons={[
            {
              name: t('add_master_exclusion'),
              tooltip: t('enrichments_button_new_master_tooltip'),
              link: `new/master`,
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: t('add_partner_exclusion'),
              tooltip: t('enrichments_button_new_partner_tooltip'),
              link: `new/outgoing/${selectedOutgoing.id}`,
            },
          ]}
        />

        <CardList
          addToClipboard={addToClipboard}
          cardHeight={CARD_HEIGHT_SMALL}
          color={LIGHT_GREY}
          description={t('master_exclusions')}
          list={inputList}
          name={t('master_feed')}
          onPause={onPause}
          onPositionChange={onPositionChange}
          titleDescription={t('exclusions')}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          removeExclusionFile={removeExclusionFile}
          side={CopyAllButton(false)}
          isFilter={true}
        />

        <CardList
          addToClipboard={addToClipboard}
          cardHeight={CARD_HEIGHT_SMALL}
          color={MIDDLE_GREY}
          description={t('partner_exclusions')}
          isPartner
          list={outputList}
          name={selectedOutgoing.name}
          onPause={onPause}
          onPositionChange={onPositionChange}
          outgoingId={selectedOutgoing.id}
          productNumberlabel={t('excluded')}
          textColor={WHITE}
          titleDescription={t('exclusions')}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          removeExclusionFile={removeExclusionFile}
          side={CopyAllButton(true)}
          isFilter={true}
        />

        <TopButtonsWrapper
          buttons={[
            {
              name: t('add_master_exclusion'),
              link: `new/master`,
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: t('add_partner_exclusion'),
              link: `new/outgoing/${selectedOutgoing.id}`,
            },
          ]}
        />
      </ColumnGridWrapper>

      <RemoveModalWindow
        t={t}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        onRemove={onRemove}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

List.propTypes = {
  addToClipboard: func.isRequired,
  handleDeleteDialogVisibility: func.isRequired,
  inputList: arrayOf(exclusionShape).isRequired,
  isDeleteDialogVisible: bool,
  onPause: func.isRequired,
  onPositionChange: func.isRequired,
  onRemove: func.isRequired,
  outputList: arrayOf(exclusionShape).isRequired,
  removeExclusionFile: func,
  selectedOutgoing: outgoingShape.isRequired,
}

export default List

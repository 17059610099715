import React from 'react'
import styled from 'styled-components'
import { func, arrayOf } from 'prop-types'

import Dropdown from '../../shared/Dropdown'

import { UPLOAD_FILE_TYPES } from '../../../constants'
import { dropdownValueShape } from '../../../types'

const FilterWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  max-width: 400px;
`

const Filter = ({ t, changeFileType, changeBrand, brands = [] }) => (
  <FilterWrapper>
    <Dropdown
      dataId="file-type-dropdown"
      label={t('sourceform_filetype')}
      name="fileType"
      onChange={changeFileType}
      options={[
        { id: '' },
        ...UPLOAD_FILE_TYPES.map(x => ({ ...x, name: t(x.id) })),
      ]}
      placeholder={t('select')}
    />
    {brands && (
      <Dropdown
        dataId="brand-dropdown"
        label={t('brand')}
        name="brand"
        onChange={changeBrand}
        options={[{ id: '' }, ...brands]}
        placeholder={t('select')}
      />
    )}
  </FilterWrapper>
)

Filter.propTypes = {
  brands: arrayOf(dropdownValueShape),
  changeBrand: func.isRequired,
  changeFileType: func.isRequired,
}

export default Filter

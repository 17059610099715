import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Downshift from 'downshift'
import { arrayOf, func, string, bool } from 'prop-types'

import {
  BLACK,
  MIDDLE_GREY,
  DARK_GREY,
  ICON_SIZE_SMALL,
  LIGHT_GREY,
  LIGHT_ORANGE,
  SHADOW_GREY,
  WHITE,
  LIGHT_TRANSPARENT_ORANGE,
  BUTTON_PADDING_SMALL,
  TEXT_SMALL,
  RED,
} from '../../../constants'
import { dropdownValueShape } from '../../../types'
import { TRANSPARENT_BUTTON } from '../../../constants/buttonThemes'

import Icon from '../Icon'
import Text from '../Text'
import Tooltip, { TooltipWrapper } from '../Tooltip'
import Button from '../Button'
import FormikError from '../FormikError'

const Container = styled.div`
  width: 100%;
  position: relative;
  ${({ isHigh }) => isHigh && 'height: 100%'};
`

const ToggleButton = styled(Button)`
  outline: none;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  transition: transform 0.2s ease-in;
  justify-self: flex-end;
  margin-left: ${({ isNarrow }) => (isNarrow ? '5px' : 0)};
  opacity: ${({ disabled }) => disabled && '0.3'};
`

const Select = styled.div`
  height: auto;
  min-height: 35px;
  max-height: 70px;
  padding: 10px 10px 12px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid;
  border-color: ${({ noBorder }) => (noBorder ? 'transparent' : LIGHT_GREY)};
  border-color: ${({ isError }) => isError && RED};
  ${({ isHigh }) => isHigh && 'height: 100%'};

  ${({ showTooltip }) =>
    showTooltip &&
    css`
      &:hover {
        ${TooltipWrapper} {
          display: block;
        }
      }
    `}

  &:focus-within {
    outline: auto 5px -webkit-focus-ring-color;
  }
`

const StyledLabel = styled.label`
  flex: 1;
`

const DropdownInput = styled.div`
  grid-template-columns: 80% 20%;
  display: ${({ isNarrow }) => (isNarrow ? 'flex' : 'grid')};
`

const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const OptionList = styled.ul`
  position: absolute;
  background: ${WHITE};
  box-shadow: ${SHADOW_GREY};
  width: 100%;
  min-width: 150px;
  max-height: ${({ isOpen }) => (isOpen ? '300px' : 0)};
  padding: 0;
  margin-top: 5px;
  overflow-y: auto;
  z-index: 11;
`

const Option = styled.li`
  max-height: 50px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ highlighted, selected }) =>
    (selected && LIGHT_ORANGE) || (highlighted && LIGHT_TRANSPARENT_ORANGE)};
`

const stateReducer = (_, changes) => {
  switch (changes.type) {
    // Preventing from clearing value once ESC is pressed
    case Downshift.stateChangeTypes.keyDownEscape:
      return { isOpen: false }
    default:
      return changes
  }
}

const SelectWithOptions = ({
  backgroundColor,
  dataId,
  findInitialValue,
  handleChange,
  isHigh,
  isNarrow,
  label,
  name,
  noBorder,
  optionImageKey,
  optionNameKey,
  options,
  optionValueKey,
  placeholder,
  placeholderColor,
  selectedValue,
  showTooltip,
  isError,
  disabled,
}) => (
  <Downshift
    selectedItem={selectedValue}
    itemToString={() => {}} //function is not needed for our purposes but required by the library
    onChange={handleChange}
    stateReducer={stateReducer}
    initialSelectedItem={findInitialValue()}
  >
    {({
      getItemProps,
      getMenuProps,
      getRootProps,
      getToggleButtonProps,
      highlightedIndex,
      isOpen,
      selectedItem,
    }) => {
      const isValueSelected = selectedItem && selectedItem[optionValueKey]

      return (
        <Container {...getRootProps()} name={name} isHigh={isHigh}>
          <Select
            backgroundColor={backgroundColor}
            noBorder={noBorder}
            showTooltip={showTooltip}
            data-id={dataId}
            isHigh={isHigh}
            isError={isError}
          >
            <StyledLabel>
              {label && (
                <Text color={MIDDLE_GREY} size={TEXT_SMALL}>
                  {label}
                </Text>
              )}

              <DropdownInput isNarrow={isNarrow}>
                {isValueSelected && (
                  <Fragment>
                    <Tooltip>{selectedItem[optionNameKey]}</Tooltip>
                    <SelectedItem>
                      <Text
                        dataId={
                          'dropdown-selected-' + selectedItem[optionNameKey]
                        }
                      >
                        {selectedItem[optionNameKey]}
                      </Text>

                      {selectedItem[optionImageKey] && (
                        <img
                          src={selectedItem[optionImageKey]}
                          alt={selectedItem[optionNameKey]}
                        />
                      )}
                    </SelectedItem>
                  </Fragment>
                )}

                {!isValueSelected && (
                  <SelectedItem>
                    {placeholder && (
                      <Text color={placeholderColor} bold>
                        {placeholder}
                      </Text>
                    )}
                  </SelectedItem>
                )}
                <ToggleButton
                  padding={BUTTON_PADDING_SMALL}
                  theme={TRANSPARENT_BUTTON}
                  isOpen={isOpen}
                  isNarrow={isNarrow}
                  {...getToggleButtonProps({
                    disabled,
                  })}
                >
                  <Icon
                    color={isValueSelected ? BLACK : placeholderColor}
                    icon={'caretDown'}
                    size={ICON_SIZE_SMALL}
                  />
                </ToggleButton>
              </DropdownInput>
            </StyledLabel>
          </Select>
          {isOpen && (
            <OptionList isOpen={isOpen} {...getMenuProps()}>
              {options.map((item, index) => {
                const highlighted = highlightedIndex === index
                const value = item[optionValueKey]
                const selected =
                  selectedItem && value === selectedItem[optionValueKey]

                return (
                  <Option
                    key={value}
                    data-id={'dropdown-item-' + item[optionNameKey]}
                    highlighted={highlighted}
                    selected={selected}
                    {...getItemProps({
                      index,
                      item,
                    })}
                  >
                    <Text
                      bold={highlighted || selected}
                      color={selected ? WHITE : DARK_GREY}
                    >
                      {value ? item[optionNameKey] : placeholder}
                    </Text>

                    {item[optionImageKey] && (
                      <img
                        src={item[optionImageKey]}
                        alt={item[optionNameKey]}
                      />
                    )}

                    {selected && (
                      <Icon
                        icon={'checkmark'}
                        color={WHITE}
                        size={ICON_SIZE_SMALL}
                      />
                    )}
                  </Option>
                )
              })}
            </OptionList>
          )}
          <FormikError name={name} />
        </Container>
      )
    }}
  </Downshift>
)

SelectWithOptions.propTypes = {
  backgroundColor: string.isRequired,
  dataId: string,
  findInitialValue: func.isRequired,
  handleChange: func.isRequired,
  isHigh: bool,
  isNarrow: bool,
  label: string,
  name: string,
  noBorder: bool,
  optionImageKey: string,
  optionNameKey: string.isRequired,
  options: arrayOf(dropdownValueShape).isRequired,
  optionValueKey: string.isRequired,
  placeholder: string,
  placeholderColor: string.isRequired,
  selectedValue: dropdownValueShape,
  showTooltip: bool,
  isError: bool,
  disabled: bool,
}
export default SelectWithOptions

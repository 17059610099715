import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import Button from '../shared/Button'
import Tooltip, { TooltipWrapper } from '../shared/Tooltip'
import Rectangle from '../shared/Rectangle'
import downloadFile from '../../lib/downloadFile'
import {
  WHITE,
  BLUE_GREY,
  ORANGE,
  GREEN,
  RED,
  TEXT_SMALL,
} from '../../constants'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'

import api from '../../lib/api'

const StatusWrapper = styled.div`
  position: relative;
  width: 100%;
  &:hover {
    ${TooltipWrapper} {
      display: block;
      padding: 8px;
    }
  }
`

const StatusDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const RectangleWrapper = styled.div`
  position: relative;

  &:hover {
    ${TooltipWrapper} {
      display: block;
      padding: 8px;
    }
  }
`

const ExpandArrowCircle = styled.div`
  background: ${BLUE_GREY};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: 0 5px;
`

const ExpandArrow = styled.div`
  background: ${WHITE};
  height: 1px;
  width: 9px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  top: 11px;
  ${({ arrowDown }) => arrowDown && 'transform: rotate(90deg)'};

  &:before,
  &:after {
    content: '';
    background: ${WHITE};
    position: absolute;
    height: 1px;
    width: 7px;
  }

  &:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg);
  }
`

const StatusIcon = styled.img`
  margin-right: 10px;
  width: 42px;
`

const jobStatus = {
  failed: { color: RED, icon: 'status-error.png' },
  pending: { color: ORANGE, icon: 'status-processing.png' },
  downloading: { color: ORANGE, icon: 'status-processing.png' },
  merging: { color: ORANGE, icon: 'status-processing.png' },
  transforming: { color: ORANGE, icon: 'status-processing.png' },
  formatting: { color: ORANGE, icon: 'status-processing.png' },
  uploading: { color: ORANGE, icon: 'status-processing.png' },
  parsing: { color: ORANGE, icon: 'status-processing.png' },
  running: { color: ORANGE, icon: 'status-processing.png' },
  succeeded: { color: GREEN, icon: 'status-completed.png' },
  succeededWithoutPublishing: { color: ORANGE, icon: 'status-processing.png' },
  cancelled: { color: RED, icon: 'status-error.png' },
}

const publish = (jobId, outgoingId, t) => {
  api
    .post(`jobs/${jobId}/publish`, { outgoingIds: [outgoingId] })
    .then(() => {
      toast.success(t('job_republish_success'))
    })
    .catch(() => {
      toast.success(t('job_republish_fail'))
      alert('naay!')
    })
}

const shouldDisableButton = status =>
  !(
    status &&
    ['succeeded', 'succeededWithoutPublishing', 'uploading'].includes(status)
  )

export const MainTableColumns = (t, calculateJobDuration, brands) => [
  {
    expander: true,
    width: 50,
    Expander: row => (
      <ExpandArrowCircle>
        {row.isExpanded ? <ExpandArrow arrowDown /> : <ExpandArrow />}
      </ExpandArrowCircle>
    ),
  },
  {
    expander: true,
    Header: t('brand'),
    width: 200,
    Expander: ({ original: { groupId: brandId } }) => {
      const selectedBrand = brands && brands.find(brand => brand.id === brandId)
      return selectedBrand ? selectedBrand.name : ''
    },
  },
  {
    expander: true,
    Header: t('feed'),
    width: 200,
    Expander: ({
      original: {
        feed: { name },
      },
    }) => name,
  },
  {
    Header: t('processing_queue_table_date_started'),
    expander: true,
    width: 250,
    Expander: row => {
      return row.original.startedAt
        ? t('dateValueInput', {
            value: new Date(row.original.startedAt),
          })
        : ''
    },
  },
  {
    Header: t('processing_queue_table_duration'),
    expander: true,
    width: 100,
    Expander: ({ original: { startedAt, finishedAt } }) =>
      calculateJobDuration(startedAt, finishedAt),
  },
  {
    Header: t('status'),
    expander: true,
    width: 150,
    Expander: row => {
      const {
        original: { status, error },
      } = row
      const content = (
        <>
          {jobStatus[status] ? (
            <StatusIcon
              src={`/status/${jobStatus[status].icon}`}
              alt="status"
            />
          ) : (
            ''
          )}
          {t(status)}
        </>
      )
      return (
        <StatusWrapper>
          {error && (
            <Tooltip>
              {t(
                error.code || '1000',
                error.messageParams && {
                  first: error.messageParams[1],
                  second: error.messageParams[2],
                }
              )}
            </Tooltip>
          )}
          {status === 'succeededWithoutPublishing' && (
            <Tooltip>{t(status)}</Tooltip>
          )}
          <StatusDiv>{content}</StatusDiv>
        </StatusWrapper>
      )
    },
  },
  {
    Header: t('alerts'),
    expander: true,
    width: 50,
    Expander: row => {
      const {
        original: { notifications },
      } = row
      return notifications && <Rectangle>{notifications.length}</Rectangle>
    },
  },
  {
    Header: '',
    accessor: 'fileId',
    width: 100,
    Cell: row => {
      const {
        value,
        original: { name, stats, fileId },
      } = row
      if (value) {
        return (
          <Button
            onClick={() => downloadFile(t, `/files/${value}/data`, name, 'csv')}
            fontSize={TEXT_SMALL}
            theme={SECONDARY_BUTTON}
            disabled={!stats.totalProductCount || !fileId}
          >
            {t('button_download').toUpperCase()}
          </Button>
        )
      }
      return null
    },
  },
]

export const JobType = {
  INCOMING: 'incoming',
  MASTER: 'master',
  OUTGOING: 'outgoing',
}

export const SubTableColumns = (
  t,
  calculateDuration,
  getPercentage,
  type,
  masterName,
  jobId,
  notifications = []
) => [
  {
    Header: t(type),
    columns: [
      {
        accessor: 'name',
        width: 200,
      },
    ],
  },
  {
    Header: t('processing_queue_subtable_published'),
    columns: [
      {
        Header: t('processing_queue_subtable_products'),
        Cell: ({
          original: {
            stats: { totalProductCount, publishedProductCount },
          },
        }) => {
          if (publishedProductCount || publishedProductCount === 0)
            return publishedProductCount
          if (totalProductCount || totalProductCount === 0)
            return totalProductCount
          return '-'
        },
        width: 100,
      },
      {
        Header: t('processing_queue_subtable_percentage'),
        width: 100,
        Cell: ({
          original: {
            stats: { totalProductCount, publishedProductCount },
          },
        }) => getPercentage(publishedProductCount, totalProductCount),
      },
    ],
  },
  {
    Header: '',
    expander: true,
    width: 250,
    Expander: row => {
      const {
        original: { stats },
      } = row
      return stats && stats.startedAt
        ? t('dateValueInput', {
            value: new Date(stats.startedAt),
          })
        : ''
    },
  },
  {
    Header: '',
    expander: true,
    width: 100,
    Expander: ({ original: { status, stats } }) =>
      calculateDuration(status, stats),
  },
  {
    Header: '',
    expander: true,
    width: 150,
    Expander: row => {
      const {
        original: { status, error },
      } = row
      const content = (
        <>
          {jobStatus[status] ? (
            <StatusIcon
              src={`/status/${jobStatus[status].icon}`}
              alt="status"
            />
          ) : (
            ''
          )}
          {t(status)}
        </>
      )
      return (
        <StatusWrapper>
          {error && (
            <Tooltip>
              {t(
                error.code || '1000',
                error.messageParams && {
                  first: error.messageParams[1],
                  second: error.messageParams[2],
                }
              )}
            </Tooltip>
          )}
          {status === 'succeededWithoutPublishing' && (
            <Tooltip>{t(status)}</Tooltip>
          )}
          <StatusDiv>{content}</StatusDiv>
        </StatusWrapper>
      )
    },
  },
  {
    Header: '',
    expander: true,
    width: 50,
    Expander: row => {
      let fun = x => {
        return (
          x.data.incomingId === undefined && x.data.outgoingId === undefined
        ) //master check
      }
      if (type === JobType.INCOMING)
        fun = x => x.data.incomingId && x.data.incomingId === row.original.id
      if (type === JobType.OUTGOING)
        fun = x => x.data.outgoingId && x.data.outgoingId === row.original.id

      const partNotifications = notifications.filter(fun)

      return (
        partNotifications &&
        partNotifications.length > 0 && (
          <RectangleWrapper>
            <Tooltip>
              {partNotifications.map((n, i) => (
                <>
                  <span key={i}>{t(`${n.type}`, n.data)}</span>
                  {i < partNotifications.length - 1 && <span>, </span>}
                </>
              ))}
            </Tooltip>
            <Rectangle>{partNotifications.length}</Rectangle>
          </RectangleWrapper>
        )
      )
    },
  },
  {
    Header: '',
    accessor: 'id',
    width: 100,
    Cell: row => {
      const { value, original } = row
      if (jobId) {
        return (
          <>
            <Button
              onClick={() => {
                const url = `/jobs/${jobId}/${type}/${
                  type === JobType.MASTER ? 'data' : `${value}/data`
                }`
                const fileName =
                  type === JobType.MASTER ? masterName : original.name
                const fileType =
                  type === JobType.OUTGOING ? original.stats.format.type : 'csv'
                downloadFile(t, url, fileName, fileType)
              }}
              fontSize={TEXT_SMALL}
              theme={SECONDARY_BUTTON}
              disabled={shouldDisableButton(original.status)}
            >
              {t('button_download').toUpperCase()}
            </Button>
            {type === JobType.OUTGOING && (
              <Button
                fontSize={TEXT_SMALL}
                theme={SECONDARY_BUTTON}
                onClick={() => publish(jobId, original.id, t)}
                disabled={shouldDisableButton(original.status)}
              >
                {t('Upload')}
              </Button>
            )}
          </>
        )
      }
      return null
    },
  },
]

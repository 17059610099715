import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { string } from 'prop-types'

import { RED } from '../../constants'

const ErrorMessageWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${RED};
`

const FormikError = ({ name, ...props }) => {
  const { t } = useTranslation()

  return (
    <ErrorMessage name={name} {...props}>
      {name => (
        <ErrorMessageWrapper>
          {name ? t(name) : t('field_required')}
        </ErrorMessageWrapper>
      )}
    </ErrorMessage>
  )
}

FormikError.propTypes = {
  name: string,
}

export default FormikError

import React from 'react'
import styled from 'styled-components'
import { arrayOf, shape, string, func } from 'prop-types'

import Button from '../Button'
import Clipboard from './Clipboard'
import {
  LIGHT_GREY,
  TEXT_BIG,
  LIGHT_TRANSPARENT_GREY,
  LAPTOP_WIDTH,
} from '../../../constants'
import useSidePanel from '../../shared/SidePanel/useSidePanel'
import { ruleShape } from '../../../types'

const SidePanelWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  width: 400px;
  top: 100px;
  height: 100%;
  transition: right 250ms ease-in-out;
  right: ${({ isPaletteCollapsed }) => (isPaletteCollapsed ? '-360px' : '0')};

  @media (max-width: ${LAPTOP_WIDTH}) {
    width: 340px;
    right: ${({ isPaletteCollapsed }) => (isPaletteCollapsed ? '-300px' : '0')};
  }
`

const PaletteButton = styled(Button)`
  pointer-events: all;

  position: relative;
  right: -40px;
  top: 10vw;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  border-radius: 0;
  outline: none;
  transition: all 75ms ease-in-out;
`

const RightColumn = styled.div`
  pointer-events: all;
  border-left: 2px solid ${LIGHT_GREY};
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  background-color: ${LIGHT_TRANSPARENT_GREY};
  top: 0;
  right: 0;
`

const Wrapper = styled.div`
  overflow-y: visible;
`

const SidePanel = ({ buttons, deleteItem, copiedItems, type }) => {
  const { title, isCollapsed, toggle } = useSidePanel()

  return (
    <SidePanelWrapper isPaletteCollapsed={isCollapsed}>
      <PaletteButton
        fontSize={TEXT_BIG}
        onClick={() => {
          toggle()
        }}
      >
        {title}
      </PaletteButton>
      <RightColumn>
        <Wrapper>
          <Clipboard
            items={copiedItems}
            buttons={buttons}
            deleteItem={deleteItem}
            type={type}
          />
        </Wrapper>
      </RightColumn>
    </SidePanelWrapper>
  )
}

SidePanel.propTypes = {
  title: string,
  buttons: arrayOf(shape({ name: string, onClick: func })),
  deleteItem: func.isRequired,
  copiedItems: arrayOf(ruleShape),
  type: string.isRequired,
}

export default SidePanel

import { toast } from 'react-toastify'
import api from '../lib/api'
import { saveAs } from 'file-saver'

const getFileNameFromHeaders = (t, headers, name, extension) => {
  if (name) {
    let forcedName = name

    if (name && extension) {
      forcedName = name.toLowerCase().endsWith(`.${extension}`)
        ? name
        : `${name}.${extension}`
    }
    return forcedName
  }

  try {
    const splitDisposition = headers['content-disposition'].split(';')
    const rawFilename = splitDisposition[splitDisposition.length - 1]
    return rawFilename.split('=')[1].replace(/"/g, '')
  } catch (error) {
    return 'download'
  }
}

const downloadFile = (t, url, name, extension) => {
  toast.info(t('download_request'))
  return api
    .get(url, { responseType: 'blob', timeout: 0 })
    .then(({ data, headers }) => {
      toast.success(t('download_request_successfull'))
      saveAs(
        new Blob([data], {
          type: headers['content-type'],
        }),
        getFileNameFromHeaders(t, headers, name, extension)
      )
    })
    .catch(() => toast.error(t('download_request_error')))
}

export default downloadFile

import { formatToTimeZone } from 'date-fns-timezone'

import { countryFormats } from '../constants/countryFormats'

export const getDiffFromUTC = value => {
  const dateFormat = countryFormats[0].dateFormat
  const dateByTimezone = formatToTimeZone(new Date(), dateFormat, {
    timeZone: value.id,
  })
  const diffUTC =
    Number(dateByTimezone.substr(dateByTimezone.length - 7, 3)) * -1
  return diffUTC
}

const UTCDiff = timezone =>
  getDiffFromUTC(timezone) * -1 >= 0
    ? `+${getDiffFromUTC(timezone) * -1}`
    : `${getDiffFromUTC(timezone) * -1}`

export const timezoneWithUTC = timezone => ({
  name: `${timezone.name} UTC${UTCDiff(timezone)}`,
  id: timezone.id,
})

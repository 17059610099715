import {
  ORGANISATIONS_FETCHING,
  ORGANISATIONS_DONE,
  ORGANISATIONS_FAILED,
} from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  organisationListState: null,
  organisationList: [],
  fetchError: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ORGANISATIONS_FETCHING:
      return {
        ...state,
        organisationListState: APP_STATE.FETCHING,
      }

    case ORGANISATIONS_DONE:
      return {
        ...state,
        organisationListState: APP_STATE.DONE,
        organisationList: action.organisationList,
      }

    case ORGANISATIONS_FAILED:
      return {
        ...state,
        organisationListState: APP_STATE.ERROR,
        organisationList: [],
        fetchError: action.message,
      }

    default:
      return state
  }
}

import React from 'react'
import { Router } from '@reach/router'

import NoMatch from '../../shared/NoMatch'
import AlertList from './AlertList'
import AlertDetail from '../AlertDetail'
import Loader from '../../shared/Loader'

import useAlerts from '../../../hooks/Alerts'
import { APP_STATE } from '../../../constants'

import { feedShape } from '../../../types'

const Alerts = ({ feed }) => {
  const alerts = useAlerts(feed)

  // use incoming or outgoing methods, outgoing autoupdates when selectedOutgoing
  // changes

  if (alerts.state === APP_STATE.FETCHING) return <Loader />

  if (alerts.state === APP_STATE.ERROR) return <p>Unable to load data</p>

  return (
    <Router primary={false}>
      <AlertList path="/" alerts={alerts} />

      <AlertDetail path=":alertId/*" alerts={alerts} feed={feed} />

      <NoMatch path="*" isRelative noThrow to="/" />
    </Router>
  )
}

Alerts.propTypes = {
  feed: feedShape.isRequired,
}

export default Alerts

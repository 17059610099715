import React from 'react'
import { func, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import ModalWindow from './ModalWindow'

const RemoveModalWindow = ({
  handleDeleteDialogVisibility,
  onRemove,
  isDeleteDialogVisible,
}) => {
  const { t } = useTranslation()
  return (
    <ModalWindow
      cancelText={t('button_cancel')}
      okText={t('confirm_dialog')}
      onCancel={() => handleDeleteDialogVisibility(false)}
      onOk={onRemove}
      title={t('delete_item')}
      visible={isDeleteDialogVisible}
      t={t}
    >
      {t('delete_confirmation')}?
    </ModalWindow>
  )
}

RemoveModalWindow.propTypes = {
  handleDeleteDialogVisibility: func.isRequired,
  onRemove: func.isRequired,
  isDeleteDialogVisible: bool,
}

export default RemoveModalWindow

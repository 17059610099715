import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Tooltip from '../Tooltip2'
import Icon from '../Icon'
import { DARK_GREY } from '../../../constants'

const Wrapper = styled.span`
  width: auto;
  margin-left: 5px;
`

const TooltipIcon = ({ title, position }) => (
  <Wrapper>
    <Tooltip title={title} position={position}>
      <Icon icon={'infoCircle'} color={DARK_GREY} size={18} />
    </Tooltip>
  </Wrapper>
)

TooltipIcon.propTypes = {
  title: string,
  position: string,
}

export default TooltipIcon

import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Text from './Text'
import Heading from './Heading'

const Container = styled.div`
  margin-bottom: 40px;
`

const PageDescription = ({ description, heading }) => (
  <Container>
    <Heading data-id={`header-${heading}`}>{heading}</Heading>
    {description && <Text>{description}</Text>}
  </Container>
)

PageDescription.propTypes = {
  description: string,
  heading: string.isRequired,
}

export default PageDescription

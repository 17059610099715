import {
  ENRICHMENT_LIST_DONE,
  ENRICHMENT_LIST_FAILED,
  ENRICHMENT_LIST_FETCHING,
  ENRICHMENT_HEADER_LOADED,
  OUTGOING_ENRICHMENT_LIST_DONE,
  OUTGOING_ENRICHMENT_LIST_FAILED,
  OUTGOING_ENRICHMENT_LIST_FETCHING,
  OUTGOING_ENRICHMENT_HEADER_LOADED,
} from './actions'

export const initialState = {
  enrichmentListState: null,
  incomingEnrichments: [],
  inputFetchError: '',
  outgoingEnrichmentListState: null,
  outgoingEnrichments: [],
  outputFetchError: '',
}

export const ENRICHMENT_LIST_STATE = {
  DONE: 'ENRICHMENT_LIST_STATE.DONE',
  ERROR: 'ENRICHMENT_LIST_STATE.ERROR',
  FETCHING: 'ENRICHMENT_LIST_STATE.FETCHING',
}

// Creates a new array and adds the action.headers to the corresponding item
// used by OUTGOING_ENRICHMENT_HEADER_LOADED and ENRICHMENT_HEADER_LOADED
const generateNewEnrichmentArrayWithHeader = (enrichments, action) =>
  enrichments.map(enrichment =>
    enrichment.fileId === action.enrichment.fileId
      ? { ...enrichment, headers: action.headers }
      : enrichment
  )

export default (state = initialState, action) => {
  switch (action.type) {
    case ENRICHMENT_LIST_DONE:
      return {
        ...state,
        enrichmentListState: ENRICHMENT_LIST_STATE.DONE,
        incomingEnrichments: action.enrichments,
      }

    case ENRICHMENT_LIST_FETCHING:
      return {
        ...state,
        enrichmentListState: ENRICHMENT_LIST_STATE.FETCHING,
      }

    case ENRICHMENT_LIST_FAILED:
      return {
        ...state,
        enrichmentListState: ENRICHMENT_LIST_STATE.ERROR,
        inputFetchError: action.message,
      }

    case ENRICHMENT_HEADER_LOADED:
      return {
        ...state,
        incomingEnrichments: generateNewEnrichmentArrayWithHeader(
          state.incomingEnrichments,
          action
        ),
      }

    case OUTGOING_ENRICHMENT_LIST_DONE:
      return {
        ...state,
        outgoingEnrichmentListState: ENRICHMENT_LIST_STATE.DONE,
        outgoingEnrichments: action.enrichments,
      }

    case OUTGOING_ENRICHMENT_LIST_FETCHING:
      return {
        ...state,
        outgoingEnrichmentListState: ENRICHMENT_LIST_STATE.FETCHING,
      }

    case OUTGOING_ENRICHMENT_LIST_FAILED:
      return {
        ...state,
        outgoingEnrichmentListState: ENRICHMENT_LIST_STATE.ERROR,
        outputFetchError: action.message,
      }

    case OUTGOING_ENRICHMENT_HEADER_LOADED:
      return {
        ...state,
        outgoingEnrichments: generateNewEnrichmentArrayWithHeader(
          state.outgoingEnrichments,
          action
        ),
      }

    default:
      return state
  }
}

export const getEnrichmentState = ({ enrichmentListState }) =>
  enrichmentListState
export const getIncomingEnrichments = ({ incomingEnrichments }) =>
  incomingEnrichments
export const getOutgoingEnrichments = ({ outgoingEnrichments }) =>
  outgoingEnrichments
export const getOutgoingEnrichmentState = ({ outgoingEnrichmentListState }) =>
  outgoingEnrichmentListState
export const getInputFetchError = ({ inputFetchError }) => inputFetchError
export const getOutputFetchError = ({ outputFetchError }) => outputFetchError

import createArrayFromRange from '../lib/createArrayFromRange'

export const daysInWeek = [
  {
    id: 0,
    name: 'basic_info_day_monday',
  },
  {
    id: 1,
    name: 'basic_info_day_tuesday',
  },
  {
    id: 2,
    name: 'basic_info_day_wednesday',
  },
  {
    id: 3,
    name: 'basic_info_day_thursday',
  },
  {
    id: 4,
    name: 'basic_info_day_friday',
  },
  {
    id: 5,
    name: 'basic_info_day_saturday',
  },
  {
    id: 6,
    name: 'basic_info_day_sunday',
  },
]

export const hours = [
  { id: 0, name: '00' },
  { id: 1, name: '01' },
  { id: 2, name: '02' },
  { id: 3, name: '03' },
  { id: 4, name: '04' },
  { id: 5, name: '05' },
  { id: 6, name: '06' },
  { id: 7, name: '07' },
  { id: 8, name: '08' },
  { id: 9, name: '09' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 23, name: '23' },
]

// Arrays below have to start with number 1, as stated in library documentation - https://www.npmjs.com/package/cron-converter

export const daysInMonth = createArrayFromRange(1, 31)

export const monthsInYear = createArrayFromRange(1, 12)

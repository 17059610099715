import { useState, useEffect } from 'react'

import api from '../../lib/api'
import { APP_STATE } from '../../constants'

const useAlerts = feed => {
  const [list, setList] = useState([])
  const [state, setState] = useState(APP_STATE.FETCHING)

  const getAlertUrl = () => `/feeds/${feed.id}/alerts`

  useEffect(
    () => {
      fetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feed]
  )

  const fetch = (resetOldList = true) => {
    if (resetOldList) setList([])
    return api
      .get(getAlertUrl())
      .then(({ data }) => {
        setState(APP_STATE.DONE)
        setList(data)
        return Promise.resolve(data)
      })
      .catch(err => {
        setState(APP_STATE.ERROR)
        if (resetOldList) setList([])
        console.log(err)
        return Promise.reject(err)
      })
  }

  const remove = id => {
    return api
      .delete(`${getAlertUrl()}/${id}`)
      .then(() => {
        fetch(false)
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
  const update = (id, alert) => {
    return api
      .patch(`${getAlertUrl()}/${id}`, alert)
      .then(({ data }) => {
        fetch(false)
        return Promise.resolve(data)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
  const get = id => {
    return api
      .get(`${getAlertUrl()}/${id}`)
      .then(({ data }) => {
        const conditions = [{ type: data.type, options: data.options }]
        delete alert.type
        delete alert.condition

        // remove this once we have multiselect dropdown
        if (
          conditions &&
          conditions[0] &&
          conditions[0].options &&
          conditions[0].options.outgoingIds &&
          conditions[0].options.outgoingIds.length > 1
        )
          conditions[0].options.outgoingIds = ['all']

        return Promise.resolve({ ...data, conditions })
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
  const add = alert => {
    return api
      .post(getAlertUrl(), alert)
      .then(({ data }) => {
        fetch(false)
        return Promise.resolve(data)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  return {
    // Variables
    list,
    state,
    // Methods
    add,
    remove,
    update,
    get,
  }
}

export default useAlerts

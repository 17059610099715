import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { arrayOf } from 'prop-types'

import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { cardButtonShape } from '../../../types'

import Button from '../Button'
import Tooltip from '../Tooltip2'

const Wrapper = styled.div`
  display: flex;
  margin: auto 0 0;
`

const StyledButton = styled(Button)`
  margin-right: 15px;
`

const TopButtonsWrapper = ({ buttons }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {buttons &&
        buttons.map(
          ({ name, isHidden, tooltip, ...otherProps }) =>
            !isHidden && (
              <Tooltip title={tooltip} key={name}>
                <StyledButton
                  dataId={`${name}`}
                  key={name}
                  theme={SECONDARY_BUTTON}
                  {...otherProps}
                >
                  {t(name)}
                </StyledButton>
              </Tooltip>
            )
        )}
    </Wrapper>
  )
}

TopButtonsWrapper.propTypes = {
  buttons: arrayOf(cardButtonShape),
}

export default TopButtonsWrapper

import { MASTER_HEADERS_RECEIVED, OUTGOING_MAPPING_RECEIVED } from './actions'

import { APP_STATE } from '../../../constants'

export const initialState = {
  stateOfAction: APP_STATE.FETCHING,
  outgoingMapping: undefined,
  headers: undefined,
  errorMsg: '',
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATE.FETCHING:
      return {
        ...state,
        stateOfAction: APP_STATE.FETCHING,
      }

    case APP_STATE.ERROR:
      return {
        ...state,
        stateOfAction: APP_STATE.ERROR,
        errorMsg: action.msg,
      }

    case APP_STATE.DONE:
      return {
        ...state,
        stateOfAction: APP_STATE.DONE,
      }

    case MASTER_HEADERS_RECEIVED:
      return {
        ...state,
        headers: action.headers,
      }

    case OUTGOING_MAPPING_RECEIVED:
      return {
        ...state,
        outgoingMapping: action.mapping,
        stateOfAction: APP_STATE.DONE,
      }

    default:
      return state
  }
}

import axios from 'axios'
import { navigate } from '@reach/router'

import { apiUrl } from '../constants/urls'

const defaultHeader = { 'Content-Type': 'application/json' }

const addTokenToHeader = config => {
  if (!config.headers) config.headers = {}

  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

const logoutAndRedirect = logoutUser => error => {
  if (error.response && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      logoutUser()
    }

    navigate('/login')
  }

  return Promise.reject(error)
}

export const setupAxios = logoutUser => {
  api.interceptors.request.use(addTokenToHeader)

  api.interceptors.response.use(
    response => response,
    logoutAndRedirect(logoutUser)
  )
}

export const clearAxiosHeaders = () => {
  api.request(config => {
    config.headers = defaultHeader
    return config
  })
}

const api = axios.create({
  baseURL: apiUrl,
  timeout: 30000,
  headers: defaultHeader,
})

export default api

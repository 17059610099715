import React, { Fragment } from 'react'
import styled from 'styled-components'
import { arrayOf, func, bool, string } from 'prop-types'

import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { fileShape } from '../../../types'
import { CARD_HEIGHT_SMALL } from '../../../constants'
import downloadFile from '../../../lib/downloadFile'

import Card from '../../shared/Card'
import Loader from '../../shared/Loader'
import Button from '../../shared/Button'
import ContentWrapper from '../../shared/ContentWrapper'
import CardGridWrapper from '../../shared/CardGridWrapper'

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 0;
`

const SystemFileList = ({
  files,
  isLoading,
  numberOfFiles,
  onDeleteClicked,
  onLoadMoreClicked,
  onUploadClicked,
  prepareNewFile,
  t,
}) => (
  <Fragment>
    <Button
      theme={SECONDARY_BUTTON}
      onClick={prepareNewFile}
      dataId="add-file-button"
    >
      {t('button_new_file')}
    </Button>

    <ContentWrapper>
      <CardGridWrapper>
        {files.length === 0 && t('no_data')}
        {files.map(({ id, name, type }) => (
          <Card
            icon={type}
            key={id}
            height={CARD_HEIGHT_SMALL}
            noStatus
            title={name}
            buttons={[
              {
                name: t('button_delete'),
                onClick: () => onDeleteClicked(id),
              },
              {
                name: t('button_upload'),
                onClick: () => onUploadClicked(id),
              },
              {
                name: t('button_download'),
                onClick: () =>
                  downloadFile(t, `/files/${id}/data`, name, 'csv'),
              },
            ]}
          />
        ))}
      </CardGridWrapper>

      {isLoading && <Loader />}

      {files.length > 0 && Number(numberOfFiles) > files.length && (
        <LoadMoreContainer>
          <Button onClick={onLoadMoreClicked}>{t('button_load_more')}</Button>
        </LoadMoreContainer>
      )}
    </ContentWrapper>
  </Fragment>
)

SystemFileList.propTypes = {
  files: arrayOf(fileShape),
  isLoading: bool,
  onDeleteClicked: func.isRequired,
  onLoadMoreClicked: func.isRequired,
  onUploadClicked: func.isRequired,
  numberOfFiles: string,
  prepareNewFile: func.isRequired,
}

export default SystemFileList

import React, { useState } from 'react'
import { any } from 'prop-types'

const AnnouncementContext = React.createContext([{}, () => {}])

const AnnouncementProvider = props => {
  const [state, setState] = useState({
    text: '',
  })
  return (
    <AnnouncementContext.Provider value={[state, setState]}>
      {props.children}
    </AnnouncementContext.Provider>
  )
}

AnnouncementProvider.propTypes = {
  children: any,
}

export { AnnouncementContext, AnnouncementProvider }

import React, { Fragment } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'
import { string } from 'prop-types'

import PageDescription from '../../shared/PageDescription'
import Input from '../../shared/Input'
import Button from '../../shared/Button'
import Form from '../../shared/Form'
import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'
import api from '../../../lib/api'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  margin: 10px;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 300px;
`

const Label = styled.div`
  padding: 10px 0;
`

const AddNewUser = ({ t, organisationId }) => {
  const saveUser = email => {
    api
      .post(`/orgs/${organisationId}/invitations`, { email: email })
      .then(() => {
        toast.success(t('new_user_invited_successful'))
        navigate(`/${organisationId}/admin/invitations`)
      })
      .catch(error =>
        toastDetailErrorMessage('new_user_invited_unsuccessful', error, t)
      )
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('invite_user')}
        description={t('add_new_user_description')}
      />
      <Form
        initialValues={{
          email: '',
        }}
        onSubmit={({ email }) => {
          saveUser(email)
        }}
        content={() => (
          <Fragment>
            <InputContainer>
              <Label>{t('email')}</Label>
              <Input type="email" name="email" data-id="new-user" />
            </InputContainer>
            <ButtonsContainer>
              <StyledButton
                onClick={() => navigate(`/${organisationId}/admin/users`)}
                theme={SECONDARY_BUTTON}
              >
                {t('button_cancel')}
              </StyledButton>
              <StyledButton data-id="button-user-save" type="submit">
                {t('button_invite_user')}
              </StyledButton>
            </ButtonsContainer>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

AddNewUser.propTypes = {
  organisationId: string.isRequired,
}

export default AddNewUser

import React from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { func, string, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'

import useOrganisations from '../../shared/system/useOrganisations'
import useBrands from '../../shared/system/useBrands'

import {
  LAPTOP_WIDTH,
  LIGHT_GREY,
  SIDE_NAVIGATION_WIDTH_DESKTOP,
  SIDE_NAVIGATION_WIDTH_LAPTOP,
  HEADER_HEIGHT,
} from '../../../constants'
import { feedShape, incomingCompactShape, outgoingShape } from '../../../types'

import HeaderRow from './HeaderRow'
import Dropdown from '../../shared/Dropdown'

const PanelWrapper = styled.div`
  width: ${SIDE_NAVIGATION_WIDTH_DESKTOP};
  height: ${HEADER_HEIGHT};
  padding-left: 25px;
  ${({ hasBorder }) => hasBorder && `border-left: 2px solid ${LIGHT_GREY}`};

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    width: ${SIDE_NAVIGATION_WIDTH_LAPTOP};
  }
`

const DropdownWrapper = styled.div`
  height: ${HEADER_HEIGHT};
  float: right;
  ${({ hasBorder }) => hasBorder && `border-left: 2px solid ${LIGHT_GREY}`};
  ${({ isOrganisationWrapper }) =>
    isOrganisationWrapper && 'position: absolute'};
  ${({ isOrganisationWrapper }) => isOrganisationWrapper && 'right: 60px'};
  width: ${({ isOrganisationWrapper }) =>
    isOrganisationWrapper ? '300px' : '220px'};

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    ${({ isOrganisationWrapper }) => isOrganisationWrapper && 'right: 0'};
  }
`

const FeedPanel = ({
  changeFeed,
  changeOutgoing,
  changeIncoming,
  feeds,
  outgoing,
  incoming,
  pathName,
  selectedFeed,
  selectedOutgoing,
  selectedIncoming,
}) => {
  const { t } = useTranslation()
  const orgs = useOrganisations()
  const brands = useBrands()

  const subPath = pathName.split('/')
  const isFeedSelected = subPath[3] !== 'new'
  const isReportsPage = subPath[2] === 'reports'
  const isAdminPage = subPath[2] === 'admin'
  const isFeedPage = subPath[2] === 'feeds' && subPath.length === 3
  const showFeedSelector =
    isFeedSelected && feeds && feeds.length > 0 && !isFeedPage && !isAdminPage
  const defaultSelect = { name: t('select'), id: 'all' }
  const pathnamesWithOutgoings = [
    'rules',
    'enrichments',
    'exclusions',
    'mapping',
  ]

  // redirect
  const setSelectedBrand = (...args) => {
    brands.setSelected(...args)
    changeFeed(null)

    const path = window.location.pathname
    navigate(
      `/${orgs.selected.id}/${path.includes('reports') ? 'reports' : 'feeds'}`
    )
  }

  return (
    <HeaderRow>
      {brands.list && !isAdminPage && (
        <PanelWrapper>
          <DropdownWrapper>
            <Dropdown
              isHigh
              label={t('brand')}
              onChange={setSelectedBrand}
              options={[defaultSelect, ...brands.list]}
              disabled={brands.list.length === 0}
              noBorder
              boundValue={brands.selected}
            />
          </DropdownWrapper>
        </PanelWrapper>
      )}

      {showFeedSelector && (
        <DropdownWrapper hasBorder>
          <Dropdown
            isHigh
            label={t('feed')}
            noBorder
            onChange={feed => changeFeed(feed.id)}
            options={[...(isReportsPage ? [defaultSelect] : []), ...feeds]}
            disabled={feeds.length <= 1}
            boundValue={selectedFeed || defaultSelect}
          />
        </DropdownWrapper>
      )}

      {(pathnamesWithOutgoings.includes(subPath[4]) ||
        (subPath[4] === 'outgoing' && subPath[5] && subPath[5] !== 'new')) &&
        outgoing && (
          <DropdownWrapper hasBorder>
            <Dropdown
              isHigh
              label={t('outgoing')}
              noBorder
              options={outgoing}
              disabled={outgoing.length <= 1}
              onChange={changeOutgoing}
              boundValue={selectedOutgoing}
            />
          </DropdownWrapper>
        )}

      {('incomingmapping' === subPath[4] ||
        ('incoming' === subPath[4] && subPath[5] && subPath[5] !== 'new')) &&
        incoming && (
          <DropdownWrapper hasBorder>
            <Dropdown
              isHigh
              label={t('incoming')}
              noBorder
              options={incoming}
              disabled={incoming.length <= 1}
              onChange={changeIncoming}
              boundValue={selectedIncoming}
            />
          </DropdownWrapper>
        )}
      {orgs.list && orgs.list.length > 0 && (
        <DropdownWrapper hasBorder isOrganisationWrapper>
          <Dropdown
            isHigh
            label={t('organisation')}
            dataId="organisation-switcher"
            noBorder
            options={orgs.list}
            disabled={orgs.list.length <= 1}
            onChange={organisation => orgs.setSelected(organisation)}
            boundValue={orgs.selected}
          />
        </DropdownWrapper>
      )}
    </HeaderRow>
  )
}

FeedPanel.propTypes = {
  changeFeed: func,
  changeOutgoing: func,
  changeIncoming: func,
  feeds: arrayOf(feedShape),
  outgoing: arrayOf(outgoingShape),
  incoming: arrayOf(incomingCompactShape),
  pathName: string,
  selectedFeed: feedShape,
  selectedOutgoing: outgoingShape,
  selectedIncoming: incomingCompactShape,
}

export default FeedPanel

import React, { useEffect, useState } from 'react'
import { string, arrayOf, func } from 'prop-types'
import styled from 'styled-components'

import { APP_STATE, CARD_HEIGHT_LARGE } from '../constants'
import { imagesUrl } from '../constants/urls'
import { PRIMARY_BUTTON, SECONDARY_BUTTON } from '../constants/buttonThemes'
import { feedShape } from '../types'
import publishSelectedFeed from '../lib/feedList/publishSelectedFeed'
import removeSelectedFeed from '../lib/feedList/removeSelectedFeed'
import getFilteredFeedsByBrand from '../lib/feedList/getFilteredFeedsByBrand'

import Button from '../components/shared/Button'
import PageDescription from '../components/shared/PageDescription'
import Card from '../components/shared/Card'
import ContentWrapper from '../components/shared/FullWidthContentWrapper'
import Loader from '../components/shared/Loader'
import CardGridWrapper from '../components/shared/CardGridWrapper'
import ErrorBoundary from '../components/shared/ErrorBoundary'
import ModalWindow from '../components/shared/ModalWindow'
import Tooltip from '../components/shared/Tooltip2'

import useOrganisations from '../components/shared/system/useOrganisations'
import useBrands from '../components/shared/system/useBrands'
import { useTranslation } from 'react-i18next'

const getPropertyFromDictionary = (id, dictionary, parameter, fallback) => {
  const value = dictionary[id]
  return value && value[parameter] ? value[parameter] : fallback
}

const DivWithBottomMargin = styled.div`
  margin-bottom: 25px;
`

const FeedListPage = ({
  errorMsg,
  feedListState,
  feeds,
  onPauseToggle,
  selectedFeedGroupId,
  reloadFeeds,
}) => {
  const { selected } = useOrganisations()
  const { t } = useTranslation()
  const brands = useBrands()
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [toDeleteFeedId, setToDeleteFeedId] = useState('')

  const organisationId = selected.id

  useEffect(() => {
    brands.selected.id === selectedFeedGroupId &&
      brands.setSelected(brands.allBrandsOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prepareFeedDelete = id => {
    setToDeleteFeedId(id)
    setDeleteDialogVisible(true)
  }

  const list = APP_STATE.DONE
    ? getFilteredFeedsByBrand(brands.selected.id, feeds)
    : []

  const brandDictionary = (brands.list || []).reduce((acc, templ) => {
    acc[templ.id] = {
      ...templ,
      imageLink: `${imagesUrl}/brands${templ.imageLink}`,
    }
    return acc
  }, {})

  return (
    <ErrorBoundary>
      <PageDescription
        heading={t('feeds_title')}
        description={t('feeds_description')}
      />

      <Tooltip title={t('add_new_feed_tooltip')}>
        <Button
          theme={SECONDARY_BUTTON}
          link="new/basic-info"
          dataId="button-newfeed"
        >
          {t('button_new_feed')}
        </Button>
      </Tooltip>

      {feedListState === APP_STATE.FETCHING && <Loader />}
      {feedListState === APP_STATE.ERROR && <p>{errorMsg}</p>}

      {feedListState === APP_STATE.DONE && (
        <ContentWrapper>
          <DivWithBottomMargin>
            {t('feeds_paging', { count: list.length, total: feeds.length })}
          </DivWithBottomMargin>
          <CardGridWrapper>
            {list.map(
              ({
                id,
                groupId,
                isConfigured,
                name,
                enabled,
                job,
                notifications = [],
              }) => (
                <Card
                  key={id}
                  title={getPropertyFromDictionary(
                    groupId,
                    brandDictionary,
                    'name',
                    t('unavailable')
                  )}
                  status={enabled ? 'active' : 'paused'}
                  alertCount={notifications.length}
                  description={name}
                  id={id}
                  img={getPropertyFromDictionary(
                    groupId,
                    brandDictionary,
                    'imageLink',
                    '/favicon.ico'
                  )}
                  height={CARD_HEIGHT_LARGE}
                  isClickable
                  column={[
                    {
                      heading: t('partners_last_published'),
                      description:
                        job && job.finishedAt
                          ? t('dateValueInput', {
                              value: new Date(job.finishedAt),
                            })
                          : t('not_available'),
                    },
                    {
                      heading: t('number_of_products'),
                      description:
                        job && job.incoming && job.incoming.stats
                          ? t('numericValueInput', {
                              value: job.incoming.stats.totalProductCount || 0,
                            })
                          : t('not_available'),
                    },
                  ]}
                  buttons={[
                    {
                      name: enabled ? t('button_pause') : t('button_enable'),
                      onClick: () => onPauseToggle(id, !enabled),
                    },
                    {
                      name: t('button_setup'),
                      link: `${id}/basic-info`,
                      tooltip: t('button_setup_tooltip'),
                    },
                    {
                      name: t('button_publish'),
                      tooltip: t('button_publish_tooltip'),
                      onClick: () => publishSelectedFeed(id, organisationId, t),
                      disabled: !isConfigured,
                    },
                    {
                      name: t('button_delete'),
                      tooltip: t('button_remove_feed_tooltip'),
                      onClick: () => prepareFeedDelete(id),
                    },
                  ]}
                />
              )
            )}
          </CardGridWrapper>
        </ContentWrapper>
      )}
      <ModalWindow
        visible={deleteDialogVisible}
        okText={t('button_delete')}
        onOk={() =>
          removeSelectedFeed(toDeleteFeedId, t).then(() => {
            reloadFeeds()
            setDeleteDialogVisible(false)
            setToDeleteFeedId('')
          })
        }
        okTheme={PRIMARY_BUTTON}
        onCancel={() => {
          setDeleteDialogVisible(false)
          setToDeleteFeedId('')
        }}
        cancelTheme={SECONDARY_BUTTON}
        t={t}
      >
        {t('feed_delete_confirmation')}
      </ModalWindow>
    </ErrorBoundary>
  )
}

FeedListPage.propTypes = {
  errorMsg: string,
  feedListState: string.isRequired,
  feeds: arrayOf(feedShape).isRequired,
  onPauseToggle: func,
  selectedFeedGroupId: string,
  reloadFeeds: func,
}

export default FeedListPage

import { toast } from 'react-toastify'

import { fetchingData, fetchingFailed } from '../../App/actions'
import api from '../../../lib/api'
import { notCanceled } from '../../../lib/signal'

export const MASTER_HEADERS_RECEIVED = 'MASTER_HEADERS_RECEIVED'
export const OUTGOING_MAPPING_RECEIVED = 'OUTGOING_MAPPING_RECEIVED'

const getMasterHeaders = feedId => `/feeds/${feedId}/master/headers`
const getOutgoingMappingUrl = (feedId, outgoingId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/mapping/`

export const receivedOutgoingMapping = mapping => ({
  type: OUTGOING_MAPPING_RECEIVED,
  mapping,
})

export const receivedMasterHeaders = headers => ({
  type: MASTER_HEADERS_RECEIVED,
  headers,
})

export const updateOutgoingMapping = (
  source,
  value,
  outgoingMapping,
  dispatch
) => {
  outgoingMapping[source] = {
    ...outgoingMapping[source],
    type: 'col',
    options: { name: value },
  }
  dispatch(receivedOutgoingMapping(outgoingMapping))
}

export const fetchOutgoingMapping = (
  feedId,
  outgoingId,
  dispatch,
  t,
  signal
) => {
  const options = { cancelToken: signal && signal.token }

  dispatch(fetchingData())
  Promise.all([
    api.get(getMasterHeaders(feedId)),
    api.get(getOutgoingMappingUrl(feedId, outgoingId), options),
  ])

    .then(([{ data: headers }, { data: mapping }]) => {
      dispatch(receivedOutgoingMapping(mapping))
      dispatch(receivedMasterHeaders(headers))
    })
    .catch(notCanceled(err => loadingFailed(err, dispatch, t)))
}

export const updateMappings = (
  feedId,
  selectedOutgoing,
  outgoingMapping,
  dispatch,
  t
) =>
  api
    .put(getOutgoingMappingUrl(feedId, selectedOutgoing.id), outgoingMapping)
    .then(() => toast.success(t('mapping_saved_success')))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('mapping_saved_error'))
    })

const loadingFailed = (err, dispatch, t) => {
  dispatch(fetchingFailed(err.message))
  toast.error(t('loading_failed'))
}

import { PARTNERS_FETCHING, PARTNERS_DONE, PARTNERS_FAILED } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  partnerListState: null,
  partnerList: [],
  fetchError: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case PARTNERS_FETCHING:
      return {
        ...state,
        partnerListState: APP_STATE.FETCHING,
      }

    case PARTNERS_DONE:
      return {
        ...state,
        partnerListState: APP_STATE.DONE,
        partnerList: action.brandList,
      }

    case PARTNERS_FAILED:
      return {
        ...state,
        partnerListState: APP_STATE.ERROR,
        partnerList: [],
        fetchError: action.message,
      }

    default:
      return state
  }
}

import React, { Fragment, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { string, func } from 'prop-types'

import { SubTableColumns, JobType } from './TableColumns'
import { MIDDLE_GREY, TEXT_SMALL } from '../../constants'

const ReactSubTable = styled(ReactTable)`
  border: none !important;
  position: initial !important;

  .rt-tbody > .rt-tr-group {
    margin: 0 !important;
    box-shadow: none !important;
    padding: 0 10px 0 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .rt-tbody > .rt-tr-group:last-child {
    margin-bottom: 10px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-top: none;
  }

  .rt-tbody > .rt-tr-group:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .-headerGroups {
    padding-top: 10px !important;
  }

  .rt-thead {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .rt-tr {
      padding: 0 10px 0 50px !important;
      text-align: left !important;
    }
  }

  .rt-resizable-header > .rt-resizable-header-content {
    color: ${MIDDLE_GREY};
    font-size: ${TEXT_SMALL};
  }
`

const SubTable = ({
  calculateDuration,
  fetchJobIncomingOutgoing,
  getPercentage,
  id,
  masterName,
  getTrProps,
  t,
}) => {
  const [outgoingItems, setOutgoingItems] = useState([])
  const [incomingItems, setIncomingItems] = useState([])
  const [masterItems, setMasterItems] = useState([])
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    fetchJobIncomingOutgoing(id, t).then(
      ({ data: { outgoing, incoming, master, notifications } }) => {
        setOutgoingItems(outgoing)
        setIncomingItems(incoming)
        setMasterItems([master])
        setNotifications(notifications)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (outgoingItems.length === 0 && incomingItems.length === 0) return null
  const { OUTGOING, INCOMING, MASTER } = JobType

  return (
    <Fragment>
      {incomingItems.length > 0 && (
        <ReactSubTable
          data={incomingItems}
          pageSize={incomingItems.length}
          columns={SubTableColumns(
            t,
            calculateDuration,
            getPercentage,
            INCOMING,
            null,
            id,
            notifications
          )}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          sortable={false}
        />
      )}
      {masterItems.length > 0 && (
        <ReactSubTable
          data={masterItems}
          pageSize={masterItems.length}
          columns={SubTableColumns(
            t,
            calculateDuration,
            getPercentage,
            MASTER,
            masterName,
            id,
            notifications
          )}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          sortable={false}
        />
      )}
      {outgoingItems.length > 0 && (
        <ReactSubTable
          data={outgoingItems}
          pageSize={outgoingItems.length}
          columns={SubTableColumns(
            t,
            calculateDuration,
            getPercentage,
            OUTGOING,
            null,
            id,
            notifications
          )}
          getTrProps={getTrProps}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          sortable={false}
        />
      )}
    </Fragment>
  )
}

SubTable.propTypes = {
  calculateDuration: func.isRequired,
  fetchJobIncomingOutgoing: func.isRequired,
  getPercentage: func.isRequired,
  getTrProps: func,
  id: string.isRequired,
  masterName: string,
}

export default SubTable

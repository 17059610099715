import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { Router, navigate } from '@reach/router'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { reducer, initialState } from './reducer'
import {
  fetchMasterRuleList,
  fetchOutgoingRuleList,
  removeRule,
  updateRule,
  updatePosition,
  selectedOutgoingChanged,
} from './actions'
import { getRuleDetail } from '../RuleDetail/actions'

import { APP_STATE } from '../../../constants'
import { incomingCompactShape, outgoingShape } from '../../../types'

import NoMatch from '../../shared/NoMatch'
import SidePanel from '../../shared/SidePanel'
import SidePanelPortal from '../../shared/SidePanel/SidePanelPortal'
import useSidePanel from '../../shared/SidePanel/useSidePanel'

import RuleList from './RuleList'
import RuleDetail from '../RuleDetail'
import Loader from '../../shared/Loader'

const RuleRouter = ({
  feedId,
  selectedOutgoing,
  selectedIncoming,
  organisationId,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { t } = useTranslation()

  const { inputList, outputList, stateOfAction, errorMsg } = state

  const [clipboardRule, setClipboardRule] = useState(null)
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [selectedRuleIds, setSelectedRuleIds] = useState({
    ruleId: null,
    incomingId: null,
    outgoingId: null,
  })

  const panel = useSidePanel()

  const savedOptimisation = 'transformations'
  const rulesFromSession =
    JSON.parse(sessionStorage.getItem(savedOptimisation)) || []
  const [copiedRules, setCopiedRules] = useState(rulesFromSession)

  useEffect(() => {
    fetchMasterRuleList(feedId, dispatch, t)
    panel.setTitle(t('sidepanel_clipboard_button'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (selectedOutgoing) {
        fetchOutgoingRuleList(feedId, selectedOutgoing.id, dispatch, t)
        dispatch(selectedOutgoingChanged(selectedOutgoing))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const fetchData = (incomingId, outgoingId) => {
    if (outgoingId) {
      fetchOutgoingRuleList(feedId, outgoingId, dispatch, t)
    }

    fetchMasterRuleList(feedId, dispatch, t)
  }

  const handleDeleteDialogVisibility = (visible, ruleId, outgoingId) => {
    setDeleteDialogVisible(visible)
    setSelectedRuleIds({
      ruleId,
      outgoingId,
    })
  }

  const onRemove = () => {
    const { ruleId, outgoingId } = selectedRuleIds
    removeRule(feedId, ruleId, outgoingId, t).finally(() =>
      fetchData(selectedIncoming.id, selectedOutgoing.id)
    )

    handleDeleteDialogVisibility(false)
  }

  const onPause = (ruleId, enabled, outgoingId) => {
    updateRule(feedId, ruleId, { enabled: enabled }, outgoingId, t).finally(
      () => fetchData(selectedIncoming.id, selectedOutgoing.id)
    )
  }

  const onPositionChange = ({ id: ruleId, newPosition, outgoingId }) => {
    updatePosition(feedId, ruleId, newPosition, outgoingId, t).finally(() =>
      fetchData(selectedIncoming.id, selectedOutgoing.id)
    )
  }

  const addToClipboard = (ids, outgoingId) => {
    setTimeout(() => {
      panel.show()
    }, 10)

    if (!Array.isArray(ids)) ids = [ids]

    const idsInClipboard = copiedRules.map(x => x.id)
    const idsToAdd = ids.filter(item => !idsInClipboard.includes(item))

    if (idsToAdd.length > 0) {
      const promises = idsToAdd.map(id => getRuleDetail(feedId, id, outgoingId))
      Promise.all(promises).then(results => {
        const items = results.map(x => x.data)
        const updatedCopiedRules = [...copiedRules, ...items]
        setCopiedRules(updatedCopiedRules)
        sessionStorage.setItem(
          savedOptimisation,
          JSON.stringify(updatedCopiedRules)
        )
      })
    }
  }

  const deleteItemClipboard = index => {
    const deletedRule = copiedRules.splice(index, 1)
    const updatedCopiedRules = copiedRules.filter(rule => rule !== deletedRule)
    setCopiedRules(updatedCopiedRules)
    sessionStorage.setItem(
      savedOptimisation,
      JSON.stringify(updatedCopiedRules)
    )
    if (copiedRules.length === 0) panel.hide()
  }

  const buttonsItemClipboard = [
    {
      name: t('clipboard-add-to-master'),
      onClick: rule => {
        setClipboardRule(rule)
        // remove the incoming and change it to new/master once we fix mapping we need for column list
        navigate(`/${organisationId}/feeds/${feedId}/rules`)
          .then(() =>
            navigate(`/${organisationId}/feeds/${feedId}/rules/new/master`)
          )
          .then(() => setClipboardRule(undefined))
      },
    },
    {
      name: t('clipboard-add-to-outgoing'),
      onClick: rule => {
        setClipboardRule(rule)
        navigate(`/${organisationId}/feeds/${feedId}/rules`)
          .then(() =>
            navigate(
              `/${organisationId}/feeds/${feedId}/rules/new/outgoing/${
                selectedOutgoing.id
              }`
            )
          )
          .then(() => setClipboardRule(undefined))
      },
    },
  ]

  if (!inputList || !outputList || !selectedOutgoing || !selectedIncoming) {
    return <Loader />
  }

  if (stateOfAction === APP_STATE.ERROR) return <p>{errorMsg}</p>

  return (
    <Fragment>
      <Router primary={false}>
        <RuleList
          addToClipboard={addToClipboard}
          path="/"
          inputList={inputList}
          outputList={outputList}
          selectedIncoming={selectedIncoming}
          selectedOutgoing={selectedOutgoing}
          onRemove={onRemove}
          onPause={onPause}
          onPositionChange={onPositionChange}
          isDeleteDialogVisible={isDeleteDialogVisible}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        />

        <RuleDetail
          feedId={feedId}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          organisationId={organisationId}
          fetchData={fetchData}
          clipboardRule={clipboardRule}
          path=":ruleId/master"
        />
        <RuleDetail
          feedId={feedId}
          fetchData={fetchData}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          organisationId={organisationId}
          clipboardRule={clipboardRule}
          selectedOutgoing={selectedOutgoing}
          path=":ruleId/outgoing/:outgoingId"
        />

        <NoMatch path="*" isRelative noThrow to="/" />
      </Router>
      <SidePanelPortal>
        <SidePanel
          t={t}
          buttons={buttonsItemClipboard}
          deleteItem={deleteItemClipboard}
          copiedItems={copiedRules}
          type="transformations"
        />
      </SidePanelPortal>
    </Fragment>
  )
}

RuleRouter.propTypes = {
  feedId: string.isRequired,
  organisationId: string.isRequired,
  selectedOutgoing: outgoingShape,
  selectedIncoming: incomingCompactShape,
}

export default RuleRouter

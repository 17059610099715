import React, { Fragment } from 'react'
import styled from 'styled-components'
import { func, string, arrayOf, number, object, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../Button'
import Dropdown from '../Dropdown'
import Selection from './Selection'
import Text from '../Text'

import { DARK_GREY, TEXT_BIG } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

const Container = styled.div`
  width: 100%;
  padding: 10px 70px 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 220px));
  grid-gap: 15px;
  position: relative;
`

const DropdownWrapper = styled.div`
  width: 100%;
  margin-right: 16px;
  max-width: 250px;
`

const RemoveButton = styled(Button)`
  margin-top: 25px;
  position: absolute;
  right: 0;
`

const OperatorComponent = ({
  caseSensitive,
  columnList,
  dataId,
  hasFullHeight,
  hideColumnList,
  index,
  label,
  outputCols,
  removeClick,
  selectedOperator,
  selectedSource,
  text,
  treeToRender,
  updateCaseSensitive,
  updateSource,
  updateOperator,
}) => {
  const { t } = useTranslation()

  const andText = text === 'then' ? 'and_then' : 'and_if'
  const shownList =
    text === 'then' && outputCols && outputCols.length > 0
      ? outputCols
      : columnList

  // fix the issue when default value was not used to updateSource
  if (!selectedSource) {
    selectedSource = shownList[0]
    updateSource(index, selectedSource)
  }

  return (
    <Fragment>
      <Text size={TEXT_BIG} color={DARK_GREY}>
        {index === 0 ? t(text) : t(andText)}
      </Text>
      <Container>
        {!hideColumnList && (
          <DropdownWrapper>
            <Dropdown
              dataId={dataId}
              label={label}
              onChange={source => updateSource(index, source.id)}
              options={shownList.map(column => ({ id: column }))}
              optionNameKey="id"
              value={{ id: selectedSource }}
            />
          </DropdownWrapper>
        )}

        <Selection
          caseSensitive={caseSensitive}
          hasFullHeight={hasFullHeight}
          index={index}
          list={treeToRender}
          onChange={updateOperator}
          selectedValue={selectedOperator}
          shownList={shownList}
          text={text}
          updateCaseSensitive={updateCaseSensitive}
        />
        <RemoveButton
          onClick={() => removeClick(index)}
          theme={SECONDARY_BUTTON}
        >
          {t('button_clear_one')}
        </RemoveButton>
      </Container>
    </Fragment>
  )
}

OperatorComponent.propTypes = {
  caseSensitive: bool,
  dataId: string,
  columnList: arrayOf(string).isRequired,
  hasFullHeight: bool,
  hideColumnList: bool,
  index: number.isRequired,
  label: string,
  outputCols: arrayOf(string),
  removeClick: func.isRequired,
  selectedOperator: string,
  selectedSource: string,
  text: string.isRequired,
  treeToRender: arrayOf(object),
  updateCaseSensitive: func.isRequired,
  updateOperator: func.isRequired,
  updateSource: func.isRequired,
}
export default OperatorComponent

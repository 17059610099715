import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

import { MIDDLE_GREY, WHITE, SHADOW_GREY } from '../constants'
import logoV from '../assets/logo_V.png'
import loginBackground from '../assets/login_bg.png'
import googleLogin from '../assets/logo_GoogleLogin.png'
import Button from '../components/shared/Button'
import Text from '../components/shared/Text'

const Container = styled.div`
  background-image: url(${loginBackground});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`

const LoginWindow = styled.div`
  background: ${WHITE};
  width: 80%;
  max-width: 500px;
  margin: 5% auto;
  padding: 30px;
  box-shadow: ${SHADOW_GREY};
  text-align: center;
`

const ProductCasterLogo = styled.img`
  display: block;
  margin: 20px auto;
`

const GoogleLogo = styled.div`
  background: ${WHITE};
  display: inline-block;
  float: left;
`

const LogInGoogleButton = styled(Button)`
  background: ${MIDDLE_GREY};
  padding: 5px;
  width: 350px;
  margin: 20px auto;
  display: block;

  p {
    margin: 11px 0;
  }
`

const LoginPage = ({ loginUser, t }) => (
  <Container>
    <LoginWindow>
      <ProductCasterLogo src={logoV} alt="productcaster logo" />
      <Text size="34px">{t('login_title')}</Text>
      <Text size="24px">{t('login_description')}</Text>
      <LogInGoogleButton
        data-id="button-google-login"
        type="submit"
        onClick={loginUser}
      >
        <GoogleLogo>
          <img src={googleLogin} alt="Google login logo" />
        </GoogleLogo>
        <p>{t('login_google_button').toUpperCase()}</p>
      </LogInGoogleButton>
    </LoginWindow>
  </Container>
)

LoginPage.propTypes = {
  loginUser: func.isRequired,
}

export default LoginPage

import React from 'react'
import { string } from 'prop-types'

const FaPause = ({ color = 'black' }) => (
  <svg
    fill={color}
    width="100%"
    height="100%"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1664 192v1408q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-1408q0-26 19-45t45-19h512q26 0 45 19t19 45zm-896 0v1408q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-1408q0-26 19-45t45-19h512q26 0 45 19t19 45z" />
  </svg>
)

FaPause.propTypes = {
  color: string,
}

export default FaPause

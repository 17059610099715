import React, { Fragment, useEffect, useReducer } from 'react'
import { string } from 'prop-types'
import { navigate } from '@reach/router'

import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import StructuredTable from '../../shared/StructuredTable'
import Button from '../../shared/Button'
import { TEXT_SMALL } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

import { fetchInvitations, removeInvitation } from './actions'
import { initialState, reducer } from './reducer'

const TableColumns = (t, organisationId, dispatch, page) => [
  {
    Header: t('invitations_table_header1'),
    accessor: 'email',
  },
  {
    Header: t('invitations_table_header2'),
    accessor: 'createdAt',
    Cell: row => {
      const {
        original: { createdAt },
      } = row
      return createdAt
        ? t('dateValueInput', {
            value: new Date(createdAt),
          })
        : ''
    },
  },
  {
    Cell: row => {
      const {
        original: { id },
      } = row
      return (
        <Button
          dataId={`remove-invitation-${id}`}
          onClick={() =>
            removeInvitation(organisationId, id, t, dispatch, page)
          }
          theme={SECONDARY_BUTTON}
          fontSize={TEXT_SMALL}
        >
          {t('button_remove').toUpperCase()}
        </Button>
      )
    },
  },
]

const InvitationList = ({ t, organisationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { invitationsList, invitationListState, pageTotal, page } = state

  const filteredOrganisation = window.location.search.split('usersOf=')[1]

  useEffect(
    () => {
      fetchInvitations(organisationId, dispatch, page, t, filteredOrganisation)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId]
  )

  if (!invitationListState || invitationListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('invitations')}
        description={t('invitations_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('invite_user'),
            onClick: () => navigate(`/${organisationId}/admin/add-new-user`),
          },
        ]}
      />
      <ContentWrapper>
        <StructuredTable
          columns={TableColumns(t, organisationId, dispatch, page)}
          data={invitationsList}
          isUsersTable
          page={page}
          pageSize={10}
          pageTotal={pageTotal}
          selectNextPage={() =>
            fetchInvitations(organisationId, dispatch, page + 1, t)
          }
          selectPreviousPage={() =>
            fetchInvitations(organisationId, dispatch, page - 1, t)
          }
          showPagination={false}
          sortable={false}
        />
      </ContentWrapper>
    </Fragment>
  )
}

InvitationList.propTypes = {
  organisationId: string.isRequired,
}

export default InvitationList

import { SET_FILES, LOAD_MORE_FILES, LOAD_FILES_FETCHING } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  files: [],
  numberOfFiles: null,
  pageSize: 15,
  currentPage: 1,
  filesListState: APP_STATE.FETCHING,
}

export default (state = initialState, { type, data, numberOfFiles }) => {
  switch (type) {
    case SET_FILES:
      return {
        ...state,
        files: [...data.files],
        numberOfFiles: numberOfFiles,
        currentPage: 1,
        filesListState: APP_STATE.DONE,
      }

    case LOAD_MORE_FILES:
      return {
        ...state,
        files: [...state.files, ...data.files],
        numberOfFiles: numberOfFiles,
        currentPage: data.currentPage,
        filesListState: APP_STATE.DONE,
      }

    case LOAD_FILES_FETCHING:
      return {
        ...state,
        filesListState: APP_STATE.FETCHING,
      }

    default:
      return state
  }
}

import React from 'react'
import styled from 'styled-components'
import { string, bool, node, func } from 'prop-types'

import { DARK_GREY, TEXT_MEDIUM, LIGHT_GREY } from '../../../constants'

const StyledText = styled.div`
  font-size: ${({ size }) => (size ? size : TEXT_MEDIUM)};
  color: ${({ color }) => (color ? color : DARK_GREY)};
  font-weight: ${({ bold }) => bold && 'bold'};
  width: ${({ width }) => width};
  cursor: ${({ onClick }) => onClick && 'pointer'};

  overflow: ${({ ellipsis }) => ellipsis && 'hidden'};
  text-overflow: ${({ ellipsis }) => ellipsis && 'ellipsis'};
  white-space: ${({ ellipsis }) => ellipsis && 'nowrap'};

  ${({ disabled }) => disabled && `color: ${LIGHT_GREY}; cursor: not-allowed`};
`

const Text = ({
  children,
  size,
  color,
  bold,
  onClick,
  disabled,
  dataId,
  width,
  ellipsis,
}) => (
  <StyledText
    disabled={disabled}
    data-id={dataId}
    size={size}
    color={color}
    bold={bold}
    width={width}
    ellipsis={ellipsis}
    onClick={!disabled ? onClick : null}
  >
    {children}
  </StyledText>
)

Text.propTypes = {
  children: node,
  size: string,
  dataId: string,
  color: string,
  bold: bool,
  disabled: bool,
  onClick: func,
  width: string,
  ellipsis: bool,
}

export default Text

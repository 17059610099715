import i18n from 'i18next'
import { navigate } from '@reach/router'

import { APP_STATE } from '../../constants'
import { apiUrl, loginRedirectUrl } from '../../constants/urls'
import { setTimeZone, setDateFormat, setNumberFormat } from '../../i18n'
import api, { setupAxios, clearAxiosHeaders } from '../../lib/api'

export const SET_USER = 'SET_USER'
export const FETCH_ERROR = 'FETCH_ERROR'
export const SAVE_FEEDS = 'SAVE_FEEDS'
export const CREATE_FEED = 'CREATE_FEED'
export const FEED_DETAIL_FETCHING = 'FEED_DETAIL_FETCHING'
export const FEED_DETAIL_DONE = 'FEED_DETAIL_DONE'
export const FEED_DETAIL_FAILED = 'FEED_DETAIL_FAILED'

export const fetchingData = () => ({
  type: APP_STATE.FETCHING,
})

export const fetchingFailed = msg => ({
  type: APP_STATE.ERROR,
  msg,
})

const saveFeeds = feeds => ({
  type: SAVE_FEEDS,
  feeds,
})

export const fetchError = msg => ({
  type: FETCH_ERROR,
  msg,
})

export const setUser = user => ({
  type: SET_USER,
  user,
})

const requestFeed = () => ({
  type: FEED_DETAIL_FETCHING,
})

export const receiveFeed = feed => ({
  type: FEED_DETAIL_DONE,
  feed,
})

export const createFeed = dispatch => {
  dispatch({
    type: CREATE_FEED,
  })
}

export const feedDetailFetchError = message => ({
  type: FEED_DETAIL_FAILED,
  message,
})

export const fetchFeed = (id, dispatch, showLoading = true, signal) => {
  if (showLoading) {
    dispatch(requestFeed())
  }

  return api
    .get(`/feeds/${id}`, { cancelToken: signal && signal.token })
    .then(({ data: feed }) => {
      dispatch(receiveFeed(feed))
    })
    .catch(err => {
      console.log(err)
      dispatch(feedDetailFetchError(err.message))
      return Promise.reject() // Need to propagate the error out
    })
}

export const fetchFeeds = (dispatch, organisationId) => {
  const limit = 50
  const getFeeds = page =>
    api.get(`/orgs/${organisationId}/feeds?page=${page}&limit=${limit}`)

  return getFeeds(1)
    .then(res => {
      const pagesToLoad = res.headers.fm_pagination_total / limit
      const promises = []
      for (let page = 1; page < pagesToLoad; page++) {
        promises.push(getFeeds(page + 1))
      }

      return Promise.all(promises).then(results => {
        // This concatenates the results of all promises and merges the feeds together
        dispatch(saveFeeds([...res.data].concat(...results.map(x => x.data))))
        dispatch(receiveFeed(null))
      })
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchError(err.message))
    })
}

export const saveFeed = dispatch => (id, data, organisationId) => {
  if (id === 'new') id = null
  const promise = id
    ? api.patch(`/feeds/${id}`, data)
    : api.post(`/orgs/${organisationId}/feeds`, data)

  return promise.then(({ data: feed }) => {
    dispatch(receiveFeed(feed))
    return feed
  })
}

export const toggleFeedEnabled = (feedId, value, dispatch, organisationId) =>
  api
    .patch(`/feeds/${feedId}`, {
      enabled: value,
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchError(err.message))
    })
    .finally(() => {
      fetchFeeds(dispatch, organisationId)
    })

const setupUserConfig = (user, dispatch) => {
  const { language, timezone, format } = user.settings

  setTimeZone(timezone)
  setDateFormat(format)
  setNumberFormat(format)
  i18n.changeLanguage(language)
  dispatch(setUser(user))
}

const getUserConfig = (dispatch, userId) =>
  api
    .get(`${apiUrl}/users/${userId}`)
    .then(({ data }) => setupUserConfig(data, dispatch))

const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

const findUserId = token => {
  if (token) {
    const userId = parseJwt(token).userId
    return userId
  }
}

export const getToken = () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const token = params.get('token')
  if (token) {
    localStorage.setItem('token', token)
    navigate(window.location.pathname, { replace: true })
  }
  return localStorage.getItem('token')
}

export const fetchUser = dispatch => {
  const token = getToken()
  const userId = findUserId(token)

  setupAxios(() => logoutUser(dispatch))

  return getUserConfig(dispatch, userId)
}

export const loginUser = () => {
  const params = new URLSearchParams(window.location.search)
  const returnUrl = params.get('returnUrl')
  window.location.href = `${apiUrl}/auth/google/redirect?returnUrl=${
    returnUrl ? returnUrl : loginRedirectUrl
  }`
}

export const logoutUser = dispatch => {
  localStorage.removeItem('token')
  dispatch(setUser(null))

  clearAxiosHeaders()

  return Promise.resolve()
}

import {
  INVITATIONS_FETCHING,
  INVITATIONS_DONE,
  INVITATIONS_FAILED,
} from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  invitationListState: null,
  invitationsList: [],
  fetchError: '',
  pageTotal: null,
  page: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case INVITATIONS_FETCHING:
      return {
        ...state,
        invitationListState: APP_STATE.FETCHING,
      }

    case INVITATIONS_DONE:
      return {
        ...state,
        invitationListState: APP_STATE.DONE,
        invitationsList: action.invitationsList,
        pageTotal: action.pageTotal,
        page: action.page,
      }

    case INVITATIONS_FAILED:
      return {
        ...state,
        invitationListState: APP_STATE.ERROR,
        invitationsList: [],
        fetchError: action.message,
        pageTotal: null,
        page: null,
      }

    default:
      return state
  }
}

import React, { Fragment, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import {
  fetchPartner,
  createNewPartner,
  editPartner,
  updatePartner,
  deleteMapping,
} from './actions'
import { reducer, initialState } from './reducer'

import { LIGHT_GREY, LAPTOP_WIDTH } from '../../../constants'

import Button from '../../shared/Button'
import Input from '../../shared/Input'
import PageDescription from '../../shared/PageDescription'
import MappingList from '../../shared/MappingList'
import Loader from '../../shared/Loader'

const ColumnWrapper = styled.div`
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
  max-width: ${LAPTOP_WIDTH};
`

const ButtonContainer = styled.div`
  max-width: ${LAPTOP_WIDTH};
  padding: 20px;
  text-align: right;
`

const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 30px 30px 0;
`

const InputLabel = styled.div`
  margin-right: 10px;
`

const columnTypeKeys = [
  { id: 'optional', name: 'optional' },
  { id: 'required', name: 'required' },
  { id: 'recommended', name: 'recommended' },
]

const CustomPartner = ({ t }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [partnerName, setPartnerName] = useState('')
  const [partnerImg, setPartnerImg] = useState('')

  const { partner, customPartnerState, newPartner } = state
  const splitPath = window.location.pathname.split('/')
  const partnerId = splitPath[3]
  const selectedPartner = partnerId ? partner : newPartner

  useEffect(
    () => {
      fetchPartner(t, dispatch, partnerId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnerId]
  )

  useEffect(
    () => {
      if (partnerId) {
        setPartnerName(partner.name)
        setPartnerImg(partner.imageUrl)
      } else {
        setPartnerName('')
        setPartnerImg('')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partner]
  )

  const saveChanges = () => {
    if (partnerName.length && partnerImg.length) {
      const savedPartner = {
        name: partnerName,
        imageUrl: partnerImg,
        mapping: selectedPartner.mapping,
      }

      if (partnerId) {
        editPartner(partnerId, t, savedPartner)
      } else {
        createNewPartner(savedPartner, dispatch, t)
      }
    } else {
      toast.error(t('partner_missing_name_or_img'))
    }
  }

  const keys =
    partner.id &&
    Object.getOwnPropertyNames(partner.mapping).map(row => ({
      id: row,
      name: row,
    }))

  if (customPartnerState === 'FETCHING') return <Loader />

  return (
    <Fragment>
      <PageDescription
        heading={
          partnerId
            ? `${t('button_edit')} ${partner.name}`
            : t('add-outgoing-partner')
        }
      />
      <InputWrapper>
        <InputLabel>{t('partner_name')}</InputLabel>
        <Input
          name="partnerName"
          data-id="input-partner-name"
          onChange={event => setPartnerName(event.target.value)}
          value={partnerName || ''}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>{t('partner_img')}</InputLabel>
        <Input
          name="partnerImage"
          data-id="input-partner-image"
          onChange={event => setPartnerImg(event.target.value)}
          value={partnerImg || ''}
          maximumLength={500}
        />
      </InputWrapper>
      <ColumnWrapper bgColor={LIGHT_GREY}>
        <MappingList
          addButtonText={t('add_new_column')}
          columnTypeKeys={columnTypeKeys}
          deleteMapping={deleteMapping}
          description={t('master_feed_description')}
          dispatch={dispatch}
          firstTitle={t('partner_feed')}
          incomingFeed={t('incoming_feed')}
          isAddPartner
          keys={keys}
          mapping={selectedPartner}
          secondTitle={t('master_feed')}
          t={t}
          thirdTitle={t('column_type')}
          updateMapping={updatePartner}
        />
      </ColumnWrapper>
      <ButtonContainer>
        <Button dataId="save-new-partner" onClick={() => saveChanges()}>
          {t('button_save_partner')}
        </Button>
      </ButtonContainer>
    </Fragment>
  )
}

CustomPartner.propTypes = {}

export default CustomPartner

import React from 'react'
import { any } from 'prop-types'

import App from '../App'

import { OrganisationsProvider } from '../shared/system/OrganisationsContext'
import { BrandsProvider } from '../shared/system/BrandsContext'

import { SidePanelProvider } from '../shared/SidePanel/SidePanelContext'
import { AnnouncementProvider } from '../../hooks/Announcements/Context.js'

const Providers = props => (
  <OrganisationsProvider>
    <BrandsProvider>
      <SidePanelProvider>
        <AnnouncementProvider>
          <App />
        </AnnouncementProvider>
      </SidePanelProvider>
    </BrandsProvider>
  </OrganisationsProvider>
)

Providers.propTypes = {
  children: any,
}

export default Providers

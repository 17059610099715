import React, { useEffect } from 'react'
import styled from 'styled-components'
import { string, bool, func, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TEXT_BIG, ICON_SIZE_SMALL, LIGHT_GREY } from '../../../constants'
import { navItem, feedShape } from '../../../types'

import Link from './Link'
import Text from '../../shared/Text'
import Icon from '../../shared/Icon'
import SubItem from './SubItem'

const Wrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
  align-self: flex-end;
`

const SubItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ToggleButton = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-left: 10px;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  transition: transform 0.2s ease-in;
`

const MenuItem = ({
  collapse,
  expand,
  isOpen,
  linkText,
  path,
  selectedFeed,
  selectedOrganisation,
  subItems,
}) => {
  const { t } = useTranslation()

  const isNew = path && path.includes('/feeds/new')
  const disabled = path === `/${selectedOrganisation.id}/feeds` || isNew

  const pathContext = path.split('/')
  const urlPage = pathContext[Math.min(pathContext.length - 1, 4)]

  useEffect(
    () => {
      disabled && collapse()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled]
  )

  useEffect(
    () => {
      expand()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  )

  const makePath = newPath => {
    let path = [...pathContext]
    if (path.includes('feeds')) {
      path.length = 4
    } else if (path.includes('system')) {
      path.length = 2
    } else path.length = 3
    path = path.concat(newPath)

    return path.join('/')
  }

  const toggle = () => (isOpen ? collapse() : expand())
  const isFeedConfigured = selectedFeed && selectedFeed.isConfigured

  return (
    <Wrapper>
      <Link
        link={!disabled && subItems ? makePath(subItems[0].path) : '/feeds'}
      >
        <Text
          onClick={() => !disabled && !isOpen && toggle()}
          dataId={`nav-item-${linkText}`}
          size={TEXT_BIG}
          disabled={disabled}
        >
          {t(linkText).toUpperCase()}
        </Text>
      </Link>

      <ToggleButton
        disabled={disabled}
        isOpen={isOpen}
        onClick={() => !disabled && toggle()}
      >
        <Icon
          color={disabled ? LIGHT_GREY : ''}
          size={ICON_SIZE_SMALL}
          icon={'caretDown'}
        />
      </ToggleButton>

      {!disabled && isOpen && (
        <SubItemsWrapper>
          {subItems &&
            subItems
              .filter(x => !x.hidden)
              .map(
                ({ linkText, path, level, requiresConfiguredFeed }, index) => (
                  <SubItem
                    disabled={requiresConfiguredFeed && !isFeedConfigured}
                    key={index}
                    linkText={linkText}
                    makePath={makePath}
                    level={level}
                    path={path}
                    t={t}
                    urlPath={urlPage}
                  />
                )
              )}
        </SubItemsWrapper>
      )}
    </Wrapper>
  )
}

MenuItem.propTypes = {
  collapse: func.isRequired,
  expand: func.isRequired,
  isOpen: bool.isRequired,
  linkText: string.isRequired,
  path: string.isRequired,
  selectedFeed: feedShape,
  selectedOrganisation: feedShape,
  subItems: arrayOf(navItem),
}

export default MenuItem

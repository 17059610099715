import React, { useState } from 'react'
import { any } from 'prop-types'

const OrganisationsContext = React.createContext([{}, () => {}])

const OrganisationsProvider = props => {
  const [state, setState] = useState({
    list: [],
    selected: null,
  })
  return (
    <OrganisationsContext.Provider value={[state, setState]}>
      {props.children}
    </OrganisationsContext.Provider>
  )
}

OrganisationsProvider.propTypes = {
  children: any,
}

export { OrganisationsContext, OrganisationsProvider }

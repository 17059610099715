import { toast } from 'react-toastify'

import { fetchingData, fetchingFailed } from '../../App/actions'
import getIncomingUrl from '../../../lib/optimization/getIncomingUrl'
import api from '../../../lib/api'
import { notCanceled } from '../../../lib/signal'

export const INCOMING_MAPPING_UPDATED = 'INCOMING_MAPPING_UPDATED'
export const OUTGOING_MAPPING_RECEIVED = 'OUTGOING_MAPPING_RECEIVED'
export const INCOMING_MAPPING_HEADERS_RECEIVED =
  'INCOMING_MAPPING_HEADERS_RECEIVED'

const getMasterPartnerMappingUrl = feedId =>
  `/partners/00000000-0000-0000-0000-000000000000`
const getIncomingMappingUrl = (feedId, incomingId) =>
  `/feeds/${feedId}/incoming/${incomingId}/mapping/`

export const receivedIncomingMappingHeaders = (mapping, headers) => ({
  type: INCOMING_MAPPING_HEADERS_RECEIVED,
  mapping,
  headers,
})

export const updatedIncomingMapping = mapping => ({
  type: INCOMING_MAPPING_UPDATED,
  mapping,
})

export const updateIncomingMapping = (
  source,
  value,
  incomingMapping,
  dispatch
) => {
  incomingMapping[source] = {
    ...incomingMapping[source],
    type: 'col',
    options: { name: value },
  }
  dispatch(updatedIncomingMapping(incomingMapping))
}

export const fetchIncoming = ({
  feedId,
  dispatch,
  selectedIncoming,
  t,
  signal,
}) => {
  dispatch(fetchingData())
  const options = { cancelToken: signal && signal.token }

  api
    .get(getIncomingUrl(feedId, selectedIncoming.id), options)
    .then(resp => {
      fetchIncomingMapping(feedId, selectedIncoming.id, dispatch, t, signal)
    })
    .catch(notCanceled(err => loadingFailed(err, dispatch, t)))
}

export const fetchIncomingMapping = (
  feedId,
  incomingId,
  dispatch,
  t,
  signal
) => {
  dispatch(fetchingData())

  const options = { cancelToken: signal && signal.token }

  // fetch mappings
  Promise.all([
    api.get(getMasterPartnerMappingUrl(feedId), options),
    api.get(getIncomingMappingUrl(feedId, incomingId), options),
  ])
    .then(([{ data: { mapping: partnerMapping } }, { data: mapping }]) => {
      // Use partner mapping and copy it over with the saved mapping
      const mergedMapping = { ...partnerMapping, ...mapping }
      dispatch(receivedIncomingMappingHeaders(mergedMapping, {}))
      return Promise.resolve(mergedMapping)
    })
    // fetch data into mapping
    .then(mapping =>
      api
        .get(
          `/feeds/${feedId}/incoming/${incomingId}/data?skip=0&take=1&format=json`,
          options
        )
        .then(({ data: headers }) => {
          dispatch(receivedIncomingMappingHeaders(mapping, headers[0]))
        })
        .catch(notCanceled(err => toast.error(t('mapping_preview_error'))))
    )
    .catch(notCanceled(err => loadingFailed(err, dispatch, t)))
}

export const updateMappings = (
  feedId,
  selectedIncoming,
  incomingMapping,
  dispatch,
  t
) => {
  // remove all items that are not set in the UI
  const filteredIncomingMapping = Object.entries(incomingMapping).reduce(
    (prev, [key, value]) => {
      if (!value?.options?.name) return prev
      prev[key] = value
      return prev
    },
    {}
  )

  api
    .put(
      getIncomingMappingUrl(feedId, selectedIncoming.id),
      filteredIncomingMapping
    )
    .then(() => toast.success(t('mapping_saved_success')))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('mapping_saved_error'))
    })
}

const loadingFailed = (err, dispatch, t) => {
  dispatch(fetchingFailed(err.message))
  toast.error(t('loading_failed'))
}

import React from 'react'
import { string } from 'prop-types'

const FaLongArrowUp = ({ color = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="100%"
    height="100%"
    viewBox="0 0 33.549 33.549"
  >
    <path
      d="M9.591,14.183h4.194V28.162h2.8V14.183h4.194L15.183,8.591ZM4,3V5.8H26.366V3Z"
      transform="translate(1.591 1.194)"
    />
  </svg>
)

FaLongArrowUp.propTypes = {
  color: string,
}

export default FaLongArrowUp

import { useContext } from 'react'
import { BrandsContext } from './BrandsContext'

import api from '../../../lib/api'

import { useTranslation } from 'react-i18next'

const useBrands = () => {
  const [state, setState] = useContext(BrandsContext)
  const { t } = useTranslation()

  const { list, selected } = state

  const fetchBrands = orgId => {
    setList([])
    return api
      .get(`/orgs/${orgId}/groups`)
      .then(({ data }) => {
        setList(data)
      })
      .catch(err => {
        setList([])
        console.log(err)
      })
  }

  const allBrandsOption = {
    id: 'all',
    name: t('select'),
  }

  const setSelected = brand => {
    if (list.includes(brand) || brand.id === allBrandsOption.id) {
      setState(state => ({ ...state, selected: brand }))

      // set selected feed to none
    }
  }

  const setList = brands => {
    setState(state => ({ ...state, list: brands, selected: allBrandsOption }))
  }

  const findBrandById = id => {
    if (!list) return null
    return list.find(b => b.id === id)
  }

  const findBrandNameById = id => {
    const brand = findBrandById(id)
    return brand ? brand.name : null
  }

  return {
    list,
    selected,
    setSelected,
    setList,
    allBrandsOption,
    fetchBrands,
    findBrandById,
    findBrandNameById,
  }
}

export default useBrands

import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

const SidePanelPortal = props => {
  const [containerEl] = useState(document.getElementById('sidePanel'))
  useEffect(() => {
    // do stuff to create panel
  }, [])
  return ReactDOM.createPortal(props.children, containerEl)
}

export default SidePanelPortal

import React from 'react'
import styled from 'styled-components'
import { any } from 'prop-types'

import { RED, TEXT_SMALL } from '../../../constants/index'

const Wrapper = styled.div`
  background-color: ${RED};
  padding: 0 8px;
  border-radius: 16px;
  text-align: center;
  color: white;
  font-size: ${TEXT_SMALL};
`

const Recatangle = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Recatangle

Recatangle.propTypes = {
  children: any,
}

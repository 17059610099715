import React from 'react'
import { string } from 'prop-types'
import { Router } from '@reach/router'

import NoMatch from '../components/shared/NoMatch'
import Processing from '../components/Processing'
import { feedShape } from '../types'
import ErrorBoundary from '../components/shared/ErrorBoundary'

import useOrganisations from '../components/shared/system/useOrganisations'
import { useTranslation } from 'react-i18next'
import useBrands from '../components/shared/system/useBrands'

const ReportPage = ({ '*': contextRoute, selectedFeed }) => {
  const { selected } = useOrganisations()
  const { t } = useTranslation()
  const brands = useBrands()

  const organisationId = selected.id

  return (
    <ErrorBoundary>
      <Router primary={false}>
        <Processing
          brands={brands.list}
          path="*/processing"
          t={t}
          organisationId={organisationId}
          selectedBrand={brands.selected}
          selectedFeed={selectedFeed}
        />

        <NoMatch path="*" isRelative noThrow to="/" />
      </Router>
    </ErrorBoundary>
  )
}

ReportPage.propTypes = {
  '*': string,
  selectedFeed: feedShape,
}

export default ReportPage

import React, { useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { connect, getIn } from 'formik'

import {
  DARK_GREY,
  LIGHT_GREY,
  MIDDLE_GREY,
  RED,
  TEXT_MEDIUM,
  TEXT_SMALL,
  WHITE,
} from '../../../constants'
import { formikShape } from '../../../types'
import hasFieldFormikError from '../../../lib/hasFieldFormikError'

import Text from '../Text'
import FormikError from '../FormikError'

const Container = styled.div`
  position: relative;
  min-height: 35px;
  max-height: 70px;
  background-color: ${WHITE};
  border: 1px solid ${({ isError }) => (isError ? RED : LIGHT_GREY)};
  ${({ isUpload }) => isUpload && 'opacity: 0'};
  ${({ isUpload }) => isUpload && 'height: 55px'};
  ${({ isUpload }) => !isUpload && 'padding: 10px 10px 12px 10px'};

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`

const Tooltip = styled.div`
  position: relative;
  ${({ isUpload }) => isUpload && 'height: 100%'};

  :before {
    content: '';
    display: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${DARK_GREY};
    position: absolute;
    top: 30px;
    left: 7px;
  }
  :after {
    z-index: 1000;
    display: none;
    content: attr(data-tip);
    position: absolute;
    top: 35px;
    padding: 5px 8px;
    background: ${DARK_GREY};
    color: ${WHITE};
    font-size: ${TEXT_SMALL};
    border-radius: 3px;
    word-wrap: break-word;
    max-width: 300px;
  }
  :hover:before,
  :hover:after {
    ${({ content }) => content && content.length > 35 && 'display:block'};
  }
`

const StyledInput = styled.input`
  width: 100%;
  display: inline-block;
  color: ${DARK_GREY};
  font-size: ${TEXT_MEDIUM};
  margin: 0;
  background-color: ${WHITE};
  border: 1px solid ${LIGHT_GREY};
  transition: all 0.3s;
  border: none;
  outline: none;
  cursor: pointer;
`

const CharacterCount = styled.span`
  font-size: 11px;
  position: absolute;
  right: 5px;
  top: 1px;
  font-style: italic;
`

const Input = connect(
  ({
    formik: { handleChange, values, errors, touched },
    label,
    maximumLength = 100,
    name,
    isUpload,
    type,
    ...props
  }) => {
    const [characterCount, setCharacterCount] = useState(null)

    return (
      <Container
        isError={hasFieldFormikError(errors, touched, name)}
        isUpload={isUpload}
      >
        <label>
          {label && (
            <Text color={MIDDLE_GREY} size={TEXT_SMALL}>
              {label}
            </Text>
          )}
          <Tooltip
            data-tip={getIn(values, name)}
            content={getIn(values, name)}
            isUpload={isUpload}
          >
            <StyledInput
              onChange={getIn(handleChange)}
              value={getIn(values, name)}
              name={name}
              type={type}
              maxLength={maximumLength}
              onKeyUp={({ target: { value } }) =>
                setCharacterCount(value.length)
              }
              {...props}
            />
          </Tooltip>
          {characterCount > 0 && type !== 'number' && (
            <CharacterCount>
              {characterCount} / {maximumLength}
            </CharacterCount>
          )}
        </label>
        <FormikError name={name} />
      </Container>
    )
  }
)

Input.propTypes = {
  formik: formikShape,
  label: string,
  name: string.isRequired,
}
Input.displayName = 'Input'

export default Input

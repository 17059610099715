import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

export default (
  alerts,
  alert,
  setAlert,
  isDuplicateOrNew,
  handleDeleteDialogVisibility,
  location,
  feed
) => {
  const { t } = useTranslation()

  const onPause = () => {
    const { id, enabled } = alert
    alerts
      .update(id, { enabled: !enabled })
      .then(() => {
        toast.success(t('alert_save_success'))
        setAlert({ ...alert, enabled: !enabled })
      })
      .catch(e => {
        console.error(e)
        toast.error(t('alert_save_error'))
      })
  }

  const onRemove = () => {
    const { id } = alert
    alerts
      .remove(id)
      .then(() => {
        toast.success(t('alert_delete_success'))
        navigate(location.href.replace(/\/alerts(.*)/, `/alerts`), {
          replace: true,
        })
      })
      .catch(e => {
        console.error(e)
        toast.error(t('alert_delete_error'))
      })

    handleDeleteDialogVisibility(false)
  }

  const deepClone = obj => JSON.parse(JSON.stringify(obj))

  const onSave = () => {
    let { name, enabled, conditions, actions } = deepClone(alert)

    if (!(conditions && conditions[0])) {
      toast.error(t('alert_condition_missing_error'))
      return
    }

    // if alert action is skipPublishing and condition is outgoingSizeChanged
    // feed we need to copy the outgoingIds to the alert
    // once we support multisellect we can remove this
    let type = conditions[0].type
    const options = conditions[0].options
    let { outgoingIds, incomingIds } = options || {}

    if (outgoingIds === 'all')
      options.outgoingIds = feed.outgoing.map(({ id }) => id)
    else {
      if (outgoingIds && !Array.isArray(outgoingIds))
        options.outgoingIds = [options.outgoingIds]
      if (incomingIds && !Array.isArray(incomingIds))
        options.incomingIds = [options.incomingIds]
    }

    // this map copies the outgoingIds for skipPublishing (needed for alerts on BE)
    // if master or incoming ...copy empty array which means stop all
    let resultActions = actions.map(action => {
      if (action.type === 'skipPublishing') {
        if (['outgoingSizeChanged', 'outgoingFailed'].includes(type)) {
          const { outgoingIds } = options
          return { ...action, options: { outgoingIds } }
        }

        if (
          [
            'incomingSizeChanged',
            'incomingFailed',
            'masterSizeChanged',
            'masterFailed',
          ].includes(type)
        ) {
          return { ...action, options: { outgoingIds: [] } }
        }
      }
      return action
    })

    const method = isDuplicateOrNew
      ? alerts.add({ name, enabled, type, options, actions: resultActions })
      : alerts.update(alert.id, {
          name,
          enabled,
          type,
          options,
          actions: resultActions,
        })
    method
      .then(data => {
        toast.success(t('alert_save_success'))
        //redirect based on new id
        navigate(
          location.href
            .replace(/\/alerts\/([^/])*/i, `/alerts/${data.id}`)
            .replace(`?isDuplicate=true`, ``),
          { replace: true }
        )
      })
      .catch(e => {
        console.error(e)
        if (isDuplicateOrNew) toast.error(t('alert_save_error'))
        else toast.error(t('alert_update_error'))
      })
  }

  const updateName = event => setAlert({ ...alert, name: event.target.value })

  const updateStatus = ({ id }) =>
    setAlert({ ...alert, enabled: id === 'active' })

  return {
    onPause,
    onRemove,
    onSave,
    updateName,
    updateStatus,
  }
}

import React, { Fragment, useState } from 'react'
import { object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import CardList from '../../shared/CardList'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import ColumnGridWrapper from '../../shared/ColumnGridWrapper'
import RemoveModalWindow from '../../shared/RemoveModalWindow'

import { LIGHT_GREY, CARD_HEIGHT_SMALL } from '../../../constants'

const Alerts = ({ alerts }) => {
  const { t } = useTranslation()
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [selected, setSelected] = useState({
    alertId: null,
    alerts: null,
  })

  const onPause = (alertId, enabled) => {
    alerts
      .update(alertId, { enabled })
      .then(() => {
        toast.success(t('alert_save_success'))
      })
      .catch(e => {
        console.log(e)
        t.error(t('alert_update_error'))
      })
  }

  const handleDeleteDialogVisibility = (visible, alertId, alerts) => {
    setDeleteDialogVisible(visible)
    setSelected({ alertId, alerts })
  }

  const onRemove = () => {
    const { alertId } = selected
    alerts
      .remove(alertId)
      .then(() => {
        toast.success(t('alert_delete_success'))
      })
      .catch(e => {
        console.log(e)
        toast.error(t('alert_delete_error'))
      })

    handleDeleteDialogVisibility(false)
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('alerts')}
        description={t('alerts_description')}
      />
      <ColumnGridWrapper>
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_alert',
              link: `new`,
              tooltip: t('alert_button_new_alert_tooltip'),
            },
          ]}
        />
        <TopButtonsWrapper />

        <CardList
          cardHeight={CARD_HEIGHT_SMALL}
          color={LIGHT_GREY}
          description={t('alert')}
          list={alerts.list}
          name={t('alert')}
          onPause={onPause}
          productNumberlabel={t('transformed')}
          t={t}
          titleDescription={t('alerts')}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={false}
          hideType
          isAlert
        />
        <div />

        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_alert',
              link: `new`,
              tooltip: t('alert_button_new_alert_tooltip'),
            },
          ]}
        />
        <TopButtonsWrapper />
      </ColumnGridWrapper>

      <RemoveModalWindow
        t={t}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        onRemove={onRemove}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

Alerts.propTypes = {
  alerts: object.isRequired,
}

export default Alerts

import countries from './countries.js'
import { daysInWeek, daysInMonth, hours, monthsInYear } from './times.js'
import {
  fileTypes,
  separators,
  quotes,
  connections,
  statusTypes,
  uploadFileTypes,
  booleans,
  compression,
} from './formData'

export const APP_STATE = {
  FETCHING: 'FETCHING',
  DONE: 'DONE',
  ERROR: 'ERROR',
}

export const VERSION = process.env.REACT_APP_VERSION || '0.0.0'

// Icon sizes
export const ICON_SIZE_SMALL = '16'
export const ICON_SIZE_MEDIUM = '25'
export const ICON_SIZE_BIG = '40'

//Colors
export const WHITE = '#FFFFFF'
export const YELLOW = '#F9A26C'
export const BLACK = '#000000'
export const LIGHT_BLACK = 'rgba(0,0,0,.65)'
export const LIGHT_BLUE = '#E6F7FF'
export const BLUE = '#1890FF'
export const DARK_BLUE = '#325D79'
export const GREY = '#F0F2F5'
export const LIGHT_GREY = '#E3E3E3'
export const LIGHT_TRANSPARENT_GREY = '#f5f5f5'
export const MIDDLE_GREY = '#A5A5A5'
export const DARK_GREY = '#727272'
export const GREY_OVERLAY = 'rgba(0,0,0,0.3)'
export const BLUE_GREY = '#2E4052'
export const ORANGE = '#F26627'
export const DARK_ORANGE = '#E88873'
export const LIGHT_ORANGE = 'rgba(249, 162, 108, 1)'
export const LIGHT_TRANSPARENT_ORANGE = 'rgba(249, 162, 108, 0.5)'
export const LIGHT_GREEN = '#7EA172'
export const GREEN = '#7EA172'
export const RED = '#C36A56'
export const PINK = '#FCD6DA'

//Shadows
export const SHADOW_GREY = `0 3px 6px rgba(0,0,0,0.2)`
export const SHADOW_LIGHT = '0 -1px 2px rgba(0,0,0,0.2)'

//Card heights
export const CARD_HEIGHT_SMALL = '170px'
export const CARD_HEIGHT_MEDIUM = '270px'
export const CARD_HEIGHT_LARGE = '320px'

// Text sizes
export const TEXT_SMALL = '12px'
export const TEXT_MEDIUM = '14px'
export const TEXT_BIG = '16px'

//Layout component sizes
const header_height = 50
export const HEADER_HEIGHT = `${header_height}px`
export const DOUBLE_HEADER_HEIGHT = `${2 * header_height}px`
export const SIDE_NAVIGATION_WIDTH_DESKTOP = '300px'
export const SIDE_NAVIGATION_WIDTH_LAPTOP = '250px'
export const MAX_CONTENT_WIDTH = '1400px'
export const FULL_MAX_CONTENT_WIDTH = '1600px'
export const LAPTOP_WIDTH = '1200px'

//Button padding
export const BUTTON_PADDING_REGULAR = '7px 10px'
export const BUTTON_PADDING_SMALL = '1px'

//Block padding
export const BLOCK_PADDING_REGULAR = '12px'
export const BLOCK_PADDING_SMALL = '8px'

//Imported constants
export const COUNTRIES = countries
export const DAYS_IN_WEEK = daysInWeek
export const DAYS_IN_MONTH = daysInMonth
export const HOURS = hours
export const MONTHS_IN_YEAR = monthsInYear

export const FILE_TYPES = fileTypes
export const SEPARATORS = separators
export const QUOTES = quotes
export const CONNECTIONS = connections
export const UPLOAD_FILE_TYPES = uploadFileTypes
export const booleanOptions = t => booleans(t)
export const compressionOptions = t => compression(t)

export const sharedStatusOptions = t => statusTypes(t)

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Papa from 'papaparse'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import ModalWindow from './ModalWindow'
import Tooltip from 'react-simple-tooltip'
import TooltipIcon from './TooltipIcon'
import Input from './Input'
import { ORANGE, WHITE, DARK_BLUE, TEXT_SMALL } from '../../constants'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
`

const UploadButton = styled.div`
  position: absolute;
  height: 55px;
  background: ${ORANGE};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const StyledInput = styled(Input)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const ContainerDiv = styled.div`
  position: relative;
`

const Spacer = styled.div`
  height: 60px;
`

const InnerDiv = styled.div`
  max-width: 200px;
  min-width: ${({ children }) =>
    children && children.length > 100 ? 400 : 200}px;
`

const NewAuditDialog = ({
  onCancel,
  onSave,
  visible,
  title,
  enableTypeSelection,
  type,
}) => {
  const { t } = useTranslation()
  const [fileSize, setFileSize] = useState(null)
  const [fileName, setFileName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [parsedColumns, setParsedColumns] = useState([])
  const [parsedRows, setParsedRows] = useState([])
  const [data, setData] = useState(null)
  const [parsedData, setParsedData] = useState([])
  const [delimiter, setDelimiter] = useState(null)
  const [fileType, setFileType] = useState(type)

  useEffect(
    () => {
      if (delimiter && data) {
        const fileData = { data, fileSize, fileName }
        parseFileData(fileData, delimiter, '"')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delimiter, data]
  )

  const modalTitle = fileName && fileSize ? `${fileName} | ${fileSize}` : title

  const handleFileChange = uploadedFile => {
    setFileName(uploadedFile.name)
    setFileSize(`${(uploadedFile.size / 1000).toFixed(1)} kB`)

    const fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(uploadedFile, 'UTF-8')
  }
  const handleFileRead = e => {
    const { result } = e.target

    const parsedData = Papa.parse(result, {
      quoteChar: '"',
    })

    setData(result)
    setDelimiter(parsedData.meta.delimiter)
  }
  const parseFileData = (uploadedFile, delimiter, quote) => {
    let { data: parsedData } = Papa.parse(uploadedFile.data, {
      delimiter,
      quoteChar: quote,
    })

    const [columns, ...rest] = parsedData

    setParsedData(parsedData)

    const rows = rest.splice(0, 4).map(row => {
      return columns.reduce((prev, _, index) => {
        prev[columns[index]] = row[index]
        return prev
      }, {})
    })

    setParsedColumns(columns)
    setParsedRows(rows)
  }

  const handleSave = () => {
    if (parsedData.length > 0 && delimiter && fileName.length > 0) {
      const stringData = Papa.unparse(parsedData, {
        delimiter: ',',
        quoteChar: '"',
      })
      if (stringData) {
        onSave(companyName, stringData)
        setStateToInitial()
      }
    } else {
      toast.error(t('system_file_upload_unsuccessfull'))
    }
  }
  const handleClose = () => {
    onCancel()
    setStateToInitial()
  }
  const setStateToInitial = () => {
    setFileName('')
    setFileSize(null)
    setParsedColumns([])
    setParsedRows([])
    setDelimiter(',')
    setData(null)
    setFileType(type)
  }

  const Line = styled.div``

  return (
    <ModalWindow
      minWidth={'70%'}
      okText={t('button_upload')}
      onCancel={handleClose}
      onOk={handleSave}
      title={modalTitle}
      visible={visible}
      isWide
    >
      <Grid>
        <Tooltip position={'bottom'}>
          <Line>
            {t('company_upload_name')}
            <TooltipIcon
              title={t('file_upload_name_tooltip')}
              position={'right'}
            />
          </Line>
        </Tooltip>
        <Input
          name="companyName"
          type="text"
          label={t('company_name_field')}
          onChange={e => setCompanyName(e.target.value)}
          value={companyName}
        />
        <Input
          name="filename"
          type="text"
          disabled
          label={t('file_name')}
          onChange={e => setFileName(e.target.value)}
          value={fileName}
        />
        {/* <Tooltip title={t('file_upload_button_tooltip')} position={'bottom'}> */}
        <Tooltip
          content={<InnerDiv>{t('file_upload_button_tooltip')}</InnerDiv>}
          zIndex={1000}
          border={'0'}
          padding={8}
          background={DARK_BLUE}
          fixed={false}
          radius={3}
          fontSize={TEXT_SMALL}
          placement={'top'}
        >
          <ContainerDiv>
            <UploadButton>{t('file_upload_button')} </UploadButton>
            <StyledInput
              name="file"
              type="file"
              onChange={e => handleFileChange(e.target.files[0])}
              isUpload
            />
          </ContainerDiv>
        </Tooltip>
      </Grid>
    </ModalWindow>
  )
}

NewAuditDialog.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  title: string.isRequired,
  visible: bool.isRequired,
  enableTypeSelection: bool,
  type: string,
}

export default NewAuditDialog

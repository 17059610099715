import React, { Fragment } from 'react'
import styled from 'styled-components'
import { any, node, string } from 'prop-types'

import { DARK_BLUE, TEXT_SMALL } from '../../../constants'

import Tooltip from 'react-simple-tooltip'

const InnerDiv = styled.div`
  max-width: 100px;
  min-width: ${({ children }) =>
    children && children.length > 50 ? 200 : 100}px;
`

const Tooltip2 = ({ children, title, position, style }) =>
  title ? (
    <Tooltip
      content={<InnerDiv>{title}</InnerDiv>}
      zIndex={1}
      border={'0'}
      padding={8}
      background={DARK_BLUE}
      fixed={false}
      radius={3}
      fontSize={TEXT_SMALL}
      placement={position || 'top'}
      style={style}
    >
      {children}
    </Tooltip>
  ) : (
    <Fragment>{children}</Fragment>
  )

Tooltip2.propTypes = {
  children: node.isRequired,
  title: string,
  position: string,
  style: any,
}

export default Tooltip2

import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const PARTNERS_FETCHING = 'PARTNERS_FETCHING'
export const PARTNERS_DONE = 'PARTNERS_DONE'
export const PARTNERS_FAILED = 'PARTNERS_FAILED'

const requestPartners = () => ({
  type: PARTNERS_FETCHING,
})

const receivePartners = brandList => ({
  type: PARTNERS_DONE,
  brandList,
})

const fetchFailed = message => ({
  type: PARTNERS_FAILED,
  message,
})

export const fetchPartners = ({ organisationId, dispatch, t, scope }) => {
  dispatch(requestPartners())

  api
    .get(`/orgs/${organisationId}/partners?scope=${scope}`)
    .then(({ data }) => {
      dispatch(receivePartners(data))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
      toast.error(t('partners_fetch_unsuccessful'))
    })
}

export const editPartner = (
  organisationId,
  partnerId,
  t,
  editedPartner,
  dispatch,
  _,
  scope
) =>
  api
    .patch(`partners/${partnerId}`, {
      name: editedPartner.name,
      imageUrl: editedPartner.imageLink || editedPartner.imageUrl,
    })
    .then(() => {
      toast.success(t('partner_edit_successful'))
      fetchPartners({ organisationId, dispatch, t, scope })
    })
    .catch(err => {
      toast.error(t('partner_edit_unsuccessful'))
      console.log(err)
    })

export const deletePartner = (partnerId, organisationId, dispatch, t, scope) =>
  api
    .delete(`partners/${partnerId}`)
    .then(() => {
      toast.success(t('partner_delete_successfull'))
      fetchPartners({ organisationId, dispatch, t, scope })
    })
    .catch(() => {
      toast.error(t('partner_delete_unsuccessfull'))
    })

export const createNewPartner = (
  organisationId,
  dispatch,
  t,
  scope,
  newPartner
) =>
  api
    .post(`/orgs/${organisationId}/partners`, newPartner)
    .then(() => {
      toast.success(t('partner_add_successfull'))
      fetchPartners({ organisationId, dispatch, t, scope })
    })
    .catch(() => {
      toast.error(t('partner_add_unsuccessfull'))
    })

import React, { Fragment, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Button from '../Button'
import ContentWrapper from '../ContentWrapper'
import EditableRow from '../EditableRow'
import Form from '../Form'
import Input from '../Input'
import Loader from '../Loader'
import PageDescription from '../PageDescription'
import Text from '../Text'
import TopButtonsWrapper from '../TopButtonsWrapper'
import { LIGHT_BLACK, WHITE } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { partnersSchema } from '../../../formValidationSchemas/index.js'

import {
  fetchPartners,
  editPartner,
  deletePartner,
  createNewPartner,
} from './actions'
import { initialState, reducer } from './reducer'

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  padding: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 10px;
  }
`

const FormRow = styled(GridRow)`
  background: ${WHITE};
  padding-bottom: 16px !important;
  padding-top: 16px !important;
`

const PartnerList = ({ t, organisationId, scope }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isFormVisible, setFormVisible] = useState(false)
  const { partnerList, partnerListState } = state

  useEffect(
    () => {
      fetchPartners({ organisationId, dispatch, t, scope })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId]
  )

  if (!partnerListState || partnerListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('partners')}
        description={t('partners_description')}
      />
      {scope === 'own' && (
        <TopButtonsWrapper
          buttons={[
            {
              name: t('button_add_partner'),
              onClick: () => setFormVisible(true),
            },
          ]}
        />
      )}
      <ContentWrapper>
        {!partnerList.length && !isFormVisible && <Text>{t('no_data')}</Text>}
        {partnerList.length !== 0 && (
          <GridRow>
            <Text bold color={LIGHT_BLACK}>
              {t('table_header1')}
            </Text>
            <Text bold color={LIGHT_BLACK}>
              {t('table_header2')}
            </Text>
          </GridRow>
        )}
        {partnerList.map(partner => (
          <EditableRow
            validation={partnersSchema}
            deleteRow={deletePartner}
            dispatch={dispatch}
            editRow={editPartner}
            fetchRows={fetchPartners}
            hasImageUrl
            key={partner.id}
            organisationId={organisationId}
            row={partner}
            scope={scope}
            t={t}
          />
        ))}
        {isFormVisible && (
          <Form
            initialValues={{
              name: '',
              imageUrl: '',
            }}
            validationSchema={partnersSchema}
            onSubmit={({ name, imageUrl }) => {
              const newPartner = {
                name,
                imageUrl,
                mapping: {},
              }
              createNewPartner(organisationId, dispatch, t, scope, newPartner)
              setFormVisible(false)
            }}
            content={() => (
              <FormRow>
                <Input
                  name="name"
                  data-id="new-partner-name"
                  placeholder={t('input_new_partner_name')}
                />
                <Input
                  name="imageUrl"
                  data-id="new-partner-imageLink"
                  placeholder={t('input_new_partner_imageLink')}
                />
                <ButtonsContainer>
                  <Button
                    theme={SECONDARY_BUTTON}
                    onClick={() => setFormVisible(false)}
                  >
                    {t('button_cancel')}
                  </Button>
                  <Button type="submit" dataId="add-new-partner-button">
                    {t('button_save_changes')}
                  </Button>
                </ButtonsContainer>
              </FormRow>
            )}
          />
        )}
      </ContentWrapper>
    </Fragment>
  )
}

PartnerList.propTypes = {
  organisationId: string.isRequired,
  scope: string.isRequired,
}

export default PartnerList

import React from 'react'
import styled from 'styled-components'
import { string, func, bool, number } from 'prop-types'

import { ORANGE } from '../../../constants'

import Link from './Link'
import Text from '../../shared/Text'

const Wrapper = styled.div`
  margin: 8px 0;

  ${({ level }) =>
    level === 2 &&
    `
    padding-left: 8px;
  `}
`

const SubItem = ({ linkText, makePath, path, t, urlPath, disabled, level }) => (
  <Wrapper level={level}>
    <Link link={makePath(path)} disabled={disabled}>
      <Text
        dataId={`nav-item-${linkText}`}
        color={urlPath === path ? ORANGE : ''}
        data-id={`nav-item-${linkText}`}
        disabled={disabled}
      >
        {t(linkText)}
      </Text>
    </Link>
  </Wrapper>
)

SubItem.propTypes = {
  linkText: string.isRequired,
  makePath: func.isRequired,
  path: string.isRequired,
  urlPath: string,
  disabled: bool,
  level: number,
}

export default SubItem

import React from 'react'
import styled from 'styled-components'
import { string, any } from 'prop-types'

import BlockRow from './Row.js'
import Button from '../Button'
import Text from '../Text'
import BlockContent from './Content.js'
import {
  SHADOW_GREY,
  WHITE,
  TEXT_BIG,
  TEXT_SMALL,
  BUTTON_PADDING_SMALL,
  LAPTOP_WIDTH,
} from '../../../constants'
import { LINK_BUTTON_SMALL } from '../../../constants/buttonThemes'

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  min-width: 240px;
  width: ${({ width }) => width || 'auto'};
  margin-right: 48px;
  margin-bottom: 24px;
  flex-direction: column;
  height: ${({ height }) => height};
  background: ${WHITE};
  box-shadow: ${SHADOW_GREY};

  @media (max-width: ${LAPTOP_WIDTH}) {
    min-width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Block = ({ title, link, linkText, children, footer, width, padding }) => {
  return (
    <BlockWrapper width={width}>
      <BlockRow border="bottom">
        <TitleWrapper>
          <Text bold size={TEXT_BIG}>
            {title}
          </Text>
          {linkText && (
            <Button
              theme={LINK_BUTTON_SMALL}
              fontSize={TEXT_SMALL}
              padding={BUTTON_PADDING_SMALL}
              link={link}
            >
              {linkText}
            </Button>
          )}
        </TitleWrapper>
      </BlockRow>
      <BlockContent padding={padding}>{children}</BlockContent>
      {footer && <BlockRow border="top">Footer</BlockRow>}
    </BlockWrapper>
  )
}

Block.propTypes = {
  padding: string,
  children: any,
  title: string,
  link: string,
  linkText: string,
  footer: any,
  width: string,
}

export default Block

import React, { Fragment, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import Input from '../../shared/Input'
import Button from '../../shared/Button'
import Form from '../../shared/Form'
import Text from '../../shared/Text'
import EditableRow from '../../shared/EditableRow'
import { WHITE } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

import {
  fetchOrganisations,
  editOrganisation,
  createOrganisation,
} from './actions'
import { initialState, reducer } from './reducer'

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 10px;
  }
`

const FormGridRow = styled(GridRow)`
  background: ${WHITE};
`

const OrganisationList = ({ t, organisationId, userId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isFormVisible, setFormVisible] = useState(false)
  const { organisationList, organisationListState } = state

  useEffect(() => {
    fetchOrganisations({ dispatch, t, userId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!organisationListState || organisationListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('organisations')}
        description={t('organisations_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('add_new_organisation'),
            onClick: () => setFormVisible(true),
          },
        ]}
      />
      <ContentWrapper>
        <GridRow>
          <Text bold>{t('organisations_table_header1')}</Text>
        </GridRow>
        {organisationList.map(organisation => (
          <EditableRow
            row={organisation}
            dispatch={dispatch}
            editRow={editOrganisation}
            fetchRows={fetchOrganisations}
            isOrganisationList
            key={organisation.id}
            dataId="orgsList"
            organisationId={organisationId}
            t={t}
            userId={userId}
          />
        ))}
        {isFormVisible && (
          <Form
            initialValues={{
              name: '',
              imageLink: '',
            }}
            onSubmit={({ name }) => {
              const newOrganisation = { name: name }
              createOrganisation(userId, newOrganisation, dispatch, t)
              setFormVisible(false)
            }}
            content={() => (
              <FormGridRow>
                <Input
                  name="name"
                  data-id="new-organisation-name"
                  placeholder={t('input_new_organisation_name')}
                />
                <ButtonsContainer>
                  <Button
                    dataId={'new-cancel'}
                    theme={SECONDARY_BUTTON}
                    onClick={() => setFormVisible(false)}
                  >
                    {t('button_cancel')}
                  </Button>
                  <Button dataId={'new-save'} type="submit">
                    {t('button_save_changes')}
                  </Button>
                </ButtonsContainer>
              </FormGridRow>
            )}
          />
        )}
      </ContentWrapper>
    </Fragment>
  )
}

OrganisationList.propTypes = {
  organisationId: string.isRequired,
  userId: string.isRequired,
}

export default OrganisationList

import { TEMPLATE_FETCHING, TEMPLATE_DONE, TEMPLATE_FAILED } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  templateState: null,
  templates: [],
  fetchError: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case TEMPLATE_FETCHING:
      return {
        ...state,
        templateState: APP_STATE.FETCHING,
      }

    case TEMPLATE_DONE:
      return {
        ...state,
        templateState: APP_STATE.DONE,
        templates: action.templates,
      }

    case TEMPLATE_FAILED:
      return {
        ...state,
        processingState: APP_STATE.ERROR,
        templates: [],
        fetchError: action.message,
      }

    default:
      return state
  }
}

import React, { Fragment, useReducer, useEffect, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { fetchIncoming, updateIncomingMapping, updateMappings } from './actions'
import { reducer, initialState } from './reducer'
import {
  APP_STATE,
  LAPTOP_WIDTH,
  LIGHT_GREY,
  MAX_CONTENT_WIDTH,
} from '../../../constants'
import { cancelable } from '../../../lib/signal'

import { incomingCompactShape } from '../../../types'

import Button from '../../shared/Button'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import ModalWindow from '../../shared/ModalWindow'
import MappingList from '../../shared/MappingList'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: ${MAX_CONTENT_WIDTH};

  @media (max-width: ${LAPTOP_WIDTH}) {
    grid-template-columns: 1fr;
  }
`

const ColumnWrapper = styled.div`
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
`

const ButtonContainer = styled.div`
  width: ${LAPTOP_WIDTH};
  padding: 20px;
  text-align: right;
`

const ModalText = styled.div`
  max-width: 300px;
`

const MappingContent = ({ feedId, selectedIncoming }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const { t } = useTranslation()

  useEffect(
    () => {
      if (!selectedIncoming) return
      cancelable(signal =>
        fetchIncoming({
          feedId,
          dispatch,
          selectedIncoming,
          t,
          signal,
        })
      )
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIncoming]
  )

  const { headers, incomingMapping, errorMsg, stateOfAction } = state

  const requiredButEmpty = value =>
    value &&
    value.system &&
    value.system.required &&
    value.options.name.length === 0

  const missingRequiredFields = () => {
    const findRequiredAndEmpty = list =>
      Object.entries(list).filter(value => requiredButEmpty(value[1]))

    const isRequiredAll = findRequiredAndEmpty(incomingMapping)

    return isRequiredAll.map(item => item[0])
  }

  const saveChanges = needsChecking => {
    if (needsChecking && missingRequiredFields().length > 0) {
      setDeleteDialogVisible(true)
    } else {
      setDeleteDialogVisible(false)
      updateMappings(feedId, selectedIncoming, incomingMapping, dispatch, t)
    }
  }

  if (stateOfAction === APP_STATE.FETCHING || !incomingMapping || !headers) {
    return <Loader />
  }
  if (stateOfAction === APP_STATE.ERROR) return <p>{errorMsg}</p>

  return (
    <Fragment>
      <PageDescription
        heading={t('field_transformer')}
        description={t('field_transformer_description')}
      />
      <GridWrapper>
        <TopButtonsWrapper
          buttons={[
            {
              name: 'button_save_changes',
              onClick: saveChanges,
              tooltip: t('mapping_button_save_tooltip'),
            },
          ]}
        />
        <TopButtonsWrapper />

        <ColumnWrapper bgColor={LIGHT_GREY}>
          <MappingList
            addButtonText={t('button_add_custom')}
            description={t('master_feed_description', {
              feedName: selectedIncoming.name,
            })}
            dispatch={dispatch}
            exampleValues={headers}
            firstTitle={t('master_feed')}
            isMaster
            keys={Object.keys(headers).map(row => ({ id: row, name: row }))}
            mapping={incomingMapping}
            requiredButEmpty={requiredButEmpty}
            secondTitle={t('incoming_feed')}
            showHeading
            thirdTitle={t('preview')}
            updateMapping={updateIncomingMapping}
          />
        </ColumnWrapper>
      </GridWrapper>
      <ButtonContainer>
        <Button
          data-id="button_save_changes2"
          onClick={() => saveChanges(true)}
        >
          {t('button_save_changes')}
        </Button>
      </ButtonContainer>

      <ModalWindow
        cancelText={t('button_back')}
        onCancel={() => setDeleteDialogVisible(false)}
        title={t('field_transformer_modal_title')}
        visible={isDeleteDialogVisible}
        t={t}
        okText={t('button_save_anyway')}
        onOk={() => saveChanges()}
      >
        <ModalText>
          <p>{t('field_transformer_modal_window')}</p>
          <p>{missingRequiredFields().join(', ')}</p>
        </ModalText>
      </ModalWindow>
    </Fragment>
  )
}

MappingContent.propTypes = {
  feedId: string,
  selectedIncoming: incomingCompactShape,
}

export default MappingContent

import { useContext /*, useEffect*/ } from 'react'
import { SidePanelContext } from './SidePanelContext'
// import { globalHistory } from '@reach/router'

const useSidePanel = titleName => {
  const [state, setState] = useContext(SidePanelContext)
  const { title, isCollapsed } = state

  // useEffect(() => {
  //   globalHistory.listen(params => {
  //     console.log('WHAT happens when we change a page?', params)
  //   })
  // }, [])

  const setTitle = title =>
    title !== state.title && setState(state => ({ ...state, title }))

  const hide = () => setState(state => ({ ...state, isCollapsed: true }))
  const show = () => setState(state => ({ ...state, isCollapsed: false }))
  const toggle = () => (isCollapsed ? show() : hide())

  if (titleName) setTitle(titleName)

  return {
    isCollapsed,
    title,
    setTitle,
    hide,
    show,
    toggle,
  }
}

export default useSidePanel

import React from 'react'
import styled from 'styled-components'
import { string, node } from 'prop-types'

import logo from '../../assets/logo.png'
import background from '../../assets/login_bg.png'
import { WHITE, SHADOW_GREY } from '../../constants'

const MessageWindowWrapper = styled.div`
  background-image: url(${background});
  min-height: 100vh;
  background-size: cover;
  display: flex;
`

const Message = styled.div`
  background: ${WHITE};
  margin: auto;
  max-width: 400px;
  padding: 20px;
  text-align: center;
  box-shadow: ${SHADOW_GREY};
`

const Logo = styled.img`
  max-width: 100%;
  margin-bottom: 60px;
`

const MessageWindow = ({ text, children }) => (
  <MessageWindowWrapper>
    <Message>
      <Logo src={logo} alt="company logo" />
      <h3>{text}</h3>
      {children}
    </Message>
  </MessageWindowWrapper>
)

MessageWindow.propTypes = {
  text: string.isRequired,
  children: node,
}

export default MessageWindow

import React, { useEffect, useReducer, useState } from 'react'
import { string, func, object } from 'prop-types'
import { Router, navigate } from '@reach/router'
import { toast } from 'react-toastify'

import { fetchOutgoing, saveOutgoing } from './actions'
import { initialState, reducer } from './reducer'
import { APP_STATE } from '../../../constants'
import { outgoingShape } from '../../../types'

import SourceForm from '../SourceForm'
import OutgoingCards from './OutgoingCards'
import Loader from '../../shared/Loader'
import api from '../../../lib/api'
import { cancelable, notCanceled } from '../../../lib/signal'

const Outgoing = ({
  feedId,
  t,
  organisationId,
  organisationName,
  reloadCurrentFeed,
  location,
  selectedOutgoing,
  changeOutgoing,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [partnerMappings, setPartnerMappings] = useState([])
  const [jobNotifications, setJobNotifications] = useState([])

  const { fetchError, items, outgoingListState } = state

  const outgoing = items.map(outgoing => ({
    ...outgoing,
    detailUrl: `/${organisationId}/feeds/${feedId}/outgoing/${outgoing.id}`,
    key: outgoing.id,
  }))

  useEffect(
    () =>
      cancelable(signal => {
        fetchOutgoing(feedId, dispatch, signal)

        api
          .get(`/orgs/${organisationId}/partners`, {
            cancelToken: signal && signal.token,
          })
          .then(({ data }) => setPartnerMappings(data))
          .catch(
            notCanceled(err => {
              toast.error(t('outgoing_partner_mapping_error'))
              console.log(err)
            })
          )

        api
          .get(`/orgs/${organisationId}/jobs?feedId=${feedId}&page=1&limit=1`, {
            cancelToken: signal && signal.token,
          })
          .then(({ data }) => {
            const n = data && data[0] && data[0].notifications
            setJobNotifications(n || [])
          })
          .catch(
            notCanceled(err => {
              console.log(err)
            })
          )
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (!selectedOutgoing) return
      const newPath = location.href.replace(
        /outgoing\/(.*)/,
        `outgoing/${selectedOutgoing.id}`
      )
      if (location.href !== newPath) navigate(newPath)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const publishAllOutgoing = feedId =>
    api
      .post(`/orgs/${organisationId}/jobs`, {
        feedId: feedId,
      })
      .then(() => {
        toast.success(t('outgoing_published_success'))
      })
      .catch(err => {
        toast.error(t('outgoing_published_error'))
        console.log(err)
      })

  const pauseAllOutgoing = (feedId, outgoing) => {
    const promises = outgoing.map(outgoing => {
      return api.patch(`/feeds/${feedId}/outgoing/${outgoing.id}`, {
        enabled: false,
      })
    })
    Promise.all(promises)
      .then(_ => {
        toast.success(t('outgoing_paused_success'))
      })
      .catch(err => {
        toast.error(t('outgoing_paused_error'))
        console.log(err)
      })
      .then(() => {
        fetchOutgoing(feedId, dispatch)
      })
  }

  const publishSelectedOutgoing = (feedId, outgoingId) => {
    api
      .post(`/orgs/${organisationId}/jobs`, {
        feedId: feedId,
        outgoingIds: [outgoingId],
      })
      .then(() => {
        toast.success(t('outgoing_published_success'))
      })
      .catch(err => {
        toast.error(t('outgoing_published_error'))
        console.log(err)
      })
  }

  const onSave = (connection, format, values, outgoingId) => {
    const { name, enabled, publish, columnMapper } = values

    const sourceData = {
      connection,
      format,
      name,
      enabled,
      publish,
    }
    const partnerId = columnMapper

    saveOutgoing(
      feedId,
      outgoingId,
      sourceData,
      partnerId,
      dispatch,
      t,
      reloadCurrentFeed
    )
  }

  if (outgoingListState === APP_STATE.FETCHING) {
    return <Loader />
  }
  if (outgoingListState === APP_STATE.ERROR) {
    return <p>{fetchError}</p>
  }

  return (
    <Router primary={false}>
      <SourceForm
        feedId={feedId}
        description={t('outgoing_desc')}
        heading={t('outgoing')}
        onCancel={() => navigate('./')}
        onSave={onSave}
        outgoingMapping={partnerMappings}
        organisationId={organisationId}
        organisationName={organisationName}
        location={location}
        change={changeOutgoing}
        path=":resourceId"
        type="outgoing"
      />
      <OutgoingCards
        feedId={feedId}
        onPublishAll={() => publishAllOutgoing(feedId)}
        onPauseAll={() => pauseAllOutgoing(feedId, outgoing)}
        onPublishSelected={publishSelectedOutgoing}
        outgoingListState={outgoingListState}
        outgoing={outgoing}
        notifications={jobNotifications}
        organisationId={organisationId}
        templates={partnerMappings}
        path="/"
        t={t}
      />
    </Router>
  )
}

Outgoing.propTypes = {
  feedId: string.isRequired,
  reloadCurrentFeed: func.isRequired,
  organisationId: string.isRequired,
  organisationName: string.isRequired,
  location: object,
  selectedOutgoing: outgoingShape,
  changeOutgoing: func.isRequired,
}

export default Outgoing

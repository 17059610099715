import {
  CREATE_SOURCE,
  SOURCE_FETCHING,
  SOURCE_FETCHING_DONE,
  SOURCE_FAILED,
  SOURCE_DONE,
  SAVE_FEED_FILE,
  SOURCE_CHANGE,
} from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  connectionHostname: '',
  connectionPassword: '',
  connectionPath: '',
  connectionPort: 0,
  connectionType: null,
  connectionUsername: '',
  feedFile: null,
  fetchError: '',
  formatDelimiter: null,
  formatNormalizeNewlines: false,
  formatQuote: null,
  formatType: null,
  formatJson: null,
  formatHeader: true,
  formatRelaxColumnCount: false,
  name: '',
  sourceFormState: APP_STATE.DONE,
  enabled: false,
  publish: false,
  lastPublishedFileId: null,
  sourceId: null,
  job: null,
  mergeType: null,
  mergeSrcKey: null,
  mergeDstKey: null,
}

export const dummyPassword = 'No psw for you :P'

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SOURCE:
      return {
        ...state,
        sourceFormState: APP_STATE.DONE,
        enabled: false,
        publish: false,
        connectionType: 'ftp',
        connectionPort: 21,
        formatType: 'csv',
        formatDelimiter: '\t',
        formatQuote: '"',
        formatHeader: true,
        formatRelaxColumnCount: false,
      }

    case SOURCE_FETCHING:
      return {
        ...state,
        sourceFormState: APP_STATE.FETCHING,
      }

    case SOURCE_FETCHING_DONE:
      return {
        ...state,
        sourceFormState: APP_STATE.DONE,
      }

    case SOURCE_DONE:
      return {
        ...state,
        ...action.source,
        connectionPassword: dummyPassword,
        sourceFormState: APP_STATE.DONE,
      }

    case SOURCE_FAILED:
      return {
        ...state,
        sourceFormState: APP_STATE.ERROR,
        fetchError: action.message,
      }

    case SAVE_FEED_FILE:
      return {
        ...state,
        feedFile: action.file,
      }

    case SOURCE_CHANGE:
      return {
        ...state,
        [action.valueObject.name]: action.valueObject.value,
      }

    default:
      return state
  }
}

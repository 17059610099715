import { string, number, object } from 'yup'

export const basicInfoSchema = object().shape({
  name: string().required('Name is required'),
  country: string().required('Country is required'),
  groupId: string()
    .required('Brand is required')
    .nullable(),
  status: string().required(),
})

export const getSourceFormSchema = isNew => {
  return object().shape({
    name: string().required(),
    connectionPort: number().required(),
    connectionHostname: string().required(),
    connectionPath: string().required(),
    connectionUsername: string(),
    connectionPassword: string(),
    formatType: string().required(),

    formatQuote: string().when('formatType', {
      is: 'csv',
      then: string().required(),
      otherwise: string().optional(),
    }),
    formatJson: string().when('formatType', {
      is: 'xml',
      then: string().required(),
      otherwise: string()
        .optional()
        .isValid(null),
    }),
  })
}

export const newMappingRowSchema = object().shape({
  name: string()
    .matches(/^[^.]*$/, { message: 'forbidden_dot' })
    .required()
    .min(1)
    .max(255),
})

export const newPartnerSchema = object().shape({
  partnerName: string().required(),
  partnerImage: string().required(),
  mapping: object().shape({
    newMappingRowSchema,
  }),
})

export const getOutgoingSchema = isNew =>
  object().shape({
    name: string().required(),
    columnMapper: string(),
    connectionType: string().required(),
    connectionPort: string().when('connectionType', {
      is: 'fs',
      then: number(),
      otherwise: number().required(),
    }),
    connectionHostname: string().when('connectionType', {
      is: 'fs',
      then: string(),
      otherwise: string().required(),
    }),
    connectionPath: string().when('connectionType', {
      is: 'fs',
      then: string(),
      otherwise: string().required(),
    }),
    connectionUsername: string(),
    connectionPassword: string(),
    formatType: string().required(),
    formatQuote: string().required(),
    status: string()
      .oneOf(['active', 'test', 'paused'])
      .required(),
  })

export const inputNumber = object().shape({
  value: number().required(),
})

export const inputString = object().shape({
  value: string().required(),
})

export const getSourceFormValidationSchema = (isNew, outgoingMapping) =>
  outgoingMapping ? getOutgoingSchema(isNew) : getSourceFormSchema(isNew)

export const userSettingSchema = object().shape({
  name: string().required(),
  avatarUrl: string().required(),
  email: string().required,
  settings: object().shape({
    language: string().required,
    timezone: string().required,
    format: string().required,
  }),
})

export const partnersSchema = object().shape({
  name: string().required(),
  imageUrl: string().required(),
})

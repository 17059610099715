import React, { useEffect, useState, Fragment } from 'react'
import { object, string } from 'prop-types'

import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { APP_STATE } from '../../../constants'
import { feedShape } from '../../../types'
import useOrganisations from '../../shared/system/useOrganisations'
import api from '../../../lib/api'

import Loader from '../../shared/Loader'
import Detail from '../../shared/OptimizationDetail'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import RemoveModalWindow from '../../shared/RemoveModalWindow'

// helper functions
import alertOps from './alerts'
import actions from './actions'
import conditions from './conditions'

const AlertDetail = ({ alerts, location, alertId, feed }) => {
  const { t } = useTranslation()
  const [alert, setAlert] = useState(null)
  const { selected: selectedOrg } = useOrganisations()

  const [status, setStatus] = useState(APP_STATE.DONE)
  const [users, setUsers] = useState([])
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)

  const parsed = queryString.parse(location.search)

  const isNew = alertId === 'new'
  const isDuplicate = parsed.isDuplicate === 'true'
  const isDuplicateOrNew = isNew || isDuplicate
  const handleDeleteDialogVisibility = setDeleteDialogVisible

  // Common alert operations functions
  const alertOperations = alertOps(
    alerts,
    alert,
    setAlert,
    isDuplicateOrNew,
    handleDeleteDialogVisibility,
    location,
    feed
  )
  const action = actions(alert, setAlert, users)
  const condition = conditions(alert, setAlert, feed.incoming, feed.outgoing)

  const onActionChanges = {
    onActionColumnChange: () => {},
    onActionFirstValueChange: (index, { target, id }) =>
      action.updateActionValue(index, target ? target.value : id),
    onActionOperatorChange: (index, { target, id }) =>
      action.updateActionValue(index, target ? target.value : id),
    onActionOptionsTypeChange: (index, value) =>
      action.updateActionOptionsType(index, value, true),
    onActionRemove: index => action.removeAction(index),
    onActionSecondOptionsTypeChange: () => {},
    onActionSecondValueChange: () => {},
    onActionTypeChange: (index, operator) =>
      action.updateActionType(index, operator),
  }

  const updateChanges = {
    // needs to be here because function is required by component but we dont use it
    updateCaseSensitive: () => {},
    updateSource: () => {},
    updateName: alertOperations.updateName,
    updateOperator: (index, operator) =>
      condition.updateConditionOperator(index, operator, alert),
    updateValue: (index, { target, id }) =>
      condition.updateConditionValue(index, target ? target.value : id, alert),
    updateValueType: (index, type, id) =>
      condition.updateConditionType(index, type, id, alert),
    updateStatus: alertOperations.updateStatus,
  }

  useEffect(
    () => {
      setStatus(APP_STATE.FETCHING)

      if (!isNew) {
        alerts
          .get(alertId)
          .then(alert => {
            if (isDuplicate) alert.name = `${alert.name} duplicate`

            // refactor once we support multiselect dropdown
            const { options, actions } = alert

            if (options && options.incomingIds)
              options.incomingIds = options.incomingIds[0]
            if (options && options.outgoingIds)
              options.outgoingIds = options.outgoingIds[0]

            for (let action of actions) {
              if (action.options && action.options.incomingIds)
                action.options.incomingIds = action.options.incomingIds[0]
              if (action.options && action.options.outgoingIds)
                action.options.outgoingIds = action.options.outgoingIds[0]
            }

            // end of refactor
            setAlert(alert)
            setStatus(APP_STATE.DONE)
          })
          .catch(e => {
            console.error(e)
            setStatus(APP_STATE.ERROR)
          })
      } else {
        setStatus(APP_STATE.DONE)
        setAlert({
          name: 'New Alert',
          enabled: true,
          conditions: [
            {
              type: undefined,
            },
          ],
          actions: [
            {
              options: { userIds: [] },
              type: 'sendEmail',
            },
          ],
        })
      }

      api
        .get(`/orgs/${selectedOrg.id}/users?page=1&limit=50`)
        .then(({ data: users }) => setUsers(users))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNew, isDuplicate]
  )

  if (status === APP_STATE.FETCHING) return <Loader />

  if (!alert || alert === {}) return <p>{t('no_data')}</p>

  return (
    <Fragment>
      <PageDescription
        heading={`${t('transformations_heading')} ${t(
          'transformations_editing'
        )} ${alert.name}`}
        description={t('transformation_detail_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: 'transformation_delete_button',
            onClick: () => handleDeleteDialogVisibility(true),
            disabled: isDuplicateOrNew,
          },
          {
            name: alert.enabled
              ? 'transformation_pause_button'
              : 'transformation_enable_button',
            onClick: () => alertOperations.onPause(),
            disabled: isDuplicateOrNew,
          },
          {
            name: 'transformation_duplicate_button',
            disabled: isDuplicateOrNew,
            link: `?isDuplicate=true`,
          },
          {
            name: 'transformation_add_new',
            onClick: () =>
              navigate(
                location.href.replace(/\/alerts\/([^/])*/i, `/alerts/new`)
              ),
            disabled: isDuplicateOrNew,
          },
        ]}
      />

      <Detail
        addNewAction={action.addNewAction}
        addNewCondition={condition.addNewCondition}
        columnList={['']}
        hideColumnList={true}
        dispatch={() => {}}
        getConditionList={condition.getConditionList}
        getConditionValue={condition.getConditionValue}
        getConditionChildValue={condition.getConditionChildValue}
        getActionValue={action.getActionValue}
        getListOfActions={action.getListOfActions}
        inputId="rule-name"
        mainLabel={t('transformation_name_label')}
        masterText={t('alert')}
        partnerText={t('partner_alert')}
        onActionChanges={onActionChanges}
        onRemoveCondition={condition.removeCondition}
        onSave={alertOperations.onSave}
        outputCols={['']}
        saveLink={`/orgId/feeds/${feed.id}/alerts`}
        selectedOutgoing={null}
        statusDataId="select-alert-status"
        type={alert}
        updateChanges={updateChanges}
        maxConditions={1}
      />

      <RemoveModalWindow
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        onRemove={alertOperations.onRemove}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

AlertDetail.propTypes = {
  alerts: object.isRequired,
  location: object,
  alertId: string,
  feed: feedShape.isRequired,
}

export default AlertDetail

import { useContext } from 'react'
import { AnnouncementContext } from './Context'

import api from '../../lib/api'

const useAnnoucements = () => {
  const [state, setState] = useContext(AnnouncementContext)
  const { text } = state

  const getAnnouncementUrl = () => `/announcements/`

  const fetch = () => {
    return api
      .get(getAnnouncementUrl())
      .then(({ data }) => {
        if (!data.text) {
          setState({ text: null })
        } else {
          setState({ text: data.text })
        }
        return Promise.resolve(data)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  const update = text => {
    return api
      .patch(getAnnouncementUrl(), { text })
      .then(() => {
        setState({ text })
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  const clear = () => update(null)

  return {
    // Variables
    text,
    // Methods
    fetch,
    update,
    clear,
  }
}

export default useAnnoucements

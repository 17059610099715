export const makeDataSource = data => {
  const headerValues = data[0]
  const tableValues = data.slice(1)

  return tableValues.reduce(
    (accessor, arrayWithValues, rowIndex) => [
      ...accessor,
      createRow(arrayWithValues, headerValues, rowIndex),
    ],
    []
  )
}

export const makeTableColumns = columnsData =>
  columnsData.map(column => ({
    Header: column,
    accessor: column,
    key: column,
    id: column,
  }))

const createRow = (arrayWithValues, headerValues, rowIndex) =>
  arrayWithValues.reduce(
    (accessor, value, index) => ({
      ...accessor,
      [headerValues[index]]: value,
      key: `${rowIndex}${index}`,
    }),
    {}
  )

import React, { useState } from 'react'
import styled from 'styled-components'
import { string, arrayOf, number, func } from 'prop-types'
import TagsInput from '../TagsInput'
import 'react-tagsinput/react-tagsinput.css'

import Button from '../Button'
import Dropdown from '../Dropdown'
import Text from '../Text'
import {
  LIGHT_GREY,
  LIGHT_TRANSPARENT_GREY,
  MIDDLE_GREY,
  DARK_GREY,
  ORANGE,
  TEXT_SMALL,
} from '../../../constants'
import { ruleInputShape } from '../../../types'

const TagsWrapper = styled.div`
  display: flex;
  width: 430px;
  align-items: center;

  .react-tagsinput {
    width: 300px;
    white-space: nowrap;
    overflow-x: auto;
    border: 1px solid transparent;
    padding: 0;

    span {
      margin-top: auto;
    }
  }

  .react-tagsinput-tag {
    background-color: ${LIGHT_GREY};
    border: ${DARK_GREY};
    color: ${DARK_GREY};
    padding: 2px 5px;
  }

  .react-tagsinput-input {
    width: 100px;
    padding: 0;
  }

  .text {
    background-color: ${LIGHT_TRANSPARENT_GREY};
  }

  .react-tagsinput--focused {
    border: 1px solid transparent;
  }

  .react-tagsinput-remove {
    color: ${ORANGE};
  }
`

const StyledLabel = styled.div`
  border: 1px solid ${LIGHT_GREY};
  padding: 10px 10px 0 10px;
`

const DropdownWrapper = styled.div`
  width: 120px;
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  width: 120px;
  height: 35px;
`

const TextWrapper = styled.div`
  padding-left: 10px;
`

const returnValue = ({ type, name, value }) =>
  type === 'column' ? name : value

const customizedInputProps = {
  placeholder: '',
}

const CombinationInput = ({ index, onChange, selectedValue, shownList, t }) => {
  const savedTags = selectedValue.input.map(item => returnValue(item))

  const [tags, setTags] = useState(savedTags)
  const [dropdownDisplay, setDropdownDisplay] = useState(false)
  const [sentValues, setSentValues] = useState(selectedValue.input)

  const handleTextChange = tags => {
    const addedTag = tags[tags.length - 1]
    const updatedSentValues = [...sentValues, { type: 'text', value: addedTag }]
    setTags(tags)
    setSentValues(updatedSentValues)
    onChange(index, { target: { value: updatedSentValues } })
  }

  const handleDropdownChange = event => {
    const updatedSentValues = [
      ...sentValues,
      { type: 'column', name: event.name },
    ]
    setTags([...tags, event.name])
    setSentValues(updatedSentValues)
    setDropdownDisplay(false)
    onChange(index, { target: { value: updatedSentValues } })
  }

  const onRemoveSentValues = key => {
    if (key > -1) {
      sentValues.splice(key, 1)
    }
    setSentValues(sentValues)
    onChange(index, { target: { value: sentValues } })
  }

  const renderTag = props => {
    const {
      classNameRemove,
      tag,
      key,
      onRemove,
      getTagDisplayValue,
      ...other
    } = props
    const className = other.className

    return (
      <span
        key={key}
        {...other}
        className={`${className} ${
          sentValues[key].type === 'text' ? 'text' : 'column'
        }`}
      >
        {tag}
        {/* eslint-disable-next-line */}
        <a
          className={classNameRemove}
          onClick={() => {
            onRemove(key)
            onRemoveSentValues(key)
          }}
        />
      </span>
    )
  }

  return (
    <div>
      <TagsWrapper>
        <StyledLabel>
          <Text color={MIDDLE_GREY} size={TEXT_SMALL}>
            {t('value')}
          </Text>
          <TagsInput
            value={tags}
            onChange={handleTextChange}
            renderTag={renderTag}
            inputProps={customizedInputProps}
          />
        </StyledLabel>
        {!dropdownDisplay && (
          <StyledButton onClick={() => setDropdownDisplay(!dropdownDisplay)}>
            + {t('column')}
          </StyledButton>
        )}
        {dropdownDisplay && (
          <DropdownWrapper>
            <Dropdown
              label={t('column')}
              onChange={event => handleDropdownChange(event)}
              options={shownList.map(column => ({
                id: column,
                name: column,
              }))}
              value={{ id: t('select'), name: t('select') }}
            />
          </DropdownWrapper>
        )}
      </TagsWrapper>
      <TextWrapper>
        <Text color={MIDDLE_GREY} size={TEXT_SMALL}>
          {t('enter_tag')}
        </Text>
      </TextWrapper>
    </div>
  )
}

CombinationInput.propTypes = {
  classNameRemove: string,
  getTagDisplayValue: func,
  index: number,
  key: number,
  onChange: func,
  onRemove: func,
  selectedValue: ruleInputShape,
  shownList: arrayOf(string).isRequired,
  tag: string,
}

export default CombinationInput

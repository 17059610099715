import React, { Fragment } from 'react'
import styled from 'styled-components'
import { func, bool, string, object, arrayOf } from 'prop-types'

import Form from './Form'
import Text from './Text'
import Input from './Input'
import Button from './Button'
import Dropdown from './Dropdown'

import { WHITE } from '../../constants'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import { newMappingRowSchema } from '../../formValidationSchemas'
import { dropdownValueShape } from '../../types'
import capitalizeFirstLetter from '../../lib/capitalizeFirstLetter'

const FormContentWrapper = styled.div`
  display: flex;
  background: ${WHITE};
  padding: 10px;
  ${({ isAddPartner }) => isAddPartner && 'justify-content: space-around'};
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  margin: 0 5px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  button {
    display: block;
    margin: 0 5px 5px;
  }
`

const MappingEditable = ({
  columnType,
  columnTypeKeys,
  dispatch,
  isAddPartner,
  keys,
  mapping,
  mappingField,
  name,
  setFormVisible,
  t,
  updateMapping,
}) => (
  <Form
    initialValues={{
      name: name || '',
      mappingField: mappingField || '',
      mappingRequired: columnType || '',
    }}
    onSubmit={(values, { resetForm }) => {
      const { name, mappingField, mappingRequired } = values
      Promise.resolve(
        updateMapping(
          name,
          mappingField || '',
          mapping,
          dispatch,
          mappingRequired
        )
      ).then(() => {
        resetForm()
        setFormVisible(false)
      })
    }}
    validationSchema={newMappingRowSchema}
    content={(_, values) => (
      <Fragment>
        <FormContentWrapper isAddPartner={isAddPartner}>
          <Container>
            {name && <Text bold>{capitalizeFirstLetter(name)}</Text>}
            {!name && (
              <Input
                name="name"
                data-id="new-row"
                placeholder={t('input_placeholder_column_name')}
              />
            )}
          </Container>
          <Container isAddPartner={isAddPartner}>
            <Dropdown
              name="mappingField"
              dataId="mapping-field-dropdown"
              options={[{ id: '', name: t('none') }, ...keys]}
              placeholder={mappingField || t('none')}
              value={{
                id: values.mappingField,
                name: values.mappingField,
              }}
            />
          </Container>
          {columnTypeKeys && (
            <Container isAddPartner={isAddPartner}>
              <Dropdown
                name="mappingRequired"
                dataId="mapping-required-dropdown"
                options={columnTypeKeys}
                placeholder={columnType || columnTypeKeys[0].name}
                value={{
                  id: values.mappingRequired,
                  name: values.mappingRequired,
                }}
              />
            </Container>
          )}
          <ButtonsContainer>
            <Button
              theme={SECONDARY_BUTTON}
              type="submit"
              dataId="save-mapping"
            >
              {t('button_save')}
            </Button>
            <Button
              theme={SECONDARY_BUTTON}
              onClick={() => setFormVisible(false)}
              dataId="cancel-mapping"
            >
              {t('button_cancel')}
            </Button>
          </ButtonsContainer>
        </FormContentWrapper>
      </Fragment>
    )}
  />
)

MappingEditable.propTypes = {
  columnType: string,
  columnTypeKeys: arrayOf(dropdownValueShape),
  isAddPartner: bool,
  keys: arrayOf(dropdownValueShape).isRequired,
  mapping: object.isRequired,
  mappingField: string,
  name: string,
  setFormVisible: func,
  updateMapping: func,
}

export default MappingEditable

import { toast } from 'react-toastify'

import { fetchingData, fetchingFailed } from '../../App/actions'
import api from '../../../lib/api'

export const MASTER_RULES_RECEIVED = 'MASTER_RULES_RECEIVED'
export const OUTGOING_RULES_RECEIVED = 'OUTGOING_RULES_RECEIVED'
export const OUTGOING_CHANGED = 'OUTGOING_CHANGED'

const getMasterRuleUrl = (feedId, ruleId) =>
  `/feeds/${feedId}/master/rules/${ruleId}`
const getOutgoingRuleUrl = (feedId, outgoingId, ruleId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/rules/${ruleId}`

export const receivedMasterRules = data => ({
  type: MASTER_RULES_RECEIVED,
  data,
})

export const receivedOutgoingRules = data => ({
  type: OUTGOING_RULES_RECEIVED,
  data,
})

export const selectedOutgoingChanged = selectedOutgoing => ({
  type: OUTGOING_CHANGED,
  selectedOutgoing: selectedOutgoing,
})

export const fetchMasterRuleList = (feedId, dispatch, t) => {
  dispatch(fetchingData())

  api
    .get(getMasterRuleUrl(feedId, ''))
    .then(resp => dispatch(receivedMasterRules(resp.data)))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

export const fetchOutgoingRuleList = (feedId, outgoingId, dispatch, t) => {
  dispatch(fetchingData())

  api
    .get(getOutgoingRuleUrl(feedId, outgoingId, ''))
    .then(resp => dispatch(receivedOutgoingRules(resp.data)))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

export const updatePosition = (feedId, ruleId, newPosition, outgoingId, t) =>
  api
    .patch(
      `${
        outgoingId
          ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
          : getMasterRuleUrl(feedId, ruleId)
      }/position`,
      {
        position: newPosition,
      }
    )
    .then(() => toast.success(t('position_update_success')))
    .catch(err => toast.error(t('position_update_failed')))

export const updateRule = (feedId, ruleId, data, outgoingId, t) =>
  api
    .patch(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
        : getMasterRuleUrl(feedId, ruleId),
      data
    )
    .then(() => toast.success(t('rule_save_success')))
    .catch(() => toast.error(t('rule_save_error')))

export const removeRule = (feedId, ruleId, outgoingId, t) =>
  api
    .delete(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
        : getMasterRuleUrl(feedId, ruleId)
    )
    .then(() => toast.success(t('rule_delete_success')))
    .catch(() => toast.error(t('rule_delete_error')))

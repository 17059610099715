import React, { useState } from 'react'
import { any } from 'prop-types'

const SidePanelContext = React.createContext([{}, () => {}])

const SidePanelProvider = props => {
  const [state, setState] = useState({
    title: 'Sidebar',
    isCollapsed: true,
  })
  return (
    <SidePanelContext.Provider value={[state, setState]}>
      {props.children}
    </SidePanelContext.Provider>
  )
}

SidePanelProvider.propTypes = {
  children: any,
}

export { SidePanelContext, SidePanelProvider }

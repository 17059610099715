import React, { useState, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { string, arrayOf } from 'prop-types'
import styled from 'styled-components'

import PageDescription from '../shared/PageDescription'
import SystemFileList from './SystemFileList'
import Filter from './SystemFileList/Filter'
import FileUploadDialog from '../shared/FileUploadDialog'
import ModalWindow from '../shared/ModalWindow'
import ErrorBoundary from '../shared/ErrorBoundary'

import { APP_STATE } from '../../constants'
import { dropdownValueShape } from '../../types'
import { PRIMARY_BUTTON, SECONDARY_BUTTON } from '../../constants/buttonThemes'
import reducer, { initialState } from './SystemFileList/reducer'
import {
  fetchFiles,
  reuploadFile,
  createNewFile,
} from './SystemFileList/actions'
import api from '../../lib/api'

const newFileId = 'NEW'

const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

const AdminPage = ({ brands, organisationId }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [activeFileId, setActiveFileId] = useState(newFileId)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [toDeleteFileId, setToDeleteFileId] = useState('')
  const [fileType, setFileType] = useState('')
  const [brand, setBrand] = useState('')

  const [state, dispatch] = useReducer(reducer, initialState)
  const { files, pageSize, numberOfFiles, currentPage, filesListState } = state

  const { t } = useTranslation()

  useEffect(
    () => {
      fetchFiles({ pageSize, dispatch, t, fileType, brand, organisationId })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileType, brand, organisationId]
  )

  const prepareNewFile = () => {
    setActiveFileId(newFileId)
    setIsDialogVisible(true)
  }

  const prepareFileReupload = id => {
    setActiveFileId(id)
    setIsDialogVisible(true)
  }

  const onSaveClicked = (name, stringData, fileType) => {
    if (activeFileId === newFileId) {
      createNewFile({
        name,
        stringData,
        fileType,
        organisationId,
        pageSize,
        dispatch,
        t,
      })
    } else {
      reuploadFile({ stringData, activeFileId, t })
    }
    setIsDialogVisible(false)
  }

  const prepareFileDelete = id => {
    setToDeleteFileId(id)
    setDeleteDialogVisible(true)
  }

  const changeFileType = ({ id }) => {
    setFileType(id)
  }

  const changeBrand = ({ id }) => {
    setBrand(id)
  }

  const onLoadMoreClicked = () =>
    fetchFiles({
      pageSize,
      dispatch,
      organisationId,
      currentPage: currentPage + 1,
      t,
      fileType,
      brand,
    })

  const deleteFile = () => {
    api
      .delete(`/files/${toDeleteFileId}`)
      .then(() => {
        toast.success(t('system_file_delete_successfull'))
        setDeleteDialogVisible(false)
        fetchFiles({ pageSize, dispatch, organisationId, t })
      })
      .catch(error => {
        toast.error(t('system_file_delete_unsuccessfull'))
        setDeleteDialogVisible(false)
        console.log(error)
        fetchFiles({ pageSize, dispatch, organisationId, t })
      })
  }

  return (
    <ErrorBoundary>
      <PageHeadingContainer>
        <PageDescription
          heading={t('system_files')}
          description={t('system_files_description')}
        />
        <Filter
          brands={brands}
          changeBrand={changeBrand}
          changeFileType={changeFileType}
          t={t}
        />
      </PageHeadingContainer>

      <SystemFileList
        files={files}
        isLoading={filesListState === APP_STATE.FETCHING}
        onDeleteClicked={prepareFileDelete}
        onLoadMoreClicked={onLoadMoreClicked}
        onUploadClicked={prepareFileReupload}
        numberOfFiles={numberOfFiles}
        prepareNewFile={prepareNewFile}
        t={t}
      />

      <FileUploadDialog
        onCancel={() => setIsDialogVisible(false)}
        enableTypeSelection={true}
        onSave={onSaveClicked}
        title={t('button_new_file')}
        visible={isDialogVisible}
        t={t}
      />

      <ModalWindow
        visible={deleteDialogVisible}
        okText={t('button_delete')}
        onOk={deleteFile}
        okTheme={PRIMARY_BUTTON}
        onCancel={() => setDeleteDialogVisible(false)}
        cancelTheme={SECONDARY_BUTTON}
        t={t}
      >
        {t('system_delete_file_confirmation')}
      </ModalWindow>
    </ErrorBoundary>
  )
}

AdminPage.propTypes = {
  organisationId: string.isRequired,
  brands: arrayOf(dropdownValueShape),
}

export default AdminPage

import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { func, string, arrayOf, object, number, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import Input from '../Input'
import Dropdown from '../Dropdown'
import Text from '../Text'
import CombinationInput from '../CombinationInput'

import { inputNumber, inputString } from '../../../formValidationSchemas'
import { calcOperatorList } from '../../../constants/actionList'

const Form = styled.form`
  max-width: 100%;
`

const ElementWrapper = styled.div`
  width: 100%;
  margin-right: 16px;
  max-width: 220px;
`

const PaddedDiv = styled.div`
  padding: 21px;
`

const renderComponent = (item, index, t, parentValue, shownList) => {
  if (!item) return
  const isNumber = item.render === 'input-number' || item.type === 'number'

  switch (item.render) {
    case 'input':
    case 'input-number':
      return (
        <Formik
          enableReinitialize
          initialValues={{
            value: item.selectedValue,
          }}
          validationSchema={isNumber ? inputNumber : inputString}
        >
          {() => (
            <Form>
              <Input
                type={isNumber ? 'number' : 'text'}
                onBlur={event => item.onChange(index, event, item.id)}
                name="value"
                min={
                  isNumber && (item.min || item.min === 0)
                    ? item.min
                    : undefined
                }
                data-id="new-row"
                label={t(item.title) || t('form_value_label')}
                maximumLength={
                  parentValue === 'setValue' || 'contains' ? 1024 : 100
                }
              />
            </Form>
          )}
        </Formik>
      )

    case 'multiple-input':
      return (
        <Fragment>
          <Formik
            enableReinitialize
            initialValues={{
              value: item.selectedValue,
            }}
            validationSchema={isNumber ? inputNumber : inputString}
          >
            {() => (
              <Form>
                <Input
                  onChange={value => item.onChange(index, value, item.id)}
                  type={isNumber ? 'number' : 'text'}
                  name="value"
                  data-id="new-row"
                  value={item.selectedValue}
                  label={t(item.title)}
                />
              </Form>
            )}
          </Formik>
          {renderComponent(item.renderChild, index, t)}
        </Fragment>
      )

    case 'dropdown':
      const itemValue = item.selectedValue ? item.selectedValue : 'select'
      const hasValues = item.list && item.list.length > 0

      return (
        <Fragment>
          <ElementWrapper>
            {!hasValues && (
              <PaddedDiv>
                <Text>{t('no_data')}</Text>
              </PaddedDiv>
            )}
            {hasValues && (
              <Dropdown
                onChange={value => item.onChange(index, value, item.id)}
                options={item.list.map(({ id, name }) => ({
                  id: id,
                  name: t(name || 'unknown'),
                }))}
                value={{
                  id: itemValue,
                  name: getName(itemValue, item.list, t),
                }}
                label={t(item.title || 'unknown')}
              />
            )}
          </ElementWrapper>
          {item.showSearchInColumn &&
            renderComponent(item.renderChild, index, t)}
        </Fragment>
      )

    case 'selection':
      return (
        <Fragment>
          <Selection
            index={index}
            label={t(item.title) || t('form_value_label')}
            list={item.list}
            onChange={item.onChange}
            shownList={shownList}
            parentValue={item.id}
            selectedValue={item.selectedValue}
            t={t}
          />
          {item.renderChild && renderComponent(item.renderChild, index, t)}
        </Fragment>
      )

    case 'calculateValue':
      return (
        <Fragment>
          {renderComponent(item.renderChild, index, t)}
          <Dropdown
            onChange={value => item.onChange(index, value, item.id)}
            options={calcOperatorList.map(({ id, name }) => ({
              id: id,
              name: t(name),
            }))}
            label={t('operator')}
            value={{
              id: item.selectedValue || calcOperatorList[0].id,
              name: item.selectedValue || t(calcOperatorList[0].name),
            }}
          />
        </Fragment>
      )

    case 'combineValues':
      return (
        <CombinationInput
          index={index}
          onChange={item.onChange}
          selectedValue={item.selectedValue}
          shownList={shownList}
          t={t}
        />
      )

    default:
      return
  }
}

const getName = (value, list, t) => {
  // refactor once we have multiselects
  value = Array.isArray(value) ? value[0] : value
  const selectedValue = list.find(listValue => listValue.id === value)
  if (selectedValue) {
    return t(selectedValue.name)
  }
  return t(value)
}

const Selection = ({
  caseSensitive,
  hasFullHeight,
  index,
  label,
  list,
  onChange,
  parentValue,
  selectedValue,
  shownList,
  text,
  updateCaseSensitive,
}) => {
  const { t } = useTranslation()

  if (!selectedValue && list.length > 0) selectedValue = t('select')
  const existRuleType = list.find(
    item => item.name === selectedValue || selectedValue === t('select')
  )
  const defaultLabel = hasFullHeight
    ? t('form_criteria_label')
    : t('form_action_label')
  const getValueById = id => {
    const value = list.find(item => item.id === id)
    if (!value) return { render: 'nothing' }

    return value || list[0]
  }

  return (
    <Fragment>
      <ElementWrapper>
        <Dropdown
          label={label ? label : defaultLabel}
          onChange={value => onChange(index, value.id)}
          options={list.map(value => ({ id: value.id, name: t(value.id) }))}
          value={{ id: selectedValue, name: t(selectedValue) }}
          t={t}
        />
        {caseSensitive !== undefined && (
          <Fragment>
            <input
              type="checkbox"
              name="caseSensitive"
              defaultChecked={caseSensitive}
              onChange={event =>
                updateCaseSensitive(
                  index,
                  event.target.checked,
                  text === 'then'
                )
              }
            />
            {t('case_sensitive')}
          </Fragment>
        )}
      </ElementWrapper>
      {renderComponent(
        getValueById(selectedValue),
        index,
        t,
        parentValue,
        shownList
      )}
      {!existRuleType && (
        <Fragment>
          "{selectedValue}" {t('transformation_does_not_exist')}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Selection

Selection.propTypes = {
  caseSensitive: bool,
  hasFullHeight: bool,
  index: number,
  label: string,
  list: arrayOf(object),
  onChange: func,
  parentValue: string,
  selectedValue: string,
  shownList: arrayOf(string),
  text: string,
  updateCaseSensitive: func,
}

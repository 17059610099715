import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Papa from 'papaparse'
import { func, string, bool } from 'prop-types'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import ModalWindow from './ModalWindow'
import Table from './Table'
import Tooltip from './Tooltip2'
import TooltipIcon from './TooltipIcon'
import Input from './Input'
import Dropdown from './Dropdown'
import { separators, uploadFileTypes } from '../../constants/formData'
import { ORANGE, WHITE } from '../../constants'

const StyledTable = styled(Table)`
  min-height: 150px;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
`

const UploadButton = styled.div`
  position: absolute;
  height: 55px;
  background: ${ORANGE};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const StyledInput = styled(Input)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const ContainerDiv = styled.div`
  position: relative;
`

const Spacer = styled.div`
  height: 60px;
`

const FileUploadDialog = ({
  onCancel,
  onSave,
  visible,
  title,
  enableTypeSelection,
  type,
}) => {
  const { t } = useTranslation()
  const [fileSize, setFileSize] = useState(null)
  const [fileName, setFileName] = useState('')
  const [parsedColumns, setParsedColumns] = useState([])
  const [parsedRows, setParsedRows] = useState([])
  const [data, setData] = useState(null)
  const [parsedData, setParsedData] = useState([])
  const [delimiter, setDelimiter] = useState(null)
  const [fileType, setFileType] = useState(type)

  useEffect(
    () => {
      if (delimiter && data) {
        const fileData = { data, fileSize, fileName }
        parseFileData(fileData, delimiter, '"')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delimiter, data]
  )

  const modalTitle = fileName && fileSize ? `${fileName} | ${fileSize}` : title

  const handleFileChange = uploadedFile => {
    setFileName(uploadedFile.name)
    setFileSize(`${(uploadedFile.size / 1000).toFixed(1)} kB`)

    const fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(uploadedFile, 'UTF-8')
  }

  const handleFileRead = e => {
    const { result } = e.target

    const parsedData = Papa.parse(result, {
      quoteChar: '"',
    })

    setData(result)
    setDelimiter(parsedData.meta.delimiter)
  }

  const parseFileData = (uploadedFile, delimiter, quote) => {
    let { data: parsedData } = Papa.parse(uploadedFile.data, {
      delimiter,
      quoteChar: quote,
    })

    const [columns, ...rest] = parsedData

    setParsedData(parsedData)

    const rows = rest.splice(0, 4).map(row => {
      return columns.reduce((prev, _, index) => {
        prev[columns[index]] = row[index]
        return prev
      }, {})
    })

    setParsedColumns(columns)
    setParsedRows(rows)
  }

  const handleSave = () => {
    if (parsedData.length > 0 && delimiter && fileType && fileName.length > 0) {
      const stringData = Papa.unparse(parsedData, {
        delimiter: '\t',
        quoteChar: '"',
      })
      if (stringData) {
        onSave(fileName, stringData, fileType)
        setStateToInitial()
      }
    } else {
      toast.error(t('system_file_upload_unsuccessfull'))
    }
  }

  const handleClose = () => {
    onCancel()
    setStateToInitial()
  }

  const setStateToInitial = () => {
    setFileName('')
    setFileSize(null)
    setParsedColumns([])
    setParsedRows([])
    setDelimiter(',')
    setData(null)
    setFileType(type)
  }

  const Line = styled.div``

  return (
    <ModalWindow
      minWidth={'50%'}
      okText={t('button_save')}
      onCancel={handleClose}
      onOk={handleSave}
      title={modalTitle}
      visible={visible}
    >
      <Formik
        initialValues={{
          checkboxValue: false,
        }}
      >
        <Grid>
          <Line>
            {t('file_upload_name')}
            <TooltipIcon
              title={t('file_upload_name_tooltip')}
              position={'right'}
            />
          </Line>
          <Tooltip position={'bottom'}>
            <Input
              name="filename"
              type="text"
              onChange={e => setFileName(e.target.value)}
              value={fileName}
            />
          </Tooltip>
          <Tooltip title={t('file_upload_button_tooltip')} position={'bottom'}>
            <ContainerDiv>
              <UploadButton>{t('file_upload_button')} </UploadButton>
              <StyledInput
                name="file"
                type="file"
                onChange={e => handleFileChange(e.target.files[0])}
                isUpload
              />
            </ContainerDiv>
          </Tooltip>
          <Spacer />
          <Tooltip title={t('file_upload_dropdown_tooltip')}>
            <Dropdown
              onChange={event => setDelimiter(event.id)}
              label={t('file_upload_delimiter')}
              dataId="file-upload-delimiter"
              name="fileUploadDelimiter"
              options={separators}
              boundValue={separators.find(x => x.id === delimiter)}
              placeholder={t('select')}
            />
          </Tooltip>
          {enableTypeSelection && (
            <Dropdown
              onChange={event => setFileType(event.id)}
              label={t('file_upload_type')}
              dataId="file-upload-type"
              name="fileUploadType"
              options={uploadFileTypes.map(x => ({ ...x, name: t(x.id) }))}
              placeholder={t('select')}
            />
          )}
        </Grid>
      </Formik>

      <StyledTable
        columns={parsedColumns.map(column => ({
          Header: column,
          accessor: column,
          key: column,
          sortable: false,
        }))}
        defaultPageSize={5}
        data={parsedRows}
        showPagination={false}
        t={t}
      />
    </ModalWindow>
  )
}

FileUploadDialog.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  title: string.isRequired,
  visible: bool.isRequired,
  enableTypeSelection: bool,
  type: string,
}

export default FileUploadDialog

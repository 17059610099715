import styled from 'styled-components'
import { FULL_MAX_CONTENT_WIDTH, LIGHT_GREY } from '../../constants'

const ContentWrapper = styled.div`
  background: ${LIGHT_GREY};
  padding: 30px 15px;
  width: 100%;
  max-width: ${FULL_MAX_CONTENT_WIDTH};
  margin-top: 15px;
`

export default ContentWrapper

import React, { Fragment, useEffect, useState, useReducer } from 'react'
import { func, string, arrayOf, any } from 'prop-types'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { downloadUrl } from '../../../constants/urls'

import reducer, { initialState, dummyPassword } from './reducer'
import {
  createSource,
  fetchSource,
  changeValue,
  requestSourceDone,
  getIncomingSourceFileUrl,
} from './actions'
import { templateShape } from '../../../types'
import { APP_STATE } from '../../../constants'
import { connectionPorts } from '../../../constants/formData'

import FormContent from './FormContent'
import Loader from '../../shared/Loader'
import PageDescription from '../../shared/PageDescription'
import PreviewModal from './PreviewModal'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import downloadFile from '../../../lib/downloadFile'
import api from '../../../lib/api'
import { cancelable } from '../../../lib/signal'

const SourceForm = ({
  description,
  heading,
  onCancel,
  onSave,
  organisationId,
  organisationName,
  outgoingMapping,
  resourceId,
  feedId,
  incoming,
  type,
  change,
}) => {
  const [showPreview, setPreviewVisible] = useState(false)
  const [previewFile, setPreviewFile] = useState('')

  const [state, dispatch] = useReducer(reducer, initialState)
  const { t } = useTranslation()

  const {
    formatType,
    formatDelimiter,
    formatQuote,
    formatJson,
    formatNormalizeNewlines,
    formatHeader,
    formatRelaxColumnCount,
    connectionType,
    connectionHostname,
    connectionPort,
    connectionPath,
    connectionUsername,
    connectionPassword,
    name,
    enabled,
    publish,
    fetchError,
    compressionType,
    sourceFormState,
    job,
    mergeType,
    mergeSrcKey,
    mergeDstKey,
  } = state

  const isNew = resourceId === 'new' || !resourceId
  const isIncoming = outgoingMapping ? false : true

  // this method is used to fix double displaying of the source form
  // once with init values and then with loaded values
  const checkIsLoaded = () => (!isNew && state === initialState ? false : true)

  const shouldPasswordBeSent =
    isNew || (!isNew && dummyPassword !== connectionPassword)

  useEffect(
    () => {
      if (isNew || !resourceId) {
        dispatch(createSource())
      } else {
        change(resourceId)

        return cancelable(signal => {
          fetchSource(dispatch, feedId, resourceId, type, signal)
        })
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedId, resourceId]
  )

  const sendTestMessage = id => {
    if (id === 'new') id = undefined
    return api.post(
      `/feeds/${feedId}/${!outgoingMapping ? 'incoming' : 'outgoing'}/test`,
      {
        id: id || undefined,
        connection: createConnectionObject(shouldPasswordBeSent),
        format: createFormatObject(),
        compression: createCompressionObject(),
      }
    )
  }

  const getPreviewFile = id => {
    setPreviewFile('')

    sendTestMessage(id)
      .then(({ data: { preview } }) => {
        if (preview.success) {
          if (!preview.data || preview.data === '') {
            toast.error(t('sourceform_file_no_data'))
          } else {
            setPreviewFile(preview.data)
            setPreviewVisible(true)
          }
        } else {
          toast.error(t(preview.error.code || 'sourceform_preview_failed'))
        }
      })
      .catch(() => toast.error(t('sourceform_preview_failed')))
      .finally(() => dispatch(requestSourceDone()))
  }

  const testData = id =>
    sendTestMessage(id)
      .then(({ data: { connection, preview } }) => {
        if (connection.success) {
          toast.success(t('sourceform_test_connection_successful'))
        } else {
          toast.error(
            t(connection.error.code || 'sourceform_test_connection_failed')
          )
        }

        if (!outgoingMapping) {
          if (preview.success) {
            toast.success(t('sourceform_test_preview_successful'))
          } else {
            if (
              !connection.error ||
              (connection.error && preview.error.code !== connection.error.code)
            )
              toast.error(
                t(preview.error.code || 'sourceform_test_preview_failed')
              )
          }
        }
      })
      .catch(() => toast.error(t('sourceform_test_failed')))

  const createConnectionObject = shouldPasswordBeSent => {
    let connectionObject = {
      type: connectionType,
      options: {
        hostname: connectionHostname,
        port: connectionPort,
        path: connectionPath,
        username: connectionUsername,
      },
    }
    if (shouldPasswordBeSent) {
      connectionObject.options.password = connectionPassword
    }

    if (connectionType === 'fs' && resourceId !== 'new') {
      connectionObject = {
        type: connectionType,
        options: { path: `${organisationId}/${resourceId}` },
      }
    } else if (connectionType === 'fs' && resourceId === 'new') {
      connectionObject = {
        type: connectionType,
      }
    }

    return connectionObject
  }

  const createCompressionObject = () => {
    if (!!outgoingMapping) return undefined
    return compressionType === 'none' || !compressionType
      ? null
      : {
          type: compressionType,
        }
  }

  const createMergeObject = () => {
    if (!!outgoingMapping) return undefined
    return !mergeType
      ? {}
      : {
          type: mergeType,
          options:
            mergeType === 'horizontal'
              ? {
                  dstKey: mergeDstKey,
                  srcKey: mergeSrcKey,
                }
              : {},
        }
  }

  const createFormatObjectOutgoing = () => {
    if (formatType === 'xml') {
      let config = {}
      try {
        config = JSON.parse(formatJson)
      } catch (e) {
        alert('1')
        toast.error(t('incoming_format_xml_parse_failed'))
        return null
      }
      if (!config.feedName || !config.rootTag || !config.rowTag) {
        toast.error(t('incoming_format_xml_parse_failed'))
        return null
      }
      // parse config

      return {
        type: formatType,
        options: {
          feedName: config.feedName,
          includeXmlDeclaration: config.includeXmlDeclaration ?? true,
          prettyPrint: config.prettyPrint ?? false,
          rootTag: config.rootTag,
          rowTag: config.rowTag,
          feedXmlns: config.feedXmlns,
          incremental: config.incremental ?? false,
        },
      }
    } else {
      let format = {
        type: formatType,
        options: {
          delimiter: formatDelimiter,
          normalizeNewlines: formatNormalizeNewlines,
          quote: formatQuote,
        },
      }
      if (!outgoingMapping) {
        format.options.header = formatHeader
        format.options.relaxColumnCount = formatRelaxColumnCount
      }
      return format
    }
  }

  const createFormatObject = () => {
    if (formatType === 'xml') {
      let config = {}
      try {
        config = JSON.parse(formatJson)
      } catch (e) {
        alert('3')
        toast.error(t('incoming_format_xml_parse_failed'))
        return null
      }

      if (!config.product || !config.columns) {
        alert('4')
        toast.error(t('incoming_format_xml_parse_failed'))
        return null
      }
      // parse config
      return {
        type: formatType,
        options: {
          product: config.product,
          columns: config.columns,
        },
      }
    } else {
      let format = {
        type: formatType,
        options: {
          delimiter: formatDelimiter,
          normalizeNewlines: formatNormalizeNewlines,
          quote: formatQuote,
        },
      }
      if (!outgoingMapping) {
        format.options.header = formatHeader
        format.options.relaxColumnCount = formatRelaxColumnCount
      }
      return format
    }
  }

  const saveForm = () => {
    const connection = createConnectionObject(shouldPasswordBeSent)
    var format
    if (outgoingMapping) {
      format = createFormatObjectOutgoing()
    } else {
      format = createFormatObject()
    }

    const compression = createCompressionObject()
    const merge = createMergeObject()

    onSave(connection, format, state, resourceId, dispatch, compression, merge)
  }

  const onFormChange = ({ target: { name, value } }) => {
    dispatch(changeValue({ name, value }))
    if (name === 'connectionType') {
      dispatch(
        changeValue({
          name: 'connectionPort',
          value: connectionPorts[value] || 21,
        })
      )
    }

    if (name === 'status') {
      let enabled, publish
      switch (value) {
        case 'active':
          enabled = true
          publish = true
          break
        case 'test':
          enabled = true
          publish = false
          break
        default:
          enabled = false
          publish = false
      }
      dispatch(
        changeValue({
          name: 'enabled',
          value: enabled,
        })
      )
      dispatch(
        changeValue({
          name: 'publish',
          value: publish,
        })
      )
    }
  }

  if (sourceFormState === APP_STATE.FETCHING && !checkIsLoaded())
    return <Loader />

  if (sourceFormState === APP_STATE.ERROR) return <p>{fetchError}</p>

  const buttons = [
    {
      name: 'button_download_last_published',
      onClick: () =>
        downloadFile(
          t,
          `/jobs/${job.id}/${
            !outgoingMapping ? 'incoming' : 'outgoing'
          }/${resourceId}/data`,
          `${name}.csv`
        ),
      tooltip: isIncoming
        ? t('incoming_button_last_publish_tooltip')
        : t('outgoing_button_last_publish_tooltip'),
      disabled: !job,
    },
    {
      name: 'button_preview',
      onClick: () => getPreviewFile(resourceId),
      'data-id': 'button-source-preview',
      tooltip: isIncoming
        ? t('incoming_button_preview_tooltip')
        : t('outgoing_button_preview_tooltip'),
      isHidden: outgoingMapping,
    },
    {
      name: 'button_test',
      onClick: () => testData(resourceId),
      'data-id': 'button-source-test',
      tooltip: t('button_test_connection_tooltip'),
      disabled: connectionType === 'fs',
    },
    {
      name: 'button_download_from_source',
      onClick: () =>
        downloadFile(t, getIncomingSourceFileUrl(feedId, resourceId)),
      isHidden: outgoingMapping,
      tooltip: t('incoming_button_from_source_tooltip'),
      disabled: !resourceId,
    },
  ]

  const showIncomingMergeOptions = () => {
    if (!isIncoming || !incoming) return false

    if (incoming.length === 0 && isNew) return false

    if (incoming.map(x => x.id).indexOf(resourceId) === 0) return false

    return true
  }

  return (
    <Fragment>
      <PageDescription heading={heading} description={description} />

      <TopButtonsWrapper buttons={buttons} />

      {sourceFormState === APP_STATE.DONE && checkIsLoaded() && (
        <FormContent
          connectionHostname={connectionHostname}
          connectionPassword={connectionPassword}
          connectionPath={connectionPath}
          connectionPort={Number(connectionPort)}
          connectionType={connectionType}
          connectionUsername={connectionUsername}
          dummyPassword={dummyPassword}
          formatDelimiter={formatDelimiter}
          formatJson={formatJson}
          formatNormalizeNewlines={formatNormalizeNewlines}
          formatQuote={formatQuote}
          formatType={formatType}
          formatHeader={String(formatHeader)}
          formatRelaxColumnCount={String(formatRelaxColumnCount)}
          compressionType={compressionType}
          isNew={isNew}
          showIncomingMergeOptions={showIncomingMergeOptions()}
          name={name}
          mergeType={mergeType}
          mergeSrcKey={mergeSrcKey}
          mergeDstKey={mergeDstKey}
          onCancel={onCancel}
          onFormChange={onFormChange}
          outgoingMapping={outgoingMapping}
          outgoingId={resourceId}
          organisationId={organisationId}
          organisationName={organisationName}
          downloadUrl={downloadUrl}
          saveForm={saveForm}
          enabled={enabled}
          publish={publish}
          t={t}
          type={type}
        />
      )}

      {showPreview && (
        <PreviewModal
          title={name}
          onOk={() => setPreviewVisible(false)}
          content={previewFile}
          isWide={true}
          t={t}
        />
      )}
    </Fragment>
  )
}

SourceForm.propTypes = {
  description: string.isRequired,
  feedId: string,
  heading: string.isRequired,
  onCancel: func,
  onSave: func.isRequired,
  organisationId: string,
  organisationName: string,
  outgoingMapping: arrayOf(templateShape),
  resourceId: string,
  type: string,
  change: func,
  incoming: arrayOf(any),
}

export default SourceForm

import React from 'react'
import styled from 'styled-components'
import { any } from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Edit = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

Edit.propTypes = {
  children: any,
}

export default Edit

export const timezones = [
  {
    abbr: 'AET',
    name: 'Australian Eastern Time',
    location: 'Australia',
    id: 'Australia/Brisbane',
  },
  {
    abbr: 'AKDT',
    name: 'Alaska Daylight Time',
    location: 'North America',
    id: 'America/Anchorage',
  },
  {
    abbr: 'AST',
    name: 'Arabia Standard Time',
    location: 'Asia',
    id: 'Asia/Baghdad',
  },
  {
    abbr: 'ASTN',
    name: 'Atlantic Standard Time',
    location: 'North America',
    id: 'America/Havana',
  },
  {
    abbr: 'AWST',
    name: 'Australian Western Standard Time',
    location: 'Australia',
    id: 'Australia/Perth',
  },
  {
    abbr: 'BST',
    name: 'British Time',
    location: 'Europe',
    id: 'Europe/London',
  },
  {
    abbr: 'CET',
    name: 'Central European Time',
    location: 'Europe',
    id: 'CET',
  },
  {
    abbr: 'CST',
    name: 'China Standard Time',
    location: 'Asia',
    id: 'Asia/Shanghai',
  },
  {
    abbr: 'CT',
    name: 'Central Time',
    location: 'North America',
    id: 'America/El_Salvador',
  },
  {
    abbr: 'EET',
    name: 'Eastern European Time',
    location: 'Europe',
    id: 'EET',
  },
  {
    abbr: 'EGST',
    name: 'Eastern Greenland Time',
    location: 'North America',
    id: 'Atlantic/Azores',
  },
  {
    abbr: 'EDT',
    name: 'Eastern Daylight Time',
    location: 'North America',
    id: 'America/Toronto',
  },
  {
    abbr: 'FET',
    name: 'Further-Eastern European Time',
    location: 'Europe',
    id: 'Europe/Minsk',
  },
  {
    abbr: 'GMT',
    name: 'Greenwich Mean Time UTC',
    location: 'Europe',
    id: 'Etc/GMT-0',
  },
  {
    abbr: 'HKT',
    name: 'Hong Kong Time',
    location: 'Asia',
    id: 'Asia/Hong_Kong',
  },
  {
    abbr: 'HST',
    name: 'Hawaii Standard Time',
    location: 'North America',
    id: 'Pacific/Honolulu',
  },
  {
    abbr: 'ICT',
    name: 'Indochina Time',
    location: 'Asia',
    id: 'Asia/Bangkok',
  },
  {
    abbr: 'ISTIR',
    name: 'Irish Standard Time',
    location: 'Europe',
    id: 'Europe/Dublin',
  },
  {
    abbr: 'IDT',
    name: 'Israel Daylight Time',
    location: 'Asia',
    id: 'Asia/Jerusalem',
  },
  {
    abbr: 'JST',
    name: 'Japan Standard Time',
    location: 'Asia',
    id: 'Asia/Tokyo',
  },
  {
    abbr: 'KST',
    name: 'Korea Standard Time',
    location: 'Asia',
    id: 'Asia/Seoul',
  },
  {
    abbr: 'MSK',
    name: 'Moscow Standard Time',
    location: 'Europe',
    id: 'Europe/Moscow',
  },
  {
    abbr: 'MT',
    name: 'Mountain Time',
    location: 'North America',
    id: 'America/Vancouver',
  },
  {
    abbr: 'NFT',
    name: 'Norfolk Time',
    location: 'Australia',
    id: 'Pacific/Norfolk',
  },
  {
    abbr: 'NOVT',
    name: 'Novosibirsk Time',
    location: 'Asia',
    id: 'Asia/Novosibirsk',
  },
  {
    abbr: 'NZST',
    name: 'New Zealand Standard Time',
    location: 'Pacific',
    id: 'Pacific/Auckland',
  },
  {
    abbr: 'PDT',
    name: 'Pacific Daylight Time',
    location: 'North America',
    id: 'America/Los_Angeles',
  },
  {
    abbr: 'SGT',
    name: 'Singapore Time',
    location: 'Asia',
    id: 'Asia/Singapore',
  },
  {
    abbr: 'TRT',
    name: 'Turkey Time',
    location: 'Asia',
    id: 'Europe/Istanbul',
  },
  {
    abbr: 'UTC',
    name: 'Coordinated Universal Time UTC',
    location: 'Worldwide',
    id: 'Etc/UTC',
  },
  {
    abbr: 'VLAT',
    name: 'Vladivostok Time',
    location: 'Asia',
    id: 'Asia/Vladivostok',
  },
  {
    abbr: 'WET',
    name: 'Western European Time',
    location: 'Europe',
    id: 'WET',
  },
  {
    abbr: 'WGT',
    name: 'West Greenland Time',
    location: 'North America',
    id: 'America/Halifax',
  },
]

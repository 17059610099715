import {
  arrayOf,
  array,
  objectOf,
  bool,
  func,
  number,
  oneOfType,
  string,
  shape,
  object,
} from 'prop-types'

export const expressionShape = shape({
  type: string,
  options: shape({
    source: string,
    value: string,
  }),
})

export const statsShape = shape({
  finishedAt: string,
  totalProductCount: number,
})

export const exclusionShape = shape({
  id: string.isRequired,
  name: string,
  enabled: bool,
  order: number,
  expressions: arrayOf(expressionShape),
})

export const locationShape = shape({
  hash: string.isRequired,
  key: string,
  pathname: string.isRequired,
  search: string.isRequired,
})

export const metaShape = shape({
  type: string.isRequired,
  headers: arrayOf(string),
})

export const stepShape = shape({
  meta: metaShape.isRequired,
  options: shape({ id: string.isRequired }),
  type: string.isRequired,
})

export const statShape = shape({
  finishedAt: string,
  totalProductCount: number,
})

export const buttonThemeShape = shape({
  backgroundColor: string.isRequired,
  textColor: string.isRequired,
})

export const cardButtonShape = shape({
  link: string,
  onClick: func,
  name: string.isRequired,
  theme: buttonThemeShape,
  target: string,
  disabled: bool,
})

export const cardColumnItemShape = shape({
  heading: string.isRequired,
  description: oneOfType([string, number]).isRequired,
  isDescriptionBold: bool,
  inline: bool,
})

export const groupShape = shape({
  id: string.isRequired,
  title: string.isRequired,
})

export const brandShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  imageLink: string,
})

export const connectionShape = shape({
  type: string.isRequired,
  options: shape({
    hostname: string.isRequired,
    port: number.isRequired,
    path: string.isRequired,
    username: string.isRequired,
    password: string,
  }),
})

export const cronValueArrayShape = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const fileShape = shape({
  createdAt: string.isRequired,
  downloadedAt: string,
  id: string.isRequired,
  name: string.isRequired,
  location: oneOfType([string, array]).isRequired,
  orgId: string,
  type: string,
  updatedAt: string,
  uploadedAt: string,
})

export const formatShape = shape({
  type: string.isRequired,
  options: shape({
    delimiter: string.isRequired,
    normalizeNewlines: bool.isRequired,
    quote: string.isRequired,
  }),
})

export const formValueObject = objectOf(
  oneOfType([array, string, number, bool, object])
)

export const formikShape = shape({
  handleChange: func,
  setFieldValue: func,
  values: formValueObject,
})

export const historyShape = shape({
  action: string.isRequired,
  block: func.isRequired,
  createHref: func.isRequired,
  go: func.isRequired,
  goBack: func.isRequired,
  goForward: func.isRequired,
  length: number.isRequired,
  listen: func.isRequired,
  location: locationShape.isRequired,
  push: func.isRequired,
  replace: func.isRequired,
})

export const mapperShape = shape({
  description: string,
  type: string,
  options: object, // this can vary based on mapper type, see fm-processor/mappers doc
})

export const outgoingShape = shape({
  id: string,
  sourceId: string,
  name: string,
  readonly: bool,
  compress: bool,
  connection: connectionShape,
  format: formatShape,
  createdAt: string,
  enabled: bool,
  publish: bool,
  updatedAt: oneOfType([string, shape({})]),
  uploadedAt: string,
  downloadedAt: string,
})

export const incomingCompactShape = shape({
  id: string,
  name: string,
})

export const progressBarShape = shape({
  percentage: number.isRequired,
  label: string.isRequired,
})

export const sortArrowShape = shape({
  onDown: func.isRequired,
  onUp: func.isRequired,
  isFirst: bool,
  isLast: bool,
})

export const tabShape = shape({
  route: string.isRequired,
  text: string.isRequired,
})

export const triggerShape = shape({
  type: string.isRequired,
  options: shape({
    cron: string,
  }),
})

export const outputShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  partnerId: string.isRequired,
  sourceId: string.isRequired,
  latestFeedJobId: string,
  steps: arrayOf(stepShape).isRequired,
  exclusions: arrayOf(exclusionShape),
})

export const inputShape = shape({
  sourceId: string.isRequired,
  latestFeedJobId: string,
  headers: arrayOf(string),
  partnerId: string.isRequired,
  exclusions: arrayOf(exclusionShape),
  steps: arrayOf(stepShape).isRequired,
})

export const feedShape = shape({
  id: string,
  name: string.isRequired,
  groupId: string,
  enabled: bool,
  country: string,
  trigger: triggerShape,
  isConfigured: bool,
  stats: statShape,
})

export const dropdownValueShape = shape({
  id: oneOfType([string, number]),
  name: string,
  image: string,
})

export const simplePartnerShape = shape({
  id: string.isRequired,
  name: string.isRequired,
})

export const templateShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  imageLink: string,
})

export const navItem = shape({
  linkText: string.isRequired,
  path: string.isRequired,
  hidden: bool,
  requiresConfiguredFeed: bool,
})

export const setRuleOptionShape = shape({
  value: string,
})

export const replaceRuleOptionShape = shape({
  find: string,
  replace: string,
})

export const combineRuleOptionShape = shape({
  values: arrayOf(string),
})

export const splitRuleOptionShape = shape({
  column: string,
  separator: string,
  index: number,
})

export const cutRuleOptionShape = shape({
  direction: string,
  unit: string,
  count: number,
})

export const lengthRuleOptionShape = shape({
  length: number,
})

export const hashRuleOptionShape = shape({
  column: string,
})

export const actionShape = shape({
  type: string,
  column: string,
  options: oneOfType([
    setRuleOptionShape,
    replaceRuleOptionShape,
    combineRuleOptionShape,
    splitRuleOptionShape,
    cutRuleOptionShape,
    lengthRuleOptionShape,
    hashRuleOptionShape,
  ]),
})

export const conditionShape = shape({
  type: string,
  options: shape({
    source: string,
    value: oneOfType([string, number]),
    caseSensitive: bool,
  }),
})

export const ruleInputShape = shape({
  input: arrayOf(
    shape({
      name: string,
      value: string,
      type: string,
    })
  ),
})

export const ruleShape = shape({
  id: string,
  name: string,
  enabled: bool,
  position: number,
  conditions: arrayOf(conditionShape),
  actions: arrayOf(actionShape),
})

export const mappingShape = shape({
  type: string.isRequired,
  system: shape({
    required: bool,
    recommended: bool,
  }),
  options: shape({
    name: string.isRequired,
  }).isRequired,
})

export const processingColumnShape = shape({
  Header: string,
  accessor: string,
  maxWidth: number,
  Cell: func,
})

export const userShape = shape({
  id: string.isRequired,
  email: string.isRequired,
  name: string.isRequired,
  avatarUrl: string,
  isAdmin: bool,
  settings: shape({
    language: string.isRequired,
    timezone: string.isRequired,
    format: string.isRequired,
  }),
})

export const onActionChangesShape = shape({
  columnChange: func,
  firstValueChange: func,
  operatorChange: func,
  optionsTypeChange: func,
  remove: func,
  secondOptionsTypeChange: func,
  secondValueChange: func,
  typeChange: func,
})

export const updateChangesShape = shape({
  updateCaseSensitive: func.isRequired,
  updateName: func.isRequired,
  updateSource: func.isRequired,
  updateOperator: func.isRequired,
  updateValue: func.isRequired,
  updateValueType: func.isRequired,
  updateStatus: func.isRequired,
})

export const structuredTableShape = arrayOf(
  shape({
    expander: bool,
    width: number,
    Expander: func,
    Header: string,
    accessor: string,
    Cell: func,
  })
)

export const structuredDataShape = arrayOf(
  shape({
    id: string.isRequired,
    groupId: string,
    status: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    name: string,
    email: string,
  })
)

import { getActionTree } from '../../../constants/alertActionList'

export default (alert, setAlert, userList) => {
  const getListOfActions = arrayOfActions => {
    return getActionTree(userList, arrayOfActions)
  }

  const getActionValue = (type, options) => {
    switch (type) {
      case 'sendEmail':
        return options && options.userIds[0]
      default:
        return undefined
    }
  }

  const updateActionValue = (index, value) => {
    const { options, type } = alert.actions[index]

    switch (type) {
      case 'sendEmail':
        options.userIds = [value]
        break
      default: {
        alert.actions[index].options = undefined
        break
      }
    }

    setAlert({ ...alert })
  }

  const updateActionOptionsType = (index, type) => {
    let options = alert.actions[index].options

    switch (type) {
      case 'sendEmail':
        options = { userIds: [] }
        break
      default: {
        options = {}
        break
      }
    }
    alert.actions[index].options = options
    setAlert({ ...alert })
  }

  const updateActionType = (index, type) => {
    let action = {
      type,
    }

    switch (type) {
      case 'sendEmail':
        action.options = { userIds: [] }
        break
      case 'skipPublishing':
        action.options = { outgoingIds: [] }
        break
      default: {
        action.options = undefined
        break
      }
    }
    alert.actions[index] = action

    setAlert({ ...alert })
  }

  const addNewAction = () => {
    alert.actions.push({
      options: { userIds: [] },
      type: 'sendEmail',
    })
    setAlert({ ...alert })
  }

  const removeAction = index => {
    alert.actions.splice(index, 1)

    if (alert.actions.length === 0) {
      addNewAction(alert)
    } else {
      setAlert({ ...alert })
    }
  }

  return {
    getListOfActions,
    getActionValue,
    updateActionValue,
    updateActionOptionsType,
    updateActionType,
    addNewAction,
    removeAction,
  }
}

import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

import { ORANGE } from '../../../constants'
import { TRANSPARENT_BUTTON } from '../../../constants/buttonThemes'
import { userShape } from '../../../types'

import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import { navigate } from '@reach/router'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
`

const UserAvatar = styled.img`
  max-width: 32px;
  max-height: 32px;
  cursor: pointer;
`

const UserName = styled.label`
  padding: 0 16px;
  min-width: 100px;
  white-space: nowrap;
  cursor: pointer;
`

const UserNavigation = ({ user, logoutUser }) => (
  <Container>
    <UserAvatar
      src={user.avatarUrl}
      onClick={() => navigate('/user-setting')}
    />
    <UserName
      data-id={'username-' + user.name}
      onClick={() => navigate('/user-setting')}
    >
      {user.name}
    </UserName>
    <Button onClick={logoutUser} theme={TRANSPARENT_BUTTON}>
      <Icon icon="logout" color={ORANGE} />
    </Button>
  </Container>
)

UserNavigation.propTypes = {
  user: userShape,
  dispatch: func,
  logoutUser: func,
}

export default UserNavigation

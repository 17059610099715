import React from 'react'
import styled from 'styled-components'
import { oneOf, string } from 'prop-types'
import {
  ICON_SIZE_SMALL,
  ICON_SIZE_MEDIUM,
  ICON_SIZE_BIG,
} from '../../../constants'
import FaAngleRight from './icons/FaAngleRight'
import FaArrowDown from './icons/FaArrowDown'
import FaArrowUp from './icons/FaArrowUp'
import FaCaretDown from './icons/FaCaretDown'
import FaCheckMark from './icons/FaCheckMark'
import FaCsvFile from './icons/FaCsvFile'
import FaInfoCircle from './icons/FaInfoCircle'
import FaLogout from './icons/FaLogout'
import FaPause from './icons/FaPause'
import FaQuestion from './icons/FaQuestion'
import FaRemove from './icons/FaRemove'
import FaUser from './icons/FaUser'
import FaWrench from './icons/FaWrench'
import FaOpenNewTab from './icons/FaOpenNewTab'
import FaTypeConversionList from './icons/FaTypeConversionList'
import FaTypeList from './icons/FaTypeList'
import FaTypeEnrichments from './icons/FaTypeEnrichments'

// Add new icons here, logic and proptypes works automatically
const icons = {
  angleRight: FaAngleRight,
  arrowDown: FaArrowDown,
  arrowUp: FaArrowUp,
  caretDown: FaCaretDown,
  checkmark: FaCheckMark,
  csv: FaCsvFile,
  infoCircle: FaInfoCircle,
  logout: FaLogout,
  pause: FaPause,
  question: FaQuestion,
  remove: FaRemove,
  user: FaUser,
  wrench: FaWrench,
  conversion_list: FaTypeConversionList,
  list: FaTypeList,
  overrides: FaTypeEnrichments,
  openInNewTab: FaOpenNewTab,
}

const renderIcon = (icon, color) => {
  const SelectedIcon = icons[icon] || icons.question

  return <SelectedIcon color={color} />
}

const IconWrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 0;
`

const Icon = ({ size = ICON_SIZE_MEDIUM, icon, color }) => (
  <IconWrapper size={size}>{renderIcon(icon, color)}</IconWrapper>
)

Icon.propTypes = {
  size: oneOf([ICON_SIZE_SMALL, ICON_SIZE_MEDIUM, ICON_SIZE_BIG]),
  icon: oneOf(Object.keys(icons)).isRequired,
  color: string,
}

export default Icon

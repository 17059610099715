import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import { fetchingData, fetchingFailed } from '../../App/actions'
import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'
import api from '../../../lib/api'

export const EXCLUSION_RECEIVED = 'EXCLUSION_RECEIVED'
export const EXCLUSION_FILE_RECEIVED = 'EXCLUSION_FILE_RECEIVED'
export const EXCLUSION_UPDATED = 'EXCLUSION_UPDATED'
export const EXCLUSION_CLONED = 'EXCLUSION_CLONED'

const getMasterExclusionUrl = (feedId, exclusionId = '') =>
  `/feeds/${feedId}/master/exclusions/${exclusionId}`
const getOutputExclusionUrl = (feedId, outputId, exclusionId) =>
  `/feeds/${feedId}/outgoing/${outputId}/exclusions/${exclusionId}`
const getMasterMappingUrl = feedId => `/feeds/${feedId}/master/headers`
const getOutputMappingUrl = (feedId, outgoingId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/mapping`

export const receivedFileData = files => ({
  type: EXCLUSION_FILE_RECEIVED,
  files,
})

export const receivedExclusionData = (
  data,
  columns,
  files,
  isDuplicateOrNew
) => ({
  type: EXCLUSION_RECEIVED,
  exclusion: data,
  columns,
  files,
  isDuplicateOrNew,
})

export const updatedExclusion = data => ({
  type: EXCLUSION_UPDATED,
  exclusion: data,
})

export const duplicatedExclusion = isDuplicateOrNew => ({
  type: EXCLUSION_CLONED,
  isDuplicateOrNew,
})

export const updateExclusion = (
  feedId,
  exclusionId,
  data,
  outgoingId,
  dispatch,
  t
) =>
  api
    .patch(
      outgoingId
        ? getOutputExclusionUrl(feedId, outgoingId, exclusionId)
        : getMasterExclusionUrl(feedId, exclusionId),
      data
    )
    .then(({ data }) => {
      dispatch(updatedExclusion(data))
      dispatch(duplicatedExclusion(false))
      toast.success(t('exclusion_update_success'))
    })
    .catch(error => toastDetailErrorMessage('exclusion_update_error', error, t))

const createExclusion = (
  feedId,
  data,
  organisationId,
  outgoingId,
  dispatch,
  t
) =>
  api
    .post(
      outgoingId
        ? getOutputExclusionUrl(feedId, outgoingId, '')
        : getMasterExclusionUrl(feedId, ''),
      data
    )
    .then(({ data }) => {
      toast.success(t('exclusion_create_success'))

      navigate(
        `/${organisationId}/feeds/${feedId}/exclusions/${data.id}/${
          outgoingId ? 'outgoing/' + outgoingId : 'master/'
        }`
      )
      dispatch(updatedExclusion(data))
      dispatch(duplicatedExclusion(false))
    })
    .catch(error => toastDetailErrorMessage('exclusion_create_error', error, t))

export const createOrUpdateExclusion = (
  feedId,
  exclusionId,
  organisationId,
  data,
  outgoingId,
  dispatch,
  t
) => {
  dispatch(fetchingData())

  if (exclusionId && exclusionId !== 'new') {
    return updateExclusion(feedId, exclusionId, data, outgoingId, dispatch, t)
  } else {
    return createExclusion(
      feedId,
      data,
      organisationId,
      outgoingId,
      dispatch,
      t
    )
  }
}

export const removeExclusion = (feedId, exclusionId, outgoingId, t) =>
  api
    .delete(
      outgoingId
        ? getOutputExclusionUrl(feedId, outgoingId, exclusionId)
        : getMasterExclusionUrl(feedId, exclusionId)
    )
    .then(() => toast.success(t('exclusion_delete_success')))
    .catch(() => toast.error(t('exclusion_delete_error')))

export const fetchExclusionData = (
  feedId,
  exclusionId,
  outgoingId,
  organisationId,
  isDuplicateOrNew,
  dispatch,
  t,
  clipboardExclusion
) => {
  dispatch(fetchingData())

  const exclusionPromise = getExclusionDetail(
    feedId,
    exclusionId,
    outgoingId,
    clipboardExclusion
  )
  const masterMappingPromise = api.get(getMasterMappingUrl(feedId))
  const outMappingPromise = outgoingId
    ? api.get(getOutputMappingUrl(feedId, outgoingId))
    : Promise.resolve({ data: [] })

  Promise.all([exclusionPromise, masterMappingPromise, outMappingPromise])
    .then(values => {
      const inputCols = values[1].data
      const outputCols = Object.keys(values[2].data)

      const exclusionData = values[0].data
      if (!clipboardExclusion && isDuplicateOrNew && exclusionData.id) {
        exclusionData.name = `${exclusionData.name} duplicate`
      }

      dispatch(
        receivedExclusionData(
          exclusionData,
          [...outputCols, ...inputCols],
          [],
          isDuplicateOrNew
        )
      )

      return getFiles(organisationId)
    })
    .then(files => {
      dispatch(receivedFileData(files))
    })
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

const getFiles = async organisationId => {
  let page = 0
  let files = {}
  while (true) {
    const values = await api.get(
      `/orgs/${organisationId}/files?page=${page}&limit=50`
    )
    if (values.data.length === 0) return Object.values(files)
    values.data.forEach(({ id, name }) => (files[id] = { id, name }))
    page++
  }
}

export const getExclusionDetail = (
  feedId,
  exclusionId,
  outgoingId,
  clipboardExclusion
) => {
  if (clipboardExclusion) return Promise.resolve({ data: clipboardExclusion })
  if (exclusionId && exclusionId !== 'new') {
    return api.get(
      outgoingId
        ? getOutputExclusionUrl(feedId, outgoingId, exclusionId)
        : getMasterExclusionUrl(feedId, exclusionId)
    )
  } else {
    return Promise.resolve({
      data: {
        name: '',
        enabled: true,
        expressions: [],
      },
    })
  }
}

export const updateConditionCaseSensitive = (
  index,
  caseSensitive,
  exclusion,
  dispatch
) => {
  exclusion.expressions[index].options.caseSensitive = caseSensitive
  dispatch(updatedExclusion(exclusion))
}

export const updateExpressionSource = (index, source, exclusion, dispatch) => {
  exclusion.expressions[index].options.source = `{{$${source}}}`
  dispatch(updatedExclusion(exclusion))
}

export const updateExpressionOperator = (
  index,
  operator,
  exclusion,
  dispatch
) => {
  let expression = {
    type: operator,
    options: { source: exclusion.expressions[index].options.source },
  }
  switch (operator) {
    case 'equalsValue':
    case 'notEqualsValue':
    case 'startsWith':
    case 'notStartsWith':
    case 'endsWith':
    case 'notEndsWith':
    case 'contains':
    case 'notContains': {
      expression.options = {
        ...expression.options,
        value: undefined,
        type: undefined,
        caseSensitive: false,
      }
      break
    }
    case 'equals':
    case 'notEquals':
    case 'greater':
    case 'greaterEquals':
    case 'less':
    case 'lessEquals':
    case 'matchesSpecial':
    case 'notMatchesSpecial': {
      expression.options = {
        ...expression.options,
        value: undefined,
      }
      break
    }
    case 'matchesRegex':
    case 'notMatchesRegex': {
      expression.options = {
        ...expression.options,
        pattern: undefined,
        caseSensitive: false,
      }
      break
    }
    default:
      break
  }
  exclusion.expressions[index] = expression
  dispatch(updatedExclusion(exclusion))
}

export const updateExpressionValue = (index, value, exclusion, dispatch) => {
  const operatorType = exclusion.expressions[index].type
  const optionsType = exclusion.expressions[index].options.type

  switch (operatorType) {
    case 'matchesRegex':
    case 'notMatchesRegex': {
      exclusion.expressions[index].options.pattern = value
      break
    }
    default: {
      if (optionsType && optionsType === 'file') {
        exclusion.expressions[index].options.fileId = value
      } else if (optionsType && optionsType === 'column') {
        exclusion.expressions[index].options.value = `{{$${value}}}`
      } else {
        exclusion.expressions[index].options.value = value
      }
      break
    }
  }
  dispatch(updatedExclusion(exclusion))
}

export const updateExpressionType = (index, type, exclusion, dispatch) => {
  let options = exclusion.expressions[index].options
  switch (type) {
    case 'column':
    case 'value': {
      options = {
        source: options.source,
        value: '',
        type: type,
        caseSensitive: options.caseSensitive,
      }
      break
    }
    case 'file': {
      options = {
        source: options.source,
        fileId: undefined,
        type: type,
        caseSensitive: options.caseSensitive,
      }
      break
    }

    default:
      break
  }
  exclusion.expressions[index].options = options

  dispatch(updatedExclusion(exclusion))
}

export const addNewExpression = (exclusion, dispatch) => {
  exclusion.expressions.push({
    options: {
      source: undefined,
      value: undefined,
      caseSensitive: undefined,
    },
    type: undefined,
  })
  dispatch(updatedExclusion(exclusion))
}

export const removeExpression = (index, exclusion, dispatch) => {
  exclusion.expressions.splice(index, 1)

  if (exclusion.expressions.length) {
    dispatch(updatedExclusion(exclusion))
  } else {
    addNewExpression(exclusion, dispatch)
  }
}

export const getFilterValue = (type, options) => {
  if (options.type && options.type === 'file') {
    return options.fileId
  }
  if (options.type && options.type === 'column') {
    return options.value ? options.value.replace(/[{$}]/g, '') : options.value
  }
  switch (type) {
    case 'matchesRegex':
    case 'notMatchesRegex': {
      return options.pattern
    }
    default:
      return options.value
  }
}

import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import api from '../../../lib/api'
import { notCanceled } from '../../../lib/signal'

import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'

export const OUTGOING_LIST_FETCHING = 'OUTGOING_LIST_FETCHING'
export const OUTGOING_LIST_DONE = 'OUTGOING_LIST_DONE'
export const OUTGOING_LIST_FAILED = 'OUTGOING_LIST_FAILED'

const requestOutgoing = () => ({
  type: OUTGOING_LIST_FETCHING,
})

const receiveOutgoing = (outgoing, outgoingFeedId) => ({
  type: OUTGOING_LIST_DONE,
  outgoing,
  outgoingFeedId,
})

const fetchFailed = message => ({
  type: OUTGOING_LIST_FAILED,
  message,
})

export const fetchOutgoing = (feedId, dispatch, signal) => {
  dispatch(requestOutgoing())

  return api
    .get(`/feeds/${feedId}/outgoing`, { cancelToken: signal && signal.token })
    .then(outgoing => {
      dispatch(receiveOutgoing(outgoing.data, feedId))
    })
    .catch(
      notCanceled(err => {
        console.log(err)
        dispatch(fetchFailed(err.message))
      })
    )
}

export const saveOutgoing = (
  feedId,
  outgoingId,
  data,
  partnerId,
  dispatch,
  t,
  reloadCurrentFeed
) => {
  const promise =
    outgoingId === 'new'
      ? api.post(`/feeds/${feedId}/outgoing`, {
          ...data,
          partnerId,
        })
      : api.patch(`/feeds/${feedId}/outgoing/${outgoingId}`, {
          ...data,
        })

  return promise
    .then(({ data }) => {
      toast.success(t('saved'))
      return fetchOutgoing(feedId, dispatch).then(() => {
        navigate(`./${data.id}`, {
          replace: true,
        })
      })
    })
    .then(reloadCurrentFeed)
    .catch(error => toastDetailErrorMessage('save_failed', error, t))
}

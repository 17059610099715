import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const BRANDS_FETCHING = 'BRANDS_FETCHING'
export const BRANDS_DONE = 'BRANDS_DONE'
export const BRANDS_FAILED = 'BRANDS_FAILED'

const requestBrands = () => ({
  type: BRANDS_FETCHING,
})

const receiveBrands = brandList => ({
  type: BRANDS_DONE,
  brandList,
})

const fetchFailed = message => ({
  type: BRANDS_FAILED,
  message,
})

export const fetchBrands = ({ organisationId, dispatch, t }) => {
  dispatch(requestBrands())
  api
    .get(`/orgs/${organisationId}/groups`)
    .then(({ data }) => {
      dispatch(receiveBrands(data))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
      toast.error(t('brands_fetch_unsuccessful'))
    })
}

export const editBrand = (organisationId, brandId, t, editedBrand, dispatch) =>
  api
    .patch(`/orgs/${organisationId}/groups/${brandId}`, editedBrand)
    .then(() => {
      toast.success(t('brand_edit_successful'))
      fetchBrands({ organisationId, dispatch, t })
    })
    .catch(err => {
      toast.error(t('brand_edit_unsuccessful'))
      console.log(err)
    })

export const createBrand = (organisationId, newBrand, dispatch, t) =>
  api
    .post(`/orgs/${organisationId}/groups`, newBrand)
    .then(() => {
      toast.success(t('brand_create_successful'))
      fetchBrands({ organisationId, dispatch, t })
    })
    .catch(err => {
      toast.error(t('brand_create_unsuccessful'))
      console.log(err)
    })

import React from 'react'
import { string } from 'prop-types'

const FaCsvFile = ({ color = 'black' }) => (
  <svg
    version="1.1"
    fill={color}
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <g>
      <path
        d="M45.8,0H10C7.8,0,6,1.8,6,4v56c0,2.2,1.8,4,4,4h44c2.2,0,4-1.8,4-4V11.1L45.8,0z M47,6.5l3.8,3.5H47V6.5z M10,60V4h33v10
		h11l0,46H10z"
      />
      <rect x="14" y="27" width="25" height="4" />
      <rect x="14" y="19" width="36" height="4" />
      <path
        d="M24,50.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.4-0.3-1.8-0.9
		c-0.4-0.6-0.6-1.6-0.6-2.9c0-0.2,0-0.5,0-0.8c0-0.3,0.1-0.6,0.1-0.9c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7
		c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1,0.2,1.4,0.5c0.3,0.3,0.6,0.7,0.7,1.2l2.5-0.6c-0.2-0.7-0.5-1.2-0.8-1.6
		c-0.3-0.4-0.6-0.7-1-1c-0.8-0.4-1.7-0.6-2.7-0.6c-1,0-1.8,0.2-2.4,0.5c-0.7,0.3-1.2,0.8-1.6,1.3c-0.4,0.6-0.7,1.2-0.9,1.9
		C17.1,46.5,17,47.2,17,48c0,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.5,1.4,0.9,1.9c0.4,0.6,0.9,1,1.6,1.3c0.7,0.3,1.5,0.5,2.4,0.5
		c1.2,0,2.2-0.3,3-0.8c0.4-0.3,0.7-0.6,1-1c0.3-0.4,0.6-0.9,0.8-1.5l-2.6-0.6C24.3,50.4,24.1,50.6,24,50.8z"
      />
      <path
        d="M35.6,48c-0.8-0.5-1.6-1-2.6-1.3c-0.7-0.2-1.3-0.5-1.8-0.8c-0.3-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.6,0,1,0.1,1.3,0.4
		c0.3,0.3,0.6,0.6,0.8,1.1l2.5-0.7c-0.2-0.5-0.5-1-0.8-1.3c-0.3-0.3-0.7-0.6-1.1-0.8C33.9,42.2,33,42,32,42c-1.2,0-2.2,0.3-3,0.9
		c-0.8,0.6-1.2,1.4-1.2,2.3c0,0.9,0.3,1.7,1,2.2c0.6,0.5,1.6,1,2.9,1.4c0.7,0.2,1.2,0.5,1.7,0.8c0.4,0.3,0.6,0.6,0.6,1
		c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.6,0-0.9-0.1
		s-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.3-0.5-0.6c-0.2-0.2-0.3-0.5-0.4-0.7L27.4,51c0.3,0.6,0.6,1,0.9,1.4c0.3,0.4,0.7,0.7,1.2,0.9
		c0.9,0.4,1.8,0.7,2.7,0.7c1.4,0,2.5-0.3,3.3-0.9c0.8-0.6,1.2-1.5,1.2-2.7C36.8,49.4,36.4,48.6,35.6,48z"
      />
      <polygon points="42.1,50 40.1,42.2 37.4,42.2 40.7,53.8 43.6,53.8 47,42.2 44.3,42.2 	" />
    </g>
  </svg>
)

FaCsvFile.propTypes = {
  color: string,
}

export default FaCsvFile

import React from 'react'
import { render } from 'react-dom'
import './assets/global.css'

import './i18n'
import Providers from './components/App/Providers'
// import * as serviceWorker from './serviceWorker'

render(<Providers />, document.getElementById('root'))

// serviceWorker.register()

import React, { Fragment } from 'react'
import { string, bool, func, arrayOf } from 'prop-types'

import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import Detail from '../../shared/OptimizationDetail'
import RemoveModalWindow from '../../shared/RemoveModalWindow'

import {
  ruleShape,
  outgoingShape,
  onActionChangesShape,
  updateChangesShape,
} from '../../../types'

const RuleRenderComponent = ({
  addNewAction,
  addNewCondition,
  addNewRule,
  columnList,
  dispatch,
  feedId,
  getActionValue,
  getConditionList,
  getConditionValue,
  getListOfActions,
  handleDeleteDialogVisibility,
  isDeleteDialogVisible,
  isDuplicateOrNew,
  onActionChanges,
  onDuplicateClick,
  onPause,
  onRemove,
  onRemoveCondition,
  onSave,
  organisationId,
  outputCols,
  rule,
  selectedOutgoing,
  t,
  updateChanges,
}) => (
  <Fragment>
    <PageDescription
      heading={`${t('transformations_heading')} ${t(
        'transformations_editing'
      )} ${rule.name}`}
      description={t('transformation_detail_description')}
    />
    <TopButtonsWrapper
      buttons={[
        {
          name: 'transformation_delete_button',
          onClick: () => handleDeleteDialogVisibility(true),
          disabled: isDuplicateOrNew,
        },
        {
          name: rule.enabled
            ? 'transformation_pause_button'
            : 'transformation_enable_button',
          onClick: () => onPause(rule.id, !rule.enabled),
          disabled: isDuplicateOrNew,
        },
        {
          name: 'transformation_duplicate_button',
          onClick: onDuplicateClick,
          disabled: isDuplicateOrNew,
          link: `?isDuplicate=true`,
        },
        {
          name: 'transformation_add_new',
          onClick: addNewRule,
          disabled: isDuplicateOrNew,
        },
      ]}
    />

    <Detail
      addNewAction={addNewAction}
      addNewCondition={addNewCondition}
      columnList={columnList}
      dispatch={dispatch}
      feedId={feedId}
      getConditionList={getConditionList}
      getConditionValue={getConditionValue}
      getActionValue={getActionValue}
      getListOfActions={getListOfActions}
      inputId="rule-name"
      mainLabel={t('transformation_name_label')}
      masterText={t('master_transformations')}
      onActionChanges={onActionChanges}
      onRemoveCondition={onRemoveCondition}
      onSave={onSave}
      outputCols={outputCols}
      partnerText={t('partner_transformations')}
      saveLink={`${organisationId}/feeds/${feedId}/rules`}
      selectedOutgoing={selectedOutgoing}
      statusDataId="select-rule-status"
      t={t}
      type={rule}
      updateChanges={updateChanges}
    />
    <RemoveModalWindow
      t={t}
      handleDeleteDialogVisibility={handleDeleteDialogVisibility}
      onRemove={onRemove}
      isDeleteDialogVisible={isDeleteDialogVisible}
    />
  </Fragment>
)

RuleRenderComponent.propTypes = {
  addNewAction: func.isRequired,
  addNewCondition: func.isRequired,
  addNewRule: func.isRequired,
  columnList: arrayOf(string),
  dispatch: func.isRequired,
  feedId: string.isRequired,
  getActionValue: func.isRequired,
  getConditionList: func.isRequired,
  getConditionValue: func.isRequired,
  getListOfActions: func.isRequired,
  handleDeleteDialogVisibility: func.isRequired,
  isDeleteDialogVisible: bool.isRequired,
  isDuplicateOrNew: bool.isRequired,
  onActionChanges: onActionChangesShape,
  onDuplicateClick: func.isRequired,
  onPause: func.isRequired,
  onRemove: func.isRequired,
  onRemoveCondition: func.isRequired,
  onSave: func.isRequired,
  organisationId: string.isRequired,
  outputCols: arrayOf(string),
  selectedMutationId: string,
  selectedOutgoing: outgoingShape,
  rule: ruleShape.isRequired,
  updateChanges: updateChangesShape.isRequired,
}

export default RuleRenderComponent

import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { string } from 'prop-types'

import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import StructuredTable from '../../shared/StructuredTable'
import Button from '../../shared/Button'
import { TEXT_SMALL } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { fetchAuditList, createNewFeedAuditFile } from './actions'
import { initialState, reducer } from './reducer'
import NewAuditDialog from '../../shared/NewAuditDialog'

const newFileId = 'NEW'

const FeedAuditList = ({ t, organisationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { auditList, auditListState, fetchError, pageTotal, page } = state
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [reloadValues, setReloadValues] = useState(false)

  const filteredOrganisation = window.location.search.split('usersOf=')[1]
  useEffect(
    () => {
      fetchAuditList(organisationId, dispatch, page, t, filteredOrganisation)
      setReloadValues(false)
    },
    [organisationId, reloadValues]
  )

  if (!auditListState || auditListState === 'FETCHING') {
    return <Loader />
  }

  const onSaveClicked = (companyName, stringData) => {
    createNewFeedAuditFile(
      organisationId,
      companyName,
      stringData,
      dispatch,
      page,
      t,
      filteredOrganisation
    )
    setIsDialogVisible(false)
    setReloadValues(true)
  }

  const openInNewTab = row => {
    let datestring = new Date(row.timestamp)
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, '')
    let audit = `report_${row.companyName}_${datestring}_${row._id}`
    let url = `https://feedaudit.productcaster.com/${audit}`
    window.open(url, '_blank', 'noreferrer')
  }

  const TableColumns = t => [
    {
      Header: t('feed_audit_table_header1'),
      accessor: 'name',
    },
    {
      Header: t('feed_audit_table_header2'),
      accessor: 'timestamp',
      Cell: row => {
        const {
          original: { timestamp },
        } = row
        return timestamp
          ? t('dateValueInput', {
              value: new Date(timestamp),
            })
          : ''
      },
    },
    {
      Cell: row => {
        const {
          original: { id },
        } = row
        return (
          <Button
            onClick={() => openInNewTab(row.original)}
            theme={SECONDARY_BUTTON}
            fontSize={TEXT_SMALL}
          >
            Open
          </Button>
        )
      },
    },
  ]

  return (
    <Fragment>
      <NewAuditDialog
        onCancel={() => setIsDialogVisible(false)}
        enableTypeSelection={false}
        onSave={onSaveClicked}
        title={t('button_new_file')}
        visible={isDialogVisible}
        t={t}
      />
      <PageDescription
        heading={t('feed_audit')}
        description={t('feed_audit_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('new_audit'),
            onClick: () => {
              setIsDialogVisible(true)
            },
          },
        ]}
      />
      <ContentWrapper>
        <StructuredTable
          columns={TableColumns(t, organisationId, dispatch, page)}
          data={auditList}
          isUsersTable
          page={page}
          pageSize={10}
          pageTotal={1}
          selectNextPage={() =>
            fetchAuditList(
              organisationId,
              dispatch,
              page + 1,
              t,
              filteredOrganisation
            )
          }
          selectPreviousPage={() =>
            fetchAuditList(
              organisationId,
              dispatch,
              page - 1,
              t,
              filteredOrganisation
            )
          }
          showPagination={false}
          sortable={false}
        />
      </ContentWrapper>
    </Fragment>
  )
}

FeedAuditList.propTypes = {
  organisationId: string.isRequired,
}

export default FeedAuditList

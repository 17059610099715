export const getConditionTree = (incomingList = [], outgoingList = []) => (
  value,
  onValueChange,
  childValue,
  onChildValueChange
) => [
  {
    id: 'notRun',
    name: 'notRun',
    render: 'input-number',
    title: 'input_days',
    min: 0,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'incomingFailed',
    name: 'incomingFailed',
    title: 'incoming',
    render: 'dropdown',
    list: incomingList,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'masterFailed',
    name: 'masterFailed',
    render: undefined,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'outgoingFailed',
    name: 'outgoingFailed',
    title: 'outgoing',
    render: 'dropdown',
    list: [
      ...outgoingList,
      ...(outgoingList.length > 0 ? [{ id: 'all', name: 'all' }] : []),
    ],
    selectedValue: childValue('outgoingIds'),
    onChange: onValueChange,
  },
  {
    id: 'masterSizeChanged',
    name: 'masterSizeChanged',
    title: 'input_days',
    min: 0,
    type: 'number',
    render: 'multiple-input',
    renderChild: {
      id: 'threshold',
      name: 'threshold',
      title: 'input_threshold',
      render: 'input-number',
      selectedValue: childValue('threshold'),
      onChange: onChildValueChange,
    },
    onChange: onValueChange,
    selectedValue: value,
  },
  {
    id: 'incomingSizeChanged',
    name: 'incomingSizeChanged',
    title: 'input_days',
    min: 0,
    type: 'number',
    render: 'multiple-input',
    renderChild: {
      id: 'threshold',
      title: 'input_threshold',
      render: 'multiple-input',
      type: 'number',
      selectedValue: childValue('threshold'),
      onChange: onChildValueChange,
      renderChild: {
        id: 'incomingIds',
        title: 'incoming',
        render: 'dropdown',
        list: incomingList,
        selectedValue: childValue('incomingIds'),
        onChange: onChildValueChange,
      },
    },
    onChange: onValueChange,
    selectedValue: value,
  },
  {
    id: 'outgoingSizeChanged',
    name: 'outgoingSizeChanged',
    title: 'input_days',
    min: 0,
    type: 'number',
    render: 'multiple-input',
    renderChild: {
      id: 'threshold',
      title: 'input_threshold',
      render: 'multiple-input',
      type: 'number',
      selectedValue: childValue('threshold'),
      onChange: onChildValueChange,
      renderChild: {
        id: 'outgoingIds',
        title: 'outgoing',
        render: 'dropdown',
        list: outgoingList,
        selectedValue: childValue('outgoingIds'),
        onChange: onChildValueChange,
      },
    },
    onChange: onValueChange,
    selectedValue: value,
  },
]

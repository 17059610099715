import React from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { node, string, bool } from 'prop-types'
import { Link as RouterLink } from '@reach/router'

import Text from '../Text'

import { TEXT_MEDIUM, DARK_BLUE, BLUE } from '../../../constants'

const StyledAnchor = styled.a`
  font-size: ${({ size }) => (size ? size : TEXT_MEDIUM)};
  color: ${DARK_BLUE};
  text-decoration: underline;
  transition: all 0s;

  :hover {
    color: ${BLUE};
    text-decoration: underline;
  }
`

const Link = ({ children, className, link, size, dataId, disabled }) => {
  const isExternalUrl = link => {
    if (link && link.includes('http://localhost')) return true
    if (link && link.includes('http://fm-')) return true
    try {
      yup
        .string()
        .url()
        .validateSync(link)
      return true
    } catch (error) {
      return false
    }
  }

  if (isExternalUrl(link)) {
    return (
      <StyledAnchor
        className={className}
        data-id={`${dataId}-link`}
        href={disabled ? null : link}
        rel="noopener noreferrer"
        target="_blank"
        size={size}
      >
        {children}
      </StyledAnchor>
    )
  }

  if (disabled) {
    return (
      <Text className={className} size={size}>
        {children}
      </Text>
    )
  }

  return (
    <RouterLink data-id={dataId} className={className} to={link}>
      {children}
    </RouterLink>
  )
}

Link.propTypes = {
  children: node.isRequired,
  className: string,
  disabled: bool,
  target: string,
  link: string.isRequired,
  size: string,
  dataId: string,
}

export default Link

import { BRANDS_FETCHING, BRANDS_DONE, BRANDS_FAILED } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  brandListState: null,
  brandList: [],
  fetchError: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case BRANDS_FETCHING:
      return {
        ...state,
        brandListState: APP_STATE.FETCHING,
      }

    case BRANDS_DONE:
      return {
        ...state,
        brandListState: APP_STATE.DONE,
        brandList: action.brandList,
      }

    case BRANDS_FAILED:
      return {
        ...state,
        brandListState: APP_STATE.ERROR,
        brandList: [],
        fetchError: action.message,
      }

    default:
      return state
  }
}

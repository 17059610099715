import React from 'react'
import styled from 'styled-components'
import { node, bool, func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import { PRIMARY_BUTTON, SECONDARY_BUTTON } from '../../constants/buttonThemes'
import { GREY_OVERLAY, WHITE } from '../../constants'
import { buttonThemeShape } from '../../types'

import Button from './Button'

const ScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${GREY_OVERLAY};
  z-index: 1;
`

const ContentWrapper = styled.div`
  overflow: auto;
  max-width: 70vw;
  max-height: 60vh;
  padding: 16px;
`

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  margin: 4px;
`

const Modal = styled.div`
  position: fixed;
  background-color: ${WHITE};
  border-radius: 10px;
  margin: auto;
  ${({ isWide }) => (isWide ? 'min-width:50vw' : 'min-width:400px')};
  width: fit-content;
  height: fit-content;
  padding: 16px;
  top: 80px;
  left: 0;
  right: 0;
`

const ModalWindow = ({
  cancelText,
  cancelTheme = SECONDARY_BUTTON,
  children,
  okText,
  okTheme = PRIMARY_BUTTON,
  onCancel,
  onOk,
  isWide,
  title,
  visible,
}) => {
  const { t } = useTranslation()

  if (!visible) return null

  return (
    <ScreenOverlay>
      <Modal isWide={isWide}>
        <h2>{title}</h2>
        <ContentWrapper>{children}</ContentWrapper>
        <FlexWrapper>
          {onCancel && (
            <ButtonWrapper>
              <Button
                data-id={`button-modal-${onCancel}`}
                onClick={onCancel}
                theme={cancelTheme}
              >
                {cancelText || t('button_cancel')}
              </Button>
            </ButtonWrapper>
          )}
          {onOk && (
            <ButtonWrapper>
              <Button
                data-id={'button-modal-' + okText}
                onClick={onOk}
                theme={okTheme}
              >
                {okText || t('button_ok')}
              </Button>
            </ButtonWrapper>
          )}
        </FlexWrapper>
      </Modal>
    </ScreenOverlay>
  )
}

ModalWindow.propTypes = {
  cancelText: string,
  children: node.isRequired,
  okText: string,
  onCancel: func,
  cancelTheme: buttonThemeShape,
  onOk: func,
  okTheme: buttonThemeShape,
  isWide: bool,
  title: string,
  visible: bool,
  minWidth: string,
}

export default ModalWindow

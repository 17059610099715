import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { string, bool } from 'prop-types'
import { feedShape } from '../../../types'

import { LAPTOP_WIDTH, SIDE_NAVIGATION_WIDTH_LAPTOP } from '../../../constants'
import { subItems } from '../../../constants/navSubItems'
import useOrganisations from '../../shared/system/useOrganisations'

import MenuItem from './MenuItem'

const EmptyContainer = styled.div`
  width: 110px;
  height: 100%;
  padding: 25px 0;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    width: 0;
  }
`

const Container = styled.div`
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  height: 100%;
  padding: 25px 0;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    margin-left: 41px;
    width: ${SIDE_NAVIGATION_WIDTH_LAPTOP};
    min-width: unset;
    max-width: unset;
  }
`

const SideNavigation = ({ isAdmin, selectedFeed, rootContextPath, show }) => {
  const { selected: selectedOrganisation } = useOrganisations()

  const [menuCollapsed, setMenuCollapsed] = useState([])
  const items = subItems(isAdmin) && subItems(isAdmin)[rootContextPath]

  if (!show) return <EmptyContainer />
  return (
    <Container>
      <Location>
        {({ location: { pathname } }) => (
          <Fragment>
            {items && (
              <Fragment>
                {items.map((menuItems, i) => (
                  <MenuItem
                    isOpen={!menuCollapsed[i]}
                    key={menuItems.linkText}
                    path={pathname}
                    linkText={menuItems.linkText}
                    subItems={menuItems.links}
                    collapse={() => {
                      menuCollapsed[i] = true
                      setMenuCollapsed([...menuCollapsed])
                    }}
                    expand={() => {
                      menuCollapsed[i] = false
                      setMenuCollapsed([...menuCollapsed])
                    }}
                    selectedFeed={selectedFeed}
                    selectedOrganisation={selectedOrganisation}
                  />
                ))}
              </Fragment>
            )}
          </Fragment>
        )}
      </Location>
    </Container>
  )
}

SideNavigation.propTypes = {
  isAdmin: bool,
  selectedFeed: feedShape,
  rootContextPath: string,
  show: bool,
}

export default SideNavigation

import api from '../../../lib/api'

export const TEMPLATE_FETCHING = 'TEMPLATE_FETCHING'
export const TEMPLATE_DONE = 'TEMPLATE_DONE'
export const TEMPLATE_FAILED = 'TEMPLATE_FAILED'

const requestTemplates = () => ({
  type: TEMPLATE_FETCHING,
})

const receiveTemplates = templates => ({
  type: TEMPLATE_DONE,
  templates,
})

const fetchFailed = message => ({
  type: TEMPLATE_FAILED,
  message,
})

const defaultExclusions = 'defaultExclusions'
const defaultTransformations = 'defaultTransformations'

export const fetchTemplates = (type, dispatch) => {
  dispatch(requestTemplates())

  const templateName =
    type === 'exclusions' ? defaultExclusions : defaultTransformations
  const url = `clipboard/${type === 'exclusions' ? 'exclusions' : 'rules'}`

  let templates
  try {
    templates = JSON.parse(sessionStorage.getItem(templateName))
  } catch {}

  if (templates) {
    // we have data cached
    return Promise.resolve(dispatch(receiveTemplates(templates)))
  }

  // Uncomment once we have decided this
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(receiveTemplates(data))
      console.log(data)
      sessionStorage.setItem(templateName, JSON.stringify(data))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
    })
}

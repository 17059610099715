import React from 'react'
import styled from 'styled-components'
import { any, string } from 'prop-types'

import { BLOCK_PADDING_REGULAR } from '../../../constants'

const Wrapper = styled.div`
  ${({ padding }) =>
    padding ? `padding: ${padding};` : `padding: ${BLOCK_PADDING_REGULAR};`}
`

const Content = ({ children, padding }) => {
  return <Wrapper padding={padding}>{children}</Wrapper>
}

Content.propTypes = {
  children: any,
  padding: string,
}

export default Content

import React, { Fragment } from 'react'
import styled from 'styled-components'
import { bool, string, func } from 'prop-types'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { COUNTRIES, sharedStatusOptions } from '../../../constants'
import { basicInfoSchema } from '../../../formValidationSchemas'

import Button from '../../shared/Button'
import Tooltip from '../../shared/Tooltip2'
import Input from '../../shared/Input'
import PageDescription from '../../shared/PageDescription'
import Form from '../../shared/Form'
import Dropdown from '../../shared/Dropdown'
import FormWrapper from '../../shared/FormWrapper'

import useBrands from '../../shared/system/useBrands'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 32px;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`

const BasicInfo = ({
  country,
  organisationId,
  feedId,
  groupId,
  name,
  saveFeedOverview,
  reloadFeeds,
  status,
}) => {
  const { t } = useTranslation()
  const brands = useBrands()

  const saveData = ({ status, ...filteredValues }) =>
    saveFeedOverview(
      feedId,
      {
        ...filteredValues,
        enabled: status === 'active',
      },
      organisationId
    )
      .then(feed => {
        toast.success(t('feed_update_successful'))
        if (feed) {
          reloadFeeds().then(() => {
            navigate(`/${organisationId}/feeds`, { replace: true })
          })
        }
      })
      .catch(err => {
        toast.error(t('feed_update_unsuccessful'))
        console.log(err)
      })

  return (
    <Fragment>
      <PageDescription
        heading={t('basic_info')}
        description={t('basic_info_description')}
      />
      <FormWrapper>
        <Form
          initialValues={{
            name,
            groupId,
            country,
            status: status ? 'active' : 'paused',
          }}
          onSubmit={values => {
            saveData(values)
          }}
          validationSchema={basicInfoSchema}
          content={() => (
            <Fragment>
              <GridWrapper>
                <Tooltip title={t('basic_info_name_tooltip')}>
                  <Input
                    label={t('basic_info_feed_name')}
                    name="name"
                    data-id="input-feed-name"
                  />
                </Tooltip>
                <Dropdown
                  label={t('status')}
                  dataId="feed-dropdown-status"
                  name="status"
                  options={sharedStatusOptions(t)}
                />

                {brands.list && (
                  <Tooltip title={t('basic_info_brand_tooltip')}>
                    <Dropdown
                      label={t('basic_info_brand')}
                      dataId="feed-dropdown-client"
                      name="groupId"
                      options={brands.list}
                      placeholder={t('select')}
                    />
                  </Tooltip>
                )}

                <Tooltip title={t('basic_info_country_tooltip')}>
                  <Dropdown
                    name="country"
                    label={t('basic_info_country')}
                    optionValueKey="id"
                    dataId="feed-dropdown-country"
                    options={COUNTRIES.map(x => ({ ...x, name: t(x.name) }))}
                    data-id="select-feed-country"
                    placeholder={t('select')}
                  />
                </Tooltip>
              </GridWrapper>

              <ButtonWrapper>
                <Button data-id="button-feed-save" type="submit">
                  {t('button_save')}
                </Button>
              </ButtonWrapper>
            </Fragment>
          )}
        />
      </FormWrapper>
    </Fragment>
  )
}

BasicInfo.propTypes = {
  country: string,
  feedId: string.isRequired,
  groupId: string,
  name: string.isRequired,
  reloadFeeds: func,
  saveFeedOverview: func.isRequired,
  organisationId: string.isRequired,
  status: bool,
}

export default BasicInfo

import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const EXISTING_PARTNER_UPDATED = 'EXISTING_PARTNER_UPDATED'
export const NEW_PARTNER_UPDATED = 'NEW_PARTNER_UPDATED'

export const updatedExistingPartner = partner => ({
  type: EXISTING_PARTNER_UPDATED,
  partner,
})

export const updatedNewPartner = newPartner => ({
  type: NEW_PARTNER_UPDATED,
  newPartner,
})

export const fetchPartner = (t, dispatch, partnerId) => {
  const masterFeed = '00000000-0000-0000-0000-000000000000'

  return api
    .get(`/partners/${partnerId || masterFeed}`)
    .then(({ data }) => dispatch(updatedExistingPartner(data)))
    .catch(err => {
      toast.error(t('outgoing_partner_mapping_error'))
      console.log(err)
    })
}

export const createNewPartner = (newPartner, dispatch, t) =>
  api
    .post(`/partners`, newPartner)
    .then(() => {
      toast.success(t('partner_saved_successful'))
    })
    .catch(error => {
      console.log(error)
      toast.error(t('partner_saved_unsuccessful'))
    })

export const updatePartner = (
  name,
  source,
  partner,
  dispatch,
  fieldRequired
) => {
  const isRequired = fieldRequired === 'required'
  const isRecommended = fieldRequired === 'recommended'

  partner = {
    ...partner,
    mapping: {
      ...partner.mapping,
      [name]: {
        type: 'col',
        system: { required: isRequired, recommended: isRecommended },
        options: { name: source },
      },
    },
  }

  if (partner.name) {
    dispatch(updatedExistingPartner(partner))
  } else dispatch(updatedNewPartner(partner))
}

export const editPartner = (partnerId, t, editedPartner) =>
  api
    .patch(`/partners/${partnerId}`, editedPartner)
    .then(() => {
      toast.success(t('partner_saved_successful'))
    })
    .catch(error => {
      console.log(error)
      toast.error(t('partner_saved_unsuccessful'))
    })

export const deleteMapping = (dispatch, partner, name) => {
  delete partner.mapping[name]
  const updatedPartner = {
    ...partner,
    mapping: {
      ...partner.mapping,
    },
  }
  if (partner.name) {
    dispatch(updatedExistingPartner(updatedPartner))
  } else {
    dispatch(updatedNewPartner(updatedPartner))
  }
}

import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import { receiveSource } from '../SourceForm/actions'
import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'
import api from '../../../lib/api'

export const getAllIncoming = (feedId, cancelToken) =>
  api
    .get(`/feeds/${feedId}/incoming`, { cancelToken })
    .then(response => response.data)

export const getIncoming = (feedId, incomingId) =>
  api
    .get(`/feeds/${feedId}/incoming/${incomingId}`)
    .then(({ data }) => data)
    .catch(error => console.log(error))

export const saveIncoming = (dispatch, feedId, incomingId, data, t) => {
  const promise =
    incomingId && incomingId !== 'new'
      ? api.patch(`/feeds/${feedId}/incoming/${incomingId}`, data)
      : api.post(`/feeds/${feedId}/incoming`, {
          ...data,
          enabled: true,
          id: undefined,
        })

  return promise
    .then(({ data: source }) => {
      toast.success(t('saved'))
      dispatch(receiveSource(source))
      navigate(`./${source.id}`, {
        replace: true,
      })
    })
    .catch(error => {
      console.log(error)
      toastDetailErrorMessage('save_failed', error, t)
    })
}

import React from 'react'
import styled from 'styled-components'
import { string, bool, func } from 'prop-types'

import {
  VERSION,
  LIGHT_GREY,
  DARK_GREY,
  LAPTOP_WIDTH,
  ORANGE,
  SIDE_NAVIGATION_WIDTH_DESKTOP,
  SIDE_NAVIGATION_WIDTH_LAPTOP,
} from '../../../constants'
import { userShape } from '../../../types'
import logo from '../../../assets/logo.png'
import useOrganisations from '../../shared/system/useOrganisations'

import Link from '../../shared/Link'
import UserNavigation from './UserNavigation'
import HeaderRow from './HeaderRow'

const Logo = styled.img`
  width: 220px;
`

const LogoWrapper = styled.div`
  width: ${SIDE_NAVIGATION_WIDTH_DESKTOP};
  text-align: right;
  position: relative;

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    width: ${SIDE_NAVIGATION_WIDTH_LAPTOP};
  }
`

const Spacer = styled.div`
  flex-grow: 1;
`

const NavWrapper = styled.div`
  margin-left: 40px;
  display: flex;
`

const Version = styled.div`
  background-color: white;
  color: ${LIGHT_GREY};
  font-size: 10px;
  position: absolute;
  top: 20px;
  right: 8px;
`

const NavLink = styled(Link)`
  color: ${DARK_GREY};
  box-sizing: border-box;
  height: 25px;
  border-bottom: ${({ selected }) => selected && ` 2px solid ${ORANGE}`};
  cursor: pointer;
  margin-right: 30px;

  :hover {
    color: ${DARK_GREY};
    border-bottom: ${({ selected }) => !selected && ` 2px solid ${ORANGE}`};
  }
`

const pagePathNames = (isAdmin, organisationId) => [
  { name: 'feeds', link: `${organisationId}/feeds` },
  { name: 'reports', link: `${organisationId}/reports` },
  { name: 'admin', link: `${organisationId}/admin` },
  ...(isAdmin ? [{ name: 'system', link: 'system' }] : []),
]

const TopNavigation = ({
  isAdmin,
  isUser,
  logoutUser,
  rootContextPath,
  t,
  user,
}) => {
  const { selected } = useOrganisations()
  const organisationId = selected.id

  return (
    <HeaderRow>
      <Link link={`${organisationId}/feeds`}>
        <LogoWrapper>
          <Logo src={logo} alt="Company logo" />
          <Version>v{VERSION}</Version>
        </LogoWrapper>
      </Link>
      {isUser && (
        <NavWrapper>
          {pagePathNames(isAdmin, organisationId).map(({ name, link }) => (
            <NavLink
              key={name}
              dataId={`nav-item-${name}`}
              selected={rootContextPath === name}
              link={link}
            >
              {t(name).toUpperCase()}
            </NavLink>
          ))}
        </NavWrapper>
      )}
      <Spacer />
      {isUser && user && <UserNavigation user={user} logoutUser={logoutUser} />}
    </HeaderRow>
  )
}

TopNavigation.propTypes = {
  isAdmin: bool,
  isUser: bool,
  logoutUser: func.isRequired,
  rootContextPath: string,
  user: userShape,
}

export default TopNavigation

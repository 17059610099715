import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { arrayOf, bool, func, string, number } from 'prop-types'

import { getSourceFormValidationSchema } from '../../../formValidationSchemas'
import {
  FILE_TYPES,
  SEPARATORS,
  QUOTES,
  CONNECTIONS,
  sharedStatusOptions,
  LIGHT_GREY,
  booleanOptions,
  compressionOptions,
} from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { outgoingShape } from '../../../types'

import Input from '../../shared/Input'
import Button from '../../shared/Button'
import Tooltip from '../../shared/Tooltip2'
import Form from '../../shared/Form'
import Dropdown from '../../shared/Dropdown'
import FormWrapper from '../../shared/FormWrapper'
import Text from '../../shared/Text'

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  justify-content: flex-end;
`

const GridWrapper = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-bottom: 30px;
`

const BasicInfoWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr 1fr;
`

const OutgoingMappingWrapper = styled(GridWrapper)`
  grid-template-columns: 2fr 3fr
  align-items: center;
  margin-top: 10px;
`

const FileLocationWrapper = styled(GridWrapper)`
  grid-template-columns: 2fr 2fr 1fr;
  margin-top: 10px;
`

const FileDownloadLocationWrapper = styled(GridWrapper)`
  grid-template-columns: 3fr 1fr;
  margin-top: 10px;
`

const CsvFormatWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

const InputOptionsLineWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr 1fr 1fr 2fr;
`

const FileFormatWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr 4fr;
  margin-top: 10px;
`

const StyledTextarea = styled.textarea`
  border-color: ${LIGHT_GREY};
`

const statusOptions = t => [
  ...sharedStatusOptions(t),
  {
    id: 'test',
    name: t('test'),
  },
]

const mergeOptions = t => [
  {
    id: 'vertical',
    name: t('merge_vertical'),
  },
  {
    id: 'horizontal',
    name: t('merge_horizontal'),
  },
]

const SourceForm = ({
  connectionHostname,
  connectionPassword,
  connectionPath,
  connectionPort,
  connectionType,
  connectionUsername,
  dummyPassword,
  downloadUrl,
  formatDelimiter,
  formatJson,
  formatNormalizeNewlines,
  formatQuote,
  formatType,
  formatHeader,
  formatRelaxColumnCount,
  compressionType,
  isNew,
  mergeType,
  mergeSrcKey,
  mergeDstKey,
  showIncomingMergeOptions,
  name,
  onCancel,
  onFormChange,
  outgoingMapping,
  outgoingId,
  organisationId,
  organisationName,
  saveForm,
  enabled,
  publish,
  t,
  type,
}) => {
  const calcStatus = ({ enabled, publish }) => {
    if (enabled) {
      if (publish) return 'active'
      return 'test'
    }
    return 'paused'
  }

  const filterOutgoingMapping = () => {
    if (outgoingMapping) {
      return outgoingMapping
        .filter(
          value =>
            (viewSystemPartners && !value.orgId) ||
            (!viewSystemPartners && value.orgId === organisationId)
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  //used with checkbox on new outgoing form
  const [viewSystemPartners, setViewSystemPartners] = useState(true)
  const orgHasSpecificPartners = () => {
    if (outgoingMapping) {
      return (
        outgoingMapping.filter(value => value.orgId === organisationId).length >
        0
      )
    }
  }

  const isIncoming = outgoingMapping ? false : true

  const initialValues = {
    name,
    status: calcStatus({ enabled, publish }),
    connectionType,
    connectionPort,
    connectionHostname,
    connectionPath,
    connectionUsername,
    connectionPassword: isNew ? connectionPassword : dummyPassword,
    formatType,
    formatDelimiter,
    formatJson,
    formatQuote,
    formatNormalizeNewlines,
    formatHeader,
    formatRelaxColumnCount,
    compressionType,
    mergeType,
    mergeSrcKey,
    mergeDstKey,
  }

  return (
    <FormWrapper>
      <Form
        initialValues={
          outgoingMapping
            ? { columnMapper: '', ...initialValues }
            : initialValues
        }
        onSubmit={saveForm}
        onChange={onFormChange}
        validationSchema={getSourceFormValidationSchema(isNew, outgoingMapping)}
        content={() => (
          <Fragment>
            <BasicInfoWrapper>
              <Input
                label={t('sourceform_name')}
                data-id="input-source-name"
                name="name"
              />
              {outgoingMapping && (
                <Dropdown
                  label={t('sourceform_status')}
                  dataId="input-source-status"
                  name="status"
                  options={statusOptions(t)}
                  placeholder={t('select')}
                  formikChange={onFormChange}
                />
              )}

              {showIncomingMergeOptions && (
                <Tooltip title={t('incoming_merge_tooltip')}>
                  <Dropdown
                    label={t('sourceform_incoming_merge')}
                    dataId="select-merge-type"
                    name="mergeType"
                    options={mergeOptions(t)}
                    formikChange={onFormChange}
                  />
                </Tooltip>
              )}
            </BasicInfoWrapper>

            {outgoingMapping && isNew && (
              <OutgoingMappingWrapper>
                <Tooltip
                  title={
                    orgHasSpecificPartners()
                      ? viewSystemPartners
                        ? t('outgoing_specific_partners_tooltip', {
                            organisationName,
                          })
                        : t('outgoing_global_partners_tooltip')
                      : t('outgoing_no_predefined_partners', {
                          organisationName,
                        })
                  }
                >
                  <label>
                    <input
                      disabled={!orgHasSpecificPartners()}
                      type="checkbox"
                      checked={viewSystemPartners}
                      defaultChecked={true}
                      style={{ marginRight: 15 }}
                      onChange={event => {
                        setViewSystemPartners(!viewSystemPartners)
                      }}
                    />
                    {viewSystemPartners
                      ? t('outgoing_global_partner_checkbox')
                      : t('outgoing_specific_partner_checkbox', {
                          organisationName,
                        })}
                  </label>
                </Tooltip>
                {
                  <Tooltip title={t('outgoing_partner_tooltip')}>
                    <Dropdown
                      label={
                        viewSystemPartners
                          ? t('sourceform_outgoing_mapping_global')
                          : t('sourceform_outgoing_mapping_specific', {
                              organisationName,
                            })
                      }
                      dataId="select-source-mapping"
                      name="columnMapper"
                      //filter dropdown content based on checkbox status
                      options={filterOutgoingMapping()}
                      placeholder={t('select')}
                      formikChange={onFormChange}
                    />
                  </Tooltip>
                }
              </OutgoingMappingWrapper>
            )}

            {!outgoingMapping && (
              <InputOptionsLineWrapper>
                <div>
                  <Text>{t('compression')}</Text>
                  <Tooltip
                    title={t('compression_tooltip')}
                    style={{ width: '100%', marginTop: 10 }}
                  >
                    <Dropdown
                      label={t('sourceform_compression_type')}
                      options={compressionOptions(t)}
                      name="compressionType"
                      dataId="input-source-compression-gzip"
                      formikChange={onFormChange}
                      notNull
                    />
                  </Tooltip>
                </div>
                <div />
                <div />
                {mergeType === 'horizontal' && (
                  <div>
                    <Text>{t('merge_options')}</Text>
                    <Tooltip
                      title={t('merge_srcKey_tooltip')}
                      style={{ width: '49%', marginTop: 8 }}
                    >
                      <Input
                        label={t('sourceform_merge_srcKey')}
                        data-id="input-merge-src-key"
                        name="mergeSrcKey"
                        type="text"
                      />
                    </Tooltip>
                    <div style={{ width: '2%', display: 'inline-block' }} />
                    <Tooltip
                      title={t('merge_dstKey_tooltip')}
                      style={{ width: '49%', marginTop: 8 }}
                    >
                      <Input
                        label={t('sourceform_merge_dstKey')}
                        data-id="input-merge-dst-key"
                        name="mergeDstKey"
                        type="text"
                      />
                    </Tooltip>
                  </div>
                )}
              </InputOptionsLineWrapper>
            )}

            <Text>{t('sourceform_location')}</Text>
            {connectionType === 'fs' && (
              <FileDownloadLocationWrapper>
                <Input
                  label={t('sourceform_file-location')}
                  data-id="input-source-file-location"
                  name="connectionPath"
                  value={
                    outgoingId !== 'new'
                      ? `${downloadUrl}/${organisationId}/${outgoingId}`
                      : t('sourceform_file-location-placeholder')
                  }
                  disabled
                />

                <Dropdown
                  label={t('sourceform_type')}
                  options={CONNECTIONS(type === 'incoming')}
                  dataId="select-source-connection-type"
                  name="connectionType"
                  formikChange={onFormChange}
                  notNull
                />
              </FileDownloadLocationWrapper>
            )}
            {connectionType !== 'fs' && (
              <FileLocationWrapper>
                <Tooltip title={t('incoming_location_tooltip')}>
                  <Input
                    label={t('sourceform_hostname')}
                    data-id="input-source-connection-hostname"
                    name="connectionHostname"
                  />
                </Tooltip>
                <Tooltip title={t('incoming_path_tooltip')}>
                  <Input
                    label={t('sourceform_path')}
                    name="connectionPath"
                    data-id="input-source-connection-path"
                  />
                </Tooltip>

                <Tooltip title={t('incoming_type_tooltip')}>
                  <Dropdown
                    label={t('sourceform_type')}
                    options={CONNECTIONS(type === 'incoming')}
                    dataId="select-source-connection-type"
                    name="connectionType"
                    formikChange={onFormChange}
                    notNull
                  />
                </Tooltip>
                <Tooltip
                  title={
                    isIncoming
                      ? t('incoming_username_tooltip')
                      : t('outgoing_username_tooltip')
                  }
                >
                  <Input
                    label={t('sourceform_username')}
                    data-id="input-source-connection-username"
                    name="connectionUsername"
                  />
                </Tooltip>
                <Tooltip
                  title={
                    isIncoming
                      ? t('incoming_password_tooltip')
                      : t('outgoing_password_tooltip')
                  }
                >
                  <Input
                    label={t('sourceform_password')}
                    data-id="input-source-connection-password"
                    name="connectionPassword"
                    type="password"
                  />
                </Tooltip>
                <Tooltip title={t('incoming_port_tooltip')}>
                  <Input
                    label={t('sourceform_port')}
                    data-id="input-source-connection-port"
                    name="connectionPort"
                    type="number"
                  />
                </Tooltip>
              </FileLocationWrapper>
            )}

            <Text>{t('sourceform_format')}</Text>
            <FileFormatWrapper>
              <Tooltip title={t('incoming_filetype_tooltip')}>
                <Dropdown
                  label={t('sourceform_filetype')}
                  options={FILE_TYPES(type === 'incoming')}
                  name="formatType"
                  dataId="input-source-format-type"
                  formikChange={onFormChange}
                  notNull
                />
              </Tooltip>
              {formatType === 'xml' && (
                <StyledTextarea
                  name="formatJson"
                  value={formatJson}
                  onChange={onFormChange}
                  dataId="textarea-format-json"
                />
              )}
              {formatType !== 'xml' && (
                <CsvFormatWrapper>
                  <Tooltip title={t('separator_tooltip')}>
                    <Dropdown
                      label={t('sourceform_separator')}
                      options={SEPARATORS}
                      name="formatDelimiter"
                      dataId="select-source-format-delimiter"
                      formikChange={onFormChange}
                      notNull
                    />
                  </Tooltip>
                  <Tooltip title={t('quote_tooltip')}>
                    <Dropdown
                      label={t('sourceform_quote')}
                      options={QUOTES}
                      name="formatQuote"
                      dataId="input-source-format-quote"
                      formikChange={onFormChange}
                      notNull
                    />
                  </Tooltip>
                  {!outgoingMapping && (
                    <Tooltip title={t('incoming_header_tooltip')}>
                      <Dropdown
                        label={t('sourceform_header')}
                        options={booleanOptions(t)}
                        name="formatHeader"
                        dataId="select-source-format-header"
                        formikChange={onFormChange}
                        notNull
                      />
                    </Tooltip>
                  )}
                  {!outgoingMapping && (
                    <Tooltip title={t('incoming_relax_tooltip')}>
                      <Dropdown
                        label={t('sourceform_relax_column_count')}
                        options={booleanOptions(t)}
                        name="formatRelaxColumnCount"
                        dataId="input-source-format-relax-column-count"
                        formikChange={onFormChange}
                        notNull
                      />
                    </Tooltip>
                  )}
                </CsvFormatWrapper>
              )}
            </FileFormatWrapper>

            <ButtonWrapper>
              {onCancel && (
                <Button
                  data-id="button-source-cancel"
                  theme={SECONDARY_BUTTON}
                  onClick={onCancel}
                >
                  {t('button_back')}
                </Button>
              )}
              <Button data-id="button-source-save" type="submit">
                {t('button_save')}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    </FormWrapper>
  )
}

SourceForm.propTypes = {
  connectionHostname: string,
  connectionPassword: string,
  connectionPath: string,
  connectionPort: number,
  connectionType: string,
  connectionUsername: string,
  dummyPassword: string.isRequired,
  downloadUrl: string,
  formatDelimiter: string,
  formatJson: string,
  formatNormalizeNewlines: bool,
  formatQuote: string,
  formatType: string,
  formatHeader: string,
  formatRelaxColumnCount: string,
  mergeType: string,
  mergeSrcKey: string,
  mergeDstKey: string,
  showIncomingMergeOptions: bool,
  isNew: bool.isRequired,
  name: string,
  onCancel: func,
  onFormChange: func.isRequired,
  outgoingMapping: arrayOf(outgoingShape),
  organisationId: string,
  organisationName: string,
  outgoingId: string,
  saveForm: func.isRequired,
  enabled: bool,
  publish: bool,
  type: string.isRequired,
  compressionType: string,
}

export default SourceForm

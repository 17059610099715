import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const SET_FILES = 'SET_FILES'
export const LOAD_MORE_FILES = 'LOAD_MORE_FILES'
export const LOAD_FILES_FETCHING = 'LOAD_FILES_FETCHING'

export const setFiles = (files, numberOfFiles) => ({
  type: SET_FILES,
  data: { files },
  numberOfFiles: numberOfFiles,
})

export const loadFilesFetching = () => ({
  type: LOAD_FILES_FETCHING,
})

export const addMoreFiles = (files, currentPage, numberOfFiles) => ({
  type: LOAD_MORE_FILES,
  data: { files, currentPage },
  numberOfFiles: numberOfFiles,
})

const dispatchFilesAction = (files, currentPage, dispatch, numberOfFiles) => {
  if (currentPage) {
    dispatch(addMoreFiles(files, currentPage, numberOfFiles))
  } else {
    dispatch(setFiles(files, numberOfFiles))
  }
}

export const fetchFiles = ({
  pageSize,
  currentPage,
  dispatch,
  organisationId,
  t,
  fileType,
  brand,
}) => {
  dispatch(loadFilesFetching)
  return api
    .get(
      `/orgs/${organisationId}/files?limit=${pageSize}${
        currentPage ? `&page=${currentPage}` : ''
      }${fileType ? `&type=${fileType}` : ''}${
        brand ? `&groupId=${brand}` : ''
      }`
    )
    .then(({ data, headers: { fm_pagination_total } }) => {
      dispatchFilesAction(data, currentPage, dispatch, fm_pagination_total)
    })
    .catch(error => {
      dispatchFilesAction([], currentPage, dispatch)

      console.log(error)
      toast.error(t('system_files_fetch_failed'))
    })
}

export const createNewFile = ({
  fileType,
  name,
  stringData,
  organisationId,
  pageSize,
  dispatch,
  t,
}) =>
  api
    .post(`/orgs/${organisationId}/files`, {
      name,
      location: 'default',
      type: fileType,
    })
    .then(({ data: { id } }) => {
      return api.post(`/files/${id}/data`, stringData, {
        headers: { 'Content-Type': 'text/csv' },
      })
    })
    .then(() => {
      fetchFiles({
        pageSize,
        dispatch,
        organisationId,
        t,
      })
      toast.success(t('system_file_upload_successfull'))
    })
    .catch(error => {
      console.log(error)
      toast.error(t('system_file_upload_unsuccessfull'))
      fetchFiles({
        pageSize,
        dispatch,
        organisationId,
        t,
      })
    })

export const reuploadFile = ({ stringData, activeFileId, t }) => {
  return api
    .post(`/files/${activeFileId}/data`, stringData, {
      headers: { 'Content-Type': 'text/csv' },
    })
    .then(() => {
      toast.success(t('system_file_upload_successfull'))
    })
    .catch(error => {
      console.log(error)
      toast.error(t('system_file_upload_unsuccessfull'))
    })
}

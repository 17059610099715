import { NEW_PARTNER_UPDATED, EXISTING_PARTNER_UPDATED } from './actions'
import { APP_STATE } from '../../../constants'

export const initialState = {
  newPartner: { mapping: {} },
  partner: {},
  customPartnerState: APP_STATE.FETCHING,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATE.FETCHING:
      return {
        ...state,
        customPartnerState: APP_STATE.FETCHING,
      }

    case APP_STATE.ERROR:
      return {
        ...state,
        customPartnerState: APP_STATE.ERROR,
        errorMsg: action.msg,
      }

    case APP_STATE.DONE:
      return {
        ...state,
        customPartnerState: APP_STATE.DONE,
      }

    case EXISTING_PARTNER_UPDATED:
      return {
        ...state,
        partner: action.partner,
        customPartnerState: APP_STATE.DONE,
      }

    case NEW_PARTNER_UPDATED:
      return {
        ...state,
        newPartner: action.newPartner,
      }

    default:
      return state
  }
}

import { toast } from 'react-toastify'

const toastDetailErrorMessage = (messageKey, error, t) => {
  let message = t(messageKey)

  if (error && error.code === 'ECONNABORTED') {
    message = message += ` - ${t('error_timeout')}`
  } else if (error && error.response.data.code) {
    message += ` - ${t(error.response.data.code)}`

    if (error.response.data.errors && error.response.data.errors.length) {
      message += `:\n${error.response.data.errors[0].message}`
    }
  }

  toast.error(message)
}

export default toastDetailErrorMessage

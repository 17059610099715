import {
  SET_USER,
  SAVE_FEEDS,
  FETCH_ERROR,
  CREATE_FEED,
  FEED_DETAIL_DONE,
  FEED_DETAIL_FETCHING,
  FEED_DETAIL_FAILED,
} from './actions'
import { APP_STATE } from '../../constants'

export const initialState = {
  feed: null,
  feedDetailError: '',
  feedDetailState: null,
  feedListError: '',
  feedListState: APP_STATE.FETCHING,
  feeds: [],
  user: null,
}

export const FEED_DETAIL_STATE = {
  DONE: 'FEED_DETAIL_STATE.DONE',
  ERROR: 'FEED_DETAIL_STATE.ERROR',
  FETCHING: 'FEED_DETAIL_STATE.FETCHING',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ERROR:
      return {
        ...state,
        feedListError: action.msg,
        feedListState: APP_STATE.ERROR,
      }

    case SAVE_FEEDS:
      return {
        ...state,
        feeds: action.feeds,
        feedListState: APP_STATE.DONE,
      }

    case SET_USER:
      return {
        ...state,
        user: action.user,
      }

    case CREATE_FEED:
      return {
        ...state,
        feed: {
          id: null,
          name: 'New feed',
          groupId: null,
          countryId: null,
        },
        feedDetailState: FEED_DETAIL_STATE.DONE,
      }

    case FEED_DETAIL_DONE:
      return {
        ...state,
        feed: action.feed,
        feedDetailState: FEED_DETAIL_STATE.DONE,
      }

    case FEED_DETAIL_FETCHING:
      return {
        ...state,
        feedDetailState: FEED_DETAIL_STATE.FETCHING,
      }

    case FEED_DETAIL_FAILED:
      return {
        ...state,
        feedDetailState: FEED_DETAIL_STATE.ERROR,
        feedListError: action.message,
      }

    default:
      return state
  }
}

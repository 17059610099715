import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { toast } from 'react-toastify'

import PageDescription from '../components/shared/PageDescription'
import Button from '../components/shared/Button'
import Input from '../components/shared/Input'
import Form from '../components/shared/Form'
import Dropdown from '../components/shared/Dropdown'

import api from '../lib/api'
import { timezoneWithUTC } from '../lib/getTimezoneWithUtc'
import { timezones } from '../constants/timezones'
import { languages } from '../constants/languages'
import { countryFormats } from '../constants/countryFormats'
import { navigate } from '@reach/router'
import { SECONDARY_BUTTON } from '../constants/buttonThemes'
import { userShape } from '../types'
import { userSettingSchema } from '../formValidationSchemas'

import useOrganisations from '../components/shared/system/useOrganisations'
import { useTranslation } from 'react-i18next'

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  grid-gap: 20px;
`

const UserEmail = styled.div`
  margin: 20px 0;
`

const AvatarWrapper = styled.div`
  text-align: right;
`

const AvatarImg = styled.img`
  width: 100px;
  margin: 20px 0 20px auto;
  display: block;
`

const UserSettingPage = ({
  dispatch,
  fetchUser,
  user: {
    id,
    name,
    email,
    avatarUrl,
    updatedAt,
    settings: { language, format, timezone },
  },
}) => {
  const { selected, list: organisations } = useOrganisations()
  const { t } = useTranslation()

  const organisationId = selected.id

  const timezonesWithUTC = timezones.map(timezone => timezoneWithUTC(timezone))
  const [userLanguage, setUserLanguage] = useState(language)
  const [userAvatarUrl, setUserAvatarUrl] = useState(avatarUrl)
  const [userTimezone, setUserTimezone] = useState(timezone)
  const [userFormat, setUserFormat] = useState(format)
  const TOAST_SUCCESS_ID = 'TOAST_SUCCESS_ID'
  const TOAST_ERROR_ID = 'TOAST_ERROR_ID'

  useEffect(
    () => {
      fetchUser(dispatch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, userAvatarUrl, userLanguage, userFormat, userTimezone]
  )

  const saveData = ({ avatarUrl, format, language, name, timezone }) => {
    const updatedUser = {
      avatarUrl: avatarUrl,
      name: name,
      settings: {
        language,
        timezone,
        format,
      },
    }

    api
      .patch(`/users/${id}`, updatedUser)
      .then(
        ({
          data: {
            settings: { language, timezone, format },
          },
          avatarUrl,
        }) => {
          setUserLanguage(language)
          setUserAvatarUrl(avatarUrl)
          setUserTimezone(timezone)
          setUserFormat(format)

          if (!toast.isActive(TOAST_SUCCESS_ID)) {
            toast.success(t('user_setting_user_saved_successful'), {
              toastId: TOAST_SUCCESS_ID,
            })
          }
        }
      )
      .catch(err => {
        if (!toast.isActive(TOAST_ERROR_ID)) {
          toast.error(t('user_setting_user_saved_unsuccessful'), {
            toastId: TOAST_ERROR_ID,
          })
        }

        console.log(err)
      })
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('user_setting_heading')}
        description={t('user_setting_description')}
      />
      <Form
        initialValues={{
          name: name,
          language: language,
          timezone: timezone,
          avatarUrl: avatarUrl,
          format: format,
        }}
        onSubmit={values => saveData(values)}
        validationSchema={userSettingSchema}
        content={() => (
          <Fragment>
            <GridWrapper>
              <FormWrapper>
                {t('email')}
                <UserEmail data-id="user_email">{email}</UserEmail>
                {t('name')}
                <Input name="name" data-id="input-user-setting-name" />
                {t('language')}
                <Dropdown
                  dataId="dropdown-user-setting-language"
                  name="language"
                  options={languages}
                />
                {t('user_setting_country_label')}
                <Dropdown
                  dataId="dropdown-user-setting-country"
                  name="format"
                  options={countryFormats}
                />
                {t('user_setting_timezone_label')}
                <Dropdown
                  name="timezone"
                  optionValueKey="id"
                  dataId="dropdown-user-setting-timezone"
                  options={timezonesWithUTC}
                />
                {t('user_setting_permission')}
                <div data-id="user_orgs">
                  {organisations.map(organisation => (
                    <div key={organisation.id}>{organisation.name}</div>
                  ))}
                </div>
              </FormWrapper>
              <AvatarWrapper>
                {t('user_setting_logged')}
                <div data-id="last-time-logged">
                  {t('dateValueInput', { value: new Date(updatedAt) })}
                </div>
                <AvatarImg
                  data-id="user_avatar"
                  src={avatarUrl}
                  alt="user avatar"
                />
                <Input
                  name="avatarUrl"
                  data-id="input-user-setting-avatar"
                  maximumLength="1024"
                />
              </AvatarWrapper>
            </GridWrapper>
            <ButtonWrapper>
              <StyledButton
                onClick={() => navigate(`${organisationId}/feeds`)}
                theme={SECONDARY_BUTTON}
              >
                {t('button_cancel')}
              </StyledButton>
              <Button data-id="button-feed-save" type="submit">
                {t('button_save_settings')}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

UserSettingPage.propTypes = {
  fetchUser: func.isRequired,
  user: userShape.isRequired,
}

export default UserSettingPage

const matchesSpecialList = (value, onChange) => [
  {
    id: 'first',
    name: 'first',
    render: undefined,
    onChange: onChange,
    selectedValue: value,
  },
  {
    id: 'all',
    name: 'all_letters_capital',
    render: undefined,
    onChange: onChange,
    selectedValue: value,
  },
]

const stringOperatorList = (fileList, columnList, value, onChange) => [
  {
    id: 'value',
    name: 'value',
    render: 'input',
    selectedValue: value,
    onChange: onChange,
  },
  {
    id: 'file',
    name: 'file',
    render: 'dropdown',
    title: 'file',
    list: fileList,
    onChange: onChange,
    selectedValue: value,
  },
  {
    id: 'column',
    name: 'column',
    render: 'dropdown',
    title: 'column',
    list: columnList,
    selectedValue: value,
    onChange: onChange,
  },
]

const compareList = (columnList, value, onChange) => [
  {
    id: 'value',
    name: 'value',
    render: 'input-number',
    selectedValue: value,
    onChange: onChange,
  },
  {
    id: 'column',
    name: 'column',
    render: 'dropdown',
    list: columnList,
    selectedValue: value,
    onChange: onChange,
  },
]

export const getConditionTree = (
  fileList,
  columnList,
  value,
  onValueChange,
  childValue,
  onChildValueChange
) => [
  {
    id: 'startsWith',
    name: 'startsWith',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'contains',
    name: 'contains',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'notStartsWith',
    name: 'notStartsWith',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'notContains',
    name: 'notContains',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'notEndsWith',
    name: 'notEndsWith',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'notEquals',
    name: 'notEquals',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'notEqualsValue',
    name: 'notEqualsValue',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'notMatchesRegex',
    name: 'notMatchesRegex',
    render: 'input',
    selectedValue: value,
    onChange: onValueChange,
    caseSensitive: false,
  },
  {
    id: 'notMatchesSpecial',
    name: 'notMatchesSpecial',
    render: 'dropdown',
    list: matchesSpecialList(value, onValueChange),
    onChange: onValueChange,
    selectedValue: value,
  },
  {
    id: 'endsWith',
    name: 'endsWith',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'equals',
    name: 'equals',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'equalsValue',
    name: 'equalsValue',
    render: 'selection',
    list: stringOperatorList(fileList, columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
    caseSensitive: false,
  },
  {
    id: 'empty',
    name: 'empty',
    render: undefined,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'greater',
    name: 'greater',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'greaterEquals',
    name: 'greaterEquals',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'less',
    name: 'less',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'lessEquals',
    name: 'lessEquals',
    render: 'selection',
    list: compareList(columnList, value, onValueChange),
    onChange: onChildValueChange,
    selectedValue: childValue,
  },
  {
    id: 'notEmpty',
    name: 'notEmpty',
    render: undefined,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'matchesRegex',
    name: 'matchesRegex',
    render: 'input',
    selectedValue: value,
    onChange: onValueChange,
    caseSensitive: false,
  },
  {
    id: 'matchesSpecial',
    name: 'matchesSpecial',
    render: 'dropdown',
    list: matchesSpecialList(value, onValueChange),
    onChange: onValueChange,
    selectedValue: value,
  },
]

export const languages = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'cz',
    name: 'Czech',
  },
  {
    id: 'fr',
    name: 'French',
  },
  {
    id: 'de',
    name: 'German',
  },
]

export const fileTypes = showAll => [
  { id: 'csv', name: 'CSV' },
  { id: 'xml', name: 'XML' },
  // { id: 'tsv', name: 'TSV' }, // not yet supported on backend
  //...(showAll ? [{ id: 'xml', name: 'XML' }] : []),
]

export const separators = [
  { id: '\t', name: 'tab' },
  { id: ',', name: ',' },
  { id: '|', name: '|' },
  { id: ';', name: ';' },
  { id: ':', name: ':' },
]

export const booleans = t => [
  { id: 'true', name: t('yes') },
  { id: 'false', name: t('no') },
]

export const compression = t => [
  { id: 'none', name: t('none') },
  { id: 'gzip', name: 'GZIP' },
]

export const quotes = [{ id: `"`, name: `"` }, { id: `'`, name: `'` }]

export const connections = showAll => [
  { id: 'ftp', name: 'FTP' },
  { id: 'ftps', name: 'FTPS' },
  { id: 'sftp', name: 'SFTP' },
  ...(showAll
    ? [{ id: 'http', name: 'HTTP' }, { id: 'https', name: 'HTTPS' }]
    : [{ id: 'fs', name: 'File' }]),
]

export const connectionPorts = {
  ftp: 21,
  ftps: 21,
  sftp: 22,
  http: 80,
  https: 443,
}

export const statusTypes = t => [
  {
    id: 'active',
    name: t('active'),
  },
  {
    id: 'paused',
    name: t('paused'),
  },
]

export const uploadFileTypes = [
  { id: 'list' },
  { id: 'overrides' },
  { id: 'conversion_list' },
]

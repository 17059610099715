import React from 'react'
import styled from 'styled-components'
import { arrayOf, oneOf, string, oneOfType, number, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tooltip, { TooltipWrapper } from '../Tooltip'
import Tooltip2 from '../Tooltip2'
import Rectangle from '../Rectangle'

import {
  BLACK,
  BUTTON_PADDING_SMALL,
  CARD_HEIGHT_MEDIUM,
  CARD_HEIGHT_SMALL,
  CARD_HEIGHT_LARGE,
  DARK_GREY,
  GREEN,
  ICON_SIZE_BIG,
  LIGHT_TRANSPARENT_GREY,
  MIDDLE_GREY,
  SHADOW_GREY,
  SHADOW_LIGHT,
  TEXT_BIG,
  TEXT_MEDIUM,
  TEXT_SMALL,
  WHITE,
} from '../../../constants'
import {
  SECONDARY_BUTTON,
  TRANSPARENT_BUTTON,
} from '../../../constants/buttonThemes'
import {
  cardButtonShape,
  cardColumnItemShape,
  progressBarShape,
  sortArrowShape,
} from '../../../types'

import Button from '../Button'
import Text from '../Text'
import ProgressBar from '../ProgressBar'
import Icon from '../Icon'
import Link from '../Link'
import ErrorBoundary from '../ErrorBoundary'

const Wrapper = styled.div`
  position: relative;

  &:hover {
    ${TooltipWrapper} {
      display: block;
      padding: 8px;
    }
  }
`

const FlexEndWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardTitle = styled.div`
  flex: 1;
  margin: 15px 15px 0 0;
  text-align: left;
  word-break: break-word;
  min-width: 0;
`

const StatusWrapper = styled.div`
  display: flex;
`

const SpaceBetweenAligned = styled(SpaceBetweenWrapper)`
  align-items: ${({ align }) => align};
`

const BottomWrapper = styled(SpaceBetweenAligned)`
  padding: 0 15px 15px;
  align-items: center;
`

const CardWrapper = styled(SpaceBetweenWrapper)`
  min-width: 200px;
  flex-direction: column;
  height: ${({ height }) => height};
  background: ${WHITE};
  box-shadow: ${SHADOW_GREY};
`

const AnchorPart = styled.div`
  padding: ${({ isClickable }) => (isClickable ? '0 15px 15px' : '0 15px')};
  &:hover {
    ${({ isClickable }) =>
      isClickable && `background: ${LIGHT_TRANSPARENT_GREY}`};
    ${({ isClickable }) => isClickable && `box-shadow: ${SHADOW_LIGHT}`};
  }
`

const Label = styled.div`
  width: 75px;
  height: 85px;
  padding: 10px 5px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  background: ${({ labelColor }) => labelColor};
`

const Arrows = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 55px;
`

const StyledImage = styled.div`
  height: 80px;
  margin-bottom: 8px;
  max-height: 49px;
  width: 100%;
  ${({ img }) => `background-image: url(${img})`};
  background-size: contain;
  background-repeat: no-repeat;
`

const ColumnItemWrapper = styled.div`
  margin-top: ${({ columnItemMargin }) => columnItemMargin || '16px'};
`

const StyledRow = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`

const SingleButtonWrapper = styled.div`
  margin: 0 2px;
`

const HugeText = styled.div`
  color: ${({ color }) => (color ? color : BLACK)};
  font-size: 40px;
  line-height: 1;
`

const statusConfig = {
  active: { color: GREEN, icon: 'checkmark' },
  paused: { color: DARK_GREY, icon: 'pause' },
  test: { color: DARK_GREY, icon: 'wrench' },
}

const Card = ({
  arrows,
  buttons,
  column,
  columnItemMargin,
  dataId,
  description,
  icon,
  id,
  img,
  isClickable,
  height = CARD_HEIGHT_MEDIUM,
  noStatus,
  position,
  progress,
  status = 'active',
  title,
  tooltip,
  alertCount = 0,
  alertLink,
}) => {
  const { t } = useTranslation()
  const isStatusString = typeof status === 'string'
  let stringStatus
  if (isStatusString) {
    stringStatus = status
  } else {
    stringStatus = status ? 'active' : 'paused'
  }
  let tooltipMessage = tooltip ? tooltip : t('button_position_tooltip')

  const cutString = (value, length) => {
    if (value.length <= length) return value
    return `${value.substring(0, length)}...`
  }

  return (
    <CardWrapper height={height} data-id={dataId || `card-${title}`}>
      <ErrorBoundary>
        <Link link={`${id}`} disabled={!isClickable}>
          <AnchorPart isClickable={isClickable}>
            <SpaceBetweenWrapper>
              <CardTitle>
                {img && <StyledImage img={img} alt={title} />}
                {icon && (
                  <Icon icon={icon} color={DARK_GREY} size={ICON_SIZE_BIG} />
                )}
                <Text ellipsis bold size={TEXT_BIG}>
                  {title}
                </Text>
                {description ? (
                  <Text ellipsis>{description}</Text>
                ) : (
                  <Text>&nbsp;</Text>
                )}
              </CardTitle>

              {!noStatus && (
                <StatusWrapper>
                  <Label labelColor={statusConfig[stringStatus].color}>
                    <Text color={WHITE} size={TEXT_SMALL} dataId="status-label">
                      {t(stringStatus).toUpperCase()}
                    </Text>
                    <Icon
                      color={WHITE}
                      icon={statusConfig[stringStatus].icon}
                    />
                  </Label>
                </StatusWrapper>
              )}
            </SpaceBetweenWrapper>

            {progress && <ProgressBar progress={progress} />}

            {column &&
              column.map(
                ({
                  heading,
                  inline,
                  description,
                  isDescriptionBold,
                  tooltip,
                }) => (
                  <ColumnItemWrapper
                    key={heading}
                    columnItemMargin={columnItemMargin}
                  >
                    <StyledRow inline={inline}>
                      <Text
                        size={inline ? TEXT_MEDIUM : TEXT_SMALL}
                        color={inline ? DARK_GREY : MIDDLE_GREY}
                      >
                        {heading}
                        {inline && <span>:&nbsp;</span>}
                      </Text>
                    </StyledRow>
                    <StyledRow inline={inline}>
                      <Tooltip2 title={tooltip}>
                        <Text bold={isDescriptionBold}>
                          {description.length > 134 ? (
                            <Wrapper>
                              {cutString(description, 134)}
                              <Tooltip>{description}</Tooltip>
                            </Wrapper>
                          ) : (
                            description
                          )}
                        </Text>
                      </Tooltip2>
                    </StyledRow>
                  </ColumnItemWrapper>
                )
              )}
          </AnchorPart>
        </Link>
        <BottomWrapper align="flex-end">
          {alertCount > 0 && (
            <Rectangle>
              {alertCount} {alertCount === 1 ? t('Alert') : t('Alerts')}
            </Rectangle>
          )}

          <div>
            {(position || arrows) && (
              <Tooltip2 title={tooltipMessage}>
                {position && (
                  <Text size={TEXT_SMALL}>{t('processing_order')}</Text>
                )}
                <SpaceBetweenAligned align="center">
                  {position && <HugeText>{position}</HugeText>}
                  {arrows && (
                    <Arrows>
                      <Button
                        onClick={arrows.onDown}
                        data-id={'position-down'}
                        disabled={arrows.isLast}
                        theme={TRANSPARENT_BUTTON}
                        padding={BUTTON_PADDING_SMALL}
                      >
                        <Icon
                          icon={'arrowDown'}
                          color={arrows.isLast ? MIDDLE_GREY : BLACK}
                        />
                      </Button>

                      <Button
                        onClick={arrows.onUp}
                        disabled={arrows.isFirst}
                        data-id={'position-up'}
                        theme={TRANSPARENT_BUTTON}
                        padding={BUTTON_PADDING_SMALL}
                      >
                        <Icon
                          icon={'arrowUp'}
                          color={arrows.isFirst ? MIDDLE_GREY : BLACK}
                        />
                      </Button>
                    </Arrows>
                  )}
                </SpaceBetweenAligned>
              </Tooltip2>
            )}
          </div>

          <FlexEndWrapper>
            {buttons.map(
              ({
                name,
                disabled,
                tooltip,
                link,
                onClick,
                theme = SECONDARY_BUTTON,
              }) => (
                <SingleButtonWrapper key={name}>
                  <Tooltip2 title={t(tooltip)}>
                    <Button
                      dataId={`button-${name.replace(/\+/, '')}`}
                      fontSize={TEXT_SMALL}
                      onClick={onClick}
                      link={link}
                      theme={theme}
                      disabled={disabled}
                    >
                      {name}
                    </Button>
                  </Tooltip2>
                </SingleButtonWrapper>
              )
            )}
          </FlexEndWrapper>
        </BottomWrapper>
      </ErrorBoundary>
    </CardWrapper>
  )
}

Card.propTypes = {
  arrows: sortArrowShape,
  buttons: arrayOf(cardButtonShape).isRequired,
  column: arrayOf(cardColumnItemShape),
  columnItemMargin: string,
  dataId: string,
  description: string,
  height: oneOf([CARD_HEIGHT_MEDIUM, CARD_HEIGHT_SMALL, CARD_HEIGHT_LARGE]),
  icon: string,
  id: string,
  img: string,
  isClickable: bool,
  noStatus: bool,
  position: oneOfType([string, number]),
  progress: progressBarShape,
  status: oneOfType([string, bool]),
  title: string.isRequired,
  tooltip: string,
  alertCount: number,
  alertLink: string,
}

export default Card

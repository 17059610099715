import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { Router, navigate } from '@reach/router'
import { string } from 'prop-types'

import { reducer, initialState } from './reducer'
import {
  fetchInputExclusionList,
  fetchOutputExclusionList,
  updatePosition,
  removeExclusion,
  updateExclusion,
  selectedOutgoingChanged,
} from './actions'
import { getExclusionDetail } from '../ExclusionDetail/actions'

import { APP_STATE } from '../../../constants'

import NoMatch from '../../shared/NoMatch'
import SidePanel from '../../shared/SidePanel'
import SidePanelPortal from '../../shared/SidePanel/SidePanelPortal'
import useSidePanel from '../../shared/SidePanel/useSidePanel'

import List from './List'
import ExclusionDetail from '../ExclusionDetail'
import Loader from '../../shared/Loader'
import { outgoingShape } from '../../../types'

const ExclusionList = ({ feedId, organisationId, selectedOutgoing, t }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { inputList, outputList, stateOfAction, errorMsg } = state

  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [selectedExclusionIds, setSelectedExclusionIds] = useState({
    exclusionId: null,
    outgoingId: null,
  })

  const panel = useSidePanel()

  const savedOptimisation = 'exclusions'
  const exclusionsFromSession =
    JSON.parse(sessionStorage.getItem(savedOptimisation)) || []
  const [copiedExclusions, setCopiedExclusions] = useState(
    exclusionsFromSession
  )
  const [clipboardExclusion, setClipboardExclusion] = useState(null)

  useEffect(() => {
    fetchInputExclusionList(feedId, dispatch, t)
    panel.setTitle(t('sidepanel_clipboard_button'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (selectedOutgoing) {
        fetchOutputExclusionList(feedId, selectedOutgoing.id, dispatch, t)
        dispatch(selectedOutgoingChanged(selectedOutgoing))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const fetchData = outgoingId => {
    if (outgoingId) {
      fetchOutputExclusionList(feedId, outgoingId, dispatch, t)
    }
    fetchInputExclusionList(feedId, dispatch, t)
  }

  const handleDeleteDialogVisibility = (visible, exclusionId, outgoingId) => {
    setDeleteDialogVisible(visible)
    setSelectedExclusionIds({
      exclusionId,
      outgoingId,
    })
  }

  const onRemove = () => {
    const { exclusionId, outgoingId } = selectedExclusionIds
    removeExclusion(feedId, exclusionId, outgoingId, t).finally(() =>
      fetchData(selectedOutgoing.id)
    )
    handleDeleteDialogVisibility(false)
  }

  const onPause = (exclusionId, enabled, outgoingId) => {
    updateExclusion(
      feedId,
      exclusionId,
      { enabled: enabled },
      outgoingId,
      t
    ).finally(() => fetchData(selectedOutgoing.id))
  }

  const onPositionChange = ({ id: exclusionId, newPosition, outgoingId }) => {
    updatePosition(feedId, exclusionId, newPosition, outgoingId, t).finally(
      () => fetchData(selectedOutgoing.id)
    )
  }

  const addToClipboard = (ids, outgoingId) => {
    setTimeout(() => {
      panel.show()
    }, 10)

    if (!Array.isArray(ids)) ids = [ids]

    const idsInClipboard = copiedExclusions.map(x => x.id)
    const idsToAdd = ids.filter(item => !idsInClipboard.includes(item))

    if (idsToAdd.length > 0) {
      const promises = idsToAdd.map(id =>
        getExclusionDetail(feedId, id, outgoingId)
      )
      Promise.all(promises).then(results => {
        const items = results.map(x => x.data)
        const updatedCopiedRules = [...copiedExclusions, ...items]
        setCopiedExclusions(updatedCopiedRules)
        sessionStorage.setItem(
          savedOptimisation,
          JSON.stringify(updatedCopiedRules)
        )
      })
    }
  }

  const deleteItemClipboard = index => {
    const deletedRule = copiedExclusions.splice(index, 1)
    const updatedCopiedRules = copiedExclusions.filter(
      rule => rule !== deletedRule
    )
    setCopiedExclusions(updatedCopiedRules)
    sessionStorage.setItem(
      savedOptimisation,
      JSON.stringify(updatedCopiedRules)
    )
    if (copiedExclusions.length === 0) panel.hide()
  }

  const buttonsItemClipboard = [
    {
      name: t('clipboard-add-to-master'),
      onClick: exclusion => {
        setClipboardExclusion(exclusion)
        navigate(`/${organisationId}/feeds/${feedId}/exclusions`)
          .then(() =>
            navigate(`/${organisationId}/feeds/${feedId}/exclusions/new/master`)
          )
          .then(() => setClipboardExclusion(undefined))
      },
    },
    {
      name: t('clipboard-add-to-outgoing'),
      onClick: exclusion => {
        setClipboardExclusion(exclusion)
        navigate(`/${organisationId}/feeds/${feedId}/exclusions`)
          .then(() =>
            navigate(
              `/${organisationId}/feeds/${feedId}/exclusions/new/outgoing/${
                selectedOutgoing.id
              }`
            )
          )
          .then(() => setClipboardExclusion(undefined))
      },
    },
  ]

  if (!inputList || !outputList || !selectedOutgoing) {
    return <Loader />
  }

  if (stateOfAction === APP_STATE.ERROR) return <p>{errorMsg}</p>

  return (
    <Fragment>
      <Router primary={false}>
        <List
          addToClipboard={addToClipboard}
          inputList={inputList}
          onPause={onPause}
          onPositionChange={onPositionChange}
          onRemove={onRemove}
          outputList={outputList}
          path="/"
          selectedOutgoing={selectedOutgoing}
          t={t}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
        />
        <ExclusionDetail
          clipboardExclusion={clipboardExclusion}
          feedId={feedId}
          fetchData={fetchData}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          organisationId={organisationId}
          path="/:exclusionId/master"
        />
        <ExclusionDetail
          clipboardExclusion={clipboardExclusion}
          feedId={feedId}
          fetchData={fetchData}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          mappingPartner={selectedOutgoing}
          organisationId={organisationId}
          selectedOutgoing={selectedOutgoing}
          path=":exclusionId/outgoing/:outgoingId"
        />
        <NoMatch path="*" isRelative noThrow to="/" />
      </Router>
      <SidePanelPortal>
        <SidePanel
          t={t}
          buttons={buttonsItemClipboard}
          deleteItem={deleteItemClipboard}
          copiedItems={copiedExclusions}
          type="exclusions"
        />
      </SidePanelPortal>
    </Fragment>
  )
}

ExclusionList.propTypes = {
  feedId: string,
  organisationId: string.isRequired,
  selectedOutgoing: outgoingShape,
}

export default ExclusionList

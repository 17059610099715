import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import { BLOCK_PADDING_REGULAR, TEXT_MEDIUM } from '../../../constants'

const Wrapper = styled.div`
  width: ${({ width }) => width || '140px'};
  ${({ padding }) =>
    padding ? `padding: ${padding};` : `padding: ${BLOCK_PADDING_REGULAR};`}
  margin-bottom:8px;
`

const Title = styled.div`
  text-align: center;
  font-size: ${TEXT_MEDIUM};
`

const Content = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`

const Square = ({ title, width, content, padding }) => {
  return (
    <Wrapper padding={padding} width={width}>
      <Content>{content}</Content>
      <Title>{title}</Title>
    </Wrapper>
  )
}

Square.propTypes = {
  title: string,
  content: string,
  width: string,
  padding: string,
}

export default Square

import React from 'react'
import { string } from 'prop-types'
import { Router } from '@reach/router'

import NoMatch from '../shared/NoMatch'
import CustomPartner from './CustomPartner'
import Organisations from './Organisations'
import Announcements from './Announcements'
import Partners from '../shared/PartnerList'

import ErrorBoundary from '../shared/ErrorBoundary'

import useOrganisations from '../shared/system/useOrganisations'
import { useTranslation } from 'react-i18next'

const SystemContent = ({ '*': contextRoute, userId }) => {
  const { selected } = useOrganisations()
  const { t } = useTranslation()

  const organisationId = selected.id

  return (
    <ErrorBoundary>
      <Router primary={false}>
        <Organisations
          organisationId={organisationId}
          t={t}
          path="organisations"
          userId={userId}
        />
        <Partners
          t={t}
          organisationId={organisationId}
          path="partners"
          scope="sys"
        />
        <CustomPartner t={t} path="add-outgoing-partner/*" />
        <Announcements path="announcements" />
        <NoMatch path="*" isRelative noThrow to="organisations" />
      </Router>
    </ErrorBoundary>
  )
}

SystemContent.propTypes = {
  '*': string,
  userId: string.isRequired,
}

export default SystemContent

import React, { Fragment, useEffect, useReducer } from 'react'
import { navigate } from '@reach/router'
import { string, object, func, bool } from 'prop-types'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import Loader from '../../shared/Loader'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import Detail from '../../shared/OptimizationDetail'
import RemoveModalWindow from '../../shared/RemoveModalWindow'

import { APP_STATE } from '../../../constants'
import { getConditionTree } from '../../../constants/conditionList'
import { simplePartnerShape } from '../../../types'

import { reducer, initialState } from './reducer'

import {
  updateConditionCaseSensitive,
  updateExclusion,
  createOrUpdateExclusion,
  removeExclusion,
  fetchExclusionData,
  updatedExclusion,
  updateExpressionSource,
  updateExpressionOperator,
  updateExpressionValue,
  updateExpressionType,
  addNewExpression,
  removeExpression,
  getFilterValue,
} from './actions'

const ExclusionDetail = ({
  feedId,
  exclusionId,
  handleDeleteDialogVisibility,
  isDeleteDialogVisible,
  organisationId,
  outgoingId,
  selectedOutgoing,
  location,
  fetchData,
  mappingPartner,
  clipboardExclusion,
}) => {
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(reducer, initialState)
  const { stateOfAction, exclusion, columns, files, isDuplicateOrNew } = state
  useEffect(
    () => {
      const parsed = queryString.parse(location.search)
      const newExclusion =
        parsed.isDuplicate || !exclusionId || exclusionId === 'new'

      fetchExclusionData(
        feedId,
        exclusionId,
        outgoingId,
        organisationId,
        newExclusion,
        dispatch,
        t,
        clipboardExclusion
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (selectedOutgoing)
        navigate(location.href.replace(/outgoing\/(.*)/, `outgoing/$1`))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const onDuplicateClick = () => {
    fetchExclusionData(
      feedId,
      exclusionId,
      outgoingId,
      organisationId,
      true,
      dispatch,
      t
    )
  }

  const addNewExclusion = () => {
    navigate(
      location.href.includes('/master')
        ? `/${organisationId}/feeds/${feedId}/exclusions/new/master`
        : `/${organisationId}/feeds/${feedId}/exclusions/new/outgoing/${outgoingId}`
    )

    fetchExclusionData(
      feedId,
      'new',
      outgoingId,
      organisationId,
      false,
      dispatch,
      t
    )
  }

  const updateName = event =>
    dispatch(updatedExclusion({ ...exclusion, name: event.target.value }))

  const updateStatus = ({ id }) =>
    dispatch(updatedExclusion({ ...exclusion, enabled: id === 'active' }))

  const onRemove = () => {
    removeExclusion(feedId, exclusionId, outgoingId, t).finally(() => {
      fetchData(outgoingId)
      navigate(`${organisationId}/feeds/${feedId}/exclusions`)
    })
    handleDeleteDialogVisibility(false)
  }

  const onSave = () => {
    createOrUpdateExclusion(
      feedId,
      isDuplicateOrNew || clipboardExclusion ? 'new' : exclusionId,
      organisationId,
      {
        name: exclusion.name,
        enabled: exclusion.enabled,
        expressions: exclusion.expressions,
      },
      outgoingId,
      dispatch,
      t
    ).finally(() => {
      fetchData(outgoingId)
    })
  }

  const onPause = (exclusionId, enabled) => {
    updateExclusion(
      feedId,
      exclusionId,
      { enabled: enabled },
      outgoingId,
      dispatch,
      t
    ).finally(() => {
      fetchData(outgoingId)
    })
  }

  const updateCaseSensitive = (index, caseSensitive) => {
    updateConditionCaseSensitive(index, caseSensitive, exclusion, dispatch)
  }

  const updateOperator = (index, operator) =>
    updateExpressionOperator(index, operator, exclusion, dispatch)

  const updateSource = (index, source) =>
    updateExpressionSource(index, source, exclusion, dispatch)

  const updateValue = (index, { target, id }) =>
    updateExpressionValue(
      index,
      target ? target.value : id,
      exclusion,
      dispatch
    )

  const updateValueType = (index, type) =>
    updateExpressionType(index, type, exclusion, dispatch)

  const onExpressionRemove = index =>
    removeExpression(index, exclusion, dispatch)

  const getConditionList = (
    inputValue,
    valueChange,
    valueType,
    valueTypeChange
  ) =>
    getConditionTree(
      getFileList(inputValue, valueChange),
      getColumnList(inputValue, valueChange),
      inputValue,
      valueChange,
      valueType,
      valueTypeChange
    )

  const getColumnList = (value, onChange) =>
    columns.map(column => ({
      id: column,
      name: column,
      render: undefined,
      onChange: onChange,
      selectedValue: value,
    }))

  const getFileList = (value, onChange) =>
    files.map(({ id, name }) => ({
      id: id,
      name: name,
      render: undefined,
      selectedValue: value,
      onChange: onChange,
    }))

  const updateChanges = {
    updateCaseSensitive,
    updateName,
    updateSource,
    updateOperator,
    updateValue,
    updateValueType,
    updateStatus,
  }

  if (
    stateOfAction === APP_STATE.FETCHING &&
    !exclusion.length &&
    !columns.length &&
    !files.length
  )
    return <Loader />

  if (!columns || !exclusion || !files) return <p>{t('no_data')}</p>

  return (
    <Fragment>
      <PageDescription
        description={t('exclusions_description')}
        heading={t('exclusions')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: 'button_delete',
            onClick: () => handleDeleteDialogVisibility(true),
            disabled: isDuplicateOrNew,
          },
          {
            name: exclusion.enabled
              ? 'exclusion_pause_button'
              : 'exclusion_enable_button',
            onClick: () => onPause(exclusion.id, !exclusion.enabled),
            disabled: isDuplicateOrNew,
          },
          {
            name: 'exclusion_duplicate_button',
            onClick: onDuplicateClick,
            disabled: isDuplicateOrNew,
            link: `?isDuplicate=true`,
          },
          {
            name: 'exclusion_add_new',
            onClick: addNewExclusion,
            disabled: isDuplicateOrNew,
          },
        ]}
      />
      <Detail
        addNewCondition={addNewExpression}
        columnList={columns}
        dispatch={dispatch}
        getConditionList={getConditionList}
        getConditionValue={getFilterValue}
        inputId="exclusion-name"
        mainLabel={t('exclusion_name_label')}
        masterText={t('master_exclusions')}
        onRemoveCondition={onExpressionRemove}
        onSave={onSave}
        partnerText={t('partner_exclusions')}
        saveLink={`${organisationId}/feeds/${feedId}/exclusions`}
        selectedOutgoing={mappingPartner}
        statusDataId="select-exclusion-status"
        updateChanges={updateChanges}
        t={t}
        type={exclusion}
      />
      <RemoveModalWindow
        t={t}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        onRemove={onRemove}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

ExclusionDetail.propTypes = {
  exclusionId: string,
  feedId: string.isRequired,
  fetchData: func.isRequired,
  handleDeleteDialogVisibility: func.isRequired,
  isDeleteDialogVisible: bool.isRequired,
  location: object,
  mappingPartner: simplePartnerShape,
  organisationId: string.isRequired,
  outgoingId: string,
  selectedFileId: string,
  selectedOutgoing: object,
  selectedMutationId: string,
  clipboardExclusion: object,
}
export default ExclusionDetail

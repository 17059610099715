import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const ORGANISATIONS_FETCHING = 'ORGANISATIONS_FETCHING'
export const ORGANISATIONS_DONE = 'ORGANISATIONS_DONE'
export const ORGANISATIONS_FAILED = 'ORGANISATIONS_FAILED'

const requestOrganisations = () => ({
  type: ORGANISATIONS_FETCHING,
})

const receiveOrganisations = organisationList => ({
  type: ORGANISATIONS_DONE,
  organisationList,
})

const fetchFailed = message => ({
  type: ORGANISATIONS_FAILED,
  message,
})

export const fetchOrganisations = ({ dispatch, t, userId }) => {
  dispatch(requestOrganisations())
  api
    .get(`/users/me/orgs?limit=250`)
    .then(({ data }) => {
      dispatch(receiveOrganisations(data))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
      toast.error(t('organisations_fetch_unsuccessful'))
    })
}

export const editOrganisation = (
  _,
  organisationId,
  t,
  editedOrganisation,
  dispatch,
  userId
) =>
  api
    .patch(`/orgs/${organisationId}`, { name: editedOrganisation.name })
    .then(() => {
      fetchOrganisations({ dispatch, t, userId })
      toast.success(t('organisation_edit_successful'))
    })
    .catch(err => {
      toast.error(t('organisation_edit_unsuccessful'))
      console.log(err)
    })

export const createOrganisation = (userId, newOrganisation, dispatch, t) =>
  api
    .post(`/orgs`, newOrganisation)
    .then(() => {
      fetchOrganisations({ dispatch, t, userId })
      toast.success(t('organisation_create_successful'))
    })
    .catch(err => {
      toast.error(t('organisation_create_unsuccessful'))
      console.log(err)
    })

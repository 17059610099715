import React from 'react'
import Table from 'react-table'
import styled from 'styled-components'
import { string, number } from 'prop-types'
import 'react-table/react-table.css'

const Wrapper = styled.div`
  & .ReactTable {
    border: 0 none;

    .rt-thead .rt-th {
      background: #fafafa;
      padding: 1rem;
      font-weight: bold;
    }

    .rt-tr {
      transition: all linear 0.1s;
    }

    .rt-td {
      padding: 1rem;
      display: flex;
      align-items: center;

      &.right {
        justify-content: flex-end;
      }
    }
  }

  .ReactTable .rt-noData {
    z-index: 0 !important;
  }
`

const StyledTable = props => (
  <Wrapper>
    <Table
      {...props}
      className={props.className || '-highlight'}
      defaultPageSize={props.defaultPageSize || 10}
      noDataText={props.t('no_rows_found')}
    />
  </Wrapper>
)

StyledTable.propTypes = {
  className: string,
  defaultPageSize: number,
}

export default StyledTable

import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import { fetchingData, fetchingFailed } from '../../App/actions'
import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'
import api from '../../../lib/api'

export const RULE_RECEIVED = 'RULE_RECEIVED'
export const RULE_FILES_RECEIVED = 'RULE_FILES_RECEIVED'
export const RULE_UPDATED = 'RULE_UPDATED'
export const RULE_IS_CLONED = 'RULE_IS_CLONED'

const getMasterRuleUrl = (feedId, ruleId) =>
  `/feeds/${feedId}/master/rules/${ruleId}`
const getOutgoingRuleUrl = (feedId, outgoingId, ruleId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/rules/${ruleId}`
const getMasterMappingUrl = feedId => `/feeds/${feedId}/master/headers`
const getOutgoingMappingUrl = (feedId, outgoingId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/mapping`

export const receivedFileData = files => ({
  type: RULE_FILES_RECEIVED,
  files,
})

export const receivedRuleData = (
  data,
  inputCols,
  outputCols,
  files,
  isDuplicateOrNew
) => ({
  type: RULE_RECEIVED,
  data,
  inputCols,
  outputCols,
  files,
  isDuplicateOrNew,
})

export const updatedRule = data => ({
  type: RULE_UPDATED,
  data: data,
})

export const updateDuplicateData = isDuplicateOrNew => ({
  type: RULE_IS_CLONED,
  isDuplicateOrNew: isDuplicateOrNew,
})

export const updateRule = (feedId, ruleId, data, outgoingId, dispatch, t) =>
  api
    .patch(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
        : getMasterRuleUrl(feedId, ruleId),
      data
    )
    .then(resp => {
      dispatch(updatedRule(resp.data))
      dispatch(updateDuplicateData(false))
      toast.success(t('rule_save_success'))
    })
    .catch(error => toastDetailErrorMessage('rule_save_error', error, t))

const createRule = (feedId, data, outgoingId, dispatch, t, organisationId) =>
  api
    .post(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, '')
        : getMasterRuleUrl(feedId, ''),
      data
    )
    .then(resp => {
      toast.success(t('rule_save_success'))
      navigate(
        `/${organisationId}/feeds/${feedId}/rules/${resp.data.id}/${
          outgoingId ? 'outgoing/' + outgoingId : 'master'
        }`
      )
      dispatch(updatedRule(resp.data))
      dispatch(updateDuplicateData(false))
    })
    .catch(error => toastDetailErrorMessage('rule_save_error', error, t))

export const createOrUpdateRule = (
  feedId,
  ruleId,
  data,
  outgoingId,
  dispatch,
  t,
  organisationId
) => {
  if (ruleId && ruleId !== 'new') {
    return updateRule(
      feedId,
      ruleId,
      data,
      outgoingId,
      dispatch,
      t,
      organisationId
    )
  } else {
    return createRule(feedId, data, outgoingId, dispatch, t, organisationId)
  }
}

export const removeRule = (feedId, ruleId, outgoingId, t) =>
  api
    .delete(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
        : getMasterRuleUrl(feedId, ruleId)
    )
    .then(() => toast.success(t('rule_delete_success')))
    .catch(() => toast.error(t('rule_delete_error')))

export const fetchRuleData = (
  feedId,
  ruleId,
  outgoingId,
  organisationId,
  isDuplicateOrNew,
  dispatch,
  t,
  clipboardRule
) => {
  dispatch(fetchingData())

  const rulePromise = getRuleDetail(feedId, ruleId, outgoingId, clipboardRule)
  const inMappingPromise = api.get(getMasterMappingUrl(feedId))
  const outMappingPromise = outgoingId
    ? api.get(getOutgoingMappingUrl(feedId, outgoingId))
    : Promise.resolve({ data: [] })

  Promise.all([rulePromise, inMappingPromise, outMappingPromise])
    .then(values => {
      const inputCols = values[1].data
      const outputCols = Object.keys(values[2].data)

      const ruleData = values[0].data
      if (!clipboardRule && isDuplicateOrNew && ruleData.id) {
        ruleData.name = `${ruleData.name} duplicate`
      }

      dispatch(
        receivedRuleData(ruleData, inputCols, outputCols, [], isDuplicateOrNew)
      )

      return getFiles(organisationId)
    })
    .then(files => {
      dispatch(receivedFileData(files))
    })
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

const getFiles = async organisationId => {
  let page = 0
  let files = {}
  while (true) {
    const values = await api.get(
      `/orgs/${organisationId}/files?page=${page}&limit=50`
    )
    if (values.data.length === 0) return Object.values(files)
    values.data.forEach(({ id, name }) => (files[id] = { id, name }))
    page++
  }
}

export const getRuleDetail = (feedId, ruleId, outgoingId, clipboardRule) => {
  if (clipboardRule) {
    return Promise.resolve({ data: clipboardRule })
  }

  if (ruleId && ruleId !== 'new') {
    return api.get(
      outgoingId
        ? getOutgoingRuleUrl(feedId, outgoingId, ruleId)
        : getMasterRuleUrl(feedId, ruleId)
    )
  } else {
    return Promise.resolve({
      data: {
        name: '',
        enabled: true,
        conditions: [],
        actions: [
          {
            column: undefined,
            options: {
              value: undefined,
              caseSensitive: undefined,
            },
            type: undefined,
          },
        ],
      },
    })
  }
}

export const updateConditionCaseSensitive = (
  index,
  caseSensitive,
  rule,
  dispatch,
  isAction
) => {
  if (isAction) {
    rule.actions[index].options.caseSensitive = caseSensitive
  } else {
    rule.conditions[index].options.caseSensitive = caseSensitive
  }
  dispatch(updatedRule(rule))
}

export const updateConditionSource = (index, source, rule, dispatch) => {
  rule.conditions[index].options.source = `{{$${source}}}`
  dispatch(updatedRule(rule))
}

export const updateConditionOperator = (index, operator, rule, dispatch) => {
  let condition = {
    type: operator,
    options: { source: rule.conditions[index].options.source },
  }
  switch (operator) {
    case 'equalsValue':
    case 'notEqualsValue':
    case 'startsWith':
    case 'notStartsWith':
    case 'endsWith':
    case 'notEndsWith':
    case 'contains':
    case 'notContains': {
      condition.options = {
        ...condition.options,
        value: undefined,
        type: undefined,
        caseSensitive: false,
      }
      break
    }
    case 'equals':
    case 'notEquals':
    case 'greater':
    case 'greaterEquals':
    case 'less':
    case 'lessEquals':
    case 'matchesSpecial':
    case 'notMatchesSpecial': {
      condition.options = {
        ...condition.options,
        value: undefined,
      }
      break
    }
    case 'matchesRegex':
    case 'notMatchesRegex': {
      condition.options = {
        ...condition.options,
        pattern: undefined,
        caseSensitive: false,
      }
      break
    }
    default:
      break
  }
  rule.conditions[index] = condition
  dispatch(updatedRule(rule))
}

export const updateConditionValue = (index, value, rule, dispatch) => {
  const operatorType = rule.conditions[index].type
  const optionsType = rule.conditions[index].options.type

  switch (operatorType) {
    case 'matchesRegex':
    case 'notMatchesRegex': {
      rule.conditions[index].options.pattern = value
      break
    }
    default: {
      if (optionsType && optionsType === 'file') {
        rule.conditions[index].options.fileId = value
      } else if (optionsType && optionsType === 'column') {
        rule.conditions[index].options.value = `{{$${value}}}`
      } else {
        rule.conditions[index].options.value = value
      }
      break
    }
  }
  dispatch(updatedRule(rule))
}

export const updateConditionType = (index, type, rule, dispatch) => {
  let options = rule.conditions[index].options
  switch (type) {
    case 'column':
    case 'value': {
      options = {
        source: options.source,
        value: '',
        type: type,
        caseSensitive: options.caseSensitive,
      }
      break
    }
    case 'file': {
      options = {
        source: options.source,
        fileId: undefined,
        type: type,
        caseSensitive: options.caseSensitive,
      }
      break
    }
    default:
      break
  }
  rule.conditions[index].options = options
  dispatch(updatedRule(rule))
}

export const addNewCondition = (rule, dispatch) => {
  rule.conditions.push({
    options: {
      source: undefined,
      value: undefined,
      caseSensitive: undefined,
    },
    type: undefined,
  })
  dispatch(updatedRule(rule))
}

export const removeCondition = (index, rule, dispatch) => {
  rule.conditions.splice(index, 1)
  dispatch(updatedRule(rule))
}

export const updateActionColumn = (index, column, rule, dispatch) => {
  rule.actions[index].column = `{{$${column}}}`
  dispatch(updatedRule(rule))
}

export const getConditionValue = (type, options) => {
  if (options.type && options.type === 'file') {
    return options.fileId
  }
  if (options.type && options.type === 'column') {
    return options.value ? options.value.replace(/[{$}]/g, '') : options.value
  }
  switch (type) {
    case 'matchesRegex':
    case 'notMatchesRegex': {
      return options.pattern
    }
    default:
      return options.value
  }
}

const returnValue = ({ type, name, value }) =>
  type === 'column' ? name : value

export const getActionValue = (type, options, isFirst, isOperator, column) => {
  if (!options) {
    return undefined
  }
  switch (type) {
    case 'split': {
      return isFirst ? options.separator : options.index
    }
    case 'regex': {
      return isFirst ? options.from : options.to
    }
    case 'setMaxLength': {
      return options.length
    }
    case 'createHash': {
      return options.column
        ? options.column.replace(/[{$}]/g, '')
        : options.column
    }
    case 'removeValue': {
      switch (options.type) {
        case 'file': {
          return options.fileId
        }
        case 'column': {
          return options.column
            ? options.column.replace(/[{$}]/g, '')
            : options.column
        }
        default:
          return options.value
      }
    }
    case 'setValue': {
      switch (options.type) {
        case 'file': {
          const neatSourceColumn =
            options.sourceColumn && options.sourceColumn.replace(/[{$}]/g, '')
          const neatColumn = column && column.replace(/[{$}]/g, '')
          return isFirst ? options.fileId : neatSourceColumn || neatColumn
        }
        case 'column': {
          return options.column
            ? options.column.replace(/[{$}]/g, '')
            : options.column
        }
        default:
          return options.value
      }
    }
    case 'contains': {
      const neatSourceColumn =
        options.sourceColumn && options.sourceColumn.replace(/[{$}]/g, '')
      const neatColumn = column && column.replace(/[{$}]/g, '')
      return isFirst ? options.fileId : neatSourceColumn || neatColumn
    }
    case 'replaceValue': {
      switch (options.type) {
        case 'file': {
          return options.fileId
        }
        case 'value': {
          return isFirst ? options.find : options.replace
        }
        default:
          return undefined
      }
    }
    case 'calculateValue': {
      if (isOperator) {
        return options.input[1].value
      }
      if (isFirst) {
        return returnValue(options.input[0])
      } else {
        return returnValue(options.input[2])
      }
    }
    case 'combineValues': {
      return options
    }
    default:
      return options.value
  }
}

export const updateActionType = (index, type, rule, dispatch) => {
  let action = {
    column: rule.actions[index].column,
    type: type,
  }
  switch (type) {
    case 'removeValue': {
      action.options = {
        value: undefined,
        type: undefined,
      }
      break
    }
    case 'setValue': {
      action.options = {
        value: undefined,
        type: undefined,
        sourceColumn: undefined,
      }
      break
    }
    case 'contains': {
      action.options = {
        toCheck: undefined,
        fileId: undefined,
      }
      break
    }
    case 'replaceValue': {
      action.options = {
        type: undefined,
        find: undefined,
        replace: undefined,
        caseSensitive: undefined,
      }
      break
    }
    case 'combineValues': {
      action.options = {
        input: [],
      }
      break
    }
    case 'createHash': {
      action.options = {
        column: undefined,
      }
      break
    }
    case 'setMaxLength': {
      action.options = {
        length: undefined,
      }
      break
    }
    case 'regex': {
      action.options = {
        from: '',
        to: '',
        type: undefined,
      }
      break
    }
    case 'calculateValue': {
      action.options = {
        input: [
          { type: undefined, value: undefined },
          { type: 'operator', value: undefined },
          { type: undefined, value: undefined },
        ],
      }
      break
    }
    case 'split': {
      action.options = {
        index: 0,
        separator: '',
      }
      break
    }
    default:
      break
  }
  rule.actions[index] = action
  dispatch(updatedRule(rule))
}

export const updateActionValue = (
  index,
  value,
  rule,
  isFirst,
  dispatch,
  isOperator
) => {
  const { options, type, column } = rule.actions[index]
  const optionsType = options.type

  switch (type) {
    case 'split': {
      if (isFirst) {
        options.separator = value
      } else {
        options.index = value
      }
      break
    }
    case 'regex': {
      if (isFirst) {
        options.from = value
      } else {
        options.to = value
      }
      options.type = 'value'
      break
    }
    case 'setMaxLength': {
      options.length = value
      break
    }
    case 'createHash': {
      options.column = `{{$${value}}}`
      break
    }
    case 'removeValue': {
      switch (optionsType) {
        case 'file': {
          options.fileId = isFirst ? value : options.fileId
          break
        }
        case 'column': {
          options.column = `{{$${value}}}`
          break
        }
        default: {
          options.value = value
          break
        }
      }
      break
    }
    case 'setValue': {
      switch (optionsType) {
        case 'file': {
          options.fileId = isFirst ? value : options.fileId
          options.sourceColumn = !isFirst
            ? `{{$${value}}}`
            : options.sourceColumn || column
          break
        }
        case 'column': {
          options.column = `{{$${value}}}`
          break
        }
        default: {
          options.value = value
          break
        }
      }
      break
    }
    case 'contains': {
      options.fileId = isFirst ? value : options.fileId
      options.toCheck = column && column.replace(/[{$}]/g, '')
      break
    }
    case 'replaceValue': {
      switch (optionsType) {
        case 'file': {
          options.fileId = value
          break
        }
        default: {
          if (isFirst) {
            options.find = value
          } else {
            options.replace = value
          }
          break
        }
      }
      break
    }
    case 'calculateValue': {
      if (isOperator) {
        return (options.input[1].value = value)
      } else if (isFirst) {
        return options.input[0].type === 'column'
          ? (options.input[0].name = value)
          : (options.input[0].value = value)
      } else {
        return options.input[2].type === 'column'
          ? (options.input[2].name = value)
          : (options.input[2].value = value)
      }
    }
    case 'combineValues': {
      options.input = value
      return options.input
    }
    default: {
      options.value = value
      break
    }
  }
  dispatch(updatedRule(rule))
}

const getCalculateValueInput = (isFirst, type, options) => {
  const firstObject = { type: isFirst ? type : options.input[0].type }
  const secondObject = { type: isFirst ? options.input[2].type : type }

  firstObject.type === 'value'
    ? (firstObject.value = isFirst ? '' : options.input[0].value)
    : (firstObject.name = isFirst ? '' : options.input[0].name)
  secondObject.type === 'value'
    ? (secondObject.value = isFirst ? options.input[2].value : '')
    : (secondObject.name = isFirst ? options.input[2].name : '')

  return [
    firstObject,
    { type: 'operator', value: options.input[1].value },
    secondObject,
  ]
}

export const updateActionOptionsType = (
  index,
  type,
  rule,
  dispatch,
  isFirst
) => {
  let options = rule.actions[index].options
  switch (type) {
    case 'value': {
      switch (rule.actions[index].type) {
        case 'replaceValue':
          options = {
            find: '',
            replace: '',
            type: type,
          }
          break
        case 'calculateValue':
          options = {
            ...options,
            input: getCalculateValueInput(isFirst, type, options),
          }
          break
        default:
          options = {
            value: '',
            type: type,
          }
          break
      }
      break
    }
    case 'file': {
      switch (rule.actions[index].type) {
        case 'replaceValue':
          options = {
            fileId: undefined,
            type: type,
            caseSensitive: false,
          }
          break
        default:
          options = {
            fileId: undefined,
            type: type,
          }
          break
      }
      break
    }
    case 'column': {
      switch (rule.actions[index].type) {
        case 'calculateValue':
          options = {
            ...options,
            input: getCalculateValueInput(isFirst, type, options),
          }
          break
        default:
          options = {
            column: '',
            type: type,
          }
          break
      }
      break
    }
    default:
      break
  }
  rule.actions[index].options = options
  dispatch(updatedRule(rule))
}

export const addNewAction = (rule, dispatch) => {
  rule.actions.push({
    column: undefined,
    options: {
      value: undefined,
    },
    type: undefined,
  })
  dispatch(updatedRule(rule))
}

export const removeAction = (index, rule, dispatch) => {
  rule.actions.splice(index, 1)

  if (rule.actions.length === 0) {
    addNewAction(rule, dispatch)
  } else {
    dispatch(updatedRule(rule))
  }
}

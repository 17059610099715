import { toast } from 'react-toastify'
import { formatDistanceStrict } from 'date-fns'

import api from '../../lib/api'

export const PROCESSING_FETCHING = 'PROCESSING_FETCHING'
export const PROCESSING_DONE = 'PROCESSING_DONE'
export const PROCESSING_FAILED = 'PROCESSING_FAILED'

const requestProcessing = () => ({
  type: PROCESSING_FETCHING,
})

const receiveProcessing = (processing, pageTotal, page) => ({
  type: PROCESSING_DONE,
  processing,
  pageTotal,
  page,
})

const fetchFailed = message => ({
  type: PROCESSING_FAILED,
  message,
})

export const fetchProcessing = (
  organisationId,
  dispatch,
  selectedBrand,
  selectedFeed,
  page = 0
) => {
  dispatch(requestProcessing())

  const selectedFeedId = selectedFeed ? `feedId=${selectedFeed.id}&` : ''
  const selectedBrandId =
    selectedBrand && selectedBrand.id !== 'all'
      ? `groupId=${selectedBrand.id}&`
      : ''
  const getJobsUrl = `/orgs/${organisationId}/jobs?${selectedFeedId}${selectedBrandId}page=${page +
    1}&limit=10`

  return api
    .get(getJobsUrl)
    .then(({ data, headers: { fm_pagination_total } }) =>
      dispatch(
        receiveProcessing(data, Math.ceil(fm_pagination_total / 10), page)
      )
    )
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
    })
}

export const fetchJobIncomingOutgoing = (jobId, t) =>
  api.get(`/jobs/${jobId}`).catch(err => {
    toast.error(t('job_detail_fetch_unsuccessful'))
    console.log(err)
  })

export const calculateDuration = (status, stats) => {
  let duration = ''
  if (
    (status === 'running' || status === 'pending' || status === 'processing') &&
    stats &&
    stats.startedAt &&
    !stats.finishedAt
  ) {
    const formattedStartedAt = new Date(stats.startedAt)
    const currentTime = Date.now()

    duration = formatDistanceStrict(formattedStartedAt, currentTime)
    return duration
  } else if (stats.startedAt && stats.finishedAt) {
    return formatDistanceStrict(
      new Date(stats.startedAt),
      new Date(stats.finishedAt)
    )
  }
  return duration
}

export const calculateJobDuration = (startedAt, finishedAt) => {
  if (startedAt && finishedAt) {
    return formatDistanceStrict(new Date(startedAt), new Date(finishedAt))
  } else if (startedAt && !finishedAt) {
    return formatDistanceStrict(new Date(startedAt), Date.now())
  } else {
    return ''
  }
}

export const getPercentage = (published, total) =>
  published ? `${Math.round((published / total) * 100)}%` : ''

const setValueFileList = (fileList, value, onChange) => [
  {
    id: 'value',
    name: 'value',
    render: 'input',
    selectedValue: value,
    onChange: onChange,
  },
  {
    id: 'file',
    name: 'file',
    render: 'dropdown',
    title: 'file',
    list: fileList,
    selectedValue: value,
    onChange: onChange,
  },
]

const replaceValueList = (
  fileList,
  value1,
  onValue1Change,
  value2,
  onValue2Change
) => [
  {
    id: 'value',
    name: 'value',
    title: 'find',
    render: 'multiple-input',
    renderChild: {
      id: 'value',
      title: 'replace',
      render: 'input',
      selectedValue: value2,
      onChange: onValue2Change,
    },
    selectedValue: value1,
    onChange: onValue1Change,
  },
  {
    id: 'file',
    name: 'file',
    render: 'dropdown',
    title: 'file',
    list: fileList,
    selectedValue: value1,
    onChange: onValue1Change,
    caseSensitive: false,
  },
]

const setValueColumnList = (columnList, value, onValueChange) => [
  {
    id: 'value',
    name: 'value',
    title: 'value',
    render: 'input',
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'column',
    name: 'column',
    title: 'column',
    render: 'dropdown',
    list: columnList,
    selectedValue: value,
    onChange: onValueChange,
  },
]

const setValueColumnFileList = (
  columnList,
  value,
  onValueChange,
  fileList,
  childValue,
  onchildValueChange
) => [
  {
    id: 'value',
    name: 'value',
    title: 'value',
    render: 'input',
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'column',
    name: 'column',
    title: 'column',
    render: 'dropdown',
    list: columnList,
    selectedValue: value,
    onChange: onValueChange,
  },
  {
    id: 'file',
    name: 'file',
    render: 'dropdown',
    showSearchInColumn: true,
    renderChild: {
      id: 'value',
      title: 'search_in_column',
      render: 'dropdown',
      list: columnList,
      selectedValue: childValue,
      onChange: onchildValueChange,
    },
    title: 'file',
    list: fileList,
    selectedValue: value,
    onChange: onValueChange,
  },
]

const calculateValueList = (
  columnList,
  firstValueSource,
  onFirstSourceChange,
  valueChild,
  onValueChildChange,
  secondValueChild,
  onSecondChildChange
) => [
  {
    id: 'value',
    name: 'value',
    render: 'calculateValue',
    renderChild: {
      id: 'calc-first-input',
      title: 'value',
      render: 'input',
      selectedValue: valueChild,
      onChange: onValueChildChange,
    },
    list: setValueColumnList(columnList, valueChild, onValueChildChange),
    selectedValue: firstValueSource,
    onChange: onFirstSourceChange,
  },
  {
    id: 'column',
    name: 'column',
    render: 'calculateValue',
    renderChild: {
      id: 'calc-first-column',
      title: 'column',
      render: 'dropdown',
      list: columnList,
      selectedValue: valueChild,
      onChange: onValueChildChange,
    },
    list: setValueColumnList(columnList, secondValueChild, onSecondChildChange),
    selectedValue: firstValueSource,
    onChange: onFirstSourceChange,
  },
]

export const getActionTree = (fileList, columnList, arrayOfActions) => {
  const { value: value1, function: onChange1 } = arrayOfActions[0]
  const { value: value2, function: onChange2 } = arrayOfActions[1]
  const { value: value3, function: onChange3 } = arrayOfActions[2]
  const { value: value4, function: onChange4 } = arrayOfActions[3]
  const { value: value5, function: onChange5 } = arrayOfActions[4]

  return [
    {
      id: 'calculateValue',
      name: 'calculateValue',
      render: 'selection',
      renderChild: {
        id: 'calculateValue',
        name: 'calculateValue',
        render: 'selection',
        list: setValueColumnList(columnList, value3, onChange3),
        selectedValue: value4,
        onChange: onChange4,
      },
      list: calculateValueList(
        columnList,
        value5,
        onChange5,
        value1,
        onChange1,
        value3,
        onChange3
      ),
      selectedValue: value2,
      onChange: onChange2,
    },
    {
      id: 'capitalise',
      name: 'capitalise',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'capitaliseFirst',
      name: 'capitaliseFirst',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'combineValues',
      name: 'combineValues',
      render: 'combineValues',
      onChange: onChange1,
      selectedValue: value1,
    },
    {
      id: 'createHash',
      name: 'createHash',
      render: 'dropdown',
      list: columnList,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'lowerCaseAll',
      name: 'lowerCaseAll',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'regex',
      name: 'regex',
      title: 'regexFrom',
      render: 'multiple-input',
      renderChild: {
        id: 'value',
        title: 'regexTo',
        render: 'input',
        selectedValue: value3,
        onChange: onChange3,
      },
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'removeHtmlTags',
      name: 'removeHtmlTags',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'removeValue',
      name: 'removeValue',
      render: 'selection',
      list: setValueFileList(fileList, value1, onChange1),
      selectedValue: value2,
      onChange: onChange2,
    },
    {
      id: 'removeWhitespaces',
      name: 'removeWhitespaces',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'replaceValue',
      name: 'replaceValue',
      render: 'selection',
      list: replaceValueList(fileList, value1, onChange1, value3, onChange3),
      selectedValue: value2,
      onChange: onChange2,
    },
    {
      id: 'setMaxLength',
      name: 'setMaxLength',
      render: 'input-number',
      onChange: onChange1,
      selectedValue: value1,
    },
    {
      id: 'setValue',
      name: 'setValue',
      render: 'selection',
      list: setValueColumnFileList(
        columnList,
        value1,
        onChange1,
        fileList,
        value3,
        onChange3
      ),
      selectedValue: value2,
      onChange: onChange2,
    },
    {
      id: 'split',
      name: 'split',
      title: 'split_by',
      render: 'multiple-input',
      renderChild: {
        id: 'value',
        title: 'index',
        render: 'input-number',
        selectedValue: value3,
        onChange: onChange3,
      },
      selectedValue: value1,
      onChange: onChange1,
    },
    {
      id: 'contains',
      name: 'contains',
      render: 'selection',
      list: [
        {
          id: 'file',
          name: 'file',
          render: 'dropdown',
          showSearchInColumn: true,
          renderChild: {
            id: 'value',
            title: 'search_in_column',
            render: 'dropdown',
            list: columnList,
            selectedValue: value3,
            onChange: onChange3,
          },
          title: 'file',
          list: fileList,
          selectedValue: value1,
          onChange: onChange1,
        },
      ],
      selectedValue: value2,
      onChange: onChange2,
    },
    {
      id: 'upperCaseAll',
      name: 'upperCaseAll',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
    },
  ]
}

export const calcOperatorList = [
  { id: 'select', name: 'select' },
  { id: '+', name: '+' },
  { id: '-', name: '-' },
  { id: '*', name: '*' },
  { id: '/', name: '/' },
]

import { APP_STATE } from '../../../constants'
import {
  RULE_RECEIVED,
  RULE_UPDATED,
  RULE_IS_CLONED,
  RULE_FILES_RECEIVED,
} from './actions'

export const initialState = {
  stateOfAction: APP_STATE.FETCHING,
  errorMsg: '',
  rule: {},
  inputCols: [],
  outputCols: [],
  files: [],
  isDuplicateOrNew: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATE.FETCHING:
      return {
        ...state,
        stateOfAction: APP_STATE.FETCHING,
      }

    case APP_STATE.ERROR:
      return {
        ...state,
        errorMsg: action.msg,
        stateOfAction: APP_STATE.ERROR,
      }

    case RULE_RECEIVED:
      return {
        ...state,
        rule: action.data,
        inputCols: action.inputCols,
        outputCols: action.outputCols,
        files: action.files,
        isDuplicateOrNew: action.isDuplicateOrNew,
        stateOfAction: APP_STATE.DONE,
      }

    case RULE_FILES_RECEIVED:
      return {
        ...state,
        files: action.files,
      }

    case RULE_IS_CLONED:
      return {
        ...state,
        isDuplicateOrNew: action.isDuplicateOrNew,
        stateOfAction: APP_STATE.DONE,
      }

    case RULE_UPDATED:
      return {
        ...state,
        rule: action.data,
        stateOfAction: APP_STATE.DONE,
      }

    default:
      return state
  }
}

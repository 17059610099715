import { getConditionTree } from '../../../constants/alertConditionList'

export default (alert, setAlert, incomingList, outgoingList) => {
  const getConditionList = getConditionTree(incomingList, outgoingList)

  const updateConditionOperator = (index, operator) => {
    let condition = {
      type: operator,
    }
    switch (operator) {
      case 'incomingFailed':
      case 'outgoingFailed':
        condition.options = {}
        break
      case 'masterSizeChanged':
        condition.options = {
          threshold: 10,
          span: '3d',
        }
        break
      case 'incomingSizeChanged':
        condition.options = {
          threshold: 10,
          span: '3d',
          incomingIds: undefined,
        }
        break
      case 'outgoingSizeChanged':
        condition.options = {
          threshold: 10,
          span: '3d',
          outgoingIds: undefined,
        }
        break
      case 'notRun': {
        condition.options = {
          ...condition.options,
          span: '3d',
        }
        break
      }
      default:
        break
    }

    alert.conditions[index] = condition
    setAlert({ ...alert })
  }

  const updateConditionValue = (index, value) => {
    const operatorType = alert.conditions[index].type

    switch (operatorType) {
      case 'incomingFailed':
        alert.conditions[index].options.incomingIds = value
        break
      case 'outgoingFailed':
        alert.conditions[index].options.outgoingIds = value
        break
      // check how threshold should work
      case 'outgoingSizeChanged':
      case 'incomingSizeChanged':
      case 'masterSizeChanged':
      case 'notRun': {
        alert.conditions[index].options.span = `${value}d`
        break
      }
      default:
        break
    }
    setAlert({ ...alert })
  }

  // This is named weirdly but is actually the update of an inner element
  // type contains the event, id is the id of the
  const updateConditionType = (index, event, id) => {
    const condition = alert.conditions[index]
    const { options } = condition
    const value = event.target ? event.target.value : event

    if (id === 'threshold') options.threshold = value

    if (id === 'incomingIds') options.incomingIds = value && value.id
    if (id === 'outgoingIds') options.outgoingIds = value && value.id
    alert.conditions[index].options = options
    setAlert({ ...alert })
  }

  const addNewCondition = () => {
    alert.conditions.push({
      type: undefined,
    })
    setAlert({ ...alert })
  }

  const removeCondition = (index) => {
    alert.conditions.splice(index, 1)

    if (alert.conditions.length) {
      setAlert({ ...alert })
    } else {
      addNewCondition()
    }
  }

  const getConditionValue = (type, options) => {
    switch (type) {
      case 'outgoingFailed':
        return options.outgoingIds
      case 'incomingFailed':
        return options.incomingIds
      case 'incomingSizeChanged':
      case 'outgoingSizeChanged':
      case 'masterSizeChanged':
      case 'notRun': {
        return parseInt(options.span)
      }
      default:
        return undefined
    }
  }

  const getConditionChildValue = (type, options) => (name) => {
    return options && options[name]
  }

  return {
    getConditionList,
    updateConditionOperator,
    updateConditionValue,
    updateConditionType,
    addNewCondition,
    removeCondition,
    getConditionValue,
    getConditionChildValue,
  }
}

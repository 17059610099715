export const getActionTree = (userList, arrayOfActions) => {
  const { value: value1, function: onChange1 } = arrayOfActions[0]

  return [
    {
      id: 'skipPublishing',
      name: 'skipPublishing',
      render: undefined,
      selectedValue: value1,
      onChange: onChange1,
      onFilter: alert => {
        const type = alert.conditions[0].type
        return ![
          'notRun',
          'incomingFailed',
          'outgoingFailed',
          'masterFailed',
        ].includes(type)
      },
    },
    {
      id: 'sendEmail',
      name: 'sendEmail',
      render: 'dropdown',
      title: 'User',
      list: userList,
      selectedValue: value1,
      onChange: onChange1,
    },
  ]
}

import { toast } from 'react-toastify'

import { fetchingData, fetchingFailed } from '../../App/actions'
import toastDetailErrorMessage from '../../../lib/toastDetailErrorMessage'
import api from '../../../lib/api'

export const INPUT_EXCLUSIONS_RECEIVED = 'INPUT_EXCLUSIONS_RECEIVED'
export const OUTPUT_EXCLUSIONS_RECEIVED = 'OUTPUT_EXCLUSIONS_RECEIVED'
export const OUTGOING_CHANGED = 'OUTGOING_CHANGED'

const getMasterExclusionUrl = (feedId, exclusionId) =>
  `/feeds/${feedId}/master/exclusions/${exclusionId}`
const getOutgoingExclusionUrl = (feedId, outgoingId, exclusionId) =>
  `/feeds/${feedId}/outgoing/${outgoingId}/exclusions/${exclusionId}`

export const receivedInputExclusions = data => ({
  type: INPUT_EXCLUSIONS_RECEIVED,
  data,
})

export const receivedOutputExclusions = data => ({
  type: OUTPUT_EXCLUSIONS_RECEIVED,
  data,
})

export const selectedOutgoingChanged = selectedOutgoing => ({
  type: OUTGOING_CHANGED,
  selectedOutgoing,
})

export const fetchInputExclusionList = (feedId, dispatch, t) => {
  dispatch(fetchingData())
  api
    .get(getMasterExclusionUrl(feedId, ''))
    .then(resp => dispatch(receivedInputExclusions(resp.data)))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

export const fetchOutputExclusionList = (feedId, outputId, dispatch, t) => {
  dispatch(fetchingData())
  api
    .get(getOutgoingExclusionUrl(feedId, outputId, ''))
    .then(resp => dispatch(receivedOutputExclusions(resp.data)))
    .catch(err => {
      dispatch(fetchingFailed(err.message))
      toast.error(t('loading_failed'))
    })
}

export const updatePosition = (
  feedId,
  exclusionId,
  newPosition,
  outgoingId,
  t
) =>
  api
    .patch(
      `${
        outgoingId
          ? getOutgoingExclusionUrl(feedId, outgoingId, exclusionId)
          : getMasterExclusionUrl(feedId, exclusionId)
      }/position`,
      {
        position: newPosition,
      }
    )
    .then(() => toast.success(t('position_update_success')))
    .catch(() => toast.error(t('position_update_failed')))

export const updateExclusion = (feedId, exclusionId, data, outgoingId, t) =>
  api
    .patch(
      outgoingId
        ? getOutgoingExclusionUrl(feedId, outgoingId, exclusionId)
        : getMasterExclusionUrl(feedId, exclusionId),
      data
    )
    .then(() => toast.success(t('exclusion_update_success')))
    .catch(error => toastDetailErrorMessage('exclusion_update_error', error, t))

export const removeExclusion = (feedId, exclusionId, outgoingId, t) =>
  api
    .delete(
      outgoingId
        ? getOutgoingExclusionUrl(feedId, outgoingId, exclusionId)
        : getMasterExclusionUrl(feedId, exclusionId)
    )
    .then(() => toast.success(t('exclusion_delete_success')))
    .catch(() => toast.error(t('exclusion_delete_error')))

import React from 'react'
import { string } from 'prop-types'

const FaOpenNewTab = ({ color = 'black' }) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M10 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-4m-8-2 8-8m0 0v5m0-5h-5"
    />
  </svg>
)

FaOpenNewTab.propTypes = {
  color: string,
}

export default FaOpenNewTab

import React from 'react'
import Papa from 'papaparse'

import { func, string, bool } from 'prop-types'

import { makeTableColumns, makeDataSource } from '../../../lib/sourceForm'

import ModalWindow from '../../shared/ModalWindow'
import Table from '../../shared/Table'

const PreviewModal = ({ onOk, title, content, t, isWide }) => {
  const parsedData = Papa.parse(content, { delimiter: '\t' }).data
  return (
    <ModalWindow
      onOk={onOk}
      title={title}
      okText={t('button_close')}
      visible={true}
      isWide={isWide}
      t={t}
    >
      <Table
        showPagination={false}
        columns={makeTableColumns(parsedData[0])}
        data={makeDataSource(parsedData)}
        scroll={{ x: true, y: true }}
        t={t}
      />
    </ModalWindow>
  )
}

PreviewModal.propTypes = {
  onOk: func.isRequired,
  title: string.isRequired,
  content: string.isRequired,
  isWide: bool,
}

export default PreviewModal

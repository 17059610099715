export default [
  { name: 'Australia', id: 'AU', 'country-code': '036' },
  { name: 'Austria', id: 'AT', 'country-code': '040' },
  { name: 'Belgium', id: 'BE', 'country-code': '056' },
  { name: 'Canada', id: 'CA', 'country-code': '124' },
  { name: 'Croatia', id: 'HR', 'country-code': '191' },
  { name: 'Cyprus', id: 'CY', 'country-code': '196' },
  { name: 'Czechia', id: 'CZ', 'country-code': '203' },
  { name: 'Denmark', id: 'DK', 'country-code': '208' },
  { name: 'Estonia', id: 'EE', 'country-code': '233' },
  { name: 'Finland', id: 'FI', 'country-code': '246' },
  { name: 'France', id: 'FR', 'country-code': '250' },
  { name: 'Germany', id: 'DE', 'country-code': '276' },
  { name: 'Greece', id: 'GR', 'country-code': '300' },
  { name: 'Hungary', id: 'HU', 'country-code': '348' },
  { name: 'Iceland', id: 'IS', 'country-code': '352' },
  { name: 'Ireland', id: 'IE', 'country-code': '372' },
  { name: 'Isle of Man', id: 'IM', 'country-code': '833' },
  { name: 'Israel', id: 'IL', 'country-code': '376' },
  { name: 'Italy', id: 'IT', 'country-code': '380' },
  { name: 'Japan', id: 'JP', 'country-code': '392' },
  { name: 'Latvia', id: 'LV', 'country-code': '428' },
  { name: 'Liechtenstein', id: 'LI', 'country-code': '438' },
  { name: 'Lithuania', id: 'LT', 'country-code': '440' },
  { name: 'Luxembourg', id: 'LU', 'country-code': '442' },
  { name: 'Monaco', id: 'MC', 'country-code': '492' },
  { name: 'Netherlands', id: 'NL', 'country-code': '528' },
  { name: 'New Zealand', id: 'NZ', 'country-code': '554' },
  { name: 'Norway', id: 'NO', 'country-code': '578' },
  { name: 'Poland', id: 'PL', 'country-code': '616' },
  { name: 'Portugal', id: 'PT', 'country-code': '620' },
  { name: 'Russian Federation', id: 'RU', 'country-code': '643' },
  { name: 'Serbia', id: 'RS', 'country-code': '688' },
  { name: 'Singapore', id: 'SG', 'country-code': '702' },
  { name: 'Slovakia', id: 'SK', 'country-code': '703' },
  { name: 'Slovenia', id: 'SI', 'country-code': '705' },
  { name: 'Spain', id: 'ES', 'country-code': '724' },
  { name: 'Sweden', id: 'SE', 'country-code': '752' },
  { name: 'Switzerland', id: 'CH', 'country-code': '756' },
  { name: 'Turkey', id: 'TR', 'country-code': '792' },
  {
    name: 'United Kingdom',
    id: 'GB',
    id3: 'GBR',
    'country-code': '826',
  },
  { name: 'USA', id: 'US', 'country-code': '840' },
]

import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const ENRICHMENT_LIST_DONE = 'ENRICHMENT_LIST_DONE'
export const ENRICHMENT_LIST_FAILED = 'ENRICHMENT_LIST_FETCH_ERROR'
export const ENRICHMENT_LIST_FETCHING = 'ENRICHMENT_LIST_FETCHING'
export const ENRICHMENT_HEADER_LOADED = 'ENRICHMENT_HEADER_LOADED'

export const OUTGOING_ENRICHMENT_LIST_DONE = 'OUTGOING_ENRICHMENT_LIST_DONE'
export const OUTGOING_ENRICHMENT_LIST_FAILED =
  'OUTGOING_ENRICHMENT_LIST_FETCH_ERROR'
export const OUTGOING_ENRICHMENT_LIST_FETCHING =
  'OUTGOING_ENRICHMENT_LIST_FETCHING'
export const OUTGOING_ENRICHMENT_HEADER_LOADED =
  'OUTGOING_ENRICHMENT_HEADER_LOADED'

export const SELECTED_INCOMING_DONE = 'SELECTED_INCOMING_DONE'

const getMasterEnrichmentUrl = feedId => `/feeds/${feedId}/master/enrichments`
const getOutgoingEnrichmentUrl = (feedId, sourceId) =>
  `/feeds/${feedId}/outgoing/${sourceId}/enrichments`
const getCreateFileUrl = orgId => `/orgs/${orgId}/files`
const getFileDataUrl = fileId => `/files/${fileId}/data`
const getFileHeaderUrl = fileId => `/files/${fileId}/headers`

export const requestEnrichments = () => ({
  type: ENRICHMENT_LIST_FETCHING,
})

export const receiveEnrichments = enrichments => ({
  type: ENRICHMENT_LIST_DONE,
  enrichments,
})

export const setEnrichmentHeader = (enrichment, headers) => ({
  type: ENRICHMENT_HEADER_LOADED,
  enrichment,
  headers,
})

export const fetchFailed = message => ({
  type: ENRICHMENT_LIST_FAILED,
  message,
})

const requestOutgoingEnrichments = () => ({
  type: OUTGOING_ENRICHMENT_LIST_FETCHING,
})

const receiveOutgoingEnrichments = enrichments => ({
  type: OUTGOING_ENRICHMENT_LIST_DONE,
  enrichments,
})

export const outgoingFetchFailed = message => ({
  type: OUTGOING_ENRICHMENT_LIST_FAILED,
  message,
})

export const setOutgoingEnrichmentHeader = (enrichment, headers) => ({
  type: OUTGOING_ENRICHMENT_HEADER_LOADED,
  enrichment,
  headers,
})

export const fetchIncomingEnrichments = (dispatch, feedId, incomingId, t) => {
  dispatch(requestEnrichments())

  return api
    .get(getMasterEnrichmentUrl(feedId))
    .then(({ data }) => {
      dispatch(receiveEnrichments(data))

      return data
    })
    .then(list => {
      // fetches all the headers of the enrichments and updates the entities - if it fails it doesn't matter
      list.forEach(enrichment =>
        getFileHeaders(enrichment.fileId).then(({ data }) =>
          dispatch(setEnrichmentHeader(enrichment, data))
        )
      )
    })
    .catch(err => {
      dispatch(fetchFailed(err.message))
      toast.error(`${t('enrichments_failed')}`)
      console.log(err)
    })
}

export const fetchOutgoingEnrichments = (dispatch, feedId, outgoingId, t) => {
  dispatch(requestOutgoingEnrichments())

  return api
    .get(getOutgoingEnrichmentUrl(feedId, outgoingId))
    .then(({ data }) => {
      dispatch(receiveOutgoingEnrichments(data))

      return data
    })
    .then(list => {
      // fetches all the headers of the enrichments and updates the entities - if it fails it doesn't matter
      list.forEach(enrichment => {
        getFileHeaders(enrichment.fileId).then(({ data }) =>
          dispatch(setOutgoingEnrichmentHeader(enrichment, data))
        )
      })
    })
    .catch(err => {
      dispatch(outgoingFetchFailed(err.message))
      toast.error(`${t('enrichments_failed')}`)
      console.log(err)
    })
}

export const getFileHeaders = fileId => api.get(getFileHeaderUrl(fileId))

export const changeEnrichmentPosition = (
  feedId,
  isOutgoing,
  enrichmentId,
  sourceId,
  position,
  t
) => {
  const getUrl = isOutgoing ? getOutgoingEnrichmentUrl : getMasterEnrichmentUrl
  const url = `${getUrl(feedId, sourceId)}/${enrichmentId}/position`

  return api
    .patch(url, { position })
    .then(() => {
      toast.success(t('position_update_success'))
    })
    .catch(err => {
      toast.error(t('position_update_failed'))
      console.log(err)
    })
}

export const removeEnrichment = (
  feedId,
  isOutgoing,
  sourceId,
  enrichmentId,
  t
) => {
  const getUrl = isOutgoing ? getOutgoingEnrichmentUrl : getMasterEnrichmentUrl
  const url = `${getUrl(feedId, sourceId)}/${enrichmentId}`

  return api
    .delete(url)
    .then(() => {
      toast.success(t('enrichments_delete_successful'))
    })
    .catch(err => {
      toast.error(t('enrichments_delete_unsuccessful'))
      console.log(err)
    })
}

export const pauseEnrichment = (
  feedId,
  isOutgoing,
  sourceId,
  enrichmentId,
  enabled,
  dispatch,
  t,
  enrichments
) => {
  const getUrl = isOutgoing ? getOutgoingEnrichmentUrl : getMasterEnrichmentUrl
  const url = `${getUrl(feedId, sourceId)}/${enrichmentId || ''}`

  return api
    .patch(url, { enabled })
    .then(({ data }) => {
      // update the stored enrichments
      const recieveMethod = isOutgoing
        ? receiveOutgoingEnrichments
        : receiveEnrichments
      dispatch(
        recieveMethod(
          enrichments.map(e => ({
            ...e,
            enabled: e.id === data.id ? data.enabled : e.enabled,
          }))
        )
      )
      toast.success(t('enrichments_update_successful'))
    })
    .catch(err => {
      toast.error(t('enrichments_update_unsuccessful'))
      console.log(err)
    })
}

export const updateEnrichment = (
  feedId,
  isOutgoing,
  sourceId,
  enrichmentId,
  name,
  fileId,
  data,
  t,
  isCreating = false
) => {
  const getUrl = isOutgoing ? getOutgoingEnrichmentUrl : getMasterEnrichmentUrl
  const url = `${getUrl(feedId, sourceId)}/${enrichmentId || ''}`

  return uploadOverrideFileContent(fileId, data)
    .then(() => {
      toast.success(t('system_file_upload_successfull'))
      const method = isCreating ? api.post : api.patch
      return method(url, {
        name,
        fileId,
        enabled: isCreating ? true : undefined,
      })
    })
    .catch(err => {
      toast.error(t('system_file_upload_unsuccessfull'))
      return Promise.reject()
    })
}

const uploadOverrideFileContent = (fileId, data) => {
  return api.post(getFileDataUrl(fileId), data, {
    headers: { 'Content-Type': 'text/csv' },
  })
}

export const createNewOverrideFile = (name, orgId, groupId) => {
  return api.post(getCreateFileUrl(orgId), {
    name,
    groupId,
    location: 'default',
    type: 'overrides',
  })
}

export const createEnrichment = (
  feedId,
  isOutgoing,
  sourceId,
  name,
  organisationId,
  groupId,
  data,
  t
) =>
  createNewOverrideFile(name, organisationId, groupId).then(({ data: file }) =>
    updateEnrichment(
      feedId,
      isOutgoing,
      sourceId,
      null,
      name,
      file.id,
      data,
      t,
      true
    )
  )

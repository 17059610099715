import { toast } from 'react-toastify'

import api from '../api'

const removeSelectedFeed = (feedId, t) =>
  api
    .delete(`/feeds/${feedId}`)
    .then(() => {
      toast.success(t('feed_removed_success'))
    })
    .catch(err => {
      toast.error(t('feed_removed_error'))
      console.log(err)
    })

export default removeSelectedFeed

import Cron from 'cron-converter'

import {
  DAYS_IN_WEEK,
  HOURS,
  DAYS_IN_MONTH,
  MONTHS_IN_YEAR,
} from '../constants'

const getCronValue = ({
  seconds = [0],
  hours = HOURS.map(hour => hour.id),
  daysInMonth = DAYS_IN_MONTH,
  monthsInYear = MONTHS_IN_YEAR,
  daysInWeek = DAYS_IN_WEEK.map(day => day.id),
}) => {
  const cronInstance = new Cron()

  return cronInstance
    .fromArray([seconds, hours, daysInMonth, monthsInYear, daysInWeek])
    .toString()
}

export default getCronValue

import React from 'react'
import { func, object, string } from 'prop-types'
import { Formik } from 'formik'
import { formValueObject } from '../../types'

const Form = ({
  content,
  onSubmit,
  initialValues,
  validationSchema,
  onChange = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnChange={false}
    validateOnBlur={false}
  >
    {({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleReset,
      setFieldValue,
    }) => (
      <form
        onSubmit={handleSubmit}
        onChange={event => {
          handleChange(event)
          onChange(event)
        }}
      >
        {content(errors, values, setFieldValue, handleReset)}
      </form>
    )}
  </Formik>
)

Form.propTypes = {
  content: func.isRequired,
  initialValues: formValueObject.isRequired,
  onSubmit: func.isRequired,
  validationSchema: object,
  width: string,
  onChange: func,
}

export default Form

import React from 'react'
import { Redirect } from '@reach/router'
import { bool, string } from 'prop-types'

const NoMatch = ({ to, isRelative = false, noThrow = false, uri }) => {
  if (uri == null) return null

  const cleanedURI = uri.slice(-1) === '/' ? uri.slice(0, uri.length - 1) : uri

  return (
    <Redirect
      noThrow={noThrow}
      from="/"
      to={isRelative === true ? `${cleanedURI}/${to}` : to}
    />
  )
}

NoMatch.propTypes = {
  to: string.isRequired,
  isRelative: bool,
  noThrow: bool,
  uri: string,
}

export default NoMatch

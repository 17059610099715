import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { ToastContainer } from 'react-toastify'
import { func, node, arrayOf, bool, any } from 'prop-types'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'

import {
  LAPTOP_WIDTH,
  WHITE,
  GREEN,
  RED,
  HEADER_HEIGHT,
  DOUBLE_HEADER_HEIGHT,
} from '../../constants'
import {
  feedShape,
  incomingCompactShape,
  outgoingShape,
  userShape,
} from '../../types'

import SideNavigation from './SideNavigation'
import Header from './Header'
import Announcements from './Announcements'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
`

const BodyWrapper = styled.div`
  margin-top: ${({ showFeedPanel }) =>
    showFeedPanel ? DOUBLE_HEADER_HEIGHT : HEADER_HEIGHT};
  display: flex;
  flex: 1;
  background: ${WHITE};
`

const ContentWrapper = styled.div`
  padding: 25px 40px;
  width: 80%;

  @media only screen and (max-width: ${LAPTOP_WIDTH}) {
    width: 100%;
  }
`

const ColumnFlex = styled.div`
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex: 1;
`

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
)

WrappedToastContainer.propTypes = {
  className: any,
}

const StyledWrappedToastContainer = styled(WrappedToastContainer).attrs({})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background: ${RED};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background: ${GREEN};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`

const Layout = ({
  changeFeed,
  changeOutgoing,
  changeIncoming,
  children,
  feeds,
  isUser,
  logoutUser,
  outgoing,
  incoming,
  selectedFeed,
  selectedOutgoing,
  selectedIncoming,
  user,
}) => {
  const { isAdmin } = user
  const { t } = useTranslation()

  return (
    <Location>
      {({ location: { pathname } }) => {
        const splitPathname = pathname.split('/')
        const isSystemPage = splitPathname[1] === 'system'
        const rootContextPath = isSystemPage
          ? splitPathname[1]
          : splitPathname[2]

        const showSideNav = !(
          splitPathname.length === 3 && rootContextPath === 'feeds'
        )
        return (
          <PageWrapper>
            <Header
              changeFeed={changeFeed}
              changeOutgoing={changeOutgoing}
              changeIncoming={changeIncoming}
              feeds={feeds}
              isAdmin={isAdmin}
              isUser={isUser}
              logoutUser={logoutUser}
              outgoing={outgoing}
              incoming={incoming}
              pathName={pathname}
              rootContextPath={rootContextPath}
              selectedFeed={selectedFeed}
              selectedOutgoing={selectedOutgoing}
              selectedIncoming={selectedIncoming}
              showFeedPanel={!['system', 'login'].includes(rootContextPath)}
              t={t}
              user={user}
            />
            <BodyWrapper
              isAdminPage={rootContextPath === 'admin'}
              showFeedPanel={!isSystemPage}
            >
              <ColumnFlex>
                <Announcements />

                <Content>
                  <StyledWrappedToastContainer
                    autoClose={25000}
                    closeOnClick={true}
                    hideProgressBar={true}
                    pauseOnHover={true}
                    position="bottom-right"
                  />

                  {isUser && (
                    <Fragment>
                      <SideNavigation
                        isAdmin={isAdmin}
                        rootContextPath={rootContextPath}
                        selectedFeed={selectedFeed}
                        show={showSideNav}
                      />
                      <ContentWrapper>{children}</ContentWrapper>
                    </Fragment>
                  )}
                  {!isUser && <Fragment>{children}</Fragment>}
                </Content>
              </ColumnFlex>
            </BodyWrapper>
            {isUser && <div id="sidePanel" />}
          </PageWrapper>
        )
      }}
    </Location>
  )
}

Layout.propTypes = {
  changeFeed: func,
  changeOutgoing: func,
  changeIncoming: func,
  children: node.isRequired,
  feeds: arrayOf(feedShape).isRequired,
  isUser: bool,
  logoutUser: func.isRequired,
  outgoing: arrayOf(outgoingShape),
  incoming: arrayOf(incomingCompactShape),
  selectedFeed: feedShape,
  selectedOutgoing: outgoingShape,
  selectedIncoming: incomingCompactShape,
  user: userShape,
}

export default Layout

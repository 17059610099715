import React from 'react'
import styled from 'styled-components'

import {
  LIGHT_GREY,
  LIGHT_ORANGE,
  MIDDLE_GREY,
  TEXT_SMALL,
} from '../../../constants'
import { progressBarShape } from '../../../types'

import Text from '../Text'

const StyledBar = styled.div`
  height: 10px;
  border-radius: 5px;
  width: ${({ progress }) =>
    progress || progress === 0 ? `${progress}%` : '100%'};
  background: ${({ color }) => color};
`

const ProgressBar = ({ progress: { percentage, label } }) => (
  <div>
    <Text color={MIDDLE_GREY} size={TEXT_SMALL}>
      {`${percentage}% ${label}`}
    </Text>
    <StyledBar color={LIGHT_GREY}>
      <StyledBar color={LIGHT_ORANGE} progress={percentage} />
    </StyledBar>
  </div>
)

ProgressBar.propTypes = {
  progress: progressBarShape.isRequired,
}

export default ProgressBar

import { toast } from 'react-toastify'

import api from '../../../lib/api'

export const INVITATIONS_FETCHING = 'INVITATIONS_FETCHING'
export const INVITATIONS_DONE = 'INVITATIONS_DONE'
export const INVITATIONS_FAILED = 'INVITATIONS_FAILED'

const requestInvitations = () => ({
  type: INVITATIONS_FETCHING,
})

const receiveInvitations = (invitationsList, pageTotal, page) => ({
  type: INVITATIONS_DONE,
  invitationsList,
  pageTotal,
  page,
})

const fetchFailed = message => ({
  type: INVITATIONS_FAILED,
  message,
})

export const fetchInvitations = (
  organisationId,
  dispatch,
  page = 0,
  t,
  filteredOrganisation
) => {
  dispatch(requestInvitations())

  const invitationsUrl = `/orgs/${filteredOrganisation ||
    organisationId}/invitations?page=${page + 1}&limit=10`

  api
    .get(invitationsUrl)
    .then(({ data, headers: { fm_pagination_total } }) => {
      const pageTotal = Math.ceil(fm_pagination_total / 10)
      dispatch(receiveInvitations(data, pageTotal, page))
    })
    .catch(err => {
      console.log(err)
      dispatch(fetchFailed(err.message))
      toast.error(t('job_detail_fetch_unsuccessful'))
    })
}

export const removeInvitation = (
  organisationId,
  invitationId,
  t,
  dispatch,
  page = 0
) =>
  api
    .delete(`/orgs/${organisationId}/invitations/${invitationId}`)
    .then(() => {
      toast.success(t('invitations_delete_successful'))
      fetchInvitations(organisationId, dispatch, page, t)
    })
    .catch(err => {
      toast.error(t('invitations_delete_unsuccessful'))
      console.log(err)
    })

import { ORANGE, WHITE, BLACK, RED, DARK_ORANGE } from './'

export const PRIMARY_BUTTON = {
  backgroundColor: ORANGE,
  textColor: WHITE,
}

export const SECONDARY_BUTTON = {
  backgroundColor: 'transparent',
  textColor: ORANGE,
  backgroundColorHover: ORANGE,
}

export const TRANSPARENT_BUTTON = {
  backgroundColor: 'transparent',
  textColor: BLACK,
}

export const RED_BUTTON = {
  backgroundColor: RED,
  textColor: WHITE,
  backgroundColorHover: RED,
}

export const LINK_BUTTON_SMALL = {
  backgroundColor: 'transparent',
  textColor: ORANGE,
  textColorHover: DARK_ORANGE,
  backgroundColorHover: 'transparent',
}

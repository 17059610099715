import React from 'react'
import { string } from 'prop-types'

const FaLongArrowDown = ({ color = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="100%"
    height="100%"
    viewBox="0 0 33.549 33.549"
  >
    <path
      d="M20.774,16.979H16.581V3h-2.8V16.979H9.591l5.591,5.591ZM4,25.366v2.8H26.366v-2.8Z"
      transform="translate(1.591 1.194)"
    />
  </svg>
)

FaLongArrowDown.propTypes = {
  color: string,
}

export default FaLongArrowDown

import React from 'react'
import { string, arrayOf } from 'prop-types'
import { Router } from '@reach/router'

import NoMatch from '../components/shared/NoMatch'
import AdminContent from '../components/AdminContent'
import Users from '../components/AdminContent/Users'
import Invitations from '../components/AdminContent/Invitations'
import FeedAudit from '../components/AdminContent/FeedAudit'
import Brands from '../components/AdminContent/Brands'
import AddNewUser from '../components/AdminContent/AddNewUser'
import Partners from '../components/shared/PartnerList'
import ErrorBoundary from '../components/shared/ErrorBoundary'
import { brandShape, feedShape } from '../types'

import useOrganisations from '../components/shared/system/useOrganisations'
import useBrands from '../components/shared/system/useBrands'

import { useTranslation } from 'react-i18next'

const AdminPage = ({ '*': contextRoute, selectedFeed }) => {
  const { selected } = useOrganisations()
  const { t } = useTranslation()
  const brands = useBrands()

  const organisationId = selected.id

  return (
    <ErrorBoundary>
      <Router primary={false}>
        <AdminContent
          brands={brands.list}
          path="/"
          t={t}
          organisationId={organisationId}
          selectedBrand={brands.selected}
          selectedFeed={selectedFeed}
        />
        <Users organisationId={organisationId} t={t} path="users" />
        <Brands organisationId={organisationId} t={t} path="brands" />
        <Invitations organisationId={organisationId} t={t} path="invitations" />
        <AddNewUser organisationId={organisationId} t={t} path="add-new-user" />
        <FeedAudit organisationId={organisationId} t={t} path="feed_audit" />
        <Partners
          organisationId={organisationId}
          t={t}
          path="partners"
          scope="own"
        />

        <NoMatch path="*" isRelative noThrow to="/" />
      </Router>
    </ErrorBoundary>
  )
}

AdminPage.propTypes = {
  '*': string,
  brands: arrayOf(brandShape),
  selectedFeed: feedShape,
}

export default AdminPage

export const apiUrl =
  window.urls.APP_API_URL ||
  process.env.REACT_APP_API_URL ||
  'http://localhost:4000'
export const loginRedirectUrl =
  window.urls.APP_LOGIN_REDIRECT_URL ||
  process.env.REACT_APP_LOGIN_REDIRECT_URL ||
  'http://localhost:8080/feeds'
export const downloadUrl =
  window.urls.APP_CDN_URL ||
  process.env.REACT_APP_CDN_URL ||
  'http://localhost:8081'
export const imagesUrl =
  (window.urls.APP_CDN_URL ||
    process.env.REACT_APP_CDN_URL ||
    'http://localhost:8081') + '/images'

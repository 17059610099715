import styled from 'styled-components'

import { DARK_GREY } from '../../../constants'

const Heading = styled.h1`
  font-size: 22px;
  color: ${DARK_GREY};
  margin: 10px 0 0;
`

export default Heading

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { string, func, object } from 'prop-types'
import { navigate, Router } from '@reach/router'

import { saveIncoming, getAllIncoming } from './actions'
import { APP_STATE } from '../../../constants'

import SourceForm from '../SourceForm'
import IncomingCards from './IncomingCards'
import { notCanceled, cancelable } from '../../../lib/signal'
import { incomingCompactShape } from '../../../types'

const Incoming = ({
  feedId,
  t,
  reloadCurrentFeed,
  location,
  changeIncoming,
  selectedIncoming,
  organisationId,
}) => {
  const [incoming, setIncoming] = useState([])
  const [state, setState] = useState(APP_STATE.FETCHING)

  const fetchIncoming = signal =>
    getAllIncoming(feedId, signal && signal.token)
      .then(data => {
        setIncoming(data)
        setState(APP_STATE.DONE)
      })
      .catch(
        notCanceled(err => {
          setState(APP_STATE.ERROR)
          console.log(err)
          toast.error(t('incomingLoadError'))
        })
      )

  useEffect(
    () => cancelable(fetchIncoming),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedId]
  )

  useEffect(
    () => {
      if (!selectedIncoming) return
      const newPath = location.href.replace(
        /incoming\/(.*)/,
        `incoming/${selectedIncoming.id}`
      )
      if (location.href !== newPath) navigate(newPath)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIncoming]
  )

  const onSave = (
    connection,
    format,
    values,
    incomingId,
    dispatch,
    compression,
    merge
  ) => {
    const sourceData = {
      name: values.name,
      connection,
      format,
      compression,
    }
    if (merge && merge.type) sourceData.merge = merge
    saveIncoming(dispatch, feedId, incomingId, sourceData, t)
      .then(reloadCurrentFeed)
      .finally(fetchIncoming)
  }

  // const pauseAllIncoming = () => alert('todo')

  const jobNotifications = []

  return (
    <Router primary={false}>
      <SourceForm
        description={t('incoming_description')}
        heading={t('incoming')}
        onCancel={() => navigate('./')}
        onSave={onSave}
        feedId={feedId}
        organisationId={organisationId}
        change={changeIncoming}
        path=":resourceId"
        type="incoming"
        incoming={incoming}
      />
      <IncomingCards
        feedId={feedId}
        // onPauseAll={() => pauseAllIncoming(feedId, incoming)}
        incomingListState={state}
        incoming={incoming.map(i => ({
          ...i,
          detailUrl: `/${organisationId}/feeds/${feedId}/incoming/${i.id}`,
          key: i.id,
        }))}
        notifications={jobNotifications}
        organisationId={organisationId}
        path="/"
        t={t}
      />
    </Router>
  )
}

Incoming.propTypes = {
  feedId: string.isRequired,
  reloadCurrentFeed: func.isRequired,
  organisationId: string.isRequired,
  selectedIncoming: incomingCompactShape,
  location: object,
  changeIncoming: func.isRequired,
}

export default Incoming

import React, { useEffect } from 'react'
import styled from 'styled-components'

import useAnnoucements from '../../hooks/Announcements'

import { DARK_GREY, WHITE, TEXT_SMALL } from '../../constants'

const NotificationBar = styled.div`
  padding: 4px;
  color: ${WHITE};
  width: 100%;
  font-size: ${TEXT_SMALL};
  background-color: ${DARK_GREY};
  text-align: center;
`

const AnnouncementBar = () => {
  const { text, fetch } = useAnnoucements()

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return text && <NotificationBar>{text}</NotificationBar>
}

export default AnnouncementBar

import React from 'react'
import { string } from 'prop-types'

const FaQuestion = ({ color = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="100%"
    height="100%"
    viewBox="0 0 559.393 559.393"
  >
    <g>
      <path
        d="M324.45,502.477c0-7.752-1.431-15.096-4.284-22.029c-2.856-6.938-6.938-12.954-12.24-18.057
     c-5.304-5.1-11.424-9.18-18.36-12.237c-6.936-3.063-14.279-4.593-22.031-4.593s-15.096,1.53-22.032,4.593
     c-6.936,3.06-12.954,7.14-18.054,12.237c-5.1,5.103-9.078,11.118-11.934,18.057c-2.856,6.936-4.284,14.277-4.284,22.029
     c0,7.753,1.428,15.099,4.284,22.032c2.856,6.938,6.834,12.954,11.934,18.055c5.1,5.102,11.118,9.182,18.054,12.239
     c6.936,3.063,14.28,4.59,22.032,4.59s15.096-1.527,22.031-4.59c6.938-3.06,13.059-7.14,18.36-12.239
     c5.304-5.101,9.384-11.116,12.24-18.055C323.021,517.573,324.45,510.229,324.45,502.477z M271.818,420.469h-4.896
     c-13.056-1.632-23.766-7.548-32.13-17.748c-8.364-10.197-11.934-21.828-10.71-34.884c2.448-22.438,8.058-44.166,16.83-65.178
     c8.772-21.013,20.094-39.679,33.966-55.999c4.488-5.712,9.383-10.914,14.687-15.606c5.307-4.692,10.608-9.282,15.912-13.77
     c13.464-11.424,23.769-21.624,30.906-30.6s10.71-19.992,10.71-33.048c0-11.424-2.346-20.808-7.038-28.152
     c-4.689-7.344-10.403-13.158-17.136-17.442c-6.733-4.284-13.771-7.344-21.114-9.18c-7.344-1.836-13.872-2.754-19.584-2.754h-1.224
     c-11.424,0-20.808,1.53-28.152,4.59s-13.566,7.344-18.666,12.852c-5.1,5.508-9.384,12.138-12.852,19.89
     s-7.038,16.116-10.71,25.092c-4.896,12.648-10.812,21.522-17.748,26.622s-13.872,8.058-20.808,8.874
     c-7.752,1.632-15.708,1.02-23.868-1.836c-8.16-3.264-14.688-7.956-19.584-14.076c-4.488-5.304-7.956-12.138-10.404-20.502
     c-2.448-8.364-1.632-18.666,2.448-30.906c2.448-7.752,6.732-18.87,12.852-33.354s15.402-28.662,27.846-42.534
     s28.458-25.908,48.042-36.108s44.064-15.096,73.439-14.688c19.584,0.408,38.862,3.978,57.834,10.71s36.006,16.626,51.102,29.682
     c15.099,13.056,27.336,29.07,36.723,48.042c9.384,18.972,14.073,40.698,14.073,65.178c0,17.952-2.445,33.864-7.344,47.736
     c-4.896,13.872-11.118,26.316-18.666,37.332s-15.708,20.604-24.479,28.764c-8.772,8.16-16.83,15.504-24.174,22.033
     c-4.08,3.672-7.956,7.038-11.631,10.098c-3.672,3.063-6.525,6.021-8.565,8.874c-7.344,8.979-13.566,19.482-18.666,31.521
     c-5.103,12.036-8.262,24.582-9.486,37.638c-1.224,12.648-6.426,22.95-15.604,30.906
     C294.767,416.491,284.058,420.469,271.818,420.469z"
      />
    </g>
  </svg>
)

FaQuestion.propTypes = {
  color: string,
}

export default FaQuestion
